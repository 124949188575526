<template>
    <div class="main">
        <BannerCustom title=""/>
        <div class="green-certificate-generating">
            <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/success.svg" alt="" />
            <div class="title title-success">{{ getTranslatedText('Payment Successful!') }}</div>
            <div class="sub-title">{{ getTranslatedText('Generating the Green Certificate. Please wait.') }}</div>
            <span class="loader"></span>
        </div>
    </div>
</template>
<script>
import ButtonCustom from "@/components/common/button";
import BannerCustom from "@/components/common/banner";
import LanguageMixin from "@/mixins/language/language-mixin";
import gameService from "@/services/game.service";
import authService from "@/services/auth.service";
import paymentServive from '@/services/payment.servive';
import { isArrayEmpty } from "@/components/common/utils";

const TIME_PER_GEN = process.env.VUE_APP_TIME_PER_GEN_CERTIFICATE;
const TIME_TOTAL_GEN = process.env.VUE_APP_TIME_TOTAL_GEN_CERTIFICATE;

export default {
    mixins: [LanguageMixin],
    components: { ButtonCustom, BannerCustom },  

    data() {
        return {
            timeTotalGen: TIME_TOTAL_GEN,
            timePerGen: TIME_PER_GEN,
            countDown: TIME_TOTAL_GEN,
            reference: '',
            code: [],
            paramCheckout: null
        };
    },

    created() {
        this.dataPayment = JSON.parse(localStorage.getItem("totalAmountPayment"));
        if (this.dataPayment && this.dataPayment.type === 'add-credit') {
            this.$store.dispatch("setBalance");
            this.$router.push("/payment/successful")
            localStorage.removeItem("totalAmountPayment")
        } else {
            const paramCheckout = JSON.parse(localStorage.getItem("cart_checkout"));
            if (!paramCheckout) {
                this.$router.push("/numbers")
            } 
            this.getTransaction(paramCheckout)
        }
    },

    methods: {
        countDownTimer() {
            if (this.countDown <= this.timeTotalGen && this.countDown > 0) {
                this.createCertificate()
                setTimeout(() => {
                    this.countDown -= this.timePerGen
                    this.countDownTimer()
                }, this.timePerGen)
            }
        },
        async getTransaction(paramCheckout) {
            try {
                const resp = await gameService.transact(paramCheckout);
                if (resp.status === 200) {
                    this.reference = resp.data.reference
                    this.getReference(resp.data.reference)
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async getReference(reference) {
            try {
                const resp = await gameService.getReference(reference);
                if (resp.status === 200) {
                    resp.data.transactions.map((item, index) => {
                        this.code.push(item.raffle_id)
                    })
                }
                if(!isArrayEmpty(this.code)) {
                    this.countDownTimer()
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        async createCertificate() {
            try {
                const resp = await paymentServive.createCertificate(this.code);
                if (resp.status === 200 && resp.data.status) {
                    this.countDown = 0
                    await authService.removeListCart();
                    localStorage.removeItem("list_block");
                    localStorage.removeItem("list_block_tmp");
                    localStorage.removeItem("cart_checkout");
                    localStorage.removeItem("total_items");
                    localStorage.removeItem("referencePayment");
                    localStorage.removeItem("totalAmountPayment");
                    this.$store.dispatch("onChangeAnountCart", 0);
                    this.listCheckouts = [];
                    this.$store.dispatch("setBalance");
                    this.$router.push(`/thank-you?reference=${this.reference}`);
                    window.performance.navigation.TYPE_RELOADED = true
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/green-certificate-generating/_generate.scss"
/>