<template>
    <div class="card-deail">
        <div class="card-deail__title">
            <ButtonCustom
                type="go-back"
                text="card details"
                :isGoBackHistory="false"
                @onClickButton="$emit('onClickGoBack')"
            />
            <div class="card-deail__buttons">
                <ButtonCustom
                    typeBtn="primary"
                    type="primary"
                    icon="arrow-right.png"
                    :textVariable="`${getTranslatedText(
                        'pay [CHECKOUT_PRICE] OMR',
                        {
                            checkout_price: formatNumberWinners(totalAmount),
                        }
                    )}`"
                    :isLoading="isLoadingBtn"
                    @onClickButton="onSubmitForm"
                />
            </div>
        </div>
        <div class="card-deail__container">
            <div class="box-text-warrning" v-if="isShowWarning">
                <img loading="lazy" src="@/assets/images/toast/error.png"/>
                <p class="text-warning" v-html="`${getTranslatedText(`Please click <a href='/personal-detail'>here</a> update your phone number before payment`)}`"></p>
            </div>
        </div>
        <FormCommon
            class="card-deail__container" 
            :formItem="formItem"
            :dataForm="dataForm"
            @update-form-data="updateFormData"
            @onSubmitForm="onSubmitForm"
        >
            <template slot="cardNumber">
                <b-form-group
                    :label="getTranslatedText('card number')"
                    label-for="input-number"
                >
                    <b-form-input
                        required
                        id="input-number"
                        placeholder="0000 0000 0000 0000"
                        v-model="dataForm.card_number"
                        :formatter="formatterCardNumber"
                        @keydown.native="checkDigit"
                    ></b-form-input>
                </b-form-group>
            </template>
            <div class="card-detail__form-cvv" slot="expirationDate">
                <b-form-group
                    :label="getTranslatedText('Expiration Date')"
                    label-for="input-expiration"
                >
                    <b-form-input
                        required
                        id="input-expiration"
                        :placeholder="$store.state.isRtl ? 'شهر / يوم / سنة' : 'MM/YY'" 
                        v-model="dataForm.expiry_date"
                        :formatter="formatterExpirationDate"
                        @keydown.native="checkDigit"
                        :state="stateExpirationDate"
                    ></b-form-input>
                    <p
                        class="card-detail__error-expiration-date"
                        v-if="errorMessage !== '' && !stateExpirationDate"
                    >
                        {{ errorMessage }}
                    </p>
                </b-form-group>
                <b-form-group :label="getTranslatedText('Cvv')" label-for="input-card-cvv">
                    <b-form-input
                        required
                        :state="stateCvv"
                        id="input-card-cvv"
                        :placeholder="getTranslatedText('Cvv')"
                        type="password"
                        v-model="dataForm.cvv"
                        @keydown.native="checkDigit"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-card-cvv">
                        {{ getTranslatedText("enter 3 numbers") }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </div>
            <hr slot="lineForm">
        </FormCommon>
        <div class="card-deail__container flex-end-md">
            <div class="card-deail__buttons" slot="button">
                <ButtonCustom
                    typeBtn="primary"
                    type="primary"
                    icon="arrow-right.png"
                    :textVariable="`${getTranslatedText(
                        'pay [CHECKOUT_PRICE] OMR',
                        {
                            checkout_price: formatNumberWinners(totalAmount),
                        }
                    )}`"
                    :isLoading="isLoadingBtn"
                    @onClickButton="onSubmitForm"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    checkDigitCommon,
    formatNumberMoney,
    isStrEmpty,
} from "@/components/common/utils";
import { getUserInfo } from '@/services/axios';

import PaymentService from "@/services/payment.servive";
import countriesService from '@/services/countries.service';

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";

import LanguageMixin from "@/mixins/language/language-mixin";


const ENABLE_MANAGE_CREDIT_CARDS = process.env.VUE_APP_ENABLE_MANAGE_CREDIT_CARDS;

export default {
    mixins: [LanguageMixin],
    props: {
        totalAmount: {
            type: Number,
            default: 0,
        },
        paramCheckout: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isRecurring: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormCommon,
        ButtonCustom,
    },
    data() {
        return {
            errorMessage: "",
            isShowWarning: false,
            stateExpirationDate: null,
            stateCvv: null,
            isLoadingBtn: false,
            dataForm: {
                card_number: "",
                expiry_date: "",
                cvv: "",
            },
            formItem: [
                {
                    type: "slot",
                    nameSlot: "cardNumber",
                },
                {
                    type: "slot",
                    nameSlot: "expirationDate",
                },
                {
                    type: "confirm",
                    name: "isSave",
                    id: "input-isSave",
                    text: "save this card for future payments",
                    isDisable: false,
                },
            ],
        };
    },
    created() {
        if(ENABLE_MANAGE_CREDIT_CARDS === 'no') {
            this.getUserData()
        }
    },
    methods: {
        getUserData() {
            let user = JSON.parse(getUserInfo())
            this.formItem = [
                 {
                    type: "input",
                    typeInput: "text",
                    placeholder: "John Anderson",
                    name: "card_holder_name",
                    label: "Card holder name",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "slot",
                    nameSlot: "cardNumber",
                },
                                {
                    type: "slot",
                    nameSlot: "expirationDate",
                },
                {
                    type: "slot",
                    nameSlot: "lineForm",
                },
                {
                    type: "select",
                    label: "Country",
                    name: "country_code",
                    for: "input-country",
                    id: "input-country",
                    idGroup: "input-group-country",
                    options: [],
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Address",
                    name: "address",
                    label: "address",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "City",
                    name: "city",
                    label: "city",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "State",
                    name: "state",
                    label: "state",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "zip",
                    name: "zip",
                    label: "zip",
                    for: "input-name",
                    id: "input-name",
                },
            ]
            this.dataForm = {
                card_number: "",
                expiry_date: "",
                cvv: "",
                address: user?.address,
                city: user?.city,
                state: user?.state,
                zip: user?.zipcode,
                country_code: user?.country_code,
                card_holder_name: localStorage.getItem('cardHolderName') || user?.name
            }
            // this.isShowWarning = !isStrEmpty(user.phone) ? false : true
            this.isShowWarning = false;
            this.fetchDateCountries()
        },

        async fetchDateCountries() {
            try {
                const resp = await countriesService.getCountries();
                if (resp.status === 200) {
                    const countries = resp.data.map(({ code, name }) => ({
                        value: code,
                        text: name,
                    })); 
                    this.formItem[4].options = countries;
                }
            } catch (error) {
                console.log(error);
            }
        },

        onClickCancel() {
            console.log("onClickCancel");
        },
        async onSubmitForm() {
            if (this.stateExpirationDate === false || this.isShowWarning) {
                return false;
            }
            
            this.isLoadingBtn = true;
            let dataForm = this.dataForm;

             if (isStrEmpty(this.dataForm.card_holder_name)) {
                this.$emit("showToast", this.getTranslatedText('the name field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }


            if (isStrEmpty(this.dataForm.card_number)) {
                this.$emit("showToast", this.getTranslatedText('the card number field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }

            if(isStrEmpty(this.dataForm.address) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
                this.$emit("showToast", this.getTranslatedText('the address field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }

            if(isStrEmpty(this.dataForm.city) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
                this.$emit("showToast", this.getTranslatedText('the city field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }

            if(isStrEmpty(this.dataForm.state) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
                this.$emit("showToast", this.getTranslatedText('the state field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }

            if(isStrEmpty(this.dataForm.zip) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
                this.$emit("showToast", this.getTranslatedText('the zip field is required'), "error");
                 this.isLoadingBtn = false;
                return false
            }

            if (isStrEmpty(dataForm.expiry_date)) {
                this.errorMessage = this.getTranslatedText(
                    "Please enter an Expiration Date greater than current"
                );
                this.stateExpirationDate = false;
                this.isLoadingBtn = false;
                return false;
            }

            if (dataForm.cvv.length < 3) {
                this.stateCvv = false;
                this.isLoadingBtn = false;
                return false;
            }

            if (this.isRecurring && !dataForm.isSave && ENABLE_MANAGE_CREDIT_CARDS !== 'no') {
                this.$emit(
                    "showToast",
                    this.getTranslatedText(
                        "please check save card or remove recurring ticket"
                    ),
                    "error"
                );

                this.isLoadingBtn = false;
                return false;
            }

            let { card_number, expiry_date, cvv, address, city, state, zip, country_code, card_holder_name} = this.dataForm
            this.stateCvv = null;


            let expiry_month = expiry_date.split('/')[0]
            let expiry_year = `${expiry_date.split('/')[1]}`

            let param = {
                card_number: card_number.replace(/\s/g, ''),
                expiry_month,
                expiry_year,
                card_security_code: cvv,
                amount: parseFloat(this.totalAmount.toString().replace(",", "")).toString(),
                address,
                city,
                state,
                zipcode: zip,
                country_code,
                card_holder_name
            };

            try {
                const res = await PaymentService.purchasePaypound(param);

                if (res.status === 200) {
                    let paramCheckout = JSON.stringify(this.paramCheckout);

                    if (!isStrEmpty(res.data["redirect_3ds_url"])) {
                        localStorage.setItem("methodPayment", 2);
                        localStorage.setItem("cardHolderName", card_holder_name);
                        localStorage.setItem("cart_checkout", paramCheckout);
                        window.location.href = res.data["redirect_3ds_url"];
                    } else if (res.data.status === "success") {
                        localStorage.setItem("methodPayment", 2);
                        localStorage.setItem("cardHolderName", card_holder_name);
                        localStorage.setItem("cart_checkout", paramCheckout);
                        this.$router.push("/payment/successful");
                    } else if (res.data.status === "pending") {
                        localStorage.setItem("methodPayment", 2);
                        localStorage.setItem("cardHolderName", card_holder_name);
                        this.$router.push("/payment/pending");
                    } else {
                        this.$emit(
                            "showToast",
                            res.data.message,
                            "error"
                        );
                        this.isLoadingBtn = false;
                    }
                } else {
                    this.isLoadingBtn = false;
                    this.$emit("showToast", res.message, "error");
                }
            } catch (error) {
                this.isLoadingBtn = false;
            }
        },
        updateFormData(item) {
            let { name, value } = item;

            this.dataForm[name] = value;
        },
        formatterCardNumber(value) {
            let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
            let matches = v.match(/\d{4,16}/g);
            let match = (matches && matches[0]) || "";
            let parts = [];
            for (let i = 0, len = match.length; i < len; i += 4) {
                parts.push(match.substring(i, i + 4));
            }
            if (parts.length) {
                return parts.join(" ");
            } else {
                return value;
            }
        },
        formatterExpirationDate(value) {
            const mm = value.replace(/\//g, "").substring(0, 2);
            const yy = value.replace(/\//g, "").substring(2, 4);

            const expDateFormatter =
                value.replace(/\//g, "").substring(0, 2) +
                (value.length > 2 ? "/" : "") +
                value.replace(/\//g, "").substring(2, 4);
            this.validateExpirationDate(mm, yy);
            return expDateFormatter;
        },

        validateExpirationDate(month, year) {
            const today = new Date();
            let mm = today.getMonth() + 1;
            let yy = today.getFullYear();

            if (mm < 10) mm = "0" + mm;

            yy = String(yy).substring(2);

            if (
                (Number(month) < Number(mm) && Number(year) === Number(yy)) ||
                Number(month) > 12 ||
                Number(year) < Number(yy)
            ) {
                this.errorMessage = this.getTranslatedText(
                    "Please enter an Expiration Date greater than current"
                );
                this.stateExpirationDate = false;
            } else {
                this.errorMessage = "";
                this.stateExpirationDate = null;
            }
        },

        formatNumberWinners(price) {
            return formatNumberMoney(Number(price));
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/card/card-detail.scss" />
