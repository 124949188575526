
export default {
    data() {
        return {};
    },

    methods: {
        routerPush(path) {
            if(window.location.pathname !== path) {
                this.$router.push(path);
            }
        },
    },
};
