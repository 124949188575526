<template>
    <div class="draws">
        <div class="draws__head">
            <div class="left">
                <h1 class="title">{{ getTranslatedText("PAST DRAW RESULTS") }}</h1>
                <div v-if="isScreenMobile" class="line"></div>
                <p class="sub-title">{{ getTranslatedText("Did you win? Check Your numbers Now!") }}</p>
            </div>
            <div class="right">
                <ButtonCustom
                    text="JOIN NOW"
                    typeBtn="primary"
                    type="primary"
                    classBtn="btn-participate"
                    @onClickButton="onClickBuyNow"
                />
            </div>
        </div>
        <div class="loading-full-page" v-if="isLoadingPage">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <div v-else>
            <div class="draws__contents">
                <div class="past-draw" v-for="(item, index) in listPastDraws" :key="index">
                    <p class="title">{{ getTranslatedText('DRAW RESULTS') }}</p>
                    <div class="date">{{ getDrawDate(item.draw_date) }}</div>
                    <div class="group">
                        <div class="gc-id">{{ getTranslatedText('WINNING GREEN CERTIFICATE ID') }}</div>
                        <div class="value-gc">{{ item.raffle_winning_code.slice(0, 4) + ' ' + item.raffle_winning_code.slice(4, 8) }}</div>
                        <div class="gc-id winning">{{ minimumBall + getTranslatedText(' WINNING NUMBERS') }}</div>
                        <div class="numbers" v-for="(data, i) in item.results" :key="i">
                            <div class="number" :class="value <= maxiumBlue ? 'green-ball' : 'blue-ball'" 
                                v-for="(value, idx) in data" :key="idx">
                                <div class="text">{{ value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="draws__pagination">
                <b-pagination
                    :total-rows="totalDraws"
                    v-model="page"
                    :per-page="perPageDraws"
                    @input="onChangePagination"
                    pills
                    align="center"
                    hide-goto-end-buttons
                    prev-text="Back"
                    next-text="Next"
                ></b-pagination>
                <div class="title">
                    {{ page + '/' + totalPage + ' ' + getTranslatedText('of') + ' ' + totalDraws}}
                    <span>{{ totalDraws > 1 ? getTranslatedText('entries') : getTranslatedText('entry') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { isArrayEmpty } from "@/components/common/utils";

import moment from "moment";

import DrawsService from "@/services/draws.service";
import toolService from '@/services/tool.service';
import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";

import ButtonCustom from "@/components/common/button";
import NoEmpty from "@/components/common/no-empty";
import { redirectPathApp } from "@/components/common/utils"

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;


export default {
    mixins: [languageMixin, RouterMixin],
    components: { ButtonCustom, NoEmpty },
    data() {
        return {
            listPastDraws: [],
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            maxiumBlue: 0,
            maxiumGreen: 0,
            minimumBall: 0,
            totalDraws: 1,
            isLoadingPage: true,
            perPageDraws: 6,
            page: 1,
            totalPage: 1,
            screenWidth: window.innerWidth,
            isScreenMobile: false,
        };
    },
    // metaInfo() {
    //     return {
    //         title: "O! Millionaire Draws: Guaranteed Weekly AED 100,000 Raffle Winner",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Join OMillionaire's weekly draws for a chance at AED 100,000 & more! View past results and raffle winners. Make a difference with every Green Certificate."
    //             }
    //         ]
    //     };
    // },
    watch: {
        screenWidth(value) {
            if (value <= 991) {
                this.isScreenMobile = true
                this.perPageDraws = 2
            }
            else {
                this.isScreenMobile = false
                this.perPageDraws = 6
            }
        },
    },
    created() {
        if (this.screenWidth <= 991) {
                this.isScreenMobile = true
                this.perPageDraws = 2
            }
        else {
            this.isScreenMobile = false
            this.perPageDraws = 6
        }
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.fetchDataValidateBall();
        this.getListDraws();
    },
    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        onClickBuyNow() {
            if(this.$store.state.is_app){
                redirectPathApp('/buy/green-certificates');
            } else {
                this.routerPush('/buy/green-certificates');
            }
        },

        getDrawDate(date) {
            return moment(date).format('MMMM DD, YYYY')
        },

        onChangePagination(value) {
            this.page = value
            this.totalPage = Math.ceil(this.totalDraws / this.perPageDraws)
            this.getListDraws(true);
        },

        async fetchDataValidateBall() {
            try {
                const resp = await toolService.getValidateTicket();
                this.maxiumBlue = resp.maxiumBlue;
                this.maxiumGreen = resp.maxiumGreen;
                this.minimumBall = resp.minMaxTickets[0];

            } catch (err) {
                console.log(err, "err");
            }
        },

        getListDraws(isPagination) {
            this.isLoadingPage = isPagination ? false : true
            try {
                DrawsService.listDraws(this.perPageDraws, this.page).then((res) => {
                    if (res.status === 200 && !isArrayEmpty(res.data)) {
                        let listDraws = res.data.map(x => {
                            let item = {
                                ...x,
                                results: JSON.parse(x.results),
                                raffle_winning_bet: JSON.parse(x.raffle_winning_bet)
                            }
                            return {
                                ...item
                            }
                        })
                        this.totalDraws = res.total
                        this.listPastDraws = listDraws
                        this.totalPage = Math.ceil(this.totalDraws / this.perPageDraws)
                        this.isLoadingPage = false
                    }
                });
            } catch (error) {
                console.log(error)
                this.isLoadingPage = false
            }
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/draws.scss" />
