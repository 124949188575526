var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`quick-pick ${_vm.classQuickPick} ${_vm.isError ? 'is-error' : ''} `},[(_vm.title || _vm.action)?_c('div',{staticClass:"quick-pick__header",class:_vm.classContainer},[(_vm.title)?_c('div',{staticClass:"quick-pick__title"},[_c('div',{staticClass:"quick-pick__title-header"},[_vm._v(" "+_vm._s(_vm.renderTitleTicket(_vm.title))+" ")]),(_vm.numbersChecked.length > _vm.minimumBalls)?_c('div',{staticClass:"quick-pick__amount-balls"},[_vm._v(" + "+_vm._s(_vm.priceMore)+" OMR ")]):_vm._e(),_c('div',{staticClass:"quick-pick__selected-numbers__desktop",class:_vm.classSelectedNumbers},[(_vm.showTextSelected)?_c('div',{staticClass:"quick-pick__selected-numbers__label t-transform-cap"},[_vm._v(" "+_vm._s(_vm.getTranslatedText("selected numbers"))+" ")]):_vm._e(),(_vm.minimumBalls > 0)?_c('div',{staticClass:"quick-pick__selected-numbers__numbers"},_vm._l((_vm.minimumBalls),function(item,index){return _c('div',{key:index,class:`quick-pick__selected-numbers__number number-select ${_vm.renderClass(
                            item,
                            index
                        )}`},[_c('div',{staticClass:"quick-pick__selected-numbers__box-number"},[_c('a',[_vm._v(_vm._s(_vm.renderBalls(index)))])])])}),0):_vm._e()])]):_vm._e(),(_vm.action)?_c('div',{staticClass:"quick-pick__action",class:{
                'quick-pick__action_rlt': _vm.$store.state.isRtl,
            }},[_vm._l((_vm.dataAction),function(item,index){return _c('div',{key:index,staticClass:"quick-pick__item-icon"},[_c('img',{class:{
                        'quick-pick__item-quick-pick': item.id === 3,
                        'quick-pick__item-favorite': item.id === 1,
                        'quick-pick__item-favorite-list': item.id === 2,
                    },attrs:{"loading":"lazy","src":require(`@/assets/images/${item.icon}`),"alt":item.icon},on:{"click":function($event){return _vm.$emit('onClickActionTicket', {
                            indexTicket: _vm.indexTicket,
                            idAction: item.id,
                        })}}})])}),_c('div',{staticClass:"quick-pick__item-icon"},[(_vm.showIconRemove && (_vm.numbersChecked.length > 0 || _vm.lengthBLock > 1) || _vm.showExpItem)?_c('img',{staticClass:"quick-pick__item-icon-remove",attrs:{"loading":"lazy","src":require("@/assets/images/tickets/remove.png"),"alt":"remove"},on:{"click":function($event){return _vm.$emit('onRemoveTicket', _vm.indexTicket)}}}):_vm._e()])],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"quick-pick__selected-numbers",class:_vm.classSelectedNumbers},[(_vm.showTextSelected)?_c('div',{staticClass:"quick-pick__selected-numbers__label t-transform-cap"},[_vm._v(" "+_vm._s(_vm.getTranslatedText("selected numbers"))+" ")]):_vm._e(),(_vm.minimumBalls > 0)?_c('div',{staticClass:"quick-pick__selected-numbers__numbers"},_vm._l((_vm.minimumBalls),function(item,index){return _c('div',{key:index,class:`quick-pick__selected-numbers__number number-select ${_vm.renderClass(
                    item,
                    index
                ) ? _vm.renderClass(
                    item,
                    index
                ) + ' active-ball' : ''}`},[_c('div',{staticClass:"quick-pick__selected-numbers__box-number"},[_c('a',[_vm._v(_vm._s(_vm.renderBalls(index)))])]),_c('div',{staticClass:"circle circle-left"}),_c('div',{staticClass:"circle circle-right"})])}),0):_vm._e(),(_vm.showBtnRandom)?_c('div',{staticClass:"quick-pick__random-numbers header t-transform-cap"},[_c('button',{on:{"click":function($event){return _vm.$emit('handleRandomNumbers', _vm.indexTicket)}}},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images//common/random-numbers.png"),"alt":"random-numbers"}}),_vm._v(" "+_vm._s(_vm.getTranslatedText("draw random numbers"))+" ")])]):_vm._e()]),(_vm.showLine)?_c('div',{staticClass:"line"}):_vm._e(),_c('div',{staticClass:"quick-pick__numbers-quick-pick"},[_c('div',{staticClass:"quick-pick__numbers-quick-pick_numbers"},_vm._l((_vm.listNumber),function(_,index){return _c('div',{key:++index,class:`quick-pick__selected-numbers__number selected-numbers__number__quick-pick ${_vm.renderClassListBall(
                    index
                )}`,on:{"click":function($event){return _vm.$emit('handlePickNumber', {
                        indexTicket: _vm.indexTicket,
                        number: index,
                    })}}},[_c('div',{staticClass:"quick-pick__selected-numbers__box-number"},[_c('a',[_vm._v(_vm._s(_vm.renderNumberLocale(index)))])]),_c('div',{staticClass:"circle circle-left"}),_c('div',{staticClass:"circle circle-right"})])}),0)]),(_vm.showBtnRandom)?_c('div',{staticClass:"quick-pick__random-numbers t-transform-cap"},[_c('button',{on:{"click":function($event){return _vm.$emit('handleRandomNumbers', _vm.indexTicket)}}},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/images//common/random-numbers.png"),"alt":"random-numbers"}}),_vm._v(" "+_vm._s(_vm.getTranslatedText("draw random numbers"))+" ")])]):_vm._e(),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }