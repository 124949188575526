<template>
    <div class="past-draws">
        <div class="past-draws__head">
            <div class="past-draws__head__content">
                {{ getTranslatedText("Past Draws") }}
            </div>
        </div>

        <div class="past-draws__back-filter">
            <div @click="$router.back()" class="past-draws__back-filter__back">
                <img loading="lazy"
                    :class="{backArabic: $store.state.isRtl}"
                    class="icon-back"
                    src="@/assets/images/card/back.png"
                />
                {{ getTranslatedText("Back to Draws") }}
            </div>
            <div class="past-draws__back-filter__filter">
                <div class="past-draws__back-filter__filter__label">
                    {{ getTranslatedText("Select Draw Date") }}
                </div>
                <div class="past-draws__head__times" @click="changePastDraws">
                    <div class="past-draws__head__times__calendar">
                        <img loading="lazy" src="@/assets/images/static-pages/draws/calendar.png" />
                    </div>
                    <div class="past-draws__head__times__time">
                        {{ formatDate(listPastDrawsShow.draw_date) }}
                    </div>
                    <div
                        v-if="listPastDraws.length > 1"
                        class="past-draws__head__times__dropdown"
                    >
                        <b-dropdown
                            id="dropdown-right"
                            ref="dropdownPastDraws"
                            right
                            variant="primary"
                            class="dropdown-custom-past-draw"
                        >
                            <b-dropdown-form class="b-dropdown-form__custom">
                                <div
                                    v-for="(item, index) in listPastDraws"
                                    :key="index"
                                    @click="handleChangePastDraws(index)"
                                    class="past-draws__head__times__time past-draws__head__times__dropdown__time"
                                >
                                    {{ formatDateTime(item.draw_date) }}
                                </div>
                            </b-dropdown-form>
                        </b-dropdown>
                    </div>
                    <div
                        v-if="listPastDraws.length <= 1"
                        class="past-draws__head__times__dropdown"
                    >
                        <!--                        <img loading="lazy" src="@/assets/images/static-pages/icon-drop-down.png"/>-->
                    </div>
                </div>
            </div>
        </div>
            <div class="past-draws__contents__grand">
                <div class="past-draws__contents__grand-content">
                    <div class="past-draws__contents__grand__title">
                        {{ getTranslatedText("Grand Draw Results") }}
                    </div>

                    <div class="past-draws__contents">
                        <div class="past-draws__contents__winning">
                            <div class="past-draws__contents__winning__left">
                                <div class="past-draws__contents__winning__left__number">
                                    {{ renderNumber(Number(numberWinning)) }} {{ getTranslatedText(currency) }}
                                </div>
                                <div class="past-draws__contents__winning__left__date">
                                    {{ getTranslatedText("Draw:")}} {{ formatDateString(drawDate) }}
                                </div>
                                <img loading="lazy" class="past-draws__line-mobile" src="@/assets/images/static-pages/draws/line-mobile.png"/>
                            </div>
                            <div class="past-draws__contents__winning__content">
                                <div class="past-draws__contents__winning__content__title">
                                    {{ getTranslatedText("Winning Number") }}
                                </div>
                                <div
                                    class="past-draws__contents__winning__content__numbers"
                                >
                                    <div
                                        v-for="(number, index) in numbersWinning"
                                        :key="index"
                                        :class="`past-draws__contents__winning__content__numbers__number ${renderClass(
                                number,
                                index
                            )}`"
                                    >
                                        <div
                                            class="past-draws__contents__winning__content__numbers__number__box"
                                        >
                                            <a>{{ renderNumber(Number(number)) }}</a>
                                        </div>
                                    </div>
                                </div>
                                <img loading="lazy" class="past-draws__line-mobile" src="@/assets/images/static-pages/draws/line-mobile.png"/>
                            </div>
                        </div>

                        <div class="past-draws__contents__grand__table">
                            <table class="past-draws__contents__grand__table__grand">
                                <tr class="past-draws__contents__grand__table__title">
                                    <th>{{ getTranslatedText("Match") }}</th>
                                    <th
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ getTranslatedText("Number of Winners") }}
                                    </th>
                                    <th
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ getTranslatedText("Prize Pool") }}
                                    </th>
                                    <th
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ getTranslatedText("Winnings") }}
                                    </th>
                                </tr>
                                <tr
                                    class="past-draws__contents__grand__table__item"
                                    v-for="(item, index) in listPastDrawsShow.tiers"
                                    :key="index"
                                >
                                    <td>{{ getTranslatedText(item.name) }}</td>
                                    <td
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ renderNumber(Number(item.winners)) }}
                                    </td>
                                    <td
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ renderNumber(Number(item.prize)) }}
                                        {{ getTranslatedText(currency) }}
                                    </td>
                                    <td
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ handleWinnings(item.prize, item.winners, index) }} {{ getTranslatedText(currency) }}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="past-draws__contents__grand__table__scroll-mobile">
                            <img loading="lazy" src="@/assets/images/static-pages/draws/scroll-bar-1.png"/>
                        </div>

                        <div class="past-draws__contents__play-now">
                            <div  @click="$router.push('/numbers')" class="past-draws__contents__play-now__button">
                                {{ getTranslatedText("Play now") }}
                                <img loading="lazy" src="@/assets/images/static-pages/draws/icon-next.png"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="past-draws__contents__grand-content" v-if="Object.keys(listWinners).length > 0">
                    <div class="past-draws__contents__grand__title">
                        {{ getTranslatedText("Guaranteed Draw Result") }}
                    </div>

                    <div class="past-draws__contents">
                        <div class="past-draws__contents__winning">
                            <div class="past-draws__contents__winning__left">
                                <div class="past-draws__contents__winning__left__number">
                                    {{ renderNumber(Number(listWinners.winnings)) }} {{ getTranslatedText(currency) }}
                                </div>
                                <div class="past-draws__contents__winning__left__date">
                                    {{ getTranslatedText('Draw:') }} {{ formatDateString(drawDate) }}
                                </div>
                                <img loading="lazy" class="past-draws__line-mobile" src="@/assets/images/static-pages/draws/line-mobile.png"/>
                            </div>
                        </div>

                        <div class="past-draws__contents__grand__table">
                            <table class="past-draws__contents__grand__table__grand-2">
                                <tr class="past-draws__contents__grand__table__title">
                                    <th>{{ getTranslatedText("Raffle ID") }}</th>
                                    <th
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ getTranslatedText("Name of winner") }}
                                    </th>
                                    <th
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ getTranslatedText("Amount") }}
                                    </th>
                                </tr>
                                <tr class="past-draws__contents__grand__table__item">
                                    <td>{{ listWinners.code}}</td>
                                    <td
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ listWinners.first_name }}
                                    </td>
                                    <td
                                        class="past-draws__contents__grand__table__item-center"
                                    >
                                        {{ renderNumber(Number(listWinners.winnings)) }}
                                        {{ getTranslatedText(currency) }}
                                    </td>
                                </tr>
                            </table>

                            <div class="past-draws__contents__grand__table__scroll-mobile-2">
                                <img loading="lazy" src="@/assets/images/static-pages/draws/scroll-bar-2.png"/>
                            </div>
                        </div>

                        <div class="past-draws__contents__buy-your">
                            <div  @click="$router.push('/numbers')" class="past-draws__contents__buy-your__button">
                                {{ getTranslatedText("Buy your green certificate") }}
                                <img loading="lazy" src="@/assets/images/static-pages/draws/icon-next-white.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="past-draws__content__content-bottom__last__container">
                    <div class="past-draws__content__content-bottom__last">
                        <div class="past-draws__content__content-bottom__last__text">
                            {{ getTranslatedText("Watch Live Draw")}}
                            <div class="past-draws__content__content-bottom__last__sub-text">
                                {{ getTranslatedText("Watch our weekly draws every")}}
                            </div>
                        </div>
                        <a
                            :href="linkWatchNow"
                            target="_blank"
                            class="past-draws__content__content-bottom__last__button"
                            :class="{
                            'past-draws__content__content-bottom__last__button__arabic':
                                $store.state.isRtl,
                        }"
                        >
                            {{ getTranslatedText("Watch Now") }}
                            <img loading="lazy"
                                src="@/assets/images/static-pages/icon-join-us.png"
                            />
                        </a>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import DrawsService from "@/services/draws.service";
import languageMixin from "@/mixins/language/language-mixin";
import {isStrEmpty, numberWithCommas, renderClassBall, renderLocaleNumber} from "@/components/common/utils";
import moment from "moment";
import toolService from "@/services/tool.service";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const LINK_WATCH_NOW = process.env.VUE_APP_LINK_WATCH_NOW;

export default {
    mixins: [languageMixin],
    components: {},
    data() {
        return {
            listPastDraws: [],
            listPastDrawsShow: [],
            numbersWinning: [],
            listWinners: [],
            currency: CURRENCY_CODE,
            linkWatchNow: LINK_WATCH_NOW,
            maxiumBlue: 0,
            maxiumGreen: 0,
            minimumBall: 0,
            numberWinning: 0,
            drawDate: "",
            eventID: null
        };
    },
    mounted() {
        this.eventID = this.$route.query.eventID;
        this.getListPastDraws();
        this.fetchDataValidateBall();
    },
    methods: {
        changePastDraws() {
            if(this.listPastDraws.length > 1) {
                this.$refs.dropdownPastDraws?.show();
            }
        },
        handleWinnings(prize, winners, index) {
            if(winners === 0) {
                return 0
            } else {
                if(index === 0) {
                    return this.renderNumber(Number(prize)/winners);
                } else {
                    return this.renderNumber(Number(prize));
                }
            }
        },
        renderNumber(number) {
            return renderLocaleNumber(number);
        },

        formatDateString(date) {
            let locale = this.$store.state.isRtl ? "ar" : "en";
            moment.locale(locale);
            console.log(date, 'date')
            return !isStrEmpty(date) ? moment(date).format('dddd, DD.MM.YYYY') : ''
        },

        async fetchDataValidateBall() {
            try {
                const resp = await toolService.getValidateTicket();

                this.maxiumBlue = resp.maxiumBlue;
                this.maxiumGreen = resp.maxiumGreen;
                this.minimumBall = resp.minMaxTickets[0];
            } catch (err) {
                console.log(err, "err");
            }
        },

        handleChangePastDraws(index) {
            this.listPastDraws.forEach((item, i) => {
                if (i === index) {
                    this.listPastDrawsShow = item;
                    this.numbersWinning = item.results[0];
                    this.drawDate = item.draw_date;
                    this.getListWinners(this.listPastDrawsShow.event_id);
                }
            });
            setTimeout(() => {
                this.$refs.dropdownPastDraws?.hide(true);
            }, 100);
        },
        formatNumberWinners(price) {
            return numberWithCommas(Number(price));
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatDateTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        getListPastDraws() {
            DrawsService.listPastDraws().then((res) => {
                if (res.status === "success") {
                    this.listPastDraws = res.data;
                    this.eventID = this.eventID || this.listPastDraws[0].event_id
                    this.listPastDraws.forEach(item => {
                        if(Number(item.event_id) === Number(this.eventID)) {
                            this.listPastDrawsShow = item;
                            this.drawDate = item.draw_date;
                            this.numbersWinning = item.results[0];
                            item.tiers.forEach(item=> {
                                if(item.name === "7 out of 7") {
                                    this.numberWinning = item.prize
                                }
                            })
                            this.getListWinners(this.eventID);
                        }
                    })
                }
            });
        },

        getListWinners(eventId) {
            DrawsService.listWinners(eventId).then((res) => {
                if (res.status === 200) {
                    this.listWinners = res.data;
                }
            });
        },

        renderClass(number, index) {
            return renderClassBall(
                number,
                index,
                this.minimumBall,
                this.maxiumBlue,
                this.maxiumGreen
            );
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/past-draws.scss"/>
