<template>
    <div class="main change-password">
        <BannerCustom/>
        <div
            class="section-set-password section-change-password-desktop t-transform-cap"
            id="change-pass"
        >    
            <NavBar :userName="userName"/>
            <div class="contents-change-pass">
                <div class="menu">
                    <p @click="personalDetail" :class="{ 'active': $route.fullPath === '/personal-detail' }">{{ getTranslatedText('my profile') }}</p>
                    <p @click="changePassword" :class="{ 'active': $route.fullPath === '/change-password' }">{{ getTranslatedText('Change Password') }}</p>
                    <p @click="deleteAccount" :class="{ 'active': $route.fullPath === '/delete-account' }">{{ getTranslatedText('Delete Account') }}</p>
                </div>
                <div class="menu-mobile" @click="onShowBottomMenuList">
                    <div>
                        <p>{{ getTranslatedText('change password') }}</p>
                        <div class="line"></div>
                    </div>
                    <img loading="lazy" src="@/assets/images/navigation/chevron-down.svg"/>
                </div>
                <div class="form-change-pass">
                    <p class="title">{{ getTranslatedText('Change Password') }}</p>
                    <hr class="line"/>
                    <FormCommon
                        :formItem="formItem"
                        :dataForm="dataForm"
                        @on-bur-input="onBurInputFormData"
                        @update-form-data="updateFormData"
                        @onSubmitForm="onSubmitForm"
                        @onToggleShowPassword="onToggleShowPassword"
                        class="section-change-password-desktop__form"
                    >
                        <div class="box-btn change-pass" slot="button">
                            <ButtonCustom
                                classBtn="save-changes-btn"
                                typeBtn="submit"
                                type="primary"
                                :isLoading="isLoadingButton"
                                :isDisabled="!dataForm.current_password || !dataForm.new_password || !dataForm.confirm_password"
                                text="save changes"
                            />
                        </div>
                    </FormCommon>
                </div>
            </div>
        </div>
        <div class="line-bottom"></div>
        <p class="guide">{{ getTranslatedText("fields marked with") }}</p>
        <Toast :title="toastData.message" :type="toastData.type" />
        <vue-bottom-sheet max-height="90%" ref="bottomMenuList" max-width="100%">
            <BottomSheet :isHiddenBtn="true">
                <template slot="body-sheet">
                    <ul class="bottom-sheet-list-navigation__menu-list">
                        <li
                            v-for="(item, index) in listMenuNavigationAccount"
                            :key="index"
                            v-show="item.isShow"
                            class="bottom-sheet-list-navigation__menu-item"
                        >
                            <div
                                @click="handleClickRedirect(item)"
                                class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                            >
                                <img loading="lazy"
                                    class="user-avatar"
                                    :src="require(`@/assets/images/navigation/${item.icon}`)"
                                    :alt="item.icon"
                                />
                                {{ getTranslatedText(item.title) }}
                            </div>
                        </li>
                    </ul>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
    </div>
</template>
<script>

import { isStrEmpty, redirectPathApp } from "@/components/common/utils";
import RouterMixin from "@/mixins/router/router-mixin";

import AuthService from "@/services/auth.service";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import Toast from "@/components/common/toast/Toast.vue";
import BannerCustom from "@/components/common/banner";
import NavBar from "@/components/common/nav-bar/NavBar";
import BottomSheet from "@/components/common/bottom-sheet";

import languageMixin from "@/mixins/language/language-mixin";
import { listMenuNavigationAccount } from "@/resources/listNavigationAccount";

const LIMIT_PASSWORD = process.env.VUE_APP_LIMIT_PASSWORD;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

let scriptTag

export default {
    mixins: [languageMixin, RouterMixin],
    components: {
        FormCommon,
        ButtonCustom,
        Toast,
        BannerCustom,
        NavBar,
        BottomSheet
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            listMenuNavigationAccount,
            isLoadingButton: false,
            timerCount: 60,
            isDisableResend: true,
            userName: null,
            isShowConsent: IS_SHOW_CONSENT,
            dataForm: {
                current_password: "",
                new_password: "",
                confirm_password: "",
            },
            formItem: [
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "current_password",
                    required: true,
                    label: "Current Password",
                    for: "input-password",
                    id: "input-password",
                    showIconEye: true,
                    state: null,
                    textInvalid: "please enter the correct password",
                    textValid: "",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "new_password",
                    required: true,
                    label: "New Password",
                    for: "input-password",
                    id: "input-password",
                    showIconEye: true,
                    state: null,
                    textInvalid: "please enter the correct password",
                    textValid: "",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "confirm_password",
                    required: true,
                    label: "Confirm New Password",
                    for: "input-confirmPassword",
                    id: "input-confirmPassword",
                    showIconEye: true,
                    state: null,
                    textInvalid: "passwords are not the same",
                    textValid: "",
                },
            ],
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value === 0) {
                    this.isDisableResend = false;
                }
            },
            immediate: true,
        },
    },
    created() {
        this.addScriptCanonical()
        let userInfo = JSON.parse(localStorage.getItem("user_info"));
        if(userInfo) {
            this.userName = userInfo.name
        }
    },
    mounted() {
        let elm = document.getElementById("change-pass")
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(window.screen.width <= 768) {
                elm.style.marginBottom = "160px"
            } else {
                elm.style.marginBottom = "100px"
            }
        } else {
            elm.style.marginBottom = "0"
        }
    },
    beforeDestroy(){
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    },
    // metaInfo() {
    //     return {
    //         title: 'Change Password',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Change Password'
    //             }
    //         ]
    //     };
    // },
    methods: {
        addScriptCanonical() {
            scriptTag = document.createElement("link");
            scriptTag.rel = "canonical";
            scriptTag.href =`https://omillionaire.com/change-password`
            document.head.appendChild(scriptTag);
        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        updateFormData(item) {
            let { value, name } = item;
            this.dataForm[name] = value;
            if (name === "new_password") {
                let isContainsLowercase = /^(?=.*[a-z]).*$/;
                let isContainsNumber = /^(?=.*[0-9]).*$/;
                let isContainsSymbol =
                    /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
                let isContainsUppercase = /^(?=.*[A-Z]).*$/;
                if (!isContainsLowercase.test(value)) {
                    this.formItem[1].textInvalid = 'Password must contain At Least 1 Character';
                    this.formItem[1].state = false;
                } 
                else if (!isContainsNumber.test(value)) {
                    this.formItem[1].textInvalid = 'Password must contain at least 1 number';
                    this.formItem[1].state = false;
                }
                else if (!isContainsSymbol.test(value)) {
                    this.formItem[1].textInvalid = 'Password must contain At Least 1 Special Character';
                    this.formItem[1].state = false;
                }
                else if (!isContainsUppercase.test(value)) {
                    this.formItem[1].textInvalid = 'Password must contain At Least 1 Capital Character';
                    this.formItem[1].state = false;
                }
                else if (value.length < LIMIT_PASSWORD || value.length >= 30) {
                    this.formItem[1].textInvalid = this.getTranslatedText('Password must contain At Least From [limit] To 30 Characters Long', { limit: LIMIT_PASSWORD});
                    this.formItem[1].state = false;
                } else {
                    this.formItem[1].textInvalid = '';
                    this.formItem[1].state = null;
                }
                if(value !== this.dataForm.confirm_password && !isStrEmpty(this.dataForm.confirm_password)) {
                    this.formItem[2].textInvalid = this.getTranslatedText('Passwords Are Not The Same');
                    this.formItem[2].state = false;
                } else {
                    this.formItem[2].textInvalid = '';
                    this.formItem[2].state = null;
                }
            }
            if (name === 'confirm_password') {
                if(value !== this.dataForm.new_password) {
                    this.formItem[2].textInvalid = this.getTranslatedText('Passwords Are Not The Same');
                    this.formItem[2].state = false;
                } else {
                    this.formItem[2].textInvalid = '';
                    this.formItem[2].state = null;
                }
            }
        },

        onBurInputFormData(event) {
            let { value, name } = event.target;
            if (value.length > 3) {
                let item = {
                    value,
                    name,
                };
                this.updateFormData(item);
            }
            if (
                name === "confirm_password" &&
                value === this.dataForm.new_password &&
                !this.formItem[2].state
            ) {
                this.formItem[2].state = true;
            }

            if (
                name === "confirm_password" &&
                value !== this.dataForm.new_password &&
                this.formItem[2].state !== null
            ) {
                this.formItem[2].state = false;
                this.formItem[2].textInvalid = this.getTranslatedText('Passwords Are Not The Same');
            }
        },

        onSubmitForm() {
            this.isLoadingButton = true;
            if (this.dataForm.new_password !== this.dataForm.confirm_password) {
                this.formItem[2].state = false;
                this.isLoadingButton = false;
                return false;
            }
            AuthService.changePassWord(
                this.dataForm.current_password,
                this.dataForm.new_password,
                this.dataForm.confirm_password
            ).then((res) => {
                if (res.status === 200) {
                    this.showToast(
                        this.getTranslatedText(res.message),
                        "success"
                    );
                    this.dataForm = {
                        current_password: "",
                        new_password: "",
                        confirm_password: "",
                    };
                    this.formItem[1].state = null;
                    this.formItem[2].state = null;
                } else {
                    this.showToast(
                        this.getTranslatedText(res.message),
                        "error"
                    );
                }
                this.isLoadingButton = false;
            });
        },

        onToggleShowPassword(name) {
            let index = this.formItem.findIndex(x => x.name === name)
            if(index !== -1 ) {
                this.formItem[index].typeShow = this.formItem[index].typeShow === 'password' ? 'text' : 'password'
            }
        },

        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },

        personalDetail() {
            this.routerPush('/personal-detail');
        },
        changePassword() {
            this.routerPush('/change-password');
        },
        deleteAccount() {
            this.routerPush('/delete-account');
        },
        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_register.scss"
/>
