<template>
    <div class="draws-type">
        <h1 v-if="!isScreenMobile" class="title">{{ getTranslatedText('Play Your 7 lucky Numbers to Enter the Draws every') }}
            <span>{{ getTranslatedText('Thursday!')}}</span>
        </h1>
        <carousel v-else class="slide" :items="1" :dots="true" :autoplayTimeout="6000" :autoplay="true" :nav="false">
            <h1 class="title">{{ getTranslatedText('Play Your 7 lucky Numbers to Enter the Draws every') }}
                <span>{{ getTranslatedText('Thursday!')}}</span>
            </h1>
            <div class="info">
                <div class="win">{{ getTranslatedText('WIN THE GRAND PRIZE') }}</div>
                 <div class="cost">
                    <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                    <span>{{ formatNumberWinners(1) }}</span>
                </div>
            </div>
            <div class="info">
                <div class="win">{{ getTranslatedText('DOUBLE THE GRAND PRIZE') }}</div>
                <div class="cost">
                    <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                    <span>{{ formatNumberWinners(2) }}</span>
                </div>
            </div>
            <div class="info">
                <div class="win">{{ getTranslatedText('WIN WEEKLY RAFFLE DRAW') }}</div>
                <div class="cost">
                    <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                    <span>{{ formatNumberPrice('100000') }}</span>
                </div>
            </div>
        </carousel>
        <div>
            <div v-if="!isScreenMobile" class="draws">
                <div class="info">
                    <div class="top">
                        <div class="win">{{ getTranslatedText('WIN THE') }}</div>
                        <div class="grand">{{ getTranslatedText('GRAND PRIZE') }}</div>
                    </div>
                    <div class="bottom">
                        <div class="cost">
                            <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                            <span>{{ formatNumberWinners(1) }}</span>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="top">
                        <div class="win">{{ getTranslatedText('DOUBLE THE') }}</div>
                        <div class="grand">{{ getTranslatedText('GRAND PRIZE') }}</div>
                    </div>
                    <div class="bottom">
                        <div class="cost">
                            <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                            <span>{{ formatNumberWinners(2) }}</span>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="top">
                        <div class="win">{{ getTranslatedText('WIN WEEKLY') }}</div>
                        <div class="grand">{{ getTranslatedText('RAFFLE DRAW') }}</div>
                    </div>
                    <div class="bottom">
                        <div class="cost">
                            <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                            <span>{{ formatNumberPrice('100000') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="date-draw">
                <span>{{ getTranslatedText('Next Draw Date: ')}}</span>
                <span class="next-date">{{ nextDraw }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";
import {
    isArrayEmpty,
    isStrEmpty,
    renderLocalePrice,
} from "@/components/common/utils";
import gameService from '@/services/game.service';
import moment from "moment";
import carousel from 'vue-owl-carousel'

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;

export default {
    mixins: [languageMixin, convertGSTtime],
    components: {carousel},
    data() {
        return {
            isScreenMobile: false,
            screenWidth: window.innerWidth,
            currencyCode: CURRENCY_CODE,
            nextDraw: ''
        }
    },

    watch: {
        screenWidth(value) {
            if (value <= 991) {
                    this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
            }
        },
    },

    created() {
        if (this.screenWidth <= 991) {
            this.isScreenMobile = true
        }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];
        this.nextDraw =  moment(list_games[0]?.draw_date).format("DD MMMM YYYY")
    },

    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        formatNumberWinners(number) {
            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            let price = !isArrayEmpty(list_games)
                ? parseFloat(list_games[0]?.estimated_jackpot) * number
                : 0;
            this.nextDraw =  moment(list_games[0]?.draw_date).format("DD MMMM YYYY")
            return renderLocalePrice(parseInt(price), 0);
        },
        formatNumberPrice(price) {
            price = parseInt(price)
            return price.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/draws-type/_draws-type.scss"
/>
