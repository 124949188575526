export const listMenuNavigationAccount = [
    {
        title: "My Profile",
        icon: "personal.png",
        isRedirect: false,
        url: "/personal-detail",
        isShow: true,
        isShowUserId: true,
        isShowCollapse: true
    },
    {
        title: "Change Password",
        icon: "changepass.svg",
        isRedirect: false,
        url: "/change-password",
        isShow: true
    },
    {
        title: "Delete Account",
        icon: "deleteacc.svg",
        isRedirect: false,
        url: "/delete-account",
        isShow: true
    }
];
