<template>
    <b-toast
        :id="id"
        solid
        no-close-button
        auto-hide-delay="1500"
        :toast-class="setToastClass(type)"
        :toaster="toastPosition"
    >
        <div
            class="toast__content"
            :class="{ toastContentMobile: isMobileDevice }"
        >
            <div class="toast__content__icon">
                <img loading="lazy"
                    v-if="type === 'success'"
                    src="@/assets/images/toast/success.png"
                />
                <img loading="lazy"
                    v-if="type === 'error' || type === 'errorGenC'"
                    src="@/assets/images/toast/error.png"
                />
                <img loading="lazy"
                    v-if="type === 'warning'"
                    src="@/assets/images/toast/warning.png"
                />
            </div>
            <div
                class="toast__content__title"
                :class="{
                    errorToast: type === 'error',
                    warningToast: type === 'warning',
                }"
            >
                {{ hasVariableTrans ? title : getTranslatedText(title) }}
            </div>
        </div>
    </b-toast>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";
export default {
    mixins: [LanguageMixin],
    props: {
        title: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "my-toast",
        },
        hasVariableTrans: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isMobileDevice: false,
            toastPosition: "b-toaster-bottom-right",
            toastClass: "toast-custom",
        };
    },
    components: {},
    mounted() {
        this.checkDevice();
    },
    methods: {
        checkDevice() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                this.isMobileDevice = true;
                this.toastPosition = "b-toaster-bottom-full";
                this.toastClass = "toast-custom-mobile";
            } else {
                this.isMobileDevice = true;
                this.toastPosition = "b-toaster-bottom-right";
                this.toastClass = "toast-custom";
            }
        },
        setToastClass(type) {
            if(type === 'success') {
                return this.toastClass + ' successToast'
            } else if(type === 'errorGenC') {
                return this.toastClass + ' errorGenC'
            } else {
                return this.toastClass
            }
        }
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/toast/toast.scss" />
