<template>
    <div class="oasis-park">
        <carousel :items="1" :nav="false" animateOut="fadeOut" animateIn="fadeIn">
            <div class="oasis-park__item" v-for="(item, index) in dataSource" :key="index">
                <img loading="lazy" class="img-responsive show-desktop" :src="require(`@/assets/images/static-pages/oasis-park/${item.imageDesktop}`)" />
                <img loading="lazy" class="img-responsive show-mobile" :src="require(`@/assets/images/static-pages/oasis-park/${item.imageMobile}`)" />
                <div class="oasis-park__item-header">
                    <img loading="lazy" src="@/assets/images/static-pages/oasis-park/logo.png" class="oasis-park__item-header-parklogo">
                    <h1 class="mb-4">{{ getTranslatedText(item.titleOne) }}</h1>
                    <h1 class="mb-4">{{ getTranslatedText(item.titleTwo) }}</h1>
                    <h1 class="mb-4">{{ getTranslatedText(item.titleThree) }}</h1>
                    <button @click="onShowModal('en')"  class="btn btn-primary btn-lg w-max mt-2" tabindex="0"><img loading="lazy" src="@/assets/images/static-pages/oasis-park/Subtraction.png" class="plybtn">English</button>
                    <button @click="onShowModal('ar')" class="btn btn-primary btn-lg w-max mt-2" tabindex="0"><img loading="lazy" src="@/assets/images/static-pages/oasis-park/Subtraction.png" class="plybtn">العربية</button>
                </div>
                    <!-- <p id="desktopfooter" class="oasis-park__item-desktop-footer">
                        <img loading="lazy" class="footerlogo" src="@/assets/images/static-pages/oasis-park/footerlogo.png">
                        {{ getTranslatedText(item.addressDesktop) }}
                    </p>
                     <p id="mobilefooter" class="oasis-park__item-mobile-footer">
                        <img loading="lazy" class="mobilefotter" src="@/assets/images/static-pages/oasis-park/footerlogo.png"  style="width: 70px; height: 56px;">
                        <span id="footer-mobile" style="padding-left: 10px; line-height: 13px; padding-top:9px; color:#fff;" v-html="getTranslatedText(item.addressMobile)"></span>
                    </p> -->
            </div>
        </carousel>

        <div>
            <b-modal id="bv-modal-video" hide-footer hide-header size="xl" centered>
                <img loading="lazy" class="modal-icon-close" src="@/assets/images/static-pages/oasis-park//cross.png" @click="onHideModal">
                <div class="modal-video-iframe" v-if="srcIframe">
                    <b-embed
                        id="video-iframe"
                        type="iframe"
                        aspect="16by9"
                        :src="srcIframe"
                        allowfullscreen
                    ></b-embed>
                </div>
            </b-modal>
        </div>
    </div>
</template>
<script>
import carousel from 'vue-owl-carousel'

import languageMixin from '@/mixins/language/language-mixin';
export default {
    mixins: [languageMixin],
    components: { carousel },
    data() {
        return {
            dataSource: [
                {
                    imageDesktop: 'Desktop-1.jpg',
                    imageMobile: 'Mobile_Home_11.png',
                    titleOne: 'A place like nowhere else on earth.',
                    titleTwo: 'A symbol of hope for a sustainable future.',
                    titleThree: 'Creating a greener Oman together.',
                    addressDesktop: 'Millionaire Investment LLC, 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, Floor 3, Muscat, Oman    contact@omillionaire.com    +968 24 597 188',
                    addressMobile: 'Millionaire Investment LLC, <br> 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, <br> Floor 3, Muscat, Oman <br>    contact@omillionaire.com +968 24 597 188'
                },
                {
                    imageDesktop: 'Desktop-2.jpg',
                    imageMobile: 'Mobile_Home_22.png',
                    titleOne: 'A place like nowhere else on earth.',
                    titleTwo: 'A symbol of hope for a sustainable future.',
                    titleThree: 'Creating a greener Oman together.',
                    addressDesktop: 'Millionaire Investment LLC, 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, Floor 3, Muscat, Oman    contact@omillionaire.com    +968 24 597 188',
                    addressMobile: 'Millionaire Investment LLC, <br> 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, <br> Floor 3, Muscat, Oman <br>    contact@omillionaire.com +968 24 597 188'
                },
                {
                    imageDesktop: 'Desktop-3.jpg',
                    imageMobile: 'Mobile_Home_33.png',
                    titleOne: 'A place like nowhere else on earth.',
                    titleTwo: 'A symbol of hope for a sustainable future.',
                    titleThree: 'Creating a greener Oman together.',
                    addressDesktop: 'Millionaire Investment LLC, 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, Floor 3, Muscat, Oman    contact@omillionaire.com    +968 24 597 188',
                    addressMobile: 'Millionaire Investment LLC, <br> 45 Bawshar, Ghala Ind, Way No 319, Bldg 110, <br> Floor 3, Muscat, Oman <br>    contact@omillionaire.com +968 24 597 188'
                }
            ],
            srcIframe: ''
        }
    },

    // metaInfo() {
    //     return {
    //         title: 'Oasis Park | Tree Plantation Green Initiative | Powered by Renewable Energy | Eradicating Carbon Emissions | Funded by O! Millionaire',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Plant a tree for a greener tomorrow. One life. One Planet. Make a Difference. Oasis Park is the world’s first and state of the art green sanctuary that will house millions of trees.'
    //             }
    //         ]
    //     };
    // },
    methods: {
        onShowModal(type) {
            this.srcIframe = type === 'en' ? 'https://www.youtube.com/embed/vt0NzvCAwPU' : 'https://www.youtube.com/embed/oxw9RLyBpjo'
            this.$bvModal.show('bv-modal-video')
        },

        onHideModal() {
            this.srcIframe = ''
            this.$bvModal.hide('bv-modal-video')
        }
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/oasis-park.scss" />
