<template data-view>
    <div class="loading-full-page" v-if="isLoadingPagePayment">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
    </div>
    <div v-else class="main-wallet" :class="{ 'main-step': step !== 1 && step !== 2, 'main-bank-withdraw': step === 2 }">
        <BannerCustom :title="step === 2 ? 'Bank Withdrawal' : ''"/>
        <div v-if="isShowPage" :is-hide-menu="step === 4 || step === 3" class="main-wallet__contents">
            <NavBar v-if="step === 1"/>
            <div class="section-wallet" :class="{ 'no-padding form-main': step !== 1 }" id="section-wallet">
                <template v-if="step === 1">
                    <div class="section-wallet__header section-wallet__padding-container">
                        <div class="section-wallet__header-content__desktop">
                            <!-- <h1
                                class="section-wallet__title__desktop t-transform-cap"
                            >
                                {{ getTranslatedText("my wallet") }}
                                <img loading="lazy"
                                    src="@/assets/images/wallet/vector.png"
                                    @click="onShowBottomMenuList"
                                />
                            </h1> -->
                            <div class="header">
                                <div class="inbox">
                                    <img loading="lazy" src="@/assets/images/navigation/dollar-white.svg"/>
                                    <span class="title">{{ getTranslatedText("My Wallet") }}</span>
                                </div>
                                <div>
                                    <img class="logo" loading="lazy" src="@/assets/images/common/logo.svg"/>
                                    <img class="logo-mb" loading="lazy" src="@/assets/images/header/logo.svg"/>
                                </div>
                            </div>
                            <div
                                class="section-wallet__header-content__desktop__items"
                            >
                                <div class="section-wallet__balance__desktop">
                                    <span
                                        class="section-wallet__text-credit t-transform-cap"
                                        >{{
                                            getTranslatedText("CREDIT SUMMARY")
                                        }}</span
                                    >
                                    <div class="section-wallet__number-balance">
                                        <p>{{
                                            getTranslatedText($store.state.currency_code)
                                        }}</p>
                                        {{ formatPrice($store.state.balance) }}

                                    </div>
                                </div>
                                <div class="section-wallet__box-btn__desktop">
                                    <!-- <button
                                        @click="onShowVoucherCode"
                                        class="section-wallet__btn-withdraw section-wallet__btn-white-desktop section-wallet__btn-voucher t-transform-cap"
                                    >
                                        + {{ getTranslatedText("Redeem Gift Card") }}
                                    </button> -->
                                    <div class="group-btn">
                                        <button
                                            @click="onShowAddCredit"
                                            class="t-transform-cap"
                                        >
                                            {{ getTranslatedText("Add Credit") }}
                                        </button>
                                        <!-- <button
                                            class="section-wallet__btn-withdraw section-wallet__btn-default-desktop t-transform-cap"
                                            @click="$router.push('/buy/green-certificates')"
                                        >
                                            {{ getTranslatedText("buy now") }}
                                        </button> -->
                                        <button
                                            class="share t-transform-cap"
                                            @click="onClickWithDraw(3)"
                                        >
                                            {{ getTranslatedText("Share Credit") }}
                                            <!-- <img loading="lazy"
                                                src="@/assets/images/common/arrow-right.png"
                                            /> -->
                                        </button>
                                    </div>
                                    <button
                                        class="btn-with t-transform-cap"
                                        @click="onShowModalWithdraw()"
                                    >
                                        {{ getTranslatedText("Withdraw") }}
                                        <!-- <img loading="lazy"
                                            src="@/assets/images/common/arrow-right.png"
                                        /> -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NoEmpty
                        v-if="listTransaction.length === 0"
                        :text="getTranslatedText('no data')"
                    />
                    <div v-else class="section-wallet__padding-container-main">
                       <div class="section-wallet__body section-wallet__padding-container">
                        <div class="title">
                                <p>{{ getTranslatedText('ID') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Date/Time') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Type') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Method/Description') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Amount') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Status') }}</p>
                                <p><span class="line"></span>{{ getTranslatedText('Balance') }}</p>
                        </div>
                        <div class="contents" v-for="(item, index) in listTransaction" :key="index">
                                <p>#{{ item.id }}</p>
                                <p>{{ item.date }}<span class="time">{{ item.time }}</span></p>
                                <p>{{ item.transaction_description }}<br/>
                                    <b-spinner v-if="item.isLoadingDownLoadIn" variant="success" />
                                    <span v-else>
                                        <a v-if="item.link_invoice" @click="downloadInvoice(item)" class="link-in">
                                            {{ getTranslatedText('Invoice') }}
                                        </a>
                                    </span>
                                </p>
                                <p v-if="item.transaction_description === 'Purchase'" class="link link-cer" @click="downloadFile(item)">
                                    <b-spinner v-if="item.isLoadingDownLoad" variant="success" />
                                    <a v-else>{{ item.link_certificate ? getTranslatedText("green certificate") : getTranslatedText('Re-generate Certificate') }}</a>
                                </p>
                                <p v-else><span>{{getTranslatedText('Reference ID')}}</span><br/>{{ item.reference }}</p>
                                <p><span class="currency">{{getTranslatedText($store.state.currency_code)}}</span>{{ formatPrice(item.amount.toString()) }}</p>
                                <p :class="genClass(item.transaction_status)">{{ item.transaction_status }}</p>
                                <p><span class="currency">{{getTranslatedText($store.state.currency_code)}}</span>{{ formatPrice(item.balance.toString()) }}</p>
                        </div>
                       </div>
                    </div>
                    <div v-if="totalListTrans > 0" class="section-wallet__pagination">
                        <b-pagination
                            :total-rows="totalListTrans"
                            v-model="pageTransaction"
                            :per-page="perPagePagination"
                            @input="onChangePagination"
                            pills
                            align="center"
                            hide-goto-end-buttons
                            prev-text="Back"
                            next-text="Next"
                        ></b-pagination>
                        <div class="title">
                            {{ pageTransaction + '/' + totalPage + ' ' + getTranslatedText('of') + ' ' + totalListTrans}}
                            <span>{{ totalListTrans > 1 ? getTranslatedText('entries') : getTranslatedText('entry') }}</span>
                        </div>
                    </div>
                </template>

                <template v-if="step === 2">
                    <BankTransfer
                        :amountWithdraw="amountWithdraw"
                        :isLoadingButton="isLoadingButton"
                        @onClickGoBack="onClickGoBack"
                        @onSubmit="onSubmitWithdrawal"
                        @showToast="showToast"
                    />
                </template>
                <!-- <template v-if="step === 3"> -->
                    <MobileNumber
                        id="show-amount-share-credit"
                        :isLoadingButton="isLoadingButton"
                        @onClickGoBack="onClickGoBack"
                        @onSubmit="onSubmitTransferAmount"
                        @showToast="showToast"
                    />
                <!-- </template> -->

                <!-- <template v-if="step === 4"> -->
                    <AddCredit
                        id="show-amount-popup"
                        :offsite="offsite"
                        @onClickGoBack="onClickGoBack"
                        @showToast="showToast"
                    />
                <!-- </template> -->
                <template v-if="step === 5">
                    <AddVoucher
                        @showToast="showToast"
                        @onClickGoBack="onClickGoBack"
                    />
                </template>
                <template v-if="step === 6">
                    <div class="form-success">
                        <img class="icon-noti" width="70" height="70" src="@/assets/images/payment/success.svg" alt="" />
                        <p class="title">{{ getTranslatedText(messageSuccess) }}</p>
                        <p class="sub-title">{{ getTranslatedText(subMessageSuccess) }}</p>
                    </div>
                </template>
                <VoucherCode
                    id="modalVoucherCodeWallet"
                    @showToast="showToast"
                    @onCancelModal="onHideVoucherCode"
                />
                <vue-bottom-sheet max-height="90%" ref="bottomMenuList">
                    <BottomSheet :isHiddenBtn="true">
                        <template slot="body-sheet">
                            <ul class="bottom-sheet-list-navigation__menu-list">
                                <li
                                    v-for="(item, index) in listMenuNavigation"
                                    :key="index"
                                    v-show="item.isShow"
                                    class="bottom-sheet-list-navigation__menu-item"
                                >
                                    <div
                                        @click="handleClickRedirect(item)"
                                        class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                                    >
                                        <img loading="lazy"
                                            class="user-avatar"
                                            :src="
                                                require(`@/assets/images/navigation/${item.icon}`)
                                            "
                                            :alt="item.icon"
                                        />
                                        {{ getTranslatedText(item.title) }}
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </BottomSheet>
                </vue-bottom-sheet>

                <vue-bottom-sheet
                    max-height="90%"
                    ref="bottomMenuWithdraw"
                    :background-scrollable="false"
                >
                    <BottomSheet>
                        <div slot="body-sheet" class="bottom-sheet__width-draw">
                            <h1 class="bottom-sheet__width-draw-title">
                                {{ getTranslatedText("transfer / withdraw") }}
                            </h1>
                            <div class="bottom-sheet__width-draw-form">
                                <FormCommon
                                    :formItem="formItem"
                                    :dataForm="dataForm"
                                    @update-form-data="updateWithDraw"
                                    @onSubmitForm="onSubmitWithdraw"
                                />
                            </div>
                            <ButtonCustom
                                classBtn="bottom-sheet__width-draw-button"
                                typeBtn="button"
                                type="primary"
                                text="continue"
                                icon="arrow-right.png"
                                @onClickButton="onClickWithDraw"
                            />
                        </div>
                    </BottomSheet>
                </vue-bottom-sheet>
                <Toast :title="toastData.message" :type="toastData.type" :hasVariableTrans="toastData.hasVariableTrans" />
            </div>
        </div>
        <AmountPopup id="show-modal-withdraw" title="withdraw" @resetModal="resetModal">
            <div class="bank-withdraw-form">
                <label>{{ getTranslatedText('amount') }}</label>
                <b-form-input v-model="amountWithdraw"  
                    @blur="formatter"
                    @keydown.native="checkDigit" placeholder="Enter amount here">
                </b-form-input>
                <b-form-invalid-feedback class="input-live-feedback">
                    {{ messageBankWithdraw }}
                </b-form-invalid-feedback>
                <p class="sub-title">({{ getTranslatedText('Minimum withdrawal amount is [currency] [limit]', { limit: limitAmount, currency: currencyCode}) }})</p>
                <p class="description">{{ getTranslatedText('Withdrawals can only be made to accounts in the same name as the registered account name.') }}</p>
                <ButtonCustom
                    classBtn="btn__width-draw-button"
                    typeBtn="button"
                    type="primary"
                    text="Bank Withdrawal"
                    @onClickButton="onClickWithDraw(2, 'bank-withdraw')"
                />
            </div>
        </AmountPopup>
    </div>
</template>
<script>
import { listMenuNavigation } from "@/resources/listNavigation";
import {
    isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    renderClassBall,
    renderLocaleNumber,
    renderLocalePrice,
    checkDigitCommon
} from "@/components/common/utils";

import toolService from "@/services/tool.service";
// import WalletService from "@/services/wallet.service";
import PaymentService from "@/services/payment.servive";

import BottomSheet from "@/components/common/bottom-sheet";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import NoEmpty from "@/components/common/no-empty";

import BankTransfer from "@/views/mobile/wallet/bank-transfer";
import MobileNumber from "@/views/mobile/wallet/mobile-number";
import AddCredit from "@/views/mobile/wallet/add-credit";
import walletService from "@/services/wallet.service";
import FavouriteService from "@/services/favourite.service";
import SideBar from "@/components/common/sidebar/SideBar";
import AmountPopup from "@/components/common/amount-popup";

import LanguageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"
import Toast from "@/components/common/toast/Toast.vue";
import moment from 'moment';
import MyTransactionsService from "@/services/my-transaction";
import paymentMixin from "@/mixins/payment/payment-mixin"
import VoucherCode from "@/components/common/voucherCode";
import AddVoucher from "@/views/mobile/wallet/add-voucher";
import EventBus from '@/components/common/EventBus';
import BannerCustom from "@/components/common/banner";
import NavBar from "@/components/common/nav-bar/NavBar";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
const VUE_APP_WITH_AMOUNT = process.env.VUE_APP_WITH_AMOUNT;
const VUE_APP_WITH_AMOUNT_MAXIUM = process.env.VUE_APP_WITH_AMOUNT_MAXIUM;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

let scriptTag, scriptTag1, scriptTag2

export default {
    mixins: [LanguageMixin, RouterMixin, paymentMixin],
    components: {
        BottomSheet,
        FormCommon,
        ButtonCustom,
        BankTransfer,
        MobileNumber,
        AddCredit,
        NoEmpty,
        SideBar,
        Toast,
        VoucherCode,
        AddVoucher,
        BannerCustom,
        NavBar,
        AmountPopup
    },

    data() {
        return {
            perPagePagination: 20,
            pageTransaction: 1,
            totalPage: 1,
            pageCount: 0,
            listTransaction: [],
            isShowPage: false,
            messageSuccess: '',
            subMessageSuccess: '',
            isShowConsent: IS_SHOW_CONSENT,
            toastData: {
                message: "",
                type: "success",
                hasVariableTrans: false
            },
            step: 1,
            offsite: false,
            isLoadingButton: false,
            balance: "0.00",
            totalWinnings: "0.00",
            currency_code: "AED",
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            listMenuNavigation,
            dataForm: {
                methodsWithDraw: "1",
            },
            listBalls: [],
            formItem: [
                {
                    type: "radio",
                    name: "methodsWithDraw",
                    label: "",
                    required: false,
                    id: "input-methodsWithDraw",
                    options: [
                        {
                            text: this.getTranslatedText("bank transfer"),
                            value: "1",
                        },
                        {
                            text: this.getTranslatedText(
                                "to mobile number/email"
                            ),
                            value: "2",
                        },
                    ],
                },
            ],
            isLoading: false,
            totalListTrans: 0,
            amountWithdraw: '',
            limitAmount: VUE_APP_WITH_AMOUNT * EXCHANGE_RATE,
            maxiumAmount: VUE_APP_WITH_AMOUNT_MAXIUM * EXCHANGE_RATE,
            messageBankWithdraw: ''
        };
    },
    created() {
        scriptTag2 = document.createElement("meta");
        scriptTag2.name = "robots";
        scriptTag2.content = "noindex";
        document.head.appendChild(scriptTag2);
        this.fetchDataValidateBall();
        this.fetchDataTransaction();
        this.$store.dispatch("setBalance");
        this.$store.dispatch("getListGame");
        window.addEventListener("resize", this.myEventHandler);
    },
    // metaInfo() {
    //     return {
    //         title: 'Omillionaire Wallet',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Omillionaire Wallet'
    //             }
    //         ]
    //     };
    // },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        const parameters = this.$route.query;
        if(parameters?.step === '4') {
            this.onShowAddCredit()
            this.$router.replace({'query': null});
        } else if(parameters?.step === '5') {
            this.step = 5;
            this.isShowPage = true
            this.$router.replace({'query': null});
        } else {
            this.isShowPage = true
        }
    },
    updated() {
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("section-wallet")) {
                document.getElementById("section-wallet").style.marginBottom = "200px"
            }
        } else {
            if(document.getElementById("section-wallet")) {
                document.getElementById("section-wallet").style.marginBottom = "0"
            }
        }
    },
    watch: {
        pageTransaction(val) {
            if(val) {
                this.pageTransaction = val;
                this.fetchDataTransaction();
            }
        }
    },
    methods: {
        myEventHandler(e) {
            if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) && this.step === 5) {
                this.step = 1;
            }
        },
        fomarNumberWinners(price) {
            price = renderLocaleNumber(parseFloat(price))
            return price;
        },
        showToast(message, type, hasVariableTrans) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.toastData.hasVariableTrans = hasVariableTrans;
            this.$bvToast.show("my-toast");
        },
        resetModal() {
            this.amountWithdraw = ""
            this.messageBankWithdraw = ""
        },
        formatter(event) {
            const { value } = event.target;
            let isContainsLowercase = /^(?=.*[a-z]).*$/;
            let isNumber = /^[0-9.]*$/
            this.amountWithdraw = !isStrEmpty(value) && !isContainsLowercase.test(value) && isNumber.test(value) ? parseFloat(value.replace(/,/g, ""))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            // let input = document.getElementById("input-price");
            let length = this.amountWithdraw.replaceAll(".", "").length;
            let numberMore = (this.amountWithdraw.match(/,/g) || []).length;
            numberMore = numberMore > 0 ? -numberMore + 1 : 0.5;
            // input.style.width = length + numberMore + "ch";
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },
        onShowVoucherCode() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                this.step = 5;
            } else {
                this.$bvModal.show("modalVoucherCodeWallet");
            }
        },

        onHideVoucherCode() {
            EventBus.$emit('onHideVoucherCode');
            this.$bvModal.hide("modalVoucherCodeWallet");
        },
        onChangePagination(value) {
            this.pageTransaction = value
            this.totalPage = Math.ceil(this.totalListTrans / this.perPagePagination)
        },
        genClass(type) {
            if(type === 'Completed') {
                return 'green'
            } else if (type === 'Pending') {
                return 'orange'
            } else {
                return 'red'
            }
        },
        downloadInvoice(item) {
            item.isLoadingDownLoadIn = true
            window.open(item.link_invoice, "_blank")
            setTimeout(() => {
                item.isLoadingDownLoadIn = false
            }, 2000);
        },
        downloadFile(item) {
            if(item.link_certificate) {
                item.isLoadingDownLoad = true
                window.open(item.link_certificate, "_blank")
                setTimeout(() => {
                    item.isLoadingDownLoad = false
                }, 2000);
            } else {
                this.reGenCerticate(item)
            }
        },
        async reGenCerticate(data) {
            data.isLoadingDownLoad = true
            try {
                const res = await FavouriteService.reGenCerticate(data.raffle_code)
                if(res.status === 200) {
                    window.open(res.data, "_blank")
                    setTimeout(() => {
                        data.isLoadingDownLoad = false
                    }, 2000);
                    // this.fetdataListPasrCombinations()
                    // data.isShowButton = true
                    // data.link_certificate = res.data
                    // data.isLoadingDownLoad = false
                } else {
                    data.isLoadingDownLoad = false
                    let message = ''
                    !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                    })
                    : (message = res.message);
                    this.showToast(this.getTranslatedText(message), "errorGenC")
                }
            } catch (error) {
                // data.isLoadingDownLoad = false
                console.log(error)
            }
        },
        async fetchDataTransaction() {
            this.isLoadingPage = true;
            const param = {
                perPage: this.perPagePagination,
                page: this.pageTransaction,
                type: "",
                dateFrom: "",
                dateTo: "",
            }
            try {
                const resp = await MyTransactionsService.getList(param);
                if (resp.status === 200) {
                    let listTransaction = resp.data.map((item) => {
                        return {
                            ...item,
                            isLoadingDownLoad: false,
                            isLoadingDownLoadIn: false
                        }
                    })
                    this.listTransaction = listTransaction;
                    this.totalListTrans = resp.total;
                }
                this.isLoadingPage = false;
            } catch (err) {
                console.log(err);
            }
        },
        async fetchDataValidateBall() {
            try {
                const resp = await toolService.getValidateTicket();

                this.listNumber = new Array(resp.quatityNumber);

                this.maxiumBlue = resp.maxiumBlue;
                this.maxiumGreen = resp.maxiumGreen;
                this.minimumBall = resp.minMaxTickets[0];
            } catch (err) {
                console.log(err, "err");
            }
        },

        async onSubmitWithdrawal(param) {
            this.isLoadingButton = true;
            let regexSpace = /^\S*$/
            let letters = /[^A-Za-z0-9]+/
            if (isStrEmpty(param.account_first_name)) {
                this.showToast("The First Name is required", "error");
                this.isLoadingButton = false;
                return false;
            }

            if (isStrEmpty(param.account_last_name)) {
                this.showToast("The Last Name is required", "error");
                this.isLoadingButton = false;
                return false;
            }
            
            if (isStrEmpty(param.country)) {
                this.showToast("The Country is required", "error");
                this.isLoadingButton = false;
                return false;
            }

            if (isStrEmpty(param.bank_account_currency)) {
                this.showToast(
                    "The Bank Account Currency is required",
                    "error"
                );
                this.isLoadingButton = false;
                return false;
            }

            if (isStrEmpty(param.branch_code) && param.isIban === true) {
                this.showToast("The Iban / Clabe # is required", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.branch_code) && !regexSpace.test(param.branch_code)) {
                this.showToast("The Iban / Clabe # must not contain spaces", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.branch_code) && letters.test(param.branch_code)) {
                this.showToast("The Iban / Clabe # only contain alphabets & numbers!", "error");
                this.isLoadingButton = false;
                return false;
            }

            if (isStrEmpty(param.account_number) && param.isAccountNumber === true) {
                this.showToast("The Beneficiary Account is required", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.account_number) && !regexSpace.test(param.account_number)) {
                this.showToast("The Beneficiary Account must not contain spaces", "error");
                this.isLoadingButton = false;
                return false;
            }

            if (!isStrEmpty(param.account_number) && letters.test(param.account_number)) {
                this.showToast("The Beneficiary Account only contain alphabets & numbers!", "error");
                this.isLoadingButton = false;
                return false;
            }

            if (isStrEmpty(param.swift_code) && param.isSwiftCode === true) {
                this.showToast("The Swift Code is required", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.swift_code) && param.swift_code.trim().length < 8 || param.swift_code.trim().length > 11) {
                this.showToast("Swift code should be limited to 8-11 characters", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.swift_code) && !regexSpace.test(param.swift_code)) {
                this.showToast("Swift code must not contain spaces", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (!isStrEmpty(param.swift_code) && letters.test(param.swift_code)) {
                this.showToast("Swift code should only contain alphabets & numbers!", "error");
                this.isLoadingButton = false;
                return false;
            }
            if (isStrEmpty(param.bank_name)) {
                this.showToast("The Bank Name is required", "error");
                this.isLoadingButton = false;
                return false;
            }   
            if (isStrEmpty(param.city)) {
                this.showToast("The City is required", "error");
                this.isLoadingButton = false;
                return false;
            }
            let { isIban, isAccountNumber, ...paramData } = param;

            try {
                const resp = await walletService.withdrawalBanking(paramData);
                if (resp.status === 400) {
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                              return this.showToast(element.message, "error");
                          })
                        : this.showToast(resp.message, "error");
                } else {
                    scriptTag1 = document.createElement('script');
                    scriptTag1.textContent = `
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'bank_withdrawal',
                            'amount': ${paramData.amount * this.exchangeRate},
                        });
                    `;
                    document.head.appendChild(scriptTag1);
                    this.$store.dispatch("setBalance");
                    this.showToast(resp.message, "success");
                    this.messageSuccess = resp.message
                    this.subMessageSuccess = 'Celebrate your success, and we appreciate your assistance in planting trees. We look forward to your continued support in enhancing the greenery of our beautiful world.'
                    this.step = 6;
                }
                this.isLoadingButton = false;
            } catch (error) {
                console.log(error);
            }
        },

        async onSubmitTransferAmount(item) {
            this.isLoadingButton = true;
            let param = {
                amount: item.amount,
            };

            if (isStrEmpty(item.phone)) {
                this.showToast(
                    "Please enter your phone number",
                    "error"
                );
                this.isLoadingButton = false;
                return false;
            }

            if (!isStrEmpty(item.email)) {
                param["email"] = item.email;
            } else {
                param["phone"] = item.phone;
                param["calling_code"] = item.calling_code;
            }
            try {
                const resp = await walletService.balanceTransfer(param);
                if (resp.status === 400) {
                    let message = "";
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                              message += element.message;
                          })
                        : (message = resp.message);

                    this.showToast(message, "error");
                } else {
                    let phone = param.phone ? param.calling_code + param.phone : 'N/A'
                    scriptTag = document.createElement('script');
                    scriptTag.textContent = `
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'share_credit',
                            'amount': ${param.amount * this.exchangeRate},
                            'mobile': ${phone},
                        });
                    `;
                    document.head.appendChild(scriptTag);
                    this.$store.dispatch("setBalance");
                    this.showToast(resp.message, "success");
                    this.$bvModal.hide("show-amount-share-credit");
                    this.messageSuccess = resp.message
                    this.subMessageSuccess = 'The transfer of the funds has been completed successfully. We appreciate your support for our environmental sustainability efforts.'
                    this.step = 6;
                }
                this.isLoadingButton = false;
            } catch (error) {
                this.isLoadingButton = false;

                console.log(error);
            }
        },

        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },

        onClickGoBack() {
            this.step = 1;
        },

        // with draw
        onShowModalWithdraw() {
            this.$bvModal.show("show-modal-withdraw");       
        },

        onClickWithDraw(number, type) {
            if (type === 'bank-withdraw' && number === 2) {
                if (isStrEmpty(this.amountWithdraw)) {
                    this.messageBankWithdraw = this.getTranslatedText("please enter transfer amount");
                    return false;
                }

                if (
                    parseFloat(this.amountWithdraw.toString().replaceAll(",", "")) <
                    parseFloat(this.limitAmount)
                ) {
                    this.messageBankWithdraw = this.getTranslatedText('Minimum withdrawal amount is [currency] [limit]', { limit: this.limitAmount, currency: this.currencyCode});
                    return false;
                }

                if (
                    parseFloat(this.amountWithdraw.toString().replaceAll(",", "")) >
                    parseFloat(this.maxiumAmount)
                ) {
                    this.messageBankWithdraw = this.getTranslatedText('Maximum withdrawal amount is [currency] [limit]', { limit: this.maxiumAmount, currency: this.currencyCode});
                    return false;
                }
                this.step = 2;
                this.$bvModal.hide("show-modal-withdraw");
            } else if(number === 3) {
                this.$bvModal.show("show-amount-share-credit");
                // this.step = 3;
            } else {
                this.step = number ? number : this.dataForm.methodsWithDraw === "1" ? 2 : 3;
            }
            this.$refs.bottomMenuWithdraw?.close();
        },

        onShowWithdraw() {
            this.$refs.bottomMenuWithdraw?.open();
        },

        onSubmitWithdraw() {
            console.log("onSubmitWithdraw");
        },

        updateWithDraw(event) {
            const { name, value } = event;
            this.dataForm[name] = value;
        },

        // on show add credit
        async onShowAddCredit() {

            const resp = await PaymentService.getGateway();

            if (resp.status === 200) {
                const data = resp.data;
                const config = data.config ? JSON.parse(data.config): null;

                if (config) {
                    const offsite = config.offsite ? config.offsite : 'no';
                    if (offsite === 'yes') {
                        this.offsite = true;
                    }
                }
                // this.step = 4;
                this.$bvModal.show("show-amount-popup");
                this.isShowPage = true
            } else {
                // this.step = 4;
                this.$bvModal.show("show-amount-popup");
                this.isShowPage = true
            }
        },

        renderClass(number, index) {
            return renderClassBall(
                number,
                index,
                this.minimumBall,
                this.maxiumBlue,
                this.maxiumGreen
            );
        },

        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },

        formatPrice(price) {
            price = price.replaceAll(',', '')
            return renderLocalePrice(parseFloat(price))
        },

        formatNumber(number) {
            return renderLocaleNumber(number)
        }
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script?.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
      removeScript(scriptTag1);
      removeScript(scriptTag2);
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/draws.scss" />
<style scoped lang="scss" src="@/assets/scss/mobile/wallet/_wallet.scss" />
