<template>
    <b-modal :id="id" 
        title="RECAPTCHA" 
        centered
        hide-footer>
        <div :ref="refName" :id="idRecaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div>
        <div class="message" id="mess-id">{{ getTranslatedText(messageRecaptcha) }}</div>
        <ButtonCustom
            :isLoading="isLoadingButton"
            text="submit"
            classBtn="submit-recaptcha"
            type="primary"
            @onClickButton="onClickButton"
        />
    </b-modal>
</template>

<script>
import ButtonCustom from "@/components/common/button";
import languageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [languageMixin],
    components: { ButtonCustom },
    props: {
        refName: {
            type: String,
            default: "",
        },
        idRecaptcha: {
            type: String,
            default: "",
        },
        sitekey: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        isLoadingButton: {
            type: Boolean,
            default: false
        },
        messageRecaptcha: {
            type: String,
            default: "",
        },
    },
    methods: {
        onClickButton() {
            this.$emit("onSubmitRecaptcha");
        },
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/confirm-recaptcha/_confirm-recaptcha.scss" />
