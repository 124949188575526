export const listUrlBuyNow = [
    '/buy/green-ce', 
    '/buy/green-', 
    '/tickets', 
    '/tickets%C2%A0and',
    '/buy/green',
    '/news/1000',
    '/tickets%20and',
    '/purchase/green-endorsements',
    '/tickets.',
    '/buy/green-ce...',
    '/buy/green-certificates-',
    '/pockets'
];
