export const phoneNumbers = [
    {
      "name": "Afghanistan",
      "flag": "Afghanistan.png",
      "number": "+93"
    },
    {
      "name": "Albania",
      "flag": "Flag_of_Albania.svg",
      "number": "+355"
    },
    {
      "name": "Algeria",
      "flag": "Flag_of_Algeria.svg",
      "number": "+213"
    },
    {
      "name": "Andorra",
      "flag": "Flag_of_Andorra.svg",
      "number": "+376"
    },
    {
      "name": "Angola",
      "flag": "Flag_of_Angola.svg",
      "number": "+244"
    },
    {
      "name": "Anguilla",
      "flag": "Flag_of_Anguilla.svg",
      "number": "+1264"
    },
    {
      "name": "Antigua and Barbuda",
      "flag": "Flag_of_Antigua_and_Barbuda.svg",
      "number": "+1268"
    },
    {
      "name": "Argentina",
      "flag": "Flag_of_Argentina.svg",
      "number": "+54"
    },
    {
      "name": "Armenia",
      "flag": "Flag_of_Armenia.svg",
      "number": "+374"
    },
    {
      "name": "Aruba",
      "flag": "Flag_of_Aruba.svg",
      "number": "+297"
    },
    {
      "name": "Australia",
      "flag": "Flag_of_Australia.svg",
      "number": "+61",
      "country": "AU"
    },
    {
      "name": "Austria",
      "flag": "Flag_of_Austria.svg",
      "number": "+43"
    },
    {
      "name": "Azerbaijan",
      "flag": "Flag_of_Azerbaijan.svg",
      "number": "+994"
    },
    {
      "name": "Bahamas",
      "flag": "Flag_of_the_Bahamas.svg",
      "number": "+1242"
    },
    {
      "name": "Bahrain",
      "flag": "Flag_of_Bahrain.svg",
      "number": "+973"
    },
    {
      "name": "Bangladesh",
      "flag": "Flag_of_Bangladesh.svg",
      "number": "+880"
    },
    {
      "name": "Barbados",
      "flag": "Flag_of_Barbados.svg",
      "number": "+1246"
    },
    {
      "name": "Belarus",
      "flag": "Flag_of_Belarus.svg",
      "number": "+375"
    },
    {
      "name": "Belgium",
      "flag": "Flag_of_Belgium.svg",
      "number": "+32"
    },
    {
      "name": "Belize",
      "flag": "Flag_of_Belize.svg",
      "number": "+501"
    },
    {
      "name": "Benin",
      "flag": "Flag_of_Benin.svg",
      "number": "+229"
    },
    {
      "name": "Bermuda",
      "flag": "Flag_of_Bermuda.svg",
      "number": "+1441"
    },
    {
      "name": "Bhutan",
      "flag": "Flag_of_Bhutan.svg",
      "number": "+975"
    },
    {
      "name": "Bosnia and Herzegovina",
      "flag": "Flag_of_Bosnia_and_Herzegovina.svg",
      "number": "+387"
    },
    {
      "name": "Botswana",
      "flag": "Flag_of_Botswana.svg",
      "number": "+267"
    },
    {
      "name": "Bouvet Island",
      "flag": "Flag_of_Norway.svg",
      "number": "+55"
    },
    {
      "name": "Brazil",
      "flag": "Flag_of_Brazil.svg",
      "number": "+55"
    },
    // {
    //   "name": "British Indian Ocean Territory",
    //   "flag": "Flag_of_the_British_Indian_Ocean_Territory.svg",
    //   "number": "+246"
    // },
    {
      "name": "Brunei Darussalam",
      "flag": "Flag_of_Brunei.svg",
      "number": "+673"
    },
    {
      "name": "Bulgaria",
      "flag": "Flag_of_Bulgaria.svg",
      "number": "+359"
    },
    {
      "name": "Burkina Faso",
      "flag": "Flag_of_Burkina_Faso.svg",
      "number": "+226"
    },
    {
      "name": "Burundi",
      "flag": "Flag_of_Burundi.svg",
      "number": "+257"
    },
    {
      "name": "Cambodia",
      "flag": "Flag_of_Cambodia.svg",
      "number": "+855"
    },
    {
      "name": "Cameroon",
      "flag": "Flag_of_Cameroon.svg",
      "number": "+237"
    },
    {
      "name": "Canada",
      "flag": "Flag_of_Canada.svg",
      "number": "+1",
      "country": "CA"
    },
    {
      "name": "Cape Verde",
      "flag": "Flag_of_Cape_Verde.svg",
      "number": "+238"
    },
    {
      "name": "Cayman Islands",
      "flag": "Flag_of_the_Cayman_Islands.svg",
      "number": "+1345"
    },
    {
      "name": "Central African Republic",
      "flag": "Flag_of_the_Central_African_Republic.svg",
      "number": "+236"
    },
    {
      "name": "Chad",
      "flag": "Flag_of_Chad.svg",
      "number": "+235"
    },
    {
      "name": "Chile",
      "flag": "Flag_of_Chile.svg",
      "number": "+56"
    },
    {
      "name": "China",
      "flag": "Flag_of_the_People's_Republic_of_China.svg",
      "number": "+86"
    },
    {
      "name": "Christmas Island",
      "flag": "Flag_of_Christmas_Island.svg",
      "number": "+61",
      "country": "CX"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "flag": "Flag_of_the_Cocos_(Keeling)_Islands.svg",
      "number": "+61",
      "country": "CC"
    },
    {
      "name": "Colombia",
      "flag": "Flag_of_Colombia.svg",
      "number": "+57"
    },
    {
      "name": "Comoros",
      "flag": "Flag_of_the_Comoros.svg",
      "number": "+269"
    },
    {
      "name": "Congo",
      "flag": "Flag_of_the_Republic_of_the_Congo.svg",
      "number": "+242"
    },
    {
      "name": "Congo",
      "flag": "Flag_of_the_Democratic_Republic_of_the_Congo.svg",
      "number": "+243"
    },
    {
      "name": "Cook Islands",
      "flag": "Flag_of_the_Cook_Islands.svg",
      "number": "+682"
    },
    // {
    //   "name": "Costa Rica",
    //   "flag": "Flag_of_Costa_Rica_%28state%29.svg",
    //   "number": "+506"
    // },
    {
      "name": "Croatia",
      "flag": "Flag_of_Croatia.svg",
      "number": "+385"
    },
    {
      "name": "Cuba",
      "flag": "Flag_of_Cuba.svg",
      "number": "+53"
    },
    {
      "name": "Cyprus",
      "flag": "Flag_of_Cyprus.svg",
      "number": "+357"
    },
    {
      "name": "Czech Republic",
      "flag": "Flag_of_the_Czech_Republic.svg",
      "number": "+420"
    },
    {
      "name": "Denmark",
      "flag": "Flag_of_Denmark.svg",
      "number": "+45"
    },
    {
      "name": "Djibouti",
      "flag": "Flag_of_Djibouti.svg",
      "number": "+253"
    },
    {
      "name": "Dominica",
      "flag": "Flag_of_Dominica.svg",
      "number": "+1767"
    },
    {
      "name": "Dominican Republic",
      "flag": "Flag_of_the_Dominican_Republic.svg",
      "number": "+1849"
    },
    {
      "name": "Ecuador",
      "flag": "Flag_of_Ecuador.svg",
      "number": "+593"
    },
    {
      "name": "Egypt",
      "flag": "Flag_of_Egypt.svg",
      "number": "+20"
    },
    {
      "name": "El Salvador",
      "flag": "Flag_of_El_Salvador.svg",
      "number": "+503"
    },
    {
      "name": "Equatorial Guinea",
      "flag": "Flag_of_Equatorial_Guinea.svg",
      "number": "+240"
    },
    {
      "name": "Eritrea",
      "flag": "Flag_of_Eritrea.svg",
      "number": "+291"
    },
    {
      "name": "Estonia",
      "flag": "Flag_of_Estonia.svg",
      "number": "+372"
    },
    {
      "name": "Ethiopia",
      "flag": "Flag_of_Ethiopia.svg",
      "number": "+251"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "flag": "Flag_of_the_Falkland_Islands.svg",
      "number": "+500",
      "country": "FK"
    },
    {
      "name": "Faroe Islands",
      "flag": "Flag_of_the_Faroe_Islands.svg",
      "number": "+298"
    },
    {
      "name": "Fiji",
      "flag": "Flag_of_Fiji.svg",
      "number": "+679"
    },
    {
      "name": "Finland",
      "flag": "Flag_of_Finland.svg",
      "number": "+358"
    },
    {
      "name": "France",
      "flag": "Flag_of_France.svg",
      "number": "+33"
    },
    {
      "name": "French Guiana",
      "flag": "Flag_of_French_Guiana.svg",
      "number": "+594"
    },
    {
      "name": "French Polynesia",
      "flag": "Flag_of_French_Polynesia.svg",
      "number": "+689"
    },
    {
      "name": "Gabon",
      "flag": "Flag_of_Gabon.svg",
      "number": "+241"
    },
    {
      "name": "Gambia",
      "flag": "Flag_of_The_Gambia.svg",
      "number": "+220"
    },
    {
      "name": "Georgia",
      "flag": "Flag_of_Georgia.svg",
      "number": "+995"
    },
    {
      "name": "Germany",
      "flag": "Flag_of_Germany.svg",
      "number": "+49"
    },
    {
      "name": "Ghana",
      "flag": "Flag_of_Ghana.svg",
      "number": "+233"
    },
    {
      "name": "Gibraltar",
      "flag": "Flag_of_Gibraltar.svg",
      "number": "+350"
    },
    {
      "name": "Greece",
      "flag": "Flag_of_Greece.svg",
      "number": "+30"
    },
    {
      "name": "Greenland",
      "flag": "Flag_of_Greenland.svg",
      "number": "+299"
    },
    {
      "name": "Grenada",
      "flag": "Flag_of_Grenada.svg",
      "number": "+1473"
    },
    {
      "name": "Guadeloupe",
      "flag": "Unofficial_flag_of_Guadeloupe.svg",
      "number": "+590"
    },
    {
      "name": "Guam",
      "flag": "Flag_of_Guam.svg",
      "number": "+1671"
    },
    {
      "name": "Guatemala",
      "flag": "Flag_of_Guatemala.svg",
      "number": "+502"
    },
    {
      "name": "Guernsey",
      "flag": "Flag_of_Guernsey.svg",
      "number": "+44",
      "country": "GG"
    },
    {
      "name": "Guinea",
      "flag": "Flag_of_Guinea.svg",
      "number": "+224"
    },
    {
      "name": "Guinea-Bissau",
      "flag": "Flag_of_Guinea-Bissau.svg",
      "number": "+245"
    },
    {
      "name": "Guyana",
      "flag": "Flag_of_Guyana.svg",
      "number": "+592"
    },
    {
      "name": "Haiti",
      "flag": "Flag_of_Haiti.svg",
      "number": "+509"
    },
    {
      "name": "Heard Island and McDonald Islands",
      "flag": "Proposed_flag_of_Antarctica.svg",
      "number": "+672"
    },
    {
      "name": "Holy See (Vatican City State)",
      "flag": "Flag_of_the_Vatican_City.svg",
      "number": "+379"
    },
    {
      "name": "Honduras",
      "flag": "Naval_Ensign_of_Honduras.svg",
      "number": "+504"
    },
    {
      "name": "Hong Kong",
      "flag": "Flag_of_Hong_Kong.svg",
      "number": "+852"
    },
    {
      "name": "Hungary",
      "flag": "Flag_of_Hungary.svg",
      "number": "+36"
    },
    {
      "name": "Iceland",
      "flag": "Flag_of_Iceland.svg",
      "number": "+354"
    },
    {
      "name": "India",
      "flag": "Flag_of_India.svg",
      "number": "+91"
    },
    {
      "name": "Indonesia",
      "flag": "Flag_of_Indonesia.svg",
      "number": "+62"
    },
    {
      "name": "Iraq",
      "flag": "Flag_of_Iraq.svg",
      "number": "+964"
    },
    {
      "name": "Iran",
      "flag": "Flag_of_Iran.svg",
      "number": "+98"
    },
    {
      "name": "Ireland",
      "flag": "Flag_of_Ireland.svg",
      "number": "+353"
    },
    {
      "name": "Isle of Man",
      "flag": "Flag_of_the_Isle_of_Man.svg",
      "number": "+44",
      "country": "IM"
    },
    {
      "name": "Israel",
      "flag": "Flag_of_Israel.svg",
      "number": "+972"
    },
    {
      "name": "Italy",
      "flag": "Flag_of_Italy.svg",
      "number": "+39"
    },
    {
      "name": "Jamaica",
      "flag": "Flag_of_Jamaica.svg",
      "number": "+1876"
    },
    {
      "name": "Japan",
      "flag": "Flag_of_Japan.svg",
      "number": "+81"
    },
    {
      "name": "Jersey",
      "flag": "Flag_of_Jersey.svg",
      "number": "+44",
      "country": "JE"
    },
    {
      "name": "Jordan",
      "flag": "Flag_of_Jordan.svg",
      "number": "+962"
    },
    {
      "name": "Kazakhstan",
      "flag": "Flag_of_Kazakhstan.svg",
      "number": "+7"
    },
    {
      "name": "Kenya",
      "flag": "Flag_of_Kenya.svg",
      "number": "+254"
    },
    {
      "name": "Kiribati",
      "flag": "Flag_of_Kiribati.svg",
      "number": "+686"
    },
    {
      "name": "Kuwait",
      "flag": "Flag_of_Kuwait.svg",
      "number": "+965"
    },
    {
      "name": "Kyrgyzstan",
      "flag": "Flag_of_Kyrgyzstan.svg",
      "number": "+996"
    },
    {
      "name": "Lao People's Democratic Republic",
      "flag": "Flag_of_Laos.svg",
      "number": "+856"
    },
    {
      "name": "Latvia",
      "flag": "Flag_of_Latvia.svg",
      "number": "+371"
    },
    {
      "name": "Lebanon",
      "flag": "Flag_of_Lebanon.svg",
      "number": "+961"
    },
    {
      "name": "Lesotho",
      "flag": "Flag_of_Lesotho.svg",
      "number": "+266"
    },
    {
      "name": "Liberia",
      "flag": "Flag_of_Liberia.svg",
      "number": "+231"
    },
    {
      "name": "Liechtenstein",
      "flag": "Flag_of_Liechtenstein.svg",
      "number": "+423"
    },
    // {
    //   "name": "Lithuania",
    //   "flag": "Flag_of_Lithuania.svg",
    //   "number": "+370"
    // },
    {
      "name": "Luxembourg",
      "flag": "Flag_of_Luxembourg.svg",
      "number": "+352"
    },
    {
      "name": "Macao",
      "flag": "Flag_of_Macau.svg",
      "number": "+853"
    },
    {
      "name": "Madagascar",
      "flag": "Flag_of_Madagascar.svg",
      "number": "+261"
    },
    {
      "name": "Malawi",
      "flag": "Flag_of_Malawi.svg",
      "number": "+265"
    },
    {
      "name": "Malaysia",
      "flag": "Flag_of_Malaysia.svg",
      "number": "+60"
    },
    {
      "name": "Maldives",
      "flag": "Flag_of_Maldives.svg",
      "number": "+960"
    },
    {
      "name": "Mali",
      "flag": "Flag_of_Mali.svg",
      "number": "+223"
    },
    {
      "name": "Malta",
      "flag": "Flag_of_Malta.svg",
      "number": "+356"
    },
    {
      "name": "Marshall Islands",
      "flag": "Flag_of_the_Marshall_Islands.svg",
      "number": "+692"
    },
    {
      "name": "Martinique",
      "flag": "Unofficial_flag_of_Guadeloupe.svg",
      "number": "+596"
    },
    {
      "name": "Mauritania",
      "flag": "Flag_of_Mauritania.svg",
      "number": "+222"
    },
    {
      "name": "Mauritius",
      "flag": "Flag_of_Mauritius.svg",
      "number": "+230"
    },
    {
      "name": "Mayotte",
      "flag": "Flag_of_France.svg",
      "number": "+262",
      "country": "YT"
    },
    {
      "name": "Mexico",
      "flag": "Flag_of_Mexico.svg",
      "number": "+52"
    },
    {
      "name": "Monaco",
      "flag": "Flag_of_Monaco.svg",
      "number": "+377"
    },
    {
      "name": "Mongolia",
      "flag": "Flag_of_Mongolia.svg",
      "number": "+976"
    },
    {
      "name": "Montenegro",
      "flag": "Flag_of_Montenegro.svg",
      "number": "+382"
    },
    {
      "name": "Montserrat",
      "flag": "Flag_of_Montserrat.svg",
      "number": "+1664"
    },
    {
      "name": "Morocco",
      "flag": "Flag_of_Morocco.svg",
      "number": "+212"
    },
    {
      "name": "Mozambique",
      "flag": "Flag_of_Mozambique.svg",
      "number": "+258"
    },
    {
      "name": "Myanmar",
      "flag": "Flag_of_Myanmar.svg",
      "number": "+95"
    },
    {
      "name": "Namibia",
      "flag": "Flag_of_Namibia.svg",
      "number": "+264"
    },
    {
      "name": "Nauru",
      "flag": "Flag_of_Nauru.svg",
      "number": "+674"
    },
    {
      "name": "Nepal",
      "flag": "Flag_of_Nepal.svg",
      "number": "+977"
    },
    {
      "name": "Netherlands",
      "flag": "Flag_of_the_Netherlands.svg",
      "number": "+31"
    },
    {
      "name": "New Caledonia",
      "flag": "Flags_of_New_Caledonia.svg",
      "number": "+687"
    },
    {
      "name": "New Zealand",
      "flag": "Flag_of_New_Zealand.svg",
      "number": "+64"
    },
    {
      "name": "Nicaragua",
      "flag": "Flag_of_Nicaragua.svg",
      "number": "+505"
    },
    {
      "name": "Niger",
      "flag": "Flag_of_Niger.svg",
      "number": "+227"
    },
    {
      "name": "Nigeria",
      "flag": "Flag_of_Nigeria.svg",
      "number": "+234"
    },
    {
      "name": "Niue",
      "flag": "Flag_of_Niue.svg",
      "number": "+683"
    },
    {
      "name": "Norfolk Island",
      "flag": "Flag_of_Norfolk_Island.svg",
      "number": "+672"
    },
    {
      "name": "Northern Mariana Islands",
      "flag": "Flag_of_the_Northern_Mariana_Islands.svg",
      "number": "+1670"
    },
    {
      "name": "Norway",
      "flag": "Flag_of_Norway.svg",
      "number": "+47"
    },
    {
      "name": "Oman",
      "flag": "Flag_of_Oman.svg",
      "number": "+968"
    },
    {
      "name": "Pakistan",
      "flag": "Flag_of_Pakistan.svg",
      "number": "+92"
    },
    {
      "name": "Palau",
      "flag": "Flag_of_Palau.svg",
      "number": "+680"
    },
    {
      "name": "Panama",
      "flag": "Flag_of_Panama.svg",
      "number": "+507"
    },
    {
      "name": "Papua New Guinea",
      "flag": "Flag_of_Papua_New_Guinea.svg",
      "number": "+675"
    },
    {
      "name": "Paraguay",
      "flag": "Flag_of_Paraguay.svg",
      "number": "+595"
    },
    {
      "name": "Peru",
      "flag": "Flag_of_Peru.svg",
      "number": "+51"
    },
    {
      "name": "Philippines",
      "flag": "Flag_of_the_Philippines.svg",
      "number": "+63"
    },
    {
      "name": "Pitcairn",
      "flag": "Flag_of_the_Pitcairn_Islands.svg",
      "number": "+870"
    },
    {
      "name": "Poland",
      "flag": "Flag_of_Poland.svg",
      "number": "+48"
    },
    {
      "name": "Portugal",
      "flag": "Flag_of_Portugal.svg",
      "number": "+351"
    },
    {
      "name": "Puerto Rico",
      "flag": "Flag_of_Puerto_Rico.svg",
      "number": "+1939"
    },
    {
      "name": "Qatar",
      "flag": "Flag_of_Qatar.svg",
      "number": "+974"
    },
    {
      "name": "Réunion",
      "flag": "Flag_of_France.svg",
      "number": "+262",
      "country": "RE"
    },
    {
      "name": "Romania",
      "flag": "Flag_of_Romania.svg",
      "number": "+40"
    },
    {
      "name": "Rwanda",
      "flag": "Flag_of_Rwanda.svg",
      "number": "+250"
    },
    {
      "name": "Saint Kitts and Nevis",
      "flag": "Flag_of_Saint_Kitts_and_Nevis.svg",
      "number": "+1869"
    },
    {
      "name": "Saint Lucia",
      "flag": "Flag_of_Saint_Lucia.svg",
      "number": "+1758"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "flag": "Flag_of_Saint-Pierre_and_Miquelon.svg",
      "number": "+508"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "flag": "Flag_of_Saint_Vincent_and_the_Grenadines.svg",
      "number": "+1784"
    },
    {
      "name": "Samoa",
      "flag": "Flag_of_Samoa.svg",
      "number": "+685"
    },
    {
      "name": "San Marino",
      "flag": "Flag_of_San_Marino.svg",
      "number": "+378"
    },
    {
      "name": "Sao Tome and Principe",
      "flag": "Flag_of_Sao_Tome_and_Principe.svg",
      "number": "+239"
    },
    {
      "name": "Saudi Arabia",
      "flag": "Flag_of_Saudi_Arabia.svg",
      "number": "+966"
    },
    {
      "name": "Senegal",
      "flag": "Flag_of_Senegal.svg",
      "number": "+221"
    },
    {
      "name": "Serbia",
      "flag": "Flag_of_Serbia.svg",
      "number": "+381"
    },
    {
      "name": "Seychelles",
      "flag": "Flag_of_Seychelles.svg",
      "number": "+248"
    },
    {
      "name": "Sierra Leone",
      "flag": "Flag_of_Sierra_Leone.svg",
      "number": "+232"
    },
    {
      "name": "Singapore",
      "flag": "Flag_of_Singapore.svg",
      "number": "+65"
    },
    {
      "name": "Slovakia",
      "flag": "Flag_of_Slovakia.svg",
      "number": "+421"
    },
    {
      "name": "Slovenia",
      "flag": "Flag_of_Slovenia.svg",
      "number": "+386"
    },
    {
      "name": "Solomon Islands",
      "flag": "Flag_of_the_Solomon_Islands.svg",
      "number": "+677"
    },
    {
      "name": "Somalia",
      "flag": "Flag_of_Somalia.svg",
      "number": "+252"
    },
    {
      "name": "South Africa",
      "flag": "Flag_of_South_Africa.svg",
      "number": "+27"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "flag": "Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
      "number": "+500",
      "country": "GS"
    },
    {
      "name": "Spain",
      "flag": "Flag_of_Spain.svg",
      "number": "+34"
    },
    {
      "name": "Sri Lanka",
      "flag": "Flag_of_Sri_Lanka.svg",
      "number": "+94"
    },
    {
      "name": "Sudan",
      "flag": "Flag_of_Sudan.svg",
      "number": "+249"
    },
    {
      "name": "Suriname",
      "flag": "Flag_of_Suriname.svg",
      "number": "+597"
    },
    {
      "name": "Eswatini",
      "flag": "Flag_of_Swaziland_1894.svg",
      "number": "+268"
    },
    {
      "name": "Sweden",
      "flag": "Flag_of_Sweden.svg",
      "number": "+46"
    },
    {
      "name": "Switzerland",
      "flag": "Flag_of_Switzerland.svg",
      "number": "+41"
    },
    {
      "name": "Syrian Arab Republic",
      "flag": "Flag_of_Syria.svg",
      "number": "+963"
    },
    {
      "name": "Taiwan",
      "flag": "Flag_of_the_Republic_of_China.svg",
      "number": "+886"
    },
    {
      "name": "Tajikistan",
      "flag": "Flag_of_Tajikistan.svg",
      "number": "+992"
    },
    {
      "name": "Thailand",
      "flag": "Flag_of_Thailand.svg",
      "number": "+66"
    },
    {
      "name": "Timor-Leste",
      "flag": "Flag_of_East_Timor.svg",
      "number": "+670"
    },
    {
      "name": "Togo",
      "flag": "Flag_of_Togo.svg",
      "number": "+228"
    },
    {
      "name": "Tokelau",
      "flag": "Flag_of_Tokelau.svg",
      "number": "+690"
    },
    {
      "name": "Tonga",
      "flag": "Flag_of_Tonga.svg",
      "number": "+676"
    },
    {
      "name": "Trinidad and Tobago",
      "flag": "Flag_of_Trinidad_and_Tobago.svg",
      "number": "+1868"
    },
    {
      "name": "Tunisia",
      "flag": "Flag_of_Tunisia.svg",
      "number": "+216"
    },
    {
      "name": "Turkey",
      "flag": "Flag_of_Turkey.svg",
      "number": "+90"
    },
    {
      "name": "Turkmenistan",
      "flag": "Flag_of_Turkmenistan.svg",
      "number": "+993"
    },
    // {
    //   "name": "Turks and Caicos Islands",
    //   "flag": "Flag_of_the_Turks_and_Caicos_Islands.svg",
    //   "number": "+1649"
    // },
    {
      "name": "Tuvalu",
      "flag": "Flag_of_Tuvalu.svg",
      "number": "+688"
    },
    {
      "name": "Uganda",
      "flag": "Flag_of_Uganda.svg",
      "number": "+256"
    },
    {
      "name": "Ukraine",
      "flag": "Flag_of_Ukraine.svg",
      "number": "+380"
    },
    {
      "name": "United Arab Emirates",
      "flag": "Flag_of_the_United_Arab_Emirates.svg",
      "number": "+971"
    },
    {
      "name": "United Kingdom",
      "flag": "Flag_of_the_United_Kingdom.svg",
      "number": "+44",
      "country": "GB"
    },
    {
      "name": "United States",
      "flag": "Flag_of_the_United_States.svg",
      "number": "+1",
      "country": "US"
    },
    {
      "name": "United States Minor Outlying Islands",
      "flag": "Flag_of_the_United_States.svg",
      "number": "+1581"
    },
    {
      "name": "Uruguay",
      "flag": "Flag_of_Uruguay.svg",
      "number": "+598"
    },
    {
      "name": "Uzbekistan",
      "flag": "Flag_of_Uzbekistan.svg",
      "number": "+998"
    },
    {
      "name": "Vanuatu",
      "flag": "Flag_of_Vanuatu.svg",
      "number": "+678"
    },
    {
      "name": "Viet Nam",
      "flag": "Flag_of_Vietnam.svg",
      "number": "+84"
    },
    {
      "name": "Wallis and Futuna",
      "flag": "Flag_of_Wallis_and_Futuna.svg",
      "number": "+681"
    },
    {
      "name": "Yemen",
      "flag": "Flag_of_Yemen.svg",
      "number": "+967"
    },
    {
      "name": "Zambia",
      "flag": "Flag_of_Zambia.svg",
      "number": "+260"
    },
    {
      "name": "Zimbabwe",
      "flag": "Flag_of_Zimbabwe.svg",
      "number": "+263"
    },
    {
      "name": "Tanzania",
      "flag": "Flag_of_Tanzania.svg",
      "number": "+255"
    },
    {
      "name": "South Korea",
      "flag": "Flag_of_South_Korea.svg",
      "number": "+82"
    },
    {
      "name": "Venezuela",
      "flag": "Flag_of_Venezuela.svg",
      "number": "+58"
    },
    {
      "name": "Cote D’Ivoire",
      "flag": "Flag_of_Côte_d'Ivoire.svg",
      "number": "+225"
    },
    {
      "name": "Macedonia",
      "flag": "Flag_of_North_Macedonia.svg",
      "number": "+389"
    }
  ]