<template>
    <div class="section-landing-page">
        <div class="banner-section">
            <img class="img-banner" width="100%" height="100%" src="@/assets/images/landing-page/banner.webp" alt="" />
            <div class="content-child">
                <div class="head-section">
                    <carousel v-if="!isScreenMobile && bannersList && bannersList.section_top && bannersList.section_top.desktop.length" class="slide" :items="1" :dots="false" :autoplayTimeout="6000" :autoplay="true" :nav="false">
                        <template slot="prev"><img class="img-arrow left" width="40" height="100%" src="@/assets/images/landing-page/arrow-left.svg" alt="" /></template>
                        <img @click="redirectRouter('/numbers')" v-for="(item, index) in bannersList.section_top.desktop" :key="index" class="img" width="100%" height="566" :src="item.url" alt="" />
                        <template v-if="bannersList.section_top.desktop.length > 1" slot="next"><img class="img-arrow right" width="40" height="100%" src="@/assets/images/landing-page/arrow-right.svg" alt="" /></template>
                    </carousel>
                    <carousel v-if="isScreenMobile && bannersList && bannersList.section_top && bannersList.section_top.mobile.length" class="slide" :items="1" :dots="false" :autoplayTimeout="6000" :autoplay="true" :nav="false">
                        <img v-for="(item, index) in bannersList.section_top.mobile" :key="index" class="img" width="100%" height="566" :src="item.url" alt="" />
                    </carousel>
                    <!-- <img class="img-head" width="358" height="397" v-if="isScreenMobile" src="@/assets/images/landing-page/bg-fr-mb.webp" alt="" /> -->
                    <div class="contents">
                        <!-- <img class="img-sub" v-if="isScreenMobile" width="1000" height="523" src="@/assets/images/landing-page/person-frame-mb.webp" alt="" /> -->
                        <!-- <img class="img-sub" v-else width="1000" height="523" src="@/assets/images/landing-page/person-frame.webp" alt="" /> -->
                        <div class="content-prize">
                            <div class="content-section">
                                <h1 v-if="!isScreenMobile">
                                    <div>
                                        <span class="text-title">{{ getTranslatedText('PLAY YOUR') }}</span><br/>
                                        <div class="text-title cost">{{ getTranslatedText('7 LUCKY') }}</div>
                                    </div>
                                    <div>
                                        <div class="sub-title">{{ getTranslatedText('NUMBERS') }}</div>
                                        <!-- <div class="sub-title">{{ getTranslatedText('Green Certificate for only') }}
                                            <b>{{ getTranslatedText(currencyCode) +  getTranslatedText(' 25') }}</b>
                                        </div> -->
                                    </div>
                                </h1>
                                <h1 v-else class="text-mobile">
                                    <span class="win">{{ getTranslatedText('Win') }}</span> <span>{{ getTranslatedText('your') }}</span><br/>
                                    <span >{{ getTranslatedText('best') }}</span> <span>{{ getTranslatedText('life') }}</span>
                                </h1>
                                <!-- <div v-if="!isScreenMobile" class="group-btn">
                                    <button class="buy-now-btn" @click="redirectRouter('/numbers')">{{ getTranslatedText('play now') }} </button>
                                    <button class="sign-in-btn" v-if="!$store.state.isLoggedIn && !isScreenMobile" @click="$router.push('/login')">
                                        {{ getTranslatedText('sign in') }}
                                    </button>
                                </div> -->
                            </div>
                            <div v-if="!isScreenMobile" class="prize-info">
                                    <div class="info">
                                        <div class="top">
                                            <div class="win">{{ getTranslatedText('WIN THE') }}</div>
                                            <div class="grand">{{ getTranslatedText('GRAND PRIZE') }}</div>
                                        </div>
                                        <div class="bottom">
                                            <div class="cost">
                                                <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                                                {{ fomarNumberPrice($store.state.listGameLoto[0]?.estimated_jackpot || "0") }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <div class="top">
                                            <div class="win">{{ getTranslatedText('DOUBLE THE') }}</div>
                                            <div class="grand">{{ getTranslatedText('GRAND PRIZE') }}</div>
                                        </div>
                                        <div class="bottom">
                                            <div class="cost">
                                                <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                                                {{ formatNumberWinners($store.state.listGameLoto[0]?.estimated_jackpot, 2) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <div class="top">
                                            <div class="win">{{ getTranslatedText('WIN WEEKLY') }}</div>
                                            <div class="grand">{{ getTranslatedText('RAFFLE DRAW') }}</div>
                                        </div>
                                        <div class="bottom">
                                            <div class="cost">
                                                <div class="currency">{{ getTranslatedText(currencyCode) }}</div>
                                                {{ fomarNumberPrice('100000') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info-title">{{ getTranslatedText('*Prizes displayed are in AED, and exchange rates may vary.') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="btn"><button v-if="isScreenMobile" class="green-btn" @click="redirectRouter('/numbers')">{{ getTranslatedText('PLAY NOW') }} </button></div>
                </div>
                <div v-if="!isScreenMobile" class="contents-bottom">
                    <div class="content">
                        <div class="live-watch" @click="$router.push('/live-watch')">
                            <img class="img-yt" width="136" height="136" src="@/assets/images/landing-page/view-live.webp" alt="" />
                            <div class="text-watch">
                                {{ getTranslatedText('Watch') }}
                                <span class="the">{{ getTranslatedText('the') }}</span>
                                <div class="live-draw">{{ getTranslatedText('LIVE DRAW') }}</div>
                            </div>
                        </div>
                        <div class="time-live">
                            {{ getTranslatedText('THIS THURSDAY') }}
                            <div class="time">{{ getTranslatedText('at 8PM (GST)') }}</div>
                        </div>
                        <div class="countdown">
                            <div class="next-draw">{{ getTranslatedText('Next Draw') }}</div>
                            <div class="count-date">
                                <div class="date days">
                                    <div id="days" class="date-circle"></div>
                                    <span>{{ getTranslatedText('days') }}</span>
                                </div>
                                <div class="date hours">
                                    <div id="hours" class="date-circle"></div>
                                    <span>{{ getTranslatedText('hours') }}</span>
                                </div>
                                <div class="date mins">
                                    <div id="mins" class="date-circle"></div>
                                    <span>{{ getTranslatedText('mins') }}</span>
                                </div>
                                <div class="date secs">
                                    <div id="secs" class="date-circle"></div>
                                    <span>{{ getTranslatedText('secs') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isScreenMobile" class="moblie-section">
            <!-- <img class="img-green" width="430" height="280" src="@/assets/images/landing-page/green-certificate-and-seed.webp" alt="" /> -->
            <div class="prize">
                <div class="group">
                    <div class="win"><div>{{ getTranslatedText('win the')}} <br/> <span>{{'GRAND PRIZE'}}</span></div></div>
                    <div class="cost"><span>{{ getTranslatedText(currencyCode) }}</span> {{ fomarNumberPrice($store.state.listGameLoto[0]?.estimated_jackpot || "0") }}</div>
                </div>
                <div class="group">
                    <div class="win"><div>{{ getTranslatedText('DOUBLE THE')}} <br/> <span>{{'GRAND PRIZE'}}</span></div></div>
                    <div class="cost"><span>{{ getTranslatedText(currencyCode) }}</span> {{ formatNumberWinners($store.state.listGameLoto[0]?.estimated_jackpot, 2) }}</div>
                </div>
                <div class="group">
                    <div class="win"><div>{{ getTranslatedText('WIN WEEKLY')}} <br/> <span>{{'RAFFLE DRAW'}}</span></div></div>
                    <div class="cost"><span>{{ getTranslatedText(currencyCode) }}</span> {{ fomarNumberPrice('100000') }}</div>
                </div>
            </div>
            <div class="group-date-draw">
                <span class="title">{{ getTranslatedText('NEXT DRAW DATE') }}</span>
                <span class="content">{{ getNextDraw($store.state.listGameLoto[0]?.draw_date) }}</span>
            </div>
            <div class="countdown">
                <div class="count-date">
                    <div class="date days">
                        <div id="days" class="date-circle"></div>
                        <span>{{ getTranslatedText('days') }}</span>
                    </div>
                    <div class="date hours">
                        <div id="hours" class="date-circle"></div>
                        <span>{{ getTranslatedText('hours') }}</span>
                    </div>
                    <div class="date mins">
                        <div id="mins" class="date-circle"></div>
                        <span>{{ getTranslatedText('mins') }}</span>
                    </div>
                    <div class="date secs">
                        <div id="secs" class="date-circle"></div>
                        <span>{{ getTranslatedText('secs') }}</span>
                    </div>
                </div>
            </div>
            <div class="watch">
                <div class="live-watch" @click="$router.push('/live-watch')">
                    <img class="img-yt" width="136" height="136" src="@/assets/images/landing-page/view-live.webp" alt="" />
                    <div class="text-watch">
                        {{ getTranslatedText('Watch the') }}
                        <div class="live-draw">{{ getTranslatedText('LIVE DRAW') }}</div>
                    </div>
                </div>
                <div class="time-live">
                    {{ getTranslatedText('THIS THURSDAY') }}
                    <div class="time">{{ getTranslatedText('at 8PM (GST)') }}</div>
                </div>
            </div>
            <p class="title-meet-winner">{{ getTranslatedText('MEET OUR WINNERS') }}</p>
            <div class="meet-winner">
                <div v-for="(item, index) in bannersList?.section_winner?.mobile" :key="index">
                    <a :href="item.link" target="_blank"><img class="img-meet-winner" width="100%" height="100%" :src="item.url" alt="" /></a>
                </div>
            </div>
        </div>
        <div v-if="!isScreenMobile" class="first-section">
            <div class="left">
                <div class="win">{{ getTranslatedText('WIN THE GRAND PRIZE') }}</div>
                <div class="match">{{ getTranslatedText('MATCH 7 NUMBERS IN ANY ORDER') }}</div>
                <div>
                    <div class="item-title">
                        <div class="number1">
                            <img width="130" height="261" src="@/assets/images/landing-page/number1.webp" alt=""/>
                        </div>
                        <p class="text">{{ getTranslatedText('PRIZE') }}</p>
                        <p class="text">{{ getTranslatedText('MATCH') }}</p>
                        <p class="text">{{ getTranslatedText('WIN') }}</p>
                    </div>
                    <div class="item-main"  v-for="(item, i) in listWinnings" :key="i">
                        <div class="item text">{{ getTranslatedText(item.title)}}
                            <div class="prize">{{ getTranslatedText('prize')}}</div>
                        </div>
                        <div class="item text-sub">{{ getTranslatedText(item.name)}}
                            <img class="prize-img" :src="require(`@/assets/images/landing-page/${item.icon}.webp`)"/>
                        </div>
                        <div class="item blue" v-if="i===0">{{ getTranslatedText(currencyCode) + ' ' + fomarNumberPrice($store.state.listGameLoto[0]?.estimated_jackpot || "0") }}</div>
                        <div class="item blue" v-else>{{ getTranslatedText(currencyCode) + ' ' + fomarNumberPrice(item.prize * exchangeRate) }}</div>
                    </div>
                    <div class="note">{{ getTranslatedText('*The Grand Prize and Second Prize are equally divided among numbers') }}</div>
                </div>
            </div>
            <img class="introduce" width="503" height="650" src="@/assets/images/landing-page/introduce.webp" alt=""/>
        </div>
        <div v-if="!isScreenMobile" class="second-section">
            <div class="content-number">
                <div class="numbers number-left">
                    <img class="number" width="156" height="261" src="@/assets/images/landing-page/number2.webp" alt=""/>
                </div>
                <div class="numbers number-right">
                    <img class="number" width="156" height="261" src="@/assets/images/landing-page/number3.webp" alt=""/>
                </div>
            </div>
            <div class="content">
                <div class="cnts double">
                    <!-- <img src="@/assets/images/landing-page/double.webp" alt=""/> -->
                    <!-- <div class="contents"> -->
                        <div class="title">{{ getTranslatedText('WIN UP TO AED 200 MILLION')}} <br/>
                            <span>{{ getTranslatedText('DOUBLE THE GRAND PRIZE')}}</span>
                        </div>
                        <div class="group-btn">
                            <div class="sub-title">{{ getTranslatedText('If you choose to') }}
                                <b>{{ getTranslatedText('Double & Secure1') }}</b>
                                {{ getTranslatedText('the Grand Prize, get ready to win up to AED 200,000,000 without splitting it with other Grand Prize winners.') }}
                            </div>
                            <button class="more-btn" @click="navigateUrl('/prize-breakdown?action=1', true)">{{ getTranslatedText('learn more') }} </button>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="cnts single">
                    <!-- <img src="@/assets/images/landing-page/single.webp" alt=""/> -->
                    <div class="title raffle">{{ getTranslatedText('WIN AED 100,000 WEEKLY')}} <br/>
                        <span class="certificate">{{ getTranslatedText('MATCH GREEN CERTIFICATE ID WITH RAFFLE ID')}}</span>
                    </div>
                    <div class="group-btn right">
                        <div class="sub-title">{{ getTranslatedText('One Guaranteed Raffle Winner each week when your') }}
                            <b>{{ getTranslatedText('Green Certificate ID') }}</b>
                            {{ getTranslatedText('matches with the Raffle ID') }}
                        </div>
                        <button class="more-btn learn" @click="navigateUrl('/prize-breakdown?action=2', true)">{{ getTranslatedText('learn more') }} </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isScreenMobile" class="footer-section">
            <carousel class="slide" :items="1" :dots="true" :autoplayTimeout="6000" :autoplay="true" :nav="false">
                <img class="img" width="766" height="523" src="@/assets/images/landing-page/Yousuf.webp" alt="" />
                <img class="img" width="766" height="523" src="@/assets/images/landing-page/Khalid.webp" alt="" />
                <img class="img" width="766" height="523" src="@/assets/images/landing-page/Debande.webp" alt="" />
            </carousel>
            <div class="right">
                <div class="title">{{ getTranslatedText('Let’s Hear from our Recent Winners') }}</div>
                <div class="winners">
                    <div class="content-winner" v-for="(item, index) in winnersList" :key="index">
                        <div class="content-user">
                            <img class="avt" width="80" height="80" :src="item.avatar" @error="errorImage"/>
                            <div class="info">
                                <b v-if="item.name">{{ item.name }}</b>
                                <div class="money" v-if="item.total_win">
                                    {{ getTranslatedText('Won') + ' ' + getTranslatedText(currencyCode) + ' ' + fomarNumberPrice(item.total_win) }}
                                </div>
                            </div>
                        </div>
                        <div class="section-draw__box-button" v-if="item.link_youtube">
                            <button @click="navigateUrl(item.link_youtube)" class="section-draw__box-button__button green">{{ getTranslatedText("view video") }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import carousel from 'vue-owl-carousel'
import LanguageMixin from "@/mixins/language/language-mixin";
import { redirectPathApp } from '@/components/common/utils';
import { renderLocalePrice } from "@/components/common/utils";
import DrawsService from "@/services/draws.service";
import WinnersService from "@/services/winners.service";
import BannerService from "@/services/banner.service";
import moment from "moment";
import { phoneNumbers } from "@/resources/phoneNumber";
import img from '@/assets/images/landing-page/avatar-default.png';

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
let scriptTag

export default {
    mixins: [LanguageMixin],
    components: { carousel },
    data() {
        return {
             images: [
        "https://placeimg.com/200/200/any?1",
        "https://placeimg.com/200/200/any?2",
        "https://placeimg.com/200/200/any?3",
        "https://placeimg.com/200/200/any?4",
        "https://placeimg.com/200/200/any?5",
        "https://placeimg.com/200/200/any?6",
        "https://placeimg.com/200/200/any?7",
        "https://placeimg.com/200/200/any?8",
        "https://placeimg.com/200/200/any?9",
        "https://placeimg.com/200/200/any?10"
      ],
            currencyCode: CURRENCY_CODE,
            screenWidth: window.innerWidth,
            isScreenMobile: false,
            isScreenPhone: false,
            isScreenSetSize: false,
            listWinnings: [],
            exchangeRate: EXCHANGE_RATE,
            winnersList: [],
            bannersList: null,
            phoneList: phoneNumbers
        };
    },
    watch: {
        screenWidth(value) {
            if (value <= 991) {
                    this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
            }
        },
    },
    created() {
        if (this.screenWidth <= 991) {
            this.isScreenMobile = true
        }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        this.$store.dispatch("getListGame");
        this.getCountDownDate()
        this.getListPastDraws()
        this.getBannerList()
        this.getWinnersList()
    },

    mounted() {
        this.addNetcore()
    },

    // metaInfo() {
    //     return {
    //         title: "Win over AED 100 million with O! Millionaire | Match 7 Numbers in ANY ORDER for just AED 25!",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Turn AED 25 to millions with O! Millionaire. Match only 7 numbers & win up to AED 200M. Start your millionaire journey with a Green Certificate today!'
    //             },
    //             {
    //                 property: "og:type",
    //                 content: "website"
    //             },
    //             {
    //                 property: "og:url",
    //                 content: "https://omillionaire.com/"
    //             },
    //             {
    //                 property: "og:title",
    //                 content: "Win over AED 100 million with O! Millionaire | Match 7 Numbers in ANY ORDER for just AED 25!"
    //             },
    //             {
    //                 property: "og:description",
    //                 content: "Turn AED 25 to millions with O! Millionaire. Match only 7 numbers & win up to AED 200M. Start your millionaire journey with a Green Certificate today!"
    //             },
    //             {
    //                 property: "og:site_name",
    //                 content: "Omillionaire"
    //             },
    //             {
    //                 property: "og:image",
    //                 content: "http://omillionaireresults.com/wp-content/uploads/2023/05/OM-Result-Banner.png"
    //             },
    //         ]
    //     };
    // },
    methods: {
        async addNetcore() {
            let data = await JSON.parse(localStorage.getItem("balance_data"))
            let balance = data ? parseInt(data.balance) : "'N/A'"
            let currency = data ? data.currency_code : this.currencyCode
            let user = JSON.parse(localStorage.getItem("user_info"))
            let id = user ? parseInt(user.id) : "'N/A'"
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'home_pageload',
                    'user_id': ${id},
                    'wallet_amount': ${balance},
                    'currency': '${currency}'
                });
            `;            
            document.head.appendChild(scriptTag);
        },
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        redirectRouter(path) {
            if (this.$store.state.is_app) {
                redirectPathApp(path);
            } else {
                this.$router.push(path);
            }
        },
        getNextDraw(date) {
            return moment(date).format("MMMM DD, YYYY")
        },
        errorImage(e) {
            e.target.src = img
        },
        fomarNumberPrice(price) {
            price = parseInt(price)
            return price.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        },
        redirectRouterYoutube() {
            window.open('https://www.youtube.com/@OMillionaire/streams', "_blank")
        },
        getNextThursday(date) {
            const dateCopy = new Date(date.getTime());

            const nextThursday = new Date(
                dateCopy.setDate(
                dateCopy.getDate() + ((7 - dateCopy.getDay() + 4) % 7 || 7),
                ),
            );

            return nextThursday.setHours(20, 0, 0, 0);
        },
        getCountDownDate() {
            let d = new Date();
            let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
            let uaeDate = new Date(utc + (3600000*4));
            let countDownDate
            if(uaeDate.getDay() === 4 && uaeDate.getTime() <= uaeDate.setHours(20, 0, 0, 0)) {
                const date = new Date(utc + (3600000*4));
                countDownDate = date.setHours(20, 0, 0, 0);
            } else {
                countDownDate = this.getNextThursday(uaeDate);
            }
            const updateElement = (elementId, value) => {
                const element = document.getElementById(elementId);
                if (element) {
                    element.innerHTML = value;
                }
            };
            let myFunction = setInterval(function() {
                let d = new Date();
                let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                let uaeDate = new Date(utc + (3600000*4));
                let now = uaeDate.getTime();
                let timeleft = countDownDate - now;
                let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                updateElement("days", days < 10 ? '0' + days : days);
                updateElement("hours", hours < 10 ? '0' + hours : hours);
                updateElement("mins", minutes < 10 ? '0' + minutes : minutes);
                updateElement("secs", seconds < 10 ? '0' + seconds : seconds);
                if (timeleft < 0) {
                    clearInterval( myFunction );
                    updateElement("days", "00");
                    updateElement("hours", "00");
                    updateElement("mins", "00");
                    updateElement("secs", "00");
                }
            }, 1000)
        },

        formatNumberWinners(data, number) {
            let price = data
                ? parseFloat(data) * number
                : 0;

            return renderLocalePrice(parseInt(price), 0);
        },

        navigateUrl(url, notBlank) {
            if (notBlank) {
                window.location.href = url;
            } else {
                window.open(url, "_blank")
            }
        },

        getCountTime(date) {
            return moment(date, "YYYYMMDD").fromNow()
        },

        async getListPastDraws() {
            let prizes = [
                {
                    title: "grand",
                    icon: "match-7"
                },
                {
                    title: "second",
                    icon: "match-6"
                },
                {
                    title: "third",
                    icon: "match-5"
                },
                {
                    title: "fourth",
                    icon: "match-4"
                },
                {
                    title: "fifth",
                    icon: "match-3"
                }
            ];
            try {
                const resp = await DrawsService.listPastDraws()
                if(resp.status === "success") {
                    let titleArr = []
                    for (var i = 1; i <= resp.data[0].tiers.length; i++) {
                        var index = (i - 1) % prizes.length;
                        if(prizes[index].title) {
                            titleArr.push(prizes[index]);
                        }
                    }
                    resp.data[0].tiers.map((data, i) => {
                        data['title'] = titleArr[i].title;
                        data['icon'] = titleArr[i].icon;
                    });
                    this.listWinnings = resp.data[0].tiers
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getWinnersList() {
            try {
                const resp = await WinnersService.winnersList()
                if (resp.status === 200) {
                    this.winnersList = resp.data
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getBannerList() {
            try {
                const resp = await BannerService.getBanners()
                if (resp.status === 200) {
                    this.bannersList = resp.data
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
    }
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/landing-page/_landing-page.scss"
/>
