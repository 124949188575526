<template>
    <div class="draws">
        <div class="draws__contents draws__contents__container">
            <div class="draws__content__past draws__content__past__custom">
                <div class="draws__content__past__title draws__content__past__title__custom">
                    <img loading="lazy"
                        @click="$router.back()"
                        class="draws__content__past__back"
                        src="@/assets/images/card/back.png"
                    />
                    {{ getTranslatedText('Past Draws') }}
                </div>
                <div
                    v-for="(item, i) in listDraws"
                    :key="i"
                    class="draws__content__past__item"
                >
                    <div class="draws__content__past__item__date">
                        {{ item.date }}
                    </div>
                    <div @click="getWinners(item.id)" class="draws__content__past__item__icon">
                        <img loading="lazy" src="@/assets/images/personal-detail/icon-right.png"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DrawsService from '@/services/draws.service'
import languageMixin from '@/mixins/language/language-mixin';
export default {
    mixins: [languageMixin],
    components: {
    },
    data() {
        return {
            listDraws: [],
            listWinners: [],
            perPageWinner: 5
        };
    },
    mounted() {
        this.getListDraws();
    },
    methods: {
        getListDraws() {
            DrawsService.listDraws(null, null).then(res=> {
                if(res.status===200) {
                    this.listDraws = res.data;
                }
            })
        },

        getWinners(id) {
            this.$router.push({name: 'Winners', query: {id} });
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/draws.scss" />
