import Vue from "vue";
import App from "./AppMobile.vue";
import router from "./router/mobile";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
// import VueToast from "vue-toast-notification";
// import "vue-toast-notification/dist/theme-sugar.css";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import Toast from "@/components/common/toast/Toast.vue";
import VueTour from "vue-tour";
import VueMeta from 'vue-meta';
import { StripePlugin } from '@vue-stripe/vue-stripe';

const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;
const KEY_PUBLIC_STRIPE = process.env.VUE_APP_KEY_PUBLIC_STRIPE;
const PAYMENT_TYPE = process.env.VUE_APP_PAYMENT;

import GoogleAuth from '@/config/google_oAuth.js'
import { initFacebookSdk } from '@/config/facebook_oAuth.js'

const gauthOption = {
  clientId: GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
  plugin_name: 'GoogleLoginApp'
}

require("vue-tour/dist/vue-tour.css");

Vue.use(GoogleAuth, gauthOption)
Vue.use(initFacebookSdk)

Vue.config.productionTip = false;
const options = {
    pk: KEY_PUBLIC_STRIPE,
    locale: 'en'
};
  
// if(PAYMENT_TYPE === 'stripe'){
    Vue.use(StripePlugin, options);
// }
// import "@/assets/scss/common/abstracts/_variables.scss";
import "@/assets/scss/common/base/_reset.scss";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";

const plugin = {
    install() {
        Vue.prototype.$helpers = Toast;
    },
};

Vue.use(plugin);
Vue.use(VueTour);

Vue.use(BootstrapVue);
Vue.use(VueBottomSheet);
// Vue.use(VueToast, {});
Vue.use(Vuetify, {
    theme: {
        secondary: "#0e71a3",
        primary: "#52c2b8",
        accent: "#e95e29",
    },
});
Vue.use(VueMeta, {
    keyName: 'metaInfo',
    refreshOnceOnNavigation: true
})
Vue.directive("click-outside", {
    bind(el, binding, vnode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                vnode.context[binding.expression](event);
            }
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
