<template>
    <a :class="isSetStyle ? 'floating main' : 'floating'" :href="whatsappUrl" target="_blank" id="floating-id">
        <img loading="lazy" width="55" height="55" src="@/assets/images/common/whatsapp.svg"/>
    </a>
</template>
<script>

export default {
    props: {
        isSetStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            whatsappUrl: process.env.VUE_APP_WHATSAPP_URL,
        }
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/_floating.scss" />
