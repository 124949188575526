<template>
    <div class="section-bank-transfer">
        <div class="section-bank-transfer__content-header">
            <!-- <ButtonCustom
                type="go-back"
                text="Bank Withdrawal"
                :isGoBackHistory="false"
                @onClickButton="$emit('onClickGoBack')"
            /> -->
            <div
                class="section-bank-transfer__transfer-amount section-bank-transfer__transfer-amount__mobile"
            >
                <p
                    class="section-bank-transfer__transfer-amount-text t-transform-cap"
                >
                    {{ getTranslatedText("transfer amount") }}
                </p>
                <div class="section-bank-transfer__transfer-amount-price" :class="{ isRtl: $store.state.isRtl }">
                     <b-form-input
                        v-model="amount"
                        id="input-add-credit"
                        @blur="formatter"
                        @keydown.native="checkDigit"
                    ></b-form-input>
                    <!-- <b-form-input
                        v-model="amount"
                        id="input-amount"
                        @blur="formatter"
                        @keydown.native="checkDigit"
                    ></b-form-input> -->
                    <span>{{ getTranslatedText(currencyCode) }}</span>
                </div>
            </div>
        </div>
        <div class="section-bank-transfer__content">
            <div class="section-bank-transfer__content-form">
                <div
                    class="section-bank-transfer__transfer-amount section-bank-transfer__transfer-amount__desktop"
                >
                    <p
                        class="section-bank-transfer__transfer-amount-text t-transform-cap"
                    >
                        {{ getTranslatedText("amount") }}
                    </p>
                    <div class="section-bank-transfer__transfer-amount-price">
                        <b-form-input
                            v-model="amount"
                            id="input-add-credit"
                            placeholder="enter amount here"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input>
                        <span>{{ getTranslatedText(currencyCode) }}</span>
                    </div>
                </div>
                <p class="section-bank-transfer__content-form-title t-fir-transform-cap">
                   ({{ getTranslatedText('Minimum withdrawal amount is [currency] [limit]', { limit: limitAmount, currency: currencyCode}) }})
                </p>
                <hr/>
                <FormCommon
                    :formItem="formItem"
                    :dataForm="dataForm"
                    @on-bur-input="onBurInputFormData"
                    @update-form-data="updateFormData"
                    @onSubmitForm="onSubmitForm"
                >   <div v-if="isRequiredIfsc" slot="ifsc" class="form-group">
                        <label class="ifsc__title" for="input-add-ifsc">{{ getTranslatedText('IFSC*') }}</label>
                        <b-form-input
                            v-model="ifsc"
                            id="input-add-ifsc"
                            placeholder="enter IFSC here"
                            required
                            onkeypress="return event.charCode !== 32"
                            @blur="(event) => onBurInputIfsc(event)"
                        ></b-form-input>
                    </div>
                    <div class="section-bank-transfer__box-btn" slot="button">
                        <div class="section-bank-transfer__process-time">{{ getTranslatedText("processing time might take 5-7 working days") }}</div>
                        <button
                            class="t-transform-cap"
                            type="submit"
                            :disabled="isLoadingButton"
                        >
                            <b-spinner v-if="isLoadingButton" />
                            {{ getTranslatedText("continue to bank") }}
                            {{ getTranslatedText("transfer") }}
                            <img loading="lazy"
                                src="@/assets/images/wallet/vector-right.png"
                                alt="vector-right"
                            />
                        </button>
                    </div>
                </FormCommon>
            </div>
        </div>
         <div
            class="section-bank-transfer__box-btn section-bank-transfer__box-btn-desktop"
            slot="button"
        >
            <div class="guide">
                <div class="section-bank-transfer__process-time">{{ getTranslatedText("processing time might take 10-15 working days") }}</div>
                <p class="need">{{ getTranslatedText('need help?') }}</p>
                <p class="contact">{{ getTranslatedText('Contact our Winners Team via email') }}</p>
                <a class="section-footer__contact-title__email" href="mailto:winners@omillionaire.com">
                    {{ getTranslatedText('winners@omillionaire.com') }}
                </a>
                <p>
                <span>{{ getTranslatedText('or') }}</span>
                {{ getTranslatedText('or WhatsApp at +971 52 190 8386.') }}</p>
            </div>
            <div class="group-button">
                <ButtonCustom
                    class="clear-btn"
                    type="default"
                    text="back"
                    @onClickButton="$emit('onClickGoBack')"
                />
                <button
                    class="confirm-btn"
                    type="submit"
                    @click="onSubmitForm"
                    :disabled="isLoadingButton"
                >
                    <b-spinner v-if="isLoadingButton" />
                    {{ getTranslatedText("confirm") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { checkDigitCommon, isStrEmpty, renderLocalePrice, isArrayEmpty } from "@/components/common/utils";

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import languageMixin from "@/mixins/language/language-mixin";
import countriesService from "../../../../services/countries.service";
import BankService from "../../../../services/bank.service";

const VUE_APP_WITH_AMOUNT = process.env.VUE_APP_WITH_AMOUNT;
const VUE_APP_WITH_AMOUNT_MAXIUM = process.env.VUE_APP_WITH_AMOUNT_MAXIUM;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;

export default {
    mixins: [languageMixin],
    components: { FormCommon, ButtonCustom },
    props: {
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
        amountWithdraw: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            limitAmount: VUE_APP_WITH_AMOUNT * EXCHANGE_RATE,
            maxiumAmount: VUE_APP_WITH_AMOUNT_MAXIUM * EXCHANGE_RATE,
            amount: this.amountWithdraw,
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            dataCurrencies: "",
            ifsc: "",
            isRequiredIfsc: false,
            isRequiredSwiftCode: true,
            dataForm: {
                account_first_name: "",
                account_middle_name: "",
                account_last_name: "",
                bank_name: "",
                branch_code: "",
                account_number: "",
                city: "",
                country: "",
                bank_account_currency: "",
                swift_code: "",
                isAccountNumber: true,
                isIban: true,
                isSwiftCode: true
            },
            formItem: [
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter first name here",
                    name: "account_first_name",
                    required: true,
                    label: "first name",
                    for: "input-first-name",
                    id: "input-first-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter middle name here",
                    name: "account_middle_name",
                    label: "Middle name",
                    for: "input-middle-name",
                    id: "input-middle-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter Last name here",
                    name: "account_last_name",
                    required: true,
                    label: "last name",
                    for: "input-last-name",
                    id: "input-last-name",
                },
                {
                    type: "select",
                    name: "country",
                    required: true,
                    label: "country",
                    for: "input-country",
                    id: "input-country",
                    idGroup: "input-group-country",
                    options: [],
                },
                {
                    type: "input",
                    name: "bank_account_currency",
                    required: true,
                    disabled: true,
                    label: "bank account currency",
                    for: "input-bank_account_currency",
                    id: "input-bank_account_currency",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter iban here",
                    name: "branch_code",
                    required: true,
                    label: "iban / clabe #",
                    for: "input-branch_code",
                    id: "input-branch_code",
                },
                {
                    type: "slot",
                    nameSlot: "ifsc",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter beneficiary account here",
                    name: "account_number",
                    required: true,
                    label: "beneficiary account #",
                    for: "input-account_number",
                    id: "input-account_number",
                    isShow: true,
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter swift code here",
                    name: "swift_code",
                    required: true,
                    label: "swift code",
                    for: "input-swift_code",
                    id: "input-swift_code",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter bank name here",
                    name: "bank_name",
                    required: true,
                    label: "bank name",
                    for: "input-bank-name",
                    id: "input-bank-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "enter city here",
                    name: "city",
                    required: true,
                    label: "city",
                    for: "input-city",
                    id: "input-city",
                }
            ]
        };
    },
    mounted() {
        // let input = document.getElementById("input-amount");
        // input.addEventListener("input", this.resizeInput);
        // this.resizeInput.call(input);
    },
    created() {
        this.fetchDataCountries();
        // this.fetchCurrencies();
    },

    methods: {
        fetchCurrencies() {
            try {
                BankService.getCurrencies().then(res => {
                    this.dataCurrencies = res.currencies
                    this.formItem[4].options = res.currencies.map(({ code, name }) => ({
                        value: code,
                        text: name,
                    }));
                    this.formItem[4].options.unshift({value: '', text: this.getTranslatedText('Select')})
                })

            } catch (error) {
                console.log(error);
            }
        },
        async fetchDataCountries() {
            try {
                const resp = await countriesService.getCountries();

                if (resp.status === 200) {
                    const countries = resp.data.map(({ code, name }) => ({
                        value: code,
                        text: name,
                    }));

                    this.formItem[3].options = countries;
                    this.formItem[3].options.unshift({value: '', text: this.getTranslatedText('Select')})
                };
            } catch (error) {
                console.log(error);
            }
        },
        onSubmitForm() {
            if (parseFloat(this.amount.replaceAll(",", "")) === 0) {
                this.$emit(
                    "showToast",
                    "please enter transfer amount",
                    "error"
                );

                return false;
            }

            if (
                parseFloat(this.amount.replaceAll(",", "")) <
                parseFloat(this.limitAmount)
            ) {
                this.$emit(
                    "showToast",
                    "Minimum the withdrawal amount is " + this.limitAmount + ' ' + this.currencyCode,
                    "error"
                );

                return false;
            }

            if (
                parseFloat(this.amount.replaceAll(",", "")) >
                parseFloat(this.maxiumAmount)
            ) {
                this.$emit(
                    "showToast",
                    "Maximum withdrawal amount is " +
                        this.maxiumAmount + ' ' +
                        this.currencyCode,
                    "error"
                );

                return false;
            }

            if (!isStrEmpty(this.ifsc) && this.ifsc.trim().length !== 11) {
                 this.$emit(
                    "showToast",
                    "IFSC must be 11 characters",
                    "error"
                );

                return false;
            }
            const letters = /[^A-Za-z0-9]+/
            if (!isStrEmpty(this.ifsc) && letters.test(this.ifsc)) {
                 this.$emit(
                    "showToast",
                    "IFSC can only contain numbers and alphabets",
                    "error"
                );

                return false;
            }

            const text = /[a-zA-Z]/
            if (!isStrEmpty(this.ifsc) && !(text.test(this.ifsc.charAt(0))
                && text.test(this.ifsc.charAt(1)) && text.test(this.ifsc.charAt(2)) && text.test(this.ifsc.charAt(3)))) {
                 this.$emit(
                    "showToast",
                    "IFSC must contain first 4 characters are alphabet",
                    "error"
                );

                return false;
            }

            if (isStrEmpty(this.ifsc) && this.isRequiredIfsc) {
                 this.$emit(
                    "showToast",
                    "The IFSC is required",
                    "error"
                );

                return false;
            }

            let param = { ...this.dataForm };
            param["amount"] = parseFloat(this.amount.replaceAll(",", "") / this.exchangeRate).toString();
            param["ifsc"] = this.isRequiredIfsc ? this.ifsc : ''        
            this.$emit("onSubmit", param);
        },

        resizeInput() {
            let input = document.getElementById("input-amount");
            input.style.width =
                input.value.replaceAll(".", "").length + 1 + "ch";
        },

        onBurInputFormData(event) {
            let { value, name } = event.target;
            if(name === 'branch_code' && value !== '') {
                this.checkValidateIban(value)
            }
        },

        onBurInputIfsc(event) {
            if(event.target.value !== '') {
                this.checkValidateIfsc(event.target.value)
            }
        },

        updateFormData(item) {
            let { value, name } = item;
            if (name !== "email" && name !== "phone") {
                this.dataForm[name] = value;
            }
            if(name==='country' && !isStrEmpty(this.dataForm.country)) {
                this.getCurrencyByCountryCode(value)
            } 
            if(name==='country' && isStrEmpty(this.dataForm.country)) {
                this.dataForm.bank_account_currency = ''
                this.isRequiredIfsc = false
            } 
            if(name==='country' && this.dataForm.bank_account_currency !== "AED" && !isStrEmpty(this.dataForm.country)) {
                this.checkIban(value)
            }
            if(name==='country' && this.dataForm.bank_account_currency === "AED") {
                this.dataForm.isAccountNumber = true
                this.dataForm.isIban = true
                this.formItem = [
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter first name here",
                        name: "account_first_name",
                        required: true,
                        label: "first name",
                        for: "input-first-name",
                        id: "input-first-name",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter middle name here",
                        name: "account_middle_name",
                        label: "Middle name",
                        for: "input-middle-name",
                        id: "input-middle-name",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter Last name here",
                        name: "account_last_name",
                        required: true,
                        label: "last name",
                        for: "input-last-name",
                        id: "input-last-name",
                    },
                    {
                        type: "select",
                        name: "country",
                        required: true,
                        label: "country",
                        for: "input-country",
                        id: "input-country",
                        idGroup: "input-group-country",
                        options: [],
                    },
                    {
                        type: "input",
                        name: "bank_account_currency",
                        required: true,
                        disabled: true,
                        label: "bank account currency",
                        for: "input-bank_account_currency",
                        id: "input-bank_account_currency",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter iban here",
                        name: "branch_code",
                        required: true,
                        label: "iban / clabe #",
                        for: "input-branch_code",
                        id: "input-branch_code",
                    },
                    {
                        type: "slot",
                        nameSlot: "ifsc",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter beneficiary account here",
                        name: "account_number",
                        required: true,
                        label: "beneficiary account #",
                        for: "input-account_number",
                        id: "input-account_number",
                        isShow: true,
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter swift code here",
                        name: "swift_code",
                        required: this.isRequiredSwiftCode,
                        label: "swift code",
                        for: "input-swift_code",
                        id: "input-swift_code",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter bank name here",
                        name: "bank_name",
                        required: true,
                        label: "bank name",
                        for: "input-bank-name",
                        id: "input-bank-name",
                    },
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "enter city here",
                        name: "city",
                        required: true,
                        label: "city",
                        for: "input-city",
                        id: "input-city",
                    }
                ]
                this.fetchDataCountries();
                // this.fetchCurrencies();
            }
            // const dataRequiredIfsc = this.dataCurrencies.filter(data => data.code === this.dataForm.bank_account_currency)
        },

        async checkValidateIban(item) {
            try {
                const res = await BankService.checkValidateIban(item)
                if(res.status === 200) {
                    const data = res.data.data.data
                    this.dataForm.account_number = data.bank_account
                    this.dataForm.bank_name = data.bank.bank_name
                    this.dataForm.swift_code = data.bank.bic
                    this.dataForm.city = data.bank.city
                } else {
                    this.dataForm.account_number = ''
                    this.dataForm.bank_name = ''
                    this.dataForm.swift_code = ''
                    this.dataForm.city = ''
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getCurrencyByCountryCode(item) {
            try {
                const res = await BankService.getCurrencyByCountryCode(item)
                if(res.status === 200) {
                    this.dataForm.bank_account_currency = res.data.currency_code
                    this.isRequiredIfsc = res.data.required_ifsc
                } else {
                    this.dataForm.bank_account_currency = ''
                    this.isRequiredIfsc = false
                }
            } catch (error) {
                console.log(error)
            }
        },

        async checkValidateIfsc(item) {
            try {
                const res = await BankService.checkValidateIfsc(item)
                if(res.status === 200) {
                    const data = res.data.data.data
                    this.dataForm.bank_name = data.bank.bank_name
                    this.dataForm.city = data.bank.city
                } else {
                    this.dataForm.bank_name = ''
                    this.dataForm.city = ''
                }
            } catch (error) {
                console.log(error)
            }
        },

        checkIban(data) {
            BankService.checkRequiredIban(data).then(res=> {
                const iban = res.data.iban;
                this.isRequiredSwiftCode = res.data.required_swift_code
                this.dataForm.isSwiftCode = res.data.required_swift_code
                if(iban==="required") {
                    this.dataForm.isAccountNumber = false;
                    this.dataForm.account_number = "";
                    this.dataForm.isIban = true;
                    this.formItem= [
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter first name here",
                            name: "account_first_name",
                            required: true,
                            label: "first name",
                            for: "input-first-name",
                            id: "input-first-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter middle name here",
                            name: "account_middle_name",
                            label: "Middle name",
                            for: "input-middle-name",
                            id: "input-middle-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter Last name here",
                            name: "account_last_name",
                            required: true,
                            label: "last name",
                            for: "input-last-name",
                            id: "input-last-name",
                        },
                        {
                            type: "select",
                            name: "country",
                            required: true,
                            label: "country",
                            for: "input-country",
                            id: "input-country",
                            idGroup: "input-group-country",
                            options: [],
                        },
                        {
                            type: "input",
                            name: "bank_account_currency",
                            required: true,
                            disabled: true,
                            label: "bank account currency",
                            for: "input-bank_account_currency",
                            id: "input-bank_account_currency",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter iban here",
                            name: "branch_code",
                            required: true,
                            label: "iban / clabe #",
                            for: "input-branch_code",
                            id: "input-branch_code",
                        },
                        {
                            type: "slot",
                            nameSlot: "ifsc",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter beneficiary account here",
                            name: "account_number",
                            required: false,
                            label: "beneficiary account #",
                            for: "input-account_number",
                            id: "input-account_number",
                            idFormGroup: "id-bank-account_number",
                            isShow: false,
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter swift code here",
                            name: "swift_code",
                            required: this.isRequiredSwiftCode,
                            label: "swift code",
                            for: "input-swift_code",
                            id: "input-swift_code",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter bank name here",
                            name: "bank_name",
                            required: true,
                            label: "bank name",
                            for: "input-bank-name",
                            id: "input-bank-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter city here",
                            name: "city",
                            required: true,
                            label: "city",
                            for: "input-city",
                            id: "input-city",
                        }
                    ];
                }
                if(iban==='optional') {
                    this.dataForm.isAccountNumber = true;
                    this.dataForm.isIban = false;
                    this.formItem= [
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter first name here",
                            name: "account_first_name",
                            required: true,
                            label: "first name",
                            for: "input-first-name",
                            id: "input-first-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter middle name here",
                            name: "account_middle_name",
                            label: "Middle name",
                            for: "input-middle-name",
                            id: "input-middle-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter Last name here",
                            name: "account_last_name",
                            required: true,
                            label: "last name",
                            for: "input-last-name",
                            id: "input-last-name",
                        },
                        {
                            type: "select",
                            name: "country",
                            required: true,
                            label: "country",
                            for: "input-country",
                            id: "input-country",
                            idGroup: "input-group-country",
                            options: [],
                        },
                        {
                            type: "input",
                            name: "bank_account_currency",
                            required: true,
                            disabled: true,
                            label: "bank account currency",
                            for: "input-bank_account_currency",
                            id: "input-bank_account_currency",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter iban here",
                            name: "branch_code",
                            required: false,
                            label: "iban / clabe #",
                            for: "input-branch_code",
                            id: "input-branch_code",
                        },
                        {
                            type: "slot",
                            nameSlot: "ifsc",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter beneficiary account here",
                            name: "account_number",
                            required: true,
                            label: "beneficiary account #",
                            for: "input-account_number",
                            id: "input-account_number",
                            idFormGroup: "id-bank-account_number",
                            isShow: true,
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter swift code here",
                            name: "swift_code",
                            required: this.isRequiredSwiftCode,
                            label: "swift code",
                            for: "input-swift_code",
                            id: "input-swift_code",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter bank name here",
                            name: "bank_name",
                            required: true,
                            label: "bank name",
                            for: "input-bank-name",
                            id: "input-bank-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter city here",
                            name: "city",
                            required: true,
                            label: "city",
                            for: "input-city",
                            id: "input-city",
                        }
                    ]
                }
                if(iban==="not_required") {
                    this.dataForm.isAccountNumber = true;
                    this.dataForm.isIban = false;
                    this.formItem= [
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter first name here",
                            name: "account_first_name",
                            required: true,
                            label: "first name",
                            for: "input-first-name",
                            id: "input-first-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter middle name here",
                            name: "account_middle_name",
                            label: "Middle name",
                            for: "input-middle-name",
                            id: "input-middle-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter Last name here",
                            name: "account_last_name",
                            required: true,
                            label: "last name",
                            for: "input-last-name",
                            id: "input-last-name",
                        },
                        {
                            type: "select",
                            name: "country",
                            required: true,
                            label: "country",
                            for: "input-country",
                            id: "input-country",
                            idGroup: "input-group-country",
                            options: [],
                        },
                        {
                            type: "input",
                            name: "bank_account_currency",
                            required: true,
                            disabled: true,
                            label: "bank account currency",
                            for: "input-bank_account_currency",
                            id: "input-bank_account_currency",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter iban here",
                            name: "branch_code",
                            required: false,
                            label: "iban / clabe #",
                            for: "input-branch_code",
                            id: "input-branch_code",
                            isShow: false,
                        },
                        {
                            type: "slot",
                            nameSlot: "ifsc",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter beneficiary account here",
                            name: "account_number",
                            required: true,
                            label: "beneficiary account #",
                            for: "input-account_number",
                            id: "input-account_number",
                            idFormGroup: "id-bank-account_number",
                            isShow: true,
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter swift code here",
                            name: "swift_code",
                            required: this.isRequiredSwiftCode,
                            label: "swift code",
                            for: "input-swift_code",
                            id: "input-swift_code",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter bank name here",
                            name: "bank_name",
                            required: true,
                            label: "bank name",
                            for: "input-bank-name",
                            id: "input-bank-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter city here",
                            name: "city",
                            required: true,
                            label: "city",
                            for: "input-city",
                            id: "input-city",
                        }
                    ];
                }
                if(iban==="required_iban_and_number") {
                    this.dataForm.isAccountNumber = true;
                    this.dataForm.isIban = true;
                    this.formItem = [
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter first name here",
                            name: "account_first_name",
                            required: true,
                            label: "first name",
                            for: "input-first-name",
                            id: "input-first-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter middle name here",
                            name: "account_middle_name",
                            label: "Middle name",
                            for: "input-middle-name",
                            id: "input-middle-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter Last name here",
                            name: "account_last_name",
                            required: true,
                            label: "last name",
                            for: "input-last-name",
                            id: "input-last-name",
                        },
                        {
                            type: "select",
                            name: "country",
                            required: true,
                            label: "country",
                            for: "input-country",
                            id: "input-country",
                            idGroup: "input-group-country",
                            options: [],
                        },
                        {
                            type: "input",
                            name: "bank_account_currency",
                            required: true,
                            disabled: true,
                            label: "bank account currency",
                            for: "input-bank_account_currency",
                            id: "input-bank_account_currency",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter iban here",
                            name: "branch_code",
                            required: true,
                            label: "iban / clabe #",
                            for: "input-branch_code",
                            id: "input-branch_code",
                        },
                        {
                            type: "slot",
                            nameSlot: "ifsc",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter beneficiary account here",
                            name: "account_number",
                            required: true,
                            label: "beneficiary account #",
                            for: "input-account_number",
                            id: "input-account_number",
                            isShow: true,
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter swift code here",
                            name: "swift_code",
                            required: this.isRequiredSwiftCode,
                            label: "swift code",
                            for: "input-swift_code",
                            id: "input-swift_code",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter bank name here",
                            name: "bank_name",
                            required: true,
                            label: "bank name",
                            for: "input-bank-name",
                            id: "input-bank-name",
                        },
                        {
                            type: "input",
                            typeInput: "text",
                            placeholder: "enter city here",
                            name: "city",
                            required: true,
                            label: "city",
                            for: "input-city",
                            id: "input-city",
                        }
                    ]
                }
                this.fetchDataCountries();
                // this.fetchCurrencies();
            })
        },

        formatter(event) {
            const { value } = event.target;
            let isContainsLowercase = /^(?=.*[a-z]).*$/;
            let isNumber = /^[0-9.]*$/
            this.amount = !isStrEmpty(value) && !isContainsLowercase.test(value) && isNumber.test(value) ? parseFloat(value.replace(/,/g, ""))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
            // let input = document.getElementById("input-amount");
            // let length = this.amount.replaceAll(".", "").length;
            // let numberMore = (this.amount.match(/,/g) || []).length;
            // numberMore = numberMore > 0 ? -numberMore + 1 : 0.5;
            // input.style.width = length + numberMore + "ch";
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/wallet/_wallet.scss" />
