<template>
    <b-tabs class="tabs-swift__container" :nav-wrapper-class="classContainer">
        <template v-for="(tab, index) in tabs">
            <b-tab
                v-if="tab.isShow ? tab.isShow : true"
                :key="index"
                :title="tab.title"
                :active="tab.isActive"
                :class="{
                    'in-active': tab.isActive,
                }"
                :title-item-class="customClassTitleItem"
                @click="$emit('handleChangeTab', index)"
                lazy
            >
                <template #title>
                    <div class="tabs-swift__title-notify">
                        <div class="tabs-swift__title">
                            <img loading="lazy"
                                v-if="tab.iconTab"
                                v-bind:src="
                                    require(`@/assets/images/${tab.iconTab}`)
                                "
                                class="icon-tab"
                            />
                            <h5 class="tabs-swift__title-text">
                                {{ tab.title }}
                                <span
                                    v-if="tab.isShowQuantity"
                                    class="tabs-swift__tab-quantity"
                                    >{{
                                        index === 0
                                            ? ticketsNPLength
                                            : index === 1
                                            ? ticketsCPLength
                                            : ticketsSPLength
                                    }}</span
                                >
                            </h5>
                        </div>
                    </div>
                </template>
                <slot :name="tab.nameSlot" />
                <component
                    :is="tab.component"
                    :data="tab.data"
                    :isPosDevice="isPosDevice"
                />
            </b-tab>
        </template>
    </b-tabs>
</template>

<script>
// import "@/assets/scss/common/tab/_tab-swift.scss";
import "@/assets/scss/common/tab/_tab-swift.scss";

export default {
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        isPosDevice: {
            type: Boolean,
            default: false,
        },
        customClassTitleItem: {
            type: String,
            default: "",
        },
        classContainer: {
            type: String,
            default: "",
        },
    },
    computed: {
        ticketsNPLength() {
            return this.$store.state.normalPickTicketsLength;
        },
        ticketsCPLength() {
            return this.$store.state.tabCombinationPick.length;
        },
        ticketsSPLength() {
            return this.$store.state.tabSuperQuickPick.length;
        },
    },
};
</script>
