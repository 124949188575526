import BaseService from "@/services/base.service";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class NotificationService extends BaseService {
    getList(locale: string) {
        return this.get(
            API_URL +
                `/api/user/notifications?per_page=200&sort_direction=DESC&locale=${locale}`
        ).then((response) => {
            return response.data;
        });
    }

    getUnreadNotify() {
        return this.get(API_URL + "/api/user/unread-notify").then(
            (response) => {
                return response.data;
            }
        );
    }

    readNotify(id) {
        const token = localStorage.getItem("access_token");
        return axios
            .get(API_URL + `/api/user/read-notify/${id}`, {
                headers: { Authorization: token },
            })
            .then((response) => {
                return response.data;
            });
    }

    readAllNotify() {
        return this.post(API_URL + `/api/user/read-all-notify`, {}).then(
            ({ data }) => data
        );
    }
}

export default new NotificationService();
