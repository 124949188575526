<template>
    <AmountPopup :id="id" title="add credit" @resetModal="resetModal">
        <div class="section-add-credit">
            <div class="section-add-credit__desktop">
                <div class="section-add-credit__desktop__head">
                    <div
                        class="section-add-credit__desktop__head__title t-transform-cap"
                        @click="$emit('onClickGoBack')"
                    >
                        <img loading="lazy" src="@/assets/images/card/back.png" />
                        {{ getTranslatedText("add credit") }}
                    </div>
                    <div class="section-add-credit__desktop__head__button">
                        <button
                            :disabled="isLoadingButtonAddCredit"
                            class="t-transform-cap"
                            @click="onShowModalCvv"
                        >
                            <b-spinner v-if="isLoadingButtonAddCredit" />
                            {{ getTranslatedText("add credit") }}
                            <img loading="lazy"
                                src="@/assets/images/wallet/arrow-right-white.png"
                            />
                        </button>
                    </div>
                </div>
                <div
                    class="section-add-credit__content-desktop__payment"
                    v-if="listCards.length > 0"
                >
                    <div class="section-add-credit__content-desktop__payment-card">
                        <p>
                            {{ getTranslatedText("payment via") }}
                            {{ formatCardNumber() }}
                        </p>
                        <img loading="lazy"
                            v-if="typeCard !== ''"
                            :src="
                                require(`@/assets/images/common/${renderTypeCard(
                                    typeCard
                                )}`)
                            "
                            alt="card"
                        />
                    </div>
                    <button
                        class="section-add-credit__content-desktop__payment-button t-transform-cap"
                        @click="onChangeCard"
                    >
                        {{ getTranslatedText("change") }}
                    </button>
                </div>
                <div class="section-add-credit__content-desktop__form">
                    <div
                        class="section-bank-transfer__transfer-amount section-bank-transfer__transfer-amount__desktop"
                    >
                        <div class="box-text-warrning" v-if="isShowWarning">
                            <img loading="lazy" src="@/assets/images/toast/error.png"/>
                            <p class="text-warning" v-html="`${getTranslatedText(`Please click <a href='/personal-detail'>here</a> update your phone number before payment`)}`"></p>
                        </div>
                        <FormCommon
                            class="section-add-credit__deail-container"
                            :formItem="formItemCard"
                            :dataForm="dataForm"
                            v-if="ENV_ENABLE_MANAGE_CREDIT_CARDS === 'no' && !offsite"
                            @update-form-data="updateFormData"
                        >
                            <template slot="cardNumber">
                                <b-form-group
                                    :label="getTranslatedText('card number')"
                                    label-for="input-number"
                                >
                                    <b-form-input
                                        required
                                        id="input-number"
                                        placeholder="0000 0000 0000 0000"
                                        v-model="dataForm.card_number"
                                        :formatter="formatterCardNumber"
                                        @keydown.native="checkDigit"
                                    ></b-form-input>
                                </b-form-group>
                            </template>
                            <div class="card-detail__form-cvv" slot="expirationDate">
                                <b-form-group
                                    :label="getTranslatedText('Expiration Date')"
                                    label-for="input-expiration"
                                >
                                    <b-form-input
                                        required
                                        id="input-expiration"
                                        :placeholder="$store.state.isRtl ? 'شهر / يوم / سنة' : 'MM/YY'"
                                        v-model="dataForm.expiry_date"
                                        :formatter="formatterExpirationDate"
                                        @keydown.native="checkDigit"
                                        :state="stateExpirationDate"
                                    ></b-form-input>
                                    <p
                                        class="card-detail__error-expiration-date"
                                        v-if="errorMessage !== '' && !stateExpirationDate"
                                    >
                                        {{ errorMessage }}
                                    </p>
                                </b-form-group>
                                <b-form-group :label="getTranslatedText('Cvv')" label-for="input-card-cvv">
                                    <b-form-input
                                        required
                                        :state="stateCvv"
                                        id="input-card-cvv"
                                        :placeholder="getTranslatedText('Cvv')"
                                        type="password"
                                        v-model="dataForm.cvv"
                                        @keydown.native="checkDigit"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-card-cvv">
                                        {{ getTranslatedText("enter 3 numbers") }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <hr slot="lineForm">
                        </FormCommon>
                        <p
                            class="section-add-credit__content-desktop__form__transfer-amount-text"
                        >
                            {{ getTranslatedText("enter the amount") }}
                        </p>
                        <div
                            class="section-add-credit__content-desktop__form__transfer-amount-price"
                        >
                            <span>{{ getTranslatedText(currencyCode) }}</span>
                            <b-form-input
                                v-model="price"
                                id="input-amount"
                                @blur="formatter"
                                @keydown.native="checkDigit"
                            ></b-form-input>
                        </div>
                    </div>
                    <!-- <p
                        class="section-bank-transfer__content-form-title t-fir-transform-cap"
                    >
                        {{
                            getTranslatedText(
                                "Minimum the deposit amount is [LIMIT_AMOUNT] [CURRENCY_CODE]",
                                    {
                                        limit_amount: limitAmount,
                                        currency_code: currency_code,
                                    }
                                )
                        }}
                    </p> -->
                </div>
            </div>

            <div
                class="section-add-credit__content-header hide-desktop-credit"
                :style="cssProps"
            >
                <ButtonCustom
                    classBtn="section-add-credit__header"
                    type="go-back"
                    text="add credit"
                    :isGoBackHistory="false"
                    @onClickButton="onClickGoBack"
                />

                <div class="section-add-credit__transfer-amount">
                    <p
                        class="section-add-credit__transfer-amount-text t-transform-cap"
                    >
                        {{ getTranslatedText("deposit amount") }}
                    </p>
                    <div class="section-add-credit__transfer-amount-price">
                        <b-form-input
                            v-model="price"
                            id="input-add-credit"
                            name="price"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input>
                        <!-- <b-form-input
                            v-model="price"
                            id="input-price"
                            name="price"
                            @blur="formatter"
                            @keydown.native="checkDigit"
                        ></b-form-input> -->
                        <span>{{ getTranslatedText(currencyCode) }}</span>
                    </div>
                </div>
            </div>

            <div class="section-add-credit__content-body hide-desktop-credit">
                <div
                    v-if="listCards.length > 0"
                    class="section-add-credit__content-body__payment"
                >
                    <div class="section-add-credit__content-body__payment-card">
                        <p>
                            {{ getTranslatedText("payment via") }}
                            {{ formatCardNumber() }}
                        </p>
                        <img loading="lazy"
                            v-if="typeCard !== ''"
                            :src="
                                require(`@/assets/images/common/${renderTypeCard(
                                    typeCard
                                )}`)
                            "
                            alt="card"
                        />
                    </div>
                    <button
                        class="section-add-credit__content-body__payment-button t-transform-cap"
                        @click="onChangeCard"
                    >
                        {{ getTranslatedText("change") }}
                    </button>
                </div>
                <div class="box-text-warrning" v-if="isShowWarning">
                    <img loading="lazy" src="@/assets/images/toast/error.png"/>
                    <p class="text-warning" v-html="`${getTranslatedText(`Please click <a href='/personal-detail'>here</a> update your phone number before payment`)}`"></p>
                </div>
                <!-- <p
                        class="section-bank-transfer__content-form-title t-fir-transform-cap"
                    >
                        {{
                            getTranslatedText(
                                "Minimum the deposit amount is [LIMIT_AMOUNT] [CURRENCY_CODE]",
                                    {
                                        limit_amount: limitAmount,
                                        currency_code: currency_code,
                                    }
                                )
                        }}
                    </p> -->
                <FormCommon
                    class="section-add-credit__deail-container"
                    :formItem="formItemCard"
                    :dataForm="dataForm"
                    v-if="ENV_ENABLE_MANAGE_CREDIT_CARDS === 'no' && !offsite"
                    @update-form-data="updateFormData"
                    style="marginBottom: 15px"
                >
                    <template slot="cardNumber">
                        <b-form-group
                            :label="getTranslatedText('card number')"
                            label-for="input-number"
                            >
                            <b-form-input
                                required
                                id="input-number"
                                placeholder="0000 0000 0000 0000"
                                v-model="dataForm.card_number"
                                :formatter="formatterCardNumber"
                                @keydown.native="checkDigit"
                                ></b-form-input>
                        </b-form-group>
                    </template>
                    <div class="card-detail__form-cvv" slot="expirationDate">
                        <b-form-group
                            :label="getTranslatedText('Expiration Date')"
                            label-for="input-expiration"
                            >
                            <b-form-input
                                required
                                id="input-expiration"
                                :placeholder="$store.state.isRtl ? 'MM/YY' : 'شهر / يوم / سنة'"
                                v-model="dataForm.expiry_date"
                                :formatter="formatterExpirationDate"
                                @keydown.native="checkDigit"
                                :state="stateExpirationDate"
                                ></b-form-input>
                            <p
                                class="card-detail__error-expiration-date"
                                v-if="errorMessage !== '' && !stateExpirationDate"
                                >
                                {{ errorMessage }}
                            </p>
                        </b-form-group>
                        <b-form-group label="Cvv" label-for="input-card-cvv">
                            <b-form-input
                                required
                                :state="stateCvv"
                                id="input-card-cvv"
                                placeholder="CVV"
                                type="password"
                                v-model="dataForm.cvv"
                                @keydown.native="checkDigit"
                                ></b-form-input>
                            <b-form-invalid-feedback id="input-card-cvv">
                                {{ getTranslatedText("enter 3 numbers") }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <hr slot="lineForm">
                </FormCommon>
                <ButtonCustom
                    typeBtn="button"
                    type="primary"
                    text="add credit"
                    icon="arrow-right.png"
                    :isLoading="isLoadingButtonAddCredit"
                    @onClickButton="onShowModalCvv"
                />
            </div>

            <MethodPayment id="modalMethodPayment" :totalAmountPayment="totalAmountPayment" @showToast="showToastPayment" methodPayment="1"/>

            <vue-bottom-sheet max-height="90%" ref="bottomChangeCard">
                <BottomSheet :isHiddenBtn="true">
                    <template slot="body-sheet">
                        <div class="section-add-credit__bottom-change-card">
                            <h1
                                class="section-add-credit__bottom-change-card-title t-transform-cap"
                            >
                                {{ getTranslatedText("select credit card") }}
                            </h1>
                            <FormCommon
                                :formItem="formItem"
                                @onSubmitForm="onSubmitChangeCard"
                            >
                                <template slot="radioButtonCard">
                                    <b-form-group>
                                        <b-form-radio
                                            v-model="cardID"
                                            class="section-add-credit__item-card"
                                            :value="item.id"
                                            v-for="(item, index) in listCards"
                                            :key="index"
                                            ><div
                                                class="section-add-credit__change-card"
                                            >
                                                <div
                                                    class="section-add-credit__change-card-info"
                                                >
                                                    <h4
                                                        class="section-add-credit__change-card-info-number"
                                                    >
                                                        {{ item.card_number }}
                                                    </h4>
                                                    <p
                                                        class="section-add-credit__change-card-info-description t-transform-cap"
                                                    >
                                                        {{
                                                            getTranslatedText(
                                                                "valid thru 24/28"
                                                            )
                                                        }}
                                                    </p>
                                                    <p
                                                        class="section-add-credit__change-card-info-name"
                                                    >
                                                        {{ item.card_holder_name }}
                                                    </p>
                                                    <div
                                                        v-if="item.is_default"
                                                        class="section-add-credit__change-card-info-default t-transform-cap"
                                                    >
                                                        {{
                                                            getTranslatedText(
                                                                "marked as default"
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <img loading="lazy"
                                                    class="section-add-credit__card-type"
                                                    :src="
                                                        require(`@/assets/images/common/${renderTypeCard(
                                                            item.card_type_name
                                                        )}`)
                                                    "
                                                    alt="card"
                                                />
                                            </div>
                                        </b-form-radio>
                                    </b-form-group>
                                    <div class="bottom-change-card">
                                        <ButtonCustom
                                            typeBtn="button"
                                            type="default"
                                            classBtn="button-change-card"
                                            text="add new card"
                                            @onClickButton="
                                                $router.push('/manage-cards')
                                            "
                                        />
                                        <ButtonCustom
                                            typeBtn="submit"
                                            type="primary"
                                            classBtn="button-change-card"
                                            text="select"
                                            :isLoading="isLoadingChangeCard"
                                        />
                                    </div>
                                </template>
                            </FormCommon>
                        </div>
                    </template>
                </BottomSheet>
            </vue-bottom-sheet>

            <b-modal
                id="input-ccv"
                centered
                v-model="modalShowCvv"
                title="CVV"
                :hide-footer="true"
            >
                <div class="input-ccv">
                    <b-form-input
                        :state="stateCvv"
                        :placeholder="`${getTranslatedText('Enter')} CVV`"
                        type="password"
                        id="input-live"
                        v-model="card_security_code"
                        aria-describedby="input-live-cvv"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-cvv">
                        {{ getTranslatedText("enter 3 numbers") }}
                    </b-form-invalid-feedback>
                    <ButtonCustom
                        typeBtn="button"
                        type="primary"
                        text="done"
                        @onClickButton="onAddSvv"
                    />
                </div>
            </b-modal>
        </div>
    </AmountPopup>
</template>
<script>
import {
    checkDigitCommon,
    creditCardMask,
    isArrayEmpty,
    isStrEmpty,
} from "@/components/common/utils";
import { getUserInfo } from '@/services/axios';

import MethodPayment from "@/components/common/methodPayment"
import BottomSheet from "@/components/common/bottom-sheet";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import cardService from "@/services/card.service";
import walletService from "@/services/wallet.service";
import paymentService from '@/services/payment.servive';
import languageMixin from "@/mixins/language/language-mixin";
import countriesService from '@/services/countries.service';
import paymentMixin from "@/mixins/payment/payment-mixin"
import AmountPopup from "@/components/common/amount-popup";


const ENABLE_MANAGE_CREDIT_CARDS = process.env.VUE_APP_ENABLE_MANAGE_CREDIT_CARDS;
const VUE_APP_WITH_AMOUNT = process.env.VUE_APP_WITH_AMOUNT;
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
const PAYMENT_TYPE = process.env.VUE_APP_PAYMENT;
let scriptTag

export default {
    mixins: [languageMixin, paymentMixin],
    components: { FormCommon, ButtonCustom, BottomSheet, MethodPayment, AmountPopup },
    props: {
        offsite: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            ENV_ENABLE_MANAGE_CREDIT_CARDS: ENABLE_MANAGE_CREDIT_CARDS,
            isShowWarning: false,
            errorMessage: '',
            stateExpirationDate: null,
            currency_code: "aed",
            limitAmount: VUE_APP_WITH_AMOUNT,
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            paymentType: PAYMENT_TYPE,
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/wallet/background-phone.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            cardID: "",
            cardNumberSelect: "",
            typeCard: "",
            expiryDate: "",
            price: "0.00",
            nameCard: "",
            listCards: [],
            iframe: "",
            modalShow: false,
            card_security_code: "",
            modalShowCvv: false,
            stateCvv: null,
            isLoadingButtonAddCredit: false,
            isLoadingChangeCard: false,
            totalAmountPayment: 0,
            formItem: [
                {
                    type: "slot",
                    nameSlot: "radioButtonCard",
                },
            ],
            dataForm: {
                card_number: "",
                expiry_date: "",
                cvv: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                country_code: "",
                card_holder_name: ""
            },
            formItemCard: [
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "John Anderson",
                    name: "card_holder_name",
                    label: "Card holder name",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "slot",
                    nameSlot: "cardNumber",
                },
                {
                    type: "slot",
                    nameSlot: "expirationDate",
                },
                {
                    type: "slot",
                    nameSlot: "lineForm",
                },
                {
                    type: "select",
                    label: "Country",
                    name: "country_code",
                    for: "input-country",
                    id: "input-country",
                    idGroup: "input-group-country",
                    options: [],
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Address",
                    name: "address",
                    label: "address",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "City",
                    name: "city",
                    label: "city",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "State",
                    name: "state",
                    label: "state",
                    for: "input-name",
                    id: "input-name",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "zip",
                    name: "zip",
                    label: "zip",
                    for: "input-name",
                    id: "input-name",
                },
            ],
        };
    },
    mounted() {
        // let input = document.getElementById("input-price");
        // input.addEventListener("input", this.resizeInput);
        // this.resizeInput.call(input);
    },
    created() {
        if(ENABLE_MANAGE_CREDIT_CARDS !== 'no') {
            this.getListCards();
        } else {
            this.fetchDateCountries()
            this.getUserData()
        }
    },
    methods: {
        showToastPayment(message, type) {
            this.$emit('showToast', message, type)
        },
        resetModal() {
            this.price = "0.00"
        },
        getUserData() {
            let user = JSON.parse(getUserInfo())
            this.dataForm.address = user?.address
            this.dataForm.city = user?.city
            this.dataForm.state = user?.state
            this.dataForm.zip = user?.zipcode
            this.dataForm.country_code = user?.country_code
            this.dataForm.card_holder_name = localStorage.getItem('cardHolderName') || user.name
            // this.isShowWarning = !isStrEmpty(user?.phone) ? false : true
            this.isShowWarning = false;
        },

          async fetchDateCountries() {
            try {
                const resp = await countriesService.getCountries();
                if (resp.status === 200) {
                    const countries = resp.data.map(({ code, name }) => ({
                        value: code,
                        text: name,
                    }));
                    this.formItemCard[4].options = countries;
                }
            } catch (error) {
                console.log(error);
            }
        },

        getListCards() {
            cardService.listCards().then((res) => {
                if (res.status === 200 && !isArrayEmpty(res.data)) {
                    let index = res.data.findIndex((x) => x.is_default);
                    index = index !== -1 ? index : 0;
                    this.cardNumberSelect = res.data[index].card_number;
                    this.expiryDate = res.data[index].expiry_date;
                    this.nameCard = res.data[index].card_holder_name;
                    this.typeCard = res.data[index].card_type_name;
                    this.cardID = res.data[index].id;

                    this.listCards = res.data;
                }
            });
        },

        async onSubmitAddCredit() {
            if(this.isShowWarning) {
                return false
            }

            // if (isStrEmpty(this.dataForm.card_number)) {
            //      this.$emit("showToast", 'The Card Number field is required.', "error");
            //     return false
            // }
            //
            // if(isStrEmpty(this.dataForm.address) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
            //     this.$emit("showToast", 'The Address field is required.', "error");
            //     return false
            // }
            //
            // if(isStrEmpty(this.dataForm.city) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
            //     this.$emit("showToast", 'The City field is required.', "error");
            //     return false
            // }
            //
            // if(isStrEmpty(this.dataForm.state) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
            //     this.$emit("showToast", 'The State field is required.', "error");
            //     return false
            // }
            //
            // if(isStrEmpty(this.dataForm.zip) && ENABLE_MANAGE_CREDIT_CARDS === 'no') {
            //     this.$emit("showToast", 'The Zip field is required.', "error");
            //     return false
            // }

            if(!isStrEmpty(this.errorMessage)) {
                return false
            }

            if (isStrEmpty(this.price)) {
                this.$emit(
                    "showToast",
                    "please enter transfer amount",
                    "error"
                );

                return false;
            }

            if (parseFloat(this.price.split(',').join("")) < 2) {
                this.$emit(
                    "showToast",
                    "Please Enter Amount or Minimum amount is 2 AED",
                    "error"
                );

                return false;
            }

            this.isLoadingButtonAddCredit = true;
            try {
                // let { card_number, expiry_date, cvv, address, city, state, zip, country_code, card_holder_name} = this.dataForm
                let user_info = JSON.parse(localStorage.getItem("user_info"));
                let price = this.price.split(',')
                let paramCheckLimit = {
                    user_id: user_info?.id.toString(),
                    amount: parseFloat(price.join("") / this.exchangeRate).toString(),
                };


                // let expiry_month = expiry_date.split('/')[0]
                // let expiry_year = `${expiry_date.split('/')[1]}`

                // let param = {
                //     card_number: card_number.replace(/\s/g, ''),
                //     expiry_month,
                //     expiry_year,
                //     card_security_code: cvv,
                //     amount: parseFloat(this.price.replace(",", "")).toString(),
                //     address: address,
                //     city: city,
                //     state: state,
                //     zipcode: zip,
                //     country_code: country_code,
                //     card_holder_name
                // };

                const respCheckLimitDeposit =
                    await walletService.checkLimitDeposit(paramCheckLimit);

                if (!respCheckLimitDeposit.data.status) {
                    this.$emit(
                        "showToast",
                        respCheckLimitDeposit.data.message,
                        "error"
                    );
                    this.isLoadingButtonAddCredit = false;
                    return false;
                }

                this.totalAmountPayment = Number(paramCheckLimit.amount)
                let totalAmountPayment = {
                    type: 'add-credit',
                    totalAmountPayment: paramCheckLimit.amount
                }
                if (this.paymentType === 'card' || this.paymentType === 'credit' || this.paymentType === 'ccavenue') {
                    if (this.paymentType === 'ccavenue') {
                        if (paramCheckLimit.amount * 10 >= 2) {
                            localStorage.setItem("methodPayment", "1");
                            localStorage.setItem("totalAmountPayment", JSON.stringify(totalAmountPayment))
                            this.onSubmitPayment(paramCheckLimit.amount);
                        } else {
                            this.$emit("showToast", "Minimum amount is 2 AED", "error");
                        }
                    } else {
                        this.onSubmitPayment(paramCheckLimit.amount);
                    }
                }
                else if(this.paymentType === 'stripe') {
                    if (paramCheckLimit.amount * 10 >= 2) {
                        localStorage.setItem("methodPayment", "1");
                        localStorage.setItem("totalAmountPayment", JSON.stringify(totalAmountPayment))
                        this.$router.push('/payment');
                    } else {
                         this.$emit("showToast", "Minimum amount is 2 AED", "error");
                    }
                } else {
                    this.$bvModal.show("modalMethodPayment");
                }
                this.$bvModal.hide("show-amount-popup-header");
                this.isLoadingButtonAddCredit = false;
                //  const res = await paymentService.puschaseNboPayment(paramCheckLimit.amount)
                //         Checkout.showPaymentPage()
                //         if(res.status === 200) {
                //             let data = res.data
                //             localStorage.setItem('reference', data.reference)
                //             Checkout.configure({
                //                 merchant: data.session.merchant,
                //                 session: {
                //                     id: data.session.session.id,
                //                     version: data.session.session.version
                //                 },
                //                 interaction: {
                //                     merchant: {
                //                         name: this.namePayment,
                //                         address: {
                //                             line1: this.addressPayment,
                //                         }
                //                     }
                //                 }
                //             })
                //             Checkout.showPaymentPage()
                //         }

                // const res = await paymentService.purchasePaypound(param);
                // const res = await paymentService.purchaseThawani(param);

                // if (res.status === 200) {
                //     if (!isStrEmpty(res.data["checkout_url"])) {
                //         localStorage.setItem("methodPayment", 1);
                //         window.location.href = res.data["checkout_url"];
                //     } else {
                //         this.$emit("showToast", res.data.message, "error");
                //     }
                // } else {
                //     this.$emit("showToast", res.message, "error");
                //     // this.isLoadingButtonAddCredit = false;
                // }

                // if (res.status === 200) {
                //     if (!isStrEmpty(res.data["redirect_3ds_url"])) {
                //         localStorage.setItem("methodPayment", 1);
                //         localStorage.setItem("cardHolderName", card_holder_name);
                //         window.location.href = res.data["redirect_3ds_url"];
                //     } else if (res.data.status === "success") {
                //         localStorage.setItem("methodPayment", 1);
                //         localStorage.setItem("cardHolderName", card_holder_name);
                //         this.$router.push("/payment/successful");
                //     } else if (res.data.status === "pending") {
                //         localStorage.setItem("methodPayment", 1);
                //         localStorage.setItem("cardHolderName", card_holder_name);
                //         this.$router.push("/payment/pending");
                //     } else {
                //         this.$emit(
                //             "showToast",
                //             res.data.message,
                //             "error"
                //         );
                //
                //         this.isLoadingButtonAddCredit = false;
                //     }
                // } else {
                //     this.$emit("showToast", res.message, "error");
                //     this.isLoadingButtonAddCredit = false;
                // }
            } catch (error) {
                console.log(error, "error");
            }
        },
        renderCreditInfor(amount) {
            let payType = this.paymentType === 'ccavenue' ? 'CCAvenue' : this.paymentType
            let valueAmount = amount * this.exchangeRate
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'credit_added',
                    'voucher_code': 'N/A',
                    'credit_type': '${this.paymentType}',
                    'amount': ${valueAmount},
                    'payment_vendor': '${payType}',
                });
            `;
            document.head.appendChild(scriptTag);
        },
        async onSubmitPayment(totalAmountPayment) {
            try {
                this.isLoadingButtonAddCredit = true
                let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
                let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
                if(this.paymentType === 'credit') {
                    const res = await paymentService.puschaseNboPayment(totalAmountPayment)
                    Checkout.showPaymentPage()
                    if(res.status === 200) {
                        let data = res.data
                        localStorage.setItem("methodPayment", "1");
                        localStorage.setItem('referencePayment', res.data.reference)
                        Checkout.configure({
                            merchant: data.session.merchant,
                            session: {
                                id: data.session.session.id,
                                version: data.session.session.version
                            },
                            interaction: {
                                merchant: {
                                    name: this.namePayment,
                                    address: {
                                        line1: this.addressPayment,
                                    }
                                }
                            }
                        })
                        this.showPaymentPage()
                        this.renderCreditInfor(totalAmountPayment)
                    } else {
                        this.isLoadingButtonAddCredit = false
                        this.$emit('showToast', res.message, "error")
                    }
                } else if (this.paymentType === 'ccavenue') {
                    const payload = {
                        amount: totalAmountPayment,
                        is_fund_deposit: true,
                        payment_method: 'card',
                        utm_campaign,
                        utm_content,
                        utm_medium,
                        utm_source,
                        utm_term
                    }
                    const res = await paymentService.puschaseCcavenuePayment(payload)
                    if (res.status === 200) {
                        localStorage.setItem("methodPayment", "1");
                        localStorage.setItem('referencePayment', res.data.reference)
                        // window.location.href = res.data.payment_url
                        this.$router.push({name:'Payment', params:{data: res.data}})
                        this.renderCreditInfor(totalAmountPayment)
                    } else {
                        this.isLoadingButtonAddCredit = false
                        this.$emit('showToast', res.message, "error")
                    }
                } else {
                    let param = {
                        amount: totalAmountPayment
                    };

                    const res = await paymentService.purchaseThawani(param);
                    if (res.status === 200) {
                        if (!isStrEmpty(res.data["checkout_url"])) {
                            localStorage.setItem("methodPayment", "1");
                            this.renderCreditInfor(totalAmountPayment)
                            window.location.href = res.data["checkout_url"];
                        } else {
                            this.$emit('showToast', res.message, "error")
                            this.isLoadingButtonAddCredit = false
                        }
                    } else {
                        this.$emit('showToast', res.message, "error")
                        this.isLoadingButtonAddCredit = false
                    }
                }
            } catch (error) {
                 this.isLoadingButtonAddCredit = false
                console.log(error)
            }
        },

        async onSubmitChangeCard() {
            this.isLoadingChangeCard = true;
            try {
                const resp = await cardService.updateDefault(this.cardID);
                if (resp.status === 200) {
                    let index = this.listCards.findIndex(
                        (x) => x.id === this.cardID
                    );
                    if (index !== -1) {
                        let listCart = this.listCards.map((x, i) => ({
                            ...x,
                            is_default: i === index,
                        }));

                        let element = this.listCards[index];

                        this.cardNumberSelect = element.card_number;
                        this.typeCard = element.card_type_name;
                        this.expiryDate = element.expiry_date;
                        this.nameCard = element.card_holder_name;
                        this.listCards = listCart;
                        this.$refs.bottomChangeCard?.close();
                        this.isLoadingChangeCard = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        onChangeCard() {
            this.$refs.bottomChangeCard?.open();
        },

        // add cvv
        onShowModalCvv() {
            if(ENABLE_MANAGE_CREDIT_CARDS === 'no') {
                 this.onSubmitAddCredit();
            } else {
                if (this.listCards.length === 0) {
                    this.$emit("showToast", "please add card first", "error");

                    return false;
                }

                if (parseFloat(this.price) <= 0) {
                    this.$emit(
                        "showToast",
                        "please enter transfer amount",
                        "error"
                    );

                    return false;
                }

                // if (
                //     !this.price ||
                //     parseFloat(this.price.replace(",", "")) <
                //         parseFloat(this.limitAmount)
                // ) {
                //     this.$emit(
                //         "showToast",
                //         "Minimum deposit amount is " + this.limitAmount + " OMR",
                //         "error"
                //     );

                //     return false;
                // }
                this.card_security_code = "";
                this.modalShowCvv = true;
            }
        },

        onAddSvv() {
            if (this.card_security_code.length < 3) {
                this.stateCvv = false;
                return false;
            } else {
                this.stateCvv = null;
                this.modalShowCvv = false;
                this.onSubmitAddCredit();
            }
        },

        onClickGoBack() {
            if (this.isLoadingButtonAddCredit) {
                if (
                    confirm(
                        "The transfer is in progress, are you sure you want to cancel?"
                    )
                ) {
                    this.$router.push("/wallet");
                }
            } else {
                this.$emit("onClickGoBack");
            }
        },

        // render image card

        renderTypeCard(type) {
            switch (true) {
                case type === "Visa":
                    return "visa.png";
                case type === "Mastercard":
                    return "master.png";
                case type === "Amex":
                    return "amex.png";
                default:
                    return "visa.png";
            }
        },

        // on go back screen

        onClick() {
            this.$emit("onClickGoBack");
        },

        // common format
        resizeInput() {
            let input = document.getElementById("input-price");

            input.style.width = input.value.replaceAll(".", "").length + "ch";
        },

        formatCardNumber() {
            return creditCardMask(this.cardNumberSelect);
        },

        formatter(event) {
            const { value } = event.target;
            let isContainsLowercase = /^(?=.*[a-z]).*$/;
            let isNumber = /^[0-9.]*$/
            this.price = !isStrEmpty(value) && !isContainsLowercase.test(value) && isNumber.test(value) ? parseFloat(value.replace(/,/g, ""))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
            // let input = document.getElementById("input-price");
            let length = this.price.replaceAll(".", "").length;
            let numberMore = (this.price.match(/,/g) || []).length;
            numberMore = numberMore > 0 ? -numberMore + 1 : 0.5;
            // input.style.width = length + numberMore + "ch";
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },


        validateExpirationDate(month, year) {
            const today = new Date();
            let mm = today.getMonth() + 1;
            let yy = today.getFullYear();

            if (mm < 10) mm = "0" + mm;

            yy = String(yy).substring(2);

            if (
                (Number(month) < Number(mm) && Number(year) === Number(yy)) ||
                Number(month) > 12 ||
                Number(year) < Number(yy)
            ) {
                this.errorMessage = this.getTranslatedText(
                    "Please enter an Expiration Date greater than current"
                );
                this.stateExpirationDate = false;
            } else {
                this.errorMessage = "";
                this.stateExpirationDate = null;
            }
        },

        formatNumberWinners(price) {
            return formatNumberMoney(Number(price));
        },

        updateFormData(item) {
            let { name, value } = item;

            this.dataForm[name] = value;
        },

        formatterExpirationDate(value) {
            const mm = value.replace(/\//g, "").substring(0, 2);
            const yy = value.replace(/\//g, "").substring(2, 4);

            const expDateFormatter =
                value.replace(/\//g, "").substring(0, 2) +
                (value.length > 2 ? "/" : "") +
                value.replace(/\//g, "").substring(2, 4);
            this.validateExpirationDate(mm, yy);
            return expDateFormatter;
        },

         formatterCardNumber(value) {
            let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
            let matches = v.match(/\d{4,16}/g);
            let match = (matches && matches[0]) || "";
            let parts = [];
            for (let i = 0, len = match.length; i < len; i += 4) {
                parts.push(match.substring(i, i + 4));
            }
            if (parts.length) {
                return parts.join(" ");
            } else {
                return value;
            }
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/wallet/_wallet.scss" />
