export const listNumbersRandom = [
    {
        active: false,
        number: 1
    },
    {
        active: false,
        number: 2
    },
    {
        active: false,
        number: 3
    },
    {
        active: false,
        number: 4
    },
    {
        active: false,
        number: 5
    },
    {
        active: false,
        number: 6
    },
    {
        active: false,
        number: 7
    },
    {
        active: false,
        number: 8
    },
    {
        active: false,
        number: 9
    },
    {
        active: false,
        number: 10
    },
    {
        active: false,
        number: 11
    },
    {
        active: false,
        number: 12
    },
    {
        active: false,
        number: 13
    },
    {
        active: false,
        number: 14
    },
    {
        active: false,
        number: 15
    },
    {
        active: false,
        number: 16
    },
    {
        active: false,
        number: 17
    },
    {
        active: false,
        number: 18
    },
    {
        active: false,
        number: 19
    },
    {
        active: false,
        number: 20
    },
    {
        active: false,
        number: 21
    },
    {
        active: false,
        number: 22
    },
    {
        active: false,
        number: 23
    },
    {
        active: false,
        number: 24
    },
    {
        active: false,
        number: 25
    },
    {
        active: false,
        number: 26
    },
    {
        active: false,
        number: 27
    },
    {
        active: false,
        number: 28
    },
    {
        active: false,
        number: 29
    },
    {
        active: false,
        number: 30
    },
    {
        active: false,
        number: 31
    },
    {
        active: false,
        number: 32
    },
    {
        active: false,
        number: 33
    },
    {
        active: false,
        number: 34
    },
    {
        active: false,
        number: 35
    },
    {
        active: false,
        number: 36
    },
    {
        active: false,
        number: 37
    },
    {
        active: false,
        number: 38
    },
    {
        active: false,
        number: 39
    },
    {
        active: false,
        number: 40
    },
    {
        active: false,
        number: 41
    },
    {
        active: false,
        number: 42
    },
    {
        active: false,
        number: 43
    },
    {
        active: false,
        number: 44
    }
];
