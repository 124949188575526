<template>
    <div class="consent" v-if="isShowConsent">
        <div class="content">
                <p>{{ getTranslatedText("We use cookies to improve your browsing experience and help us improve our websites. We and carefully selected third parties also use cookies to show you more relevant ads online. By clicking 'Accept All Cookies', you agree to our use of cookies in accordance with our Cookie Policy.") }}</p>
                <div>
                    <ButtonCustom
                        classBtn="tc-btn"
                        typeBtn="primary"
                        type="primary"
                        text="T&Cs"
                        @onClickButton="$router.push('/terms-and-conditions?isCookie=true')"
                    />
                        <ButtonCustom
                        classBtn="accept-btn"
                        typeBtn="primary"
                        type="primary"
                        text="ACCEPT ALL"
                        @onClickButton="handleConsent"
                    />
                </div>
        </div>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import ButtonCustom from "@/components/common/button";
let scriptTag

export default {
    mixins: [languageMixin],
    components: {ButtonCustom},
    props: {
        isSetStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isShowConsent: false,
            isScreenMobile: false,
            screenWidth: window.innerWidth,
        }
    },

    created() {
        if (this.screenWidth <= 768) {
            this.isScreenMobile = true
        }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        this.isShowConsent = !localStorage.getItem('cookieConsent');
        scriptTag = document.createElement('script');
        scriptTag.textContent = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
        `;
        document.head.appendChild(scriptTag);
    },

    mounted() {
        const elm = document.getElementById("floating-id")
        if(this.isScreenMobile) {
            if(!localStorage.getItem('cookieConsent')) {
                elm.style.bottom = "200px"
                if(document.getElementById("change-pass")) {
                    document.getElementById("change-pass").style.marginBottom = "160px"
                }
            } else {
                elm.style.bottom = "20px"
                if(document.getElementById("change-pass")) {
                 document.getElementById("change-pass").style.marginBottom = "0"
                }
            }
        } else {
            elm.style.bottom = "60px"
            if(!localStorage.getItem('cookieConsent')) {
                if(document.getElementById("change-pass")) {
                    document.getElementById("change-pass").style.marginBottom = "100px"
                }
            } else {
                if(document.getElementById("change-pass")) {
                    document.getElementById("change-pass").style.marginBottom = "0"
                }
            }
        }
        if(!localStorage.getItem('cookieConsent')) {
            if(document.getElementById("section-favourite")) {
                document.getElementById("section-favourite").style.marginBottom = "140px"
            }
        } else {
            if(document.getElementById("section-favourite")) {
                document.getElementById("section-favourite").style.marginBottom = "0"
            }
        }
    },

    watch: {
        screenWidth(value) {
            if (value <= 768) {
                this.isScreenMobile = true
                if(!localStorage.getItem('cookieConsent')) {
                    document.getElementById("floating-id").style.bottom = "200px"
                    } else {
                    document.getElementById("floating-id").style.bottom = "20px"
                }
            }
            else {
                this.isScreenMobile = false
                document.getElementById("floating-id").style.bottom = "60px"
            }
        },
    },

    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        handleConsent() {
             function removeScript(script) {
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            }
            removeScript(scriptTag);
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted'
                });
                dataLayer.push({'event': 'cookie_consent_accepted'});
            `;
            document.head.appendChild(scriptTag);
            localStorage.setItem('cookieConsent', 'accepted');
            this.isShowConsent = !localStorage.getItem('cookieConsent')
            if(this.isScreenMobile) {
                document.getElementById("floating-id").style.bottom = "20px"
            } else {
                document.getElementById("floating-id").style.bottom = "60px"
            }
            if(document.getElementById("list-item")) {
                document.getElementById("list-item").style.marginBottom = "150px"
                document.getElementById("action-numbers").style.bottom = "0"
                document.getElementById("action-personal").style.marginBottom = "125px"
            }
            if (document.getElementById("personal-detail")) {
                document.getElementById("personal-detail").style.marginBottom = "0"
            }
            if (document.getElementById("main-checkout")) {
                document.getElementById("main-checkout").style.marginBottom = "50px"
            }
            if (document.getElementById("action-checkout")) {
                document.getElementById("action-checkout").style.bottom = "0"
            }
            if(document.getElementById("section-favourite")) {
                document.getElementById("section-favourite").style.marginBottom = "0"
            }
            if(document.getElementById("section-edit-number")) {
                document.getElementById("section-edit-number").style.bottom = "0"
            }
            if(document.getElementById("number-id-favourite")) {
                document.getElementById("number-id-favourite").style.bottom = "0"
            }
            if(document.getElementById("change-pass")) {
                document.getElementById("change-pass").style.marginBottom = "0"
            }
            if(document.getElementById("section-wallet")) {
                document.getElementById("section-wallet").style.marginBottom = "0"
            }
            if(document.getElementById("section-my-transactions")) {
                document.getElementById("section-my-transactions").style.marginBottom = "0"
            }
            if(document.getElementById("section-notifications")) {
                document.getElementById("section-notifications").style.marginBottom = "0"
            }
            if(document.getElementById("footer-id")) {
                document.getElementById("footer-id").style.marginBottom = "0"
            }
            if(document.getElementById("checkout-iframe-id")) {
                document.getElementById("checkout-iframe-id").style.marginBottom = "0"
            }
            if(document.getElementById("payment-stripe-id")) {
                document.getElementById("payment-stripe-id").style.marginBottom = "0"
            }
            if(document.getElementById("checkout-payment-card")) {
                document.getElementById("checkout-payment-card").style.bottom = "10px"
            }
            if(document.getElementById("express-checkout-element")) {
                document.getElementById("express-checkout-element").style.bottom = "10px"
            }
            if(document.getElementById("apple-pay-payment")) {
                document.getElementById("apple-pay-payment").style.bottom = "10px"
            }
        }
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/_consent.scss" />
