import {idRandom, isArrayEmpty, isStrEmpty} from "@/components/common/utils";

import ToolService from "@/services/tool.service";
import GameService from "@/services/game.service";
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";

export default {
    mixins: [convertGSTtime],
    data() {
        return {
            listGameLotto: [],
            listBlockTickets: [
                {
                    id: idRandom(),
                    name: "Item 1",
                    numbersChecked: [],
                    date: [],
                    valueDate: "",
                    isError: false,
                    automatically: false,
                    priceMore: "72.500",
                },
            ],
            dataFavorite: {
                name: "",
                number: [],
                formItem: [
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "Name...",
                        name: "name",
                        required: false,
                        state: null,
                        textInvalid: "Please enter name",
                        label: "Enter Name",
                        for: "input-Name",
                        id: "input-Name",
                    },
                ],
            },
            maxiumBlue: 0,
            maxiumGreen: 0,
            minimum: null,
            maximum: null,
            indexTicket: 0,
            listNumber: null,
            dataFavoriteNumber: [],
            selectedFavoriteNumber: "",
            dateSelect: [],
            listGames: [],
            numberQuickPick: 1,
            formItemSelectDate: [
                {
                    type: "checkBox",
                    name: "date",
                    plain: false,
                    stacked: true,
                    options: [],
                },
            ],
            toastData: {
                message: "",
                type: "success",
            },
            isLoadingBtn: false,
            isLoadingPage: false,
        };
    },
    created() {
        this.fetchData();
        this.fetchGame();
    },
    methods: {
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        async fetchGame() {
            this.isLoadingPage = true
            const numberQuickPick = JSON.parse(localStorage.getItem('number-quick-pick'))
            let user_info = !isStrEmpty(localStorage.getItem("user_info"))
                ? JSON.parse(localStorage.getItem("user_info"))
                : null;
            let list_block = !isStrEmpty(localStorage.getItem("list_block"))
                ? JSON.parse(localStorage.getItem("list_block"))
                : [];

            const respLotoGame = await GameService.getLottoGames("4");
            if (user_info) {
                const respFavourite = await GameService.listFavourite({
                    user_id: user_info.id,
                });

                if (respFavourite.status === 200) {
                    this.dataFavoriteNumber = respFavourite.data.map((x) => {
                        let newFaNumber = x.favourite_number[0].map((x) =>
                            parseInt(x)
                        );
                        return {
                            value: x.id,
                            name: x.favourite_name,
                            numbers: newFaNumber,
                        };
                    });
                }
            }

            if (
                respLotoGame.status === 200 &&
                !isArrayEmpty(respLotoGame.data)
            ) {
                let listGames = [];
                this.listGameLotto = respLotoGame.data.map((x) => {
                    let date = this.convertGSTtime(x.draw_date) + ' GST';

                    let item = {
                        ...x,
                    };
                    item["text"] = date;
                    item["value"] = x.event_id.toString();

                    let itemListGames = {
                        bet: [],
                        gameId: x.id,
                        game_slug: x.game_slug,
                        cutoff: x.cutoff,
                        event_id: x.event_id,
                        draw_date: x.draw_date,
                        secure_jackpot_price: x.secure_jackpot_price,
                        double_jackpot_price: x.double_jackpot_price,
                        price: x.price,
                        estimated_jackpot: x.estimated_jackpot,
                    };

                    listGames.push(itemListGames);
                    return item;
                });
                if(this.listBlockTickets.length >= 1 && this.listBlockTickets[this.listBlockTickets.length - 1].numbersChecked.length === 0 && numberQuickPick){
                    this.listBlockTickets[this.listBlockTickets.length - 1].numbersChecked = numberQuickPick;
                    localStorage.removeItem('number-quick-pick')
                }else if(this.listBlockTickets.length >= 1 && this.listBlockTickets[this.listBlockTickets.length - 1].numbersChecked.length !== 0 && numberQuickPick){
                    let item = {
                        id: idRandom(),
                        name: `Item ${this.listBlockTickets.length + 1}`,
                        numbersChecked: numberQuickPick,
                        date: [],
                        valueDate: "",
                        isError: false,
                        automatically: false,
                        priceMore: "72.500",
                    }
                    this.listBlockTickets.push(item)
                    localStorage.removeItem('number-quick-pick')
                }
                this.listBlockTickets.forEach(item => {
                    item.valueDate = this.listGameLotto[0].draw_date;
                    item.date = [this.listGameLotto[0].event_id];
                })
                let listBlockTickets = JSON.stringify(this.listBlockTickets);
                localStorage.setItem("list_block", listBlockTickets);
                localStorage.setItem(
                    "list_games",
                    JSON.stringify(respLotoGame.data)
                );

                this.listGames = listGames;

                this.formItemSelectDate[0].options = this.listGameLotto;
                this.isLoadingPage = false
            }
        },
        async fetchData() {
            try {
                const resp = await ToolService.getValidateTicket();

                let list_block = !isStrEmpty(localStorage.getItem("list_block"))
                    ? JSON.parse(localStorage.getItem("list_block"))
                    : [];

                if (
                    this.$router.history.current.name !== "Home" &&
                    this.$router.history.current.name !== "Favourite"
                ) {
                    if (!isArrayEmpty(list_block)) {
                        this.numberQuickPick = list_block.length;

                        list_block = list_block.map((x, i) => {
                            let item = {
                                ...x,
                                name: `Item ${i + 1}`,
                            };
                            return {
                                ...item,
                            };
                        });

                        this.listBlockTickets = list_block;
                    }
                } else {
                    let element = this.listBlockTickets;
                    element[0].name = `Item ${list_block.length + 1}`;
                    this.listBlockTickets = element;
                }

                this.listNumber = new Array(resp.quatityNumber);
                this.minimum = resp.minMaxTickets[0];
                this.maximum = resp.minMaxTickets[1];
                this.maxiumBlue = resp.maxiumBlue;
                this.maxiumGreen = resp.maxiumGreen;
            } catch (err) {
                console.log(err, "err");
            }
        },

        handleQuickPickAll() {
            this.listBlockTickets.forEach((element) => {
                const numbers = Array(44)
                    .fill()
                    .map((_, index) => index + 1);
                numbers.sort(() => Math.random() - 0.5);
                element.numbersChecked = [...numbers.slice(0, 7)];

                if (element.date.length > 0 && element.isError) {
                    element.isError = false;
                }
            });

            let listBlockTickets = JSON.stringify(this.listBlockTickets);
            localStorage.setItem("list_block", listBlockTickets);
        },

        handleRandomNumbers(index, isOneTickets) {
            this.indexTicket = index;
            const numbers = Array(44)
                .fill()
                .map((_, index) => index + 1);
            numbers.sort(() => Math.random() - 0.5);

            if (
                this.listBlockTickets[index].date.length > 0 &&
                this.listBlockTickets[index].isError
            ) {
                this.listBlockTickets[index].isError = false;
            }

            this.listBlockTickets[index].numbersChecked = [
                ...numbers.slice(0, 7),
            ];
            if (!isOneTickets) {
                let listBlockTickets = JSON.stringify(this.listBlockTickets);
                localStorage.setItem("list_block", listBlockTickets);
            }
        },

        handlePickNumber(payload, isOneTickets) {
            let { number, indexTicket } = payload;

            let itemNumbersChecked =
                this.listBlockTickets[indexTicket].numbersChecked;

            let index = itemNumbersChecked.findIndex((x) => x === number);

            if (index !== -1) {
                itemNumbersChecked.splice(index, 1);
            } else {
                if (itemNumbersChecked.length < this.maximum) {
                    itemNumbersChecked.push(number);
                }
            }

            if (!isOneTickets) {
                let listBlockTickets = JSON.stringify(this.listBlockTickets);
                localStorage.setItem("list_block", listBlockTickets);
            }
        },

        // onClick Action
        onClickActionTicket(action) {
            let { indexTicket, idAction } = action;
            this.indexTicket = indexTicket;
            switch (true) {
                case idAction === 1:
                    if (
                        this.listBlockTickets[indexTicket].numbersChecked
                            .length < this.minimum
                    ) {
                        this.showToast(
                            `${this.getTranslatedText(`Please select`)} ${
                                this.minimum
                            } ${this.getTranslatedText(`number`)}`,
                            "warning"
                        );
                        return false;
                    }
                    this.dataFavorite.number =
                        this.listBlockTickets[indexTicket].numbersChecked;

                    this.$refs.bottomFavorite?.open();
                    this.dataFavorite.formItem[0].state = null;
                    this.dataFavorite["name"] = "";

                    break;
                case idAction === 2:
                    if (this.dataFavoriteNumber.length === 0) {
                        this.showToast(
                            this.getTranslatedText("No favorites yet"),
                            "warning"
                        );
                        return false;
                    }
                    this.selectedFavoriteNumber = "";
                    this.$refs.bottomFavoriteNumber?.open();
                    break;
                case idAction === 3:
                    this.handleRandomNumbers(indexTicket);
                    break;
                default:
                    break;
            }
        },

        // update input name
        updateDataFavorite(item) {
            let { value, name } = item;
            if (
                name === "name" &&
                this.dataFavorite.formItem[0].state === false
            ) {
                this.dataFavorite.formItem[0].state = true;
            }
            this.dataFavorite[name] = value;
        },

        // update common
        updateFormData(item) {
            let { value, name } = item;

            this.listBlockTickets[this.indexTicket][name] = value;
            if (name === "date") {
                if (
                    !isArrayEmpty(this.listBlockTickets[this.indexTicket][name])
                ) {
                    this.listBlockTickets[
                        this.indexTicket
                    ].automatically = false;
                }
            }

            let listBlockTickets = JSON.stringify(this.listBlockTickets);
            localStorage.setItem("list_block", listBlockTickets);
        },

        onChangeSelectAuto(value) {
            this.listBlockTickets[this.indexTicket].automatically = value;
            this.listBlockTickets[this.indexTicket].date = [];
            let listBlockTickets = JSON.stringify(this.listBlockTickets);
            localStorage.setItem("list_block", listBlockTickets);
        },

        // action favorite
        async onFinishFavorite() {
            this.isLoadingBtn = true;
            if (this.dataFavorite.name === "") {
                this.dataFavorite.formItem[0].state = false;
                this.isLoadingBtn = false;
                return false;
            }

            if (!this.$store.state.isLoggedIn) {
                this.showToast(
                    this.getTranslatedText("Please Log In"),
                    "error"
                );

                this.$router.push("/login");
                this.isLoadingBtn = false;
                return false;
            }

            try {
                let user_info = JSON.parse(localStorage.getItem("user_info"));

                let param = {
                    user_id: user_info.id,
                    favourite_name: this.dataFavorite.name,
                    favourite_number: this.dataFavorite.number,
                    bonus_ball: [],
                };

                const resp = await GameService.addFavourite(param);

                if (resp.status === 200) {
                    let item = {
                        value: resp.data.id,
                        name: resp.data.favourite_name,
                        numbers: this.dataFavorite.number,
                    };
                    this.dataFavoriteNumber.push({ ...item });
                    this.dataFavorite.name = "";
                    this.dataFavorite.formItem[0].state = true;
                    this.$refs.bottomFavorite?.close();
                    this.isLoadingBtn = false;
                    this.showToast(
                        this.getTranslatedText("Number market as Favorite"),
                        "success"
                    );
                } else {
                    this.isLoadingBtn = false;
                    if (!isArrayEmpty(resp.errors)) {
                        resp.errors.map((x) => {
                            this.showToast(
                                this.getTranslatedText(x.message),
                                "error"
                            );
                        });
                    } else {
                        this.showToast(
                            this.getTranslatedText(resp.message),
                            "error"
                        );
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },

        onCancelFavorite() {
            this.$refs.bottomFavorite?.close();
        },

        //action select numbers favorite
        onFinishFavoriteNumber() {
            let index = this.dataFavoriteNumber.findIndex(
                (x) => x.value === this.selectedFavoriteNumber
            );    
            if(index > -1) {
                let { numbers: newNumber } = this.dataFavoriteNumber[index];
                this.listBlockTickets[this.indexTicket].numbersChecked = newNumber;
                this.$refs.bottomFavoriteNumber?.close();
            } else {
                this.showToast(
                    this.getTranslatedText('Please select your favorite number'),
                    "error"
                );
            }
        },

        onCancelFavoriteNumber() {
            this.$refs.bottomFavoriteNumber?.close();
        },

        // select date
        onShowSheet(index) {
            this.indexTicket = index;
            this.$refs.bottomSelectDate?.open();
        },

        onUpdateDate(value) {
            this.dateSelect = value;
        },

        onFinishSelectDate() {
            let index = this.indexTicket;
            let element = this.listBlockTickets;
            if (
                element[index].numbersChecked.length >= this.minimum &&
                element[index].isError
            ) {
                element[index].isError = false;
                this.listBlockTickets = element;
            }

            if (
                isArrayEmpty(element[index].date) &&
                !element[index].automatically
            ) {
                element[index].valueDate = "";
            } else if (
                !isArrayEmpty(element[index].date) &&
                !element[index].automatically
            ) {
                let dateSelect = [];
                element[index].date.map((x) => {
                    let index = this.listGames.findIndex(
                        (v) => v.event_id === x
                    );
                    if (index !== -1) {
                        dateSelect.push(this.listGames[index].draw_date);
                    }
                });

                dateSelect = dateSelect.sort(
                    (a, b) => new Date(a).getTime() - new Date(b).getTime()
                );
                element[index].valueDate = dateSelect[0];
            } else {
                element[index].valueDate = this.listGames[0].draw_date;
            }

            let listBlockTickets = JSON.stringify(this.listBlockTickets);
            localStorage.setItem("list_block", listBlockTickets);
            this.listBlockTickets = element;

            this.$refs.bottomSelectDate?.close();
        },
    },
};
