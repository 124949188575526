import BaseService from "@/services/base.service";
const API_URL = process.env.VUE_APP_API_URL;

class LiveWatchService extends BaseService {
    getDrawVideo(type, per_page, page = 1) {
        return this.get(API_URL + `/api/draw-video/get-list?type=${type}&per_page=${per_page}&page=${page}`).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new LiveWatchService();
