<template>
    <div class="section-no-empty" :class="{ 'no-padding': isShowEmptyCart }">
        <template v-if="!isShowEmptyCart">
            <div class="section-no-empty__image">
                <svg
                    class="section-no-empty__img-simple"
                    width="64"
                    height="41"
                    viewBox="0 0 64 41"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        transform="translate(0 1)"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <ellipse
                            class="section-no-empty__img-simple-ellipse"
                            cx="32"
                            cy="33"
                            rx="32"
                            ry="7"
                        ></ellipse>
                        <g
                            class="section-no-empty__img-simple-g"
                            fill-rule="nonzero"
                        >
                            <path
                                d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                            ></path>
                            <path
                                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                class="section-no-empty__img-simple-path"
                            ></path>
                        </g>
                    </g>
                </svg>
            </div>
            <p class="section-no-empty__text">
                {{ text }}
            </p>
        </template>
        <template v-else>
            <div class="section-no-empty__cart" :style="cssProps">
                <div class="section-no-empty__cart-header">
                    {{ title }}
                </div>
                <div class="section-no-empty__cart-content">
                    <img loading="lazy"
                        class="section-no-empty__cart-img"
                        src="@/assets/images/checkout/cart-empty.webp"
                        alt="cart-img"
                    />
                    <p class="section-no-empty__cart-sub-title">
                        {{ subTitle }}
                    </p>
                    <p class="section-no-empty__cart-description">
                        {{ description }}
                    </p>
                    <div class="section-no-empty__cart-button">
                        <ButtonCustom
                            typeBtn="button"
                            type="primary"
                            text="buy now"
                            @onClickButton="$router.push('/numbers')"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import ButtonCustom from "@/components/common/button";

export default {
    components: { ButtonCustom },
    props: {
        text: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        isShowEmptyCart: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/checkout/empty-cart.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
        };
    },

    methods: {},
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/no-empty/_no-empty.scss" />
