<template>
    <div :class="classBtn">
        <b-button
            class="btn-button primary"
            :class="classOption"
            v-if="type === 'primary'"
            :type="typeBtn"
            :disabled="isLoading || isDisabled"
            @click="onClickButton"
        >
            <b-spinner v-if="isLoading" />
            <img loading="lazy"
                v-if="iconLeft && !isHiddenValue"
                :src="require(`@/assets/images/${iconLeft}`)"
                :alt="iconLeft" 
            />
            <img loading="lazy"
                v-if="icon && !isHiddenValue && isDownload"
                :src="require(`@/assets/images/${icon}`)"
                :alt="icon"
             />
            {{ !isHiddenValue ? textVariable ? textVariable : getTranslatedText(text) : '' }}
            <img loading="lazy"
                v-if="icon && !isHiddenValue && !isDownload"
                :src="require(`@/assets/images/${icon}`)"
                :alt="icon"
        /></b-button>
        <b-button
            :disabled="isLoading || isDisabled"
            v-if="type === 'default'"
            :type="typeBtn"
            class="btn-button default"
            @click="onClickButton"
        >
            <b-spinner v-if="isLoading" /> {{ getTranslatedText(text) }}
            <img loading="lazy"
                v-if="icon"
                :src="require(`@/assets/images/${icon}`)"
                :alt="icon"
        /></b-button>
        <div
            @click="onClickGoBack"
            v-if="type === 'go-back'"
            class="btn-button btn-back btn-custom"
        >
            <img loading="lazy" src="@/assets/images/arrow-left.png" alt="Go Back" />
            <span>{{ getTranslatedText(text) }}</span>
        </div>
    </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    props: {
        type: {
            type: String,
            default: "",
        },
        typeBtn: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: null,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        classBtn: {
            type: String,
            default: "",
        },
        isGoBackHistory: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        textVariable: {
            type: String,
        },
        classOption: {
            type: String,
            default: "",
        },
        isHiddenValue: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isDownload: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickButton() {
            this.$emit("onClickButton");
        },

        onClickGoBack() {
            if (this.isGoBackHistory) {
                this.$router.go(-1);
            } else {
                this.$emit("onClickButton");
            }
        },
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/button/_button.scss" />
