<template>
    <div class="brand-ambassador">
        <div class="brand-ambassador__main">
            <div class="brand-ambassador__contents">
                <div class="brand-ambassador__contents__main">
                    <div class="brand-ambassador__title">{{ getTranslatedText('Maradona') }}<br/><span>{{ getTranslatedText('Rebello') }}</span></div>
                    <div class="line"></div>
                    <div class="brand-ambassador__sub-title">{{ getTranslatedText('Brand Ambassador / Host') }}</div>
                </div>
                <div class="brand-ambassador__contents__info">
                    <div class="brand-ambassador__description"><span class="t-transform-cap">{{ getTranslatedText('Maradona Rebello ') }}</span>{{ getTranslatedText('is an actor by profession but an environmentalist') }}</div>
                    <a class="instagram" href="https://www.instagram.com/maradonarebello/" target="_blank">
                        <img class="img-insta" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/insta.svg" alt="" />
                        <div class="insta-title">{{ getTranslatedText('Engage with') }}<b>{{ getTranslatedText('Maradona') }}</b>{{ getTranslatedText('on Instagram') }}</div>
                    </a>
                </div>
            </div>
            <div class="img-avatar">
                <div class="img-avatar__item">
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-1.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-4.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-7.webp" alt="" />
                </div>
                <div class="img-avatar__item">
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-2.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-5.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-8.webp" alt="" />
                </div>
                <div class="img-avatar__item">
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-3.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-6.webp" alt="" />
                    <img class="img-brand" loading="lazy" src="@/assets/images/static-pages/brand-ambassador/Maradona-9.webp" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixin from '@/mixins/language/language-mixin';
let scriptTag
export default {
    mixins: [languageMixin],
    components: {
    },
    // metaInfo() {
    //     return {
    //         title: "Brand Ambassador",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Brand Ambassador"
    //             }
    //         ]
    //     };
    // },
    created() {
        this.addScriptCanonical()
    },

    beforeDestroy(){
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    },
    
    methods: {
        addScriptCanonical() {
            scriptTag = document.createElement("link");
            scriptTag.rel = "canonical";
            scriptTag.href =`https://omillionaire.com/brand-ambassador`
            document.head.appendChild(scriptTag);
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/brand-ambassador.scss" />
