<template>
    <div class="section-action-number-favourite">
       <div class="section-action-number-favourite__title">
          {{ getTranslatedText('Edit Your Favorite Number') }}
        </div>
        <div class="section-action-number-favourite__contents-main">
            <div class="content">
                <div class="content__head">
                    <p class="title-fav">{{getTranslatedText('item')}} {{`${indexItem + 1}`}}</p>
                    <div class="quick-pick__item-icon">
                        <img loading="lazy"
                            v-if="listBlockTickets[0].numbersChecked.length > 0"
                            class="quick-pick__item-icon-remove"
                            @click="$emit('onRemoveTicket', indexItem)"
                            src="@/assets/images/tickets/remove.png"
                            alt="remove"
                        />
                    </div>
                </div>
                <FormCommon
                    :formItem="formItem"
                    :dataForm="dataForm"
                    :class-container="'section-action-number-favourite__form'"
                    @update-form-data="updateFormData"
                    @onSubmitForm="onSubmitForm"
                >
                </FormCommon>
                <QuickPick
                    :listNumber="listNumber"
                    :numbersChecked="listBlockTickets[0].numbersChecked"
                    :showTextSelected="false"
                    :indexTicket="0"
                    :minimumBalls="minimum"
                    :maxiumBlue="maxiumBlue"
                    :maxiumGreen="maxiumGreen"
                    :showBtnRandom="false"
                    :showLine="true"
                    class="section-action-number-favourite__quick-pick"
                    @handleRandomNumbers="handleRandomNumbers"
                    @handlePickNumber="handlePickNumber"
                >
                </QuickPick>
            </div>
        <div class="section-edit-number" id="number-id-favourite">
            <button
                @click="onSubmitForm"
                :disabled="isLoadingButton"
                class="section-edit-number__btn-right t-transform-cap"
            >
                <b-spinner v-if="isLoadingButton" />
                {{ getTranslatedText("confirm") }}
            </button>
        </div>
        </div>
    </div>
</template>
<script>
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";

import quickPickMixin from "@/mixins/quick-pick/quick-pick-mixin";
import LanguageMixin from "@/mixins/language/language-mixin";

import favouriteService from "@/services/favourite.service";
import QuickPick from "@/components/common/quick-pick/QuickPick.vue";
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [quickPickMixin, LanguageMixin],
    components: {
        FormCommon,
        ButtonCustom,
        QuickPick,
    },
    props: {
        dataDetail: {
            type: Object,
            default: () => {
                return {};
            },
        },
        indexItem: {
            type: Number,
            default: -1,
        }
    },
    data() {
        return {
            isLoadingButton: false,
            dataForm: {
                name: "",
            },
            isShowConsent: IS_SHOW_CONSENT,
            formItem: [
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "name",
                    hideIconRq: true,
                    name: "name",
                    required: true,
                    label: "",
                    for: "input-name",
                    id: "input-name",
                },
            ],
        };
    },
    created() {
        this.fetchDataDetailNumber();
    },
    mounted() {
        let elm = document.getElementById("number-id-favourite")
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            elm.style.bottom = "200px"
        } else {
            elm.style.bottom = "0"
        }
        const element = document.getElementById("showMenu")
        console.log("🚀 ~ onClickEditBall ~ element:", element)
        if(element) {
            element.style.display = 'none'
        }
    },
    methods: {
        fetchDataDetailNumber() {
            this.listBlockTickets[0].numbersChecked = this.dataDetail.balls;
            this.dataForm.name = this.dataDetail.title;
        },

        async onSubmitForm() {
            this.isLoadingButton = true;
            let payload = {
                id: this.dataDetail.id,
                title: this.dataForm.name || "",
                balls: this.listBlockTickets[0].numbersChecked,
                ballsFinal: []
            };
            if (payload.balls < this.minimum) {
                this.$emit(
                    "showToast",
                    `${this.getTranslatedText("Please choose at least")} ${
                        this.minimum
                    } ${this.getTranslatedText("numbers")}`,
                    "error"
                );

                this.isLoadingButton = false;
                return false;
            }

            try {
                let param = {
                    id: this.dataDetail.id,
                    favourite_name: payload.title,
                    favourite_number: payload.balls,
                    bonus_ball: [],
                };

                const resp = await favouriteService.update(param);
                if (resp.status === 200) {
                    payload.message =
                        resp?.message || "Favourite number is saved";
                    this.isLoadingButton = false;
                    this.listBlockTickets[0].numbersChecked = [];
                    localStorage.setItem("list_block", JSON.stringify(this.listBlockTickets));
                    this.$emit("onSubmit", payload);
                } else {
                    this.isLoadingButton = false;
                    if (resp.errors) {
                        resp.errors.map((message) => {
                            return this.$emit(
                                "showToast",
                                this.getTranslatedText(message.message),
                                "error"
                            );
                        });
                    } else {
                        this.$emit(
                            "showToast",
                            this.getTranslatedText(resp.message),
                            "error"
                        );
                    }
                }
            } catch (err) {
                console.log(err, "err");
            }
        },
        updateFormData(event) {
            let { value } = event;
            this.dataForm.name = value;
        },
        onClickGoBack() {
            this.$emit("onGoBack");
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/favourite/_favourite.scss"
/>
