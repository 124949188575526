<template>
    <b-modal
        :id="id"
        modal-class="modal-fullscreen"
        style="max-width: 100% !important"
        centered
        header-class="methodPayment__modal-header"
        body-class="methodPayment__modal-body"
        hide-footer
        :hide-header-close="true"
    >
        <template #modal-title>
            <h1 class="methodPayment__title">{{ getTranslatedText("Online Banking") }}</h1>
        </template>
        <div
            v-if="isShowWarningSelect"
            class="methodPayment__warning-not-selected"
        >
            {{ getTranslatedText('Please select a payment type') }}
        </div>
        <div class="methodPayment">
            <b-form-group class="methodPayment__form" v-slot="{ ariaDescribedby }">
                <div class="methodPayment__item" v-if="payment.includes('card')">
                    <b-form-radio
                        @change="changeOptionSelect"
                        v-model="selected"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="2"
                    >
                        {{getTranslatedText('Omani Debit Card Only')}}
                    </b-form-radio>
                    <div class="methodPayment__item-image">
                        <img loading="lazy" src="@/assets/images/common/nbo-master.png" alt="nbo-master">
                        <img loading="lazy" src="@/assets/images/common/nbo-visa.png" alt="nbo-visa">
                    </div>
                </div>
                <div class="methodPayment__item" v-if="payment.includes('credit')">
                    <b-form-radio
                        @change="changeOptionSelect"
                        v-model="selected"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="1"
                    >
                        {{getTranslatedText('International credit card')}}
                    </b-form-radio>
                    <div class="methodPayment__item-image">
                        <img loading="lazy" src="@/assets/images/common/card-visa.png" alt="card-visa">
                        <img loading="lazy" src="@/assets/images/common/card-master.png" alt="card-master">
                    </div>
                </div>  
                 <div class="methodPayment__item" v-if="payment.includes('ccavenue')">
                    <b-form-radio
                        @change="changeOptionSelect"
                        v-model="selected"
                        :aria-describedby="ariaDescribedby"
                        name="some-radios"
                        value="3">
                        <span class="text-uppercase">{{getTranslatedText('CC')}}</span>
                        <span class="text-capitalize">{{getTranslatedText('Avenue')}}</span>
                    </b-form-radio>
                     <div class="methodPayment__item-image">
                        <img loading="lazy" src="@/assets/images/common/card-visa.png" alt="card-visa">
                        <img loading="lazy" src="@/assets/images/common/card-master.png" alt="card-master">
                    </div>
                </div>
            </b-form-group>
            <b-button
                class="methodPayment__button"
                @click="onSubmitPayment"
                :disabled="isLoadingButton || isShowWarningSelect"
            >
                <b-spinner v-if="isLoadingButton" />
                {{ getTranslatedText("confirm and pay")}}
            </b-button>
        </div>
    </b-modal>
</template>
<script>
import { isStrEmpty } from '@/components/common/utils';

import PaymentService from "@/services/payment.servive";

import languageMixin from "@/mixins/language/language-mixin";
import paymentMixin from "@/mixins/payment/payment-mixin"

import ButtonCustom from "@/components/common/button";
const PAYMENT_TYPE = process.env.VUE_APP_PAYMENT;

export default {
    mixins: [languageMixin, paymentMixin],
    components: {
        ButtonCustom,
    },
    props: {
        totalAmountPayment: {
            type: Number,
            default: 0
        },
        id: {
            type: String,
            default: ''
        },
        // methodPayment: {
        //     type: String,
        //     default: ''
        // }
    },
    mounted() {
        this.loadDefaultSelected();
        let gateway = document.createElement('script')
        gateway.setAttribute('src', 'https://nbo.gateway.mastercard.com/checkout/version/62/checkout.js')
        gateway.setAttribute('data-error', 'errorCallback')
        gateway.setAttribute('data-cancel', 'cancelCallback')
        gateway.setAttribute('data-complete', `completeCallback`)
        document.head.appendChild(gateway)
    },
    data() {
        return {
            selected: '',
            isLoadingButton: false,
            isShowWarningSelect: false,
            payment: PAYMENT_TYPE
        };
    },
    methods: {
        loadDefaultSelected() {
            this.selected = localStorage.getItem('puschasePayment');
        },
        changeOptionSelect() {
            this.isShowWarningSelect = false;
        },
        async onSubmitPayment() {
            try {
                if(this.selected !== '1' && this.selected !== '2' && this.selected !== '3') {
                    this.isShowWarningSelect = true;
                } else {
                    this.isLoadingButton = true
                    localStorage.setItem('puschasePayment', this.selected)
                    if(this.selected === '1') {
                        const res = await PaymentService.puschaseNboPayment(this.totalAmountPayment)
                        Checkout.showPaymentPage()
                        if(res.status === 200) {
                            let data = res.data
                            localStorage.setItem("methodPayment", "1");
                            localStorage.setItem('referencePayment', res.data.reference)
                            Checkout.configure({
                                merchant: data.session.merchant,
                                session: {
                                    id: data.session.session.id,
                                    version: data.session.session.version
                                },
                                interaction: {
                                    merchant: {
                                        name: this.namePayment,
                                        address: {
                                            line1: this.addressPayment,
                                        }
                                    }
                                }
                            })
                            this.showPaymentPage()
                        } else {
                            this.isLoadingButton = false
                            this.$emit('showToast', res.message, "error")
                        }
                    } else if (this.selected === '3') {
                        const res = await PaymentService.puschaseCcavenuePayment(this.totalAmountPayment)
                        if (res.status === 200) {
                            localStorage.setItem("methodPayment", "1");
                            localStorage.setItem('referencePayment', res.data.reference)
                            window.location.href = res.data.payment_url;
                        } else {
                            this.isLoadingButton = false
                            this.$emit('showToast', res.message, "error")
                        }      
                    } else {
                        let param = {
                            amount: this.totalAmountPayment
                        };

                        const res = await PaymentService.purchaseThawani(param);
                        if (res.status === 200) {
                            if (!isStrEmpty(res.data["checkout_url"])) {
                                localStorage.setItem("methodPayment", "2");
                                window.location.href = res.data["checkout_url"];
                            } else {
                                this.$emit('showToast', res.message, "error")
                                this.isLoadingButton = false
                            }
                        } else {
                            this.$emit('showToast', res.message, "error")
                            this.isLoadingButton = false
                        }
                    }
                }
            } catch (error) {
                 this.isLoadingButton = false
                console.log(error)
            }
        }
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/methodPayment/methodPayment.scss" />
