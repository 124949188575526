import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class CardService extends BaseService {
    listCards() {
        return this.get(API_URL + "/api/credit-card/list").then((response) => {
            return response.data;
        });
    }

    addCard(param) {
        return this.post(API_URL + "/api/credit-card/create", param).then(
            (response) => {
                return response.data;
            }
        );
    }

    updateCard(id, param) {
        return this.post(API_URL + `/api/credit-card/edit/${id}`, param).then(
            (response) => {
                return response.data;
            }
        );
    }

    updateDefault(id) {
        return this.put(API_URL + `/api/credit-card/default/${id}`, "").then(
            (response) => {
                return response.data;
            }
        );
    }

    removeCard(id) {
        return this.delete(API_URL + `/api/credit-card/delete/${id}`).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new CardService();
