<template>
    <div class="main-email" >
        <div class="loading-full-page" v-if="isLoadingPage">
                <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </b-card>
            </div>
        <div v-else>
            <BannerCustom/>
            <div class="section-email-confirm">
                <img v-if="statusSuccess" loading="lazy" src="@/assets/images/payment/success.svg"/>
                <img v-else loading="lazy" src="@/assets/images/common/email-fail.svg"/>
                <p class="title-verify" :class="statusSuccess ? 'success' : 'failed'">{{ getTranslatedText(message) }}</p>
                <div class="sub-title" v-if="statusSuccess">
                    {{ getTranslatedText('Return to your profile now that your email is verified.')}}
                </div>
                <div class="sub-title" v-else>
                    {{ getTranslatedText('Your verification has failed. Please try again in 15-20 minutes.')}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerCustom from "@/components/common/banner";
import LanguageMixin from "@/mixins/language/language-mixin";
import UserService from "@/services/user.service";

export default {
    mixins: [LanguageMixin],
    components: {BannerCustom},
    data() {
        return {
            isLoadingPage: false,
            statusSuccess: false,
            message: ''
        }
    },
    created() {
        if(this.$route.query && this.$route.query.tokenEmail) {
            this.verifyEmail(this.$route.query.tokenEmail, this.$route.query.type)
        } else {
            this.$router.push({path: '/'})
        }
    },
    methods: {
        async verifyEmail(token, type) {
            this.isLoadingPage = true
            try {
                const res = await UserService.verifyEmail(token, type)
                if(res) {
                    if(res.message) {
                        this.message = res.message
                    }
                    if(res.status === 200) {
                        this.statusSuccess = true
                    } else {
                        this.statusSuccess = false
                    }
                    if(this.$route.query && this.$route.query.tokenEmail) {
                        let url = this.$route.fullPath
                        let urlParts = url.split('?');
                        let params = new URLSearchParams(urlParts[1]);
                        params.delete('tokenEmail');
                        params.delete('type');
                        this.$router.push(urlParts[0])
                    }
                }
                this.isLoadingPage = false
            } catch (error) {
                this.isLoadingPage = false
                console.log(error)
            }
        }
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/confirm/_confirm.scss" />
