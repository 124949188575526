import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;
const VERS_LANG = process.env.VUE_APP_VERS_LANG;
class ToolService extends BaseService {
    getValidateTicket() {
        const param = {
            minMaxTickets: [7, 7],
            quatityNumber: 44,
            maxiumBlue: 22,
            maxiumGreen: 44,
        };
        return param;
    }

    getMutiLanguage(locale: string) {
        return this.get(
            API_URL + `/locale/callback/all?lang=${locale}&v=${VERS_LANG}`,
            ""
        ).then(({ data }) => data);
    }
}

export default new ToolService();
