import { isStrEmpty } from "@/components/common/utils";
import moment from "moment";

export default {
    methods: {
        convertGSTtime(time, isShowHours) {
            let utc = new Date(moment(time).format()).getTime() + (new Date(moment(time).format()).getTimezoneOffset() * 60000);
            let uaeDate = new Date(utc + (3600000*4));
            let date = isShowHours ? moment(uaeDate).format("DD/MM/YYYY HH:mm:ss") : moment(uaeDate).format("DD/MM/YYYY");
            return `${isStrEmpty(date) ? "" : date}`;
        },
    },
};
