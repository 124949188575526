const ENABLE_MANAGE_CREDIT_CARDS = process.env.VUE_APP_ENABLE_MANAGE_CREDIT_CARDS;
const ENABLE_RECURRING = process.env.VUE_APP_ENABLE_RECURRING;

export const listMenuNavigation = [
    {
        title: "My Profile",
        icon: "personal.png",
        iconFocus: "personal-white.svg",
        isRedirect: true,
        url: "/personal-detail",
        url1: "/change-password",
        url2: "/delete-account",
        isShow: true,
        isShowUserId: true,
        isShowCollapse: false,
        subMenu: [
            {
                title: 'My profile',
                url: '/personal-detail'
            },
            {
                title: 'change password',
                url: '/change-password'
            },
            {
                title: 'delete account',
                url: '/delete-account'
            }
        ]
    },
    {
        title: "My Numbers",
        icon: "hashtag.png",
        iconFocus: "hashtag-white.svg",
        isRedirect: false,
        url: "/favourite",
        isShow: true,
        isShowCollapse: false,
        subMenu: [
            {
                title: 'Past Combinations',
                url: '/favourite?tab=past'
            },
            {
                title: 'winning numbers',
                url: '/favourite?tab=win'
            },
            {
                title: 'favorite numbers',
                url: '/favourite?tab=fav'
            }
        ]
    },
    {
        title: "Manage Cards",
        icon: "manage.png",
        isRedirect: true,
        url: "/manage-cards",
        isShow: ENABLE_MANAGE_CREDIT_CARDS !== 'no' ? true : false
    },
    {
        title: "My Wallet",
        icon: "dollar.png",
        iconFocus: "dollar-white.svg",
        isRedirect: true,
        url: "/wallet",
        isShow: true
    },
    {
        title: "My Inbox",
        icon: "notify.png",
        iconFocus: "notify-white.svg",
        isRedirect: false,
        url: "/notifications",
        isShow: true,
        hasNumber: true
    },
    {
        title: "Recurring Games",
        icon: "recurring.png",
        isRedirect: true,
        url: "/recurring-games",
        isShow: ENABLE_RECURRING !== 'no' ? true : false
    },
    {
        title: "My Transactions",
        icon: "my-transactions.png",
        isRedirect: false,
        url: "/my-transactions",
        isShow: false
    },
];
