import BaseService from "@/services/base.service";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class TermsConditionsService extends BaseService {
    getTermsConditions(locale: string) {
        return axios.get(API_URL + '/api/page/terms', {
            headers: { locale: locale },
        }).then((response) => {
            return response.data;
        });
    }
}

export default new TermsConditionsService();
