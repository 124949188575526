<template>
    <div class="main">
        <BannerCustom title="About Us"/>
        <div class="about-us">
            <div class="about-us__contents">
                <div class="about-us__contents__content who">
                    <div class="img-mb"><img loading="lazy" class="img-d" src="@/assets/images/static-pages/family.webp" alt="" /></div>
                    <p class="about-us__contents__content__main">{{ getTranslatedText('WHO WE ARE')}}</p>
                    <div class="about-us__contents__content__group head">
                        <div class="about-us__contents__content__title">
                            <div class="about-us__contents__content__title__first">
                                <b class="oml">{{ getTranslatedText('O! MILLIONAIRE') }}</b>
                                {{ getTranslatedText('a green draw that makes dreams come true') }}
                            </div>

                            <div class="about-us__contents__content__title__second">
                                <b class="oml">{{ getTranslatedText('O! MILLIONAIRE') }}</b>
                                {{ getTranslatedText('why not for something good') }}
                            </div>

                            <div class="about-us__contents__content__title__last">
                                {{ getTranslatedText('we know how important') }}
                                <a class="link-text" href="https://omillionaire.shop/" target="_blank">{{ getTranslatedText("www.omillionaire.shop.")}}</a>
                            </div>
                        </div>
                        <img loading="lazy" class="img-fml" src="@/assets/images/static-pages/family.webp" alt="" />
                    </div>
                </div>
                <div class="about-us__contents__content left">
                    <div class="about-us__contents__content__group">
                        <img loading="lazy" class="img-map" src="@/assets/images/static-pages/map.webp" alt="" />
                        <div class="about-us__contents__content__title history">
                            <p class="about-us__contents__content__main">{{ getTranslatedText('HISTORY & BACKGROUND')}}</p>
                            <div class="about-us__contents__content__title__first">
                                <img loading="lazy" class="img-map-mb" src="@/assets/images/static-pages/map-mb.webp" alt="" />
                                {{ getTranslatedText('successfully starting and operating') }}
                            </div>

                            <div class="about-us__contents__content__title__second">
                                {{ getTranslatedText('participants have two ways to win') }}
                                <a class="link-text t-transform-cap" href="https://www.facebook.com/OMillionaire/" target="_blank">{{ getTranslatedText("Facebook")}}</a>
                                {{ getTranslatedText('and and')}}
                                <a class="link-text t-transform-cap" href="https://www.youtube.com/@OMillionaire" target="_blank">{{ getTranslatedText("Youtube")}}</a>
                                {{ getTranslatedText('channels')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-us__contents__content brand brand-position">
                    <img loading="lazy" class="img-bg" src="@/assets/images/static-pages/bg-us.webp" alt="" />
                    <img loading="lazy" class="img-bg-mb" src="@/assets/images/static-pages/bg-us-mb.webp" alt="" />
                    <div class="group">
                        <p class="about-us__contents__content__main brand">{{ getTranslatedText('BRAND POSITIONING')}}</p>
                        <div class="about-us__contents__content__title">
                            <div class="about-us__contents__content__title__first sub">
                                <div class="t-transform-up text-smaller">{{ getTranslatedText('VISION') }}</div>
                                <div class="line">
                                    {{ getTranslatedText("the first green draw in the world") }}
                                </div>
                            </div>

                            <div class="about-us__contents__content__title__second sub">
                                <div class="t-transform-up text-smaller">{{ getTranslatedText('MISSION') }}</div>
                                <div class="line">
                                    {{ getTranslatedText('as it stands') }}
                                </div>
                            </div>

                            <div class="about-us__contents__content__title__last sub">
                                <div class="t-transform-up text-smaller">{{ getTranslatedText('PURPOSE') }}</div>
                                <div class="line">
                                    {{ getTranslatedText('money for something good') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content brand brand-value">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('BRAND VALUES ')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first sub">
                            <div class="t-transform-up text-smaller">{{ getTranslatedText('SIMPLICITY ') }}</div>
                            <div class="line">
                                <div class="first">{{ getTranslatedText('Simplicity is the ultimate sophistication') }}</div>
                                <div>{{ getTranslatedText('anyone to take part') }}</div>
                            </div>
                        </div>

                        <div class="about-us__contents__content__title__second sub">
                            <div class="t-transform-up text-smaller">{{ getTranslatedText('EQUALITY ') }}</div>
                            <div class="line">
                                <div class="first">{{ getTranslatedText('Equal opportunity for all') }}</div>
                                <div>{{ getTranslatedText('they are in the world') }}</div>
                            </div>
                        </div>

                        <div class="about-us__contents__content__title__last sub">
                            <div class="t-transform-up text-smaller human">{{ getTranslatedText('HUMAN CENTRICITY ') }}</div>
                            <div class="line">
                                <div class="first">{{ getTranslatedText('participants and winners') }}</div>
                                <div>{{ getTranslatedText('we value our own') }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content cred">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('CREDIBILITY ')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first">
                        {{ getTranslatedText('draws are conducted') }}
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content how-to">
                    <img loading="lazy" class="img-draw" src="@/assets/images/static-pages/draw.webp" alt="" />
                    <div class="img-block"><img loading="lazy" class="img-draw-mb" src="@/assets/images/static-pages/draw-mb.webp" alt="" /></div>
                    <div class="how-to__content">
                        <p class="about-us__contents__content__main">{{ getTranslatedText('HOW TO PLAY ')}}</p>
                        <div class="about-us__contents__content__title">
                            <div class="about-us__contents__content__title__first">
                                {{ getTranslatedText('individuals can purchase') }}
                            </div>

                            <div class="about-us__contents__content__title__second">
                                {{ getTranslatedText('the draw is presided') }}
                            </div>

                            <div class="about-us__contents__content__title__last">
                                {{ getTranslatedText('in the subsequent draw') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content cred">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('PRIZE STRUCTURE')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first">
                            {{ getTranslatedText('green draw offers two ways to win') }}<br/><br/>
                            {{ getTranslatedText('a raffle draw') }}
                        </div>

                        <div class="about-us__contents__content__title__second">
                        {{ getTranslatedText('a grand draw is held') }}
                        </div>

                        <div class="about-us__contents__content__title__last">
                        {{ getTranslatedText('available upon check-out') }}
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content cred">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('ELIGIBILITY')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first">
                        {{ getTranslatedText('participants worldwide') }}
                        </div>
                    </div>
                    <img loading="lazy" class="img-buy" src="@/assets/images/static-pages/buy.webp" alt="" />
                    <div class="img-block buy"><img loading="lazy" class="img-buy-mb" src="@/assets/images/static-pages/buy-mb.webp" alt="" /></div>
                </div>
                <div class="about-us__contents__content cred tree">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('THE TREE PLANTATION - GREEN INITIATIVE "OASIS PARK”')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first">
                        {{ getTranslatedText('plantation project') }}
                        </div>
                    </div>
                </div>

                <div class="about-us__contents__content cred">
                    <p class="about-us__contents__content__main">{{ getTranslatedText('WINNINGS AND TAXES')}}</p>
                    <div class="about-us__contents__content__title">
                        <div class="about-us__contents__content__title__first">
                        {{ getTranslatedText('automatically credited') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import languageMixin from '@/mixins/language/language-mixin';
import BannerCustom from "@/components/common/banner";

export default {
    mixins: [languageMixin],
    components: { BannerCustom },
    data() {
        return {
        };
    },
    // metaInfo() {
    //     return {
    //         title: "O! Millionaire: Green Raffle Draw | Win BIG & Save Earth",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Each entry with O! Millionaire is your contribution to our tree plantation green initiative Oasis Park plus you get a chance to Win Your Best Life while Saving the Planet at the same time."
    //             }
    //         ]
    //     };
    // },
    methods: {
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/about-us.scss" />
