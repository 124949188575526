<template>
    <div :class="`quick-pick ${classQuickPick} ${isError ? 'is-error' : ''} `">
        <div
            class="quick-pick__header"
            :class="classContainer"
            v-if="title || action"
        >
            <div v-if="title" class="quick-pick__title">
                <div class="quick-pick__title-header">
                    {{ renderTitleTicket(title) }}
                    
                </div>
                <div
                    v-if="numbersChecked.length > minimumBalls"
                    class="quick-pick__amount-balls"
                >
                    + {{ priceMore }} OMR
                </div>
                <div
                    class="quick-pick__selected-numbers__desktop"
                    :class="classSelectedNumbers"
                >
                    <div
                        v-if="showTextSelected"
                        class="quick-pick__selected-numbers__label t-transform-cap"
                    >
                        {{ getTranslatedText("selected numbers") }}
                    </div>
                    <div
                        class="quick-pick__selected-numbers__numbers"
                        v-if="minimumBalls > 0"
                    >
                        <div
                            v-for="(item, index) in minimumBalls"
                            :key="index"
                            :class="`quick-pick__selected-numbers__number number-select ${renderClass(
                                item,
                                index
                            )}`"
                        >
                            <div
                                class="quick-pick__selected-numbers__box-number"
                            >
                                <a>{{ renderBalls(index) }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="action"
                class="quick-pick__action"
                :class="{
                    'quick-pick__action_rlt': $store.state.isRtl,
                }"
            >
                <div
                    v-for="(item, index) in dataAction"
                    :key="index"
                    class="quick-pick__item-icon"
                >
                    <!-- <span v-if="index !== 0" /> -->
                    <img loading="lazy"
                        :class="{
                            'quick-pick__item-quick-pick': item.id === 3,
                            'quick-pick__item-favorite': item.id === 1,
                            'quick-pick__item-favorite-list': item.id === 2,
                        }"
                        :src="require(`@/assets/images/${item.icon}`)"
                        :alt="item.icon"
                        @click="
                            $emit('onClickActionTicket', {
                                indexTicket,
                                idAction: item.id,
                            })
                        "
                    />
                </div>
                <div class="quick-pick__item-icon">
                    <img loading="lazy"
                        v-if="showIconRemove && (numbersChecked.length > 0 || lengthBLock > 1) || showExpItem"
                        class="quick-pick__item-icon-remove"
                        @click="$emit('onRemoveTicket', indexTicket)"
                        src="@/assets/images/tickets/remove.png"
                        alt="remove"
                    />
                </div>
                
            </div>
        </div>

        <div class="quick-pick__selected-numbers" :class="classSelectedNumbers">
            <div class="quick-pick__selected-numbers__label t-transform-cap" v-if="showTextSelected">
                {{ getTranslatedText("selected numbers") }}
            </div>
            <div
                class="quick-pick__selected-numbers__numbers"
                v-if="minimumBalls > 0"
            >
                <div
                    v-for="(item, index) in minimumBalls"
                    :key="index"
                    :class="`quick-pick__selected-numbers__number number-select ${renderClass(
                        item,
                        index
                    ) ? renderClass(
                        item,
                        index
                    ) + ' active-ball' : ''}`"
                >
                    <div class="quick-pick__selected-numbers__box-number">
                        <a>{{ renderBalls(index) }}</a>
                    </div>
                    <div class="circle circle-left"></div>
                    <div class="circle circle-right"></div>
                </div>
            </div>
            <div
                v-if="showBtnRandom"
                class="quick-pick__random-numbers header t-transform-cap"
            >
            <button @click="$emit('handleRandomNumbers', indexTicket)">
                <img loading="lazy"
                    src="@/assets/images//common/random-numbers.png"
                    alt="random-numbers"
                />
                {{ getTranslatedText("draw random numbers") }}
            </button>
        </div>

        </div>
        <div v-if="showLine" class="line"></div>
        <div class="quick-pick__numbers-quick-pick">
            <div class="quick-pick__numbers-quick-pick_numbers">
                <div
                    v-for="(_, index) in listNumber"
                    :key="++index"
                    @click="
                        $emit('handlePickNumber', {
                            indexTicket,
                            number: index,
                        })
                    "
                    :class="`quick-pick__selected-numbers__number selected-numbers__number__quick-pick ${renderClassListBall(
                        index
                    )}`"
                >
                    <div class="quick-pick__selected-numbers__box-number">
                        <a>{{ renderNumberLocale(index) }}</a>
                    </div>
                    <div class="circle circle-left"></div>
                    <div class="circle circle-right"></div>
                </div>
            </div>
        </div>
        <div
            v-if="showBtnRandom"
            class="quick-pick__random-numbers t-transform-cap"
        >
            <button @click="$emit('handleRandomNumbers', indexTicket)">
                <img loading="lazy"
                    src="@/assets/images//common/random-numbers.png"
                    alt="random-numbers"
                />
                {{ getTranslatedText("draw random numbers") }}
            </button>
        </div>

        <slot name="footer" />
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import { renderLocaleNumber } from "../utils";

export default {
    mixins: [languageMixin],
    props: {
        indexTicket: {
            type: Number,
            default: -1,
        },
        listNumber: {
            type: Array,
            default: () => [],
        },
        numbersChecked: {
            type: Array,
            default: () => [],
        },

        title: {
            type: String,
            default: "",
        },
        showTextSelected: {
            type: Boolean,
            default: true,
        },
        classQuickPick: {
            type: String,
            default: "",
        },
        classSelectedNumbers: {
            type: String,
            default: "",
        },
        classContainer: {
            type: String,
            default: "",
        },
        action: {
            type: Boolean,
            default: true,
        },
        dataAction: {
            type: Array,
            default: () => [],
        },
        showBtnRandom: {
            type: Boolean,
            default: true,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
        isShowBgr: {
            type: Boolean,
            default: false,
        },
        maxiumBlue: {
            type: Number,
            default: -1,
        },
        maxiumGreen: {
            type: Number,
            default: -1,
        },
        priceMore: {
            type: String,
            default: "0",
        },
        minimumBalls: {
            type: Number,
            default: 0,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        showIconRemove: {
            type: Boolean,
            default: false,
        },
        lengthBLock: {
            type: Number,
            default: 1
        },
        showExpItem: {
            type: Boolean,
            default: false
        },
        showLine: {
            type: Boolean,
            default: false
        }
    },
    computed: {},

    methods: {
        renderClass(number, index) {
            let className;
            let numberChecked = this.numbersChecked[index]
                ? this.numbersChecked[index]
                : -1;
            if (numberChecked !== -1) {
                className =
                    index + 1 > this.minimumBalls
                        ? "ball-red"
                        : numberChecked > this.maxiumBlue &&
                          numberChecked <= this.maxiumGreen
                        ? "ball-blue"
                        : "ball-green";
            } else {
                className = "";
            }

            return className;
        },
        renderClassListBall(index) {
            let className = "";
            if (this.numbersChecked.includes(index)) {
                let arrayMoreBalls = this.numbersChecked.slice(
                    this.minimumBalls
                );
                className = arrayMoreBalls.includes(index)
                    ? "activeNumber ball-red"
                    : index > this.maxiumBlue && index <= this.maxiumGreen
                    ? "activeNumber ball-blue"
                    : "activeNumber ball-green";
            }
            return className;
        },

        renderBalls(index) {
            let number = this.numbersChecked[index]
                ? renderLocaleNumber(this.numbersChecked[index])
                : "-";
            return number;
        },

        renderTitleTicket(text) {
            let textLowercase = text.toLowerCase();
            let number = textLowercase.split(" ")[1];
            textLowercase = textLowercase.replaceAll(
                "item",
                this.getTranslatedText("item")
            );
            textLowercase = textLowercase.replaceAll(
                number,
                renderLocaleNumber(parseInt(number))
            );
            return textLowercase;
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/quick-pick/quick-pick.scss"
/>
