<template>
    <div class="nav-bar">
         <div class="personal-detail__avatar">
            <ImageInput
                @reloadAvatar="reloadAvatar"
                v-model="avatar"
                @CaptureFromCamera="toggleCamera"
                :is-open-popup="isOpenPopupOptionAvatar"
                @changeShowPopup="togglePopupOptionsAvatar"
                @showToast="showToast($event.message, $event.type)"
            >
                <div
                    slot="activator"
                    class="personal-detail__avatar__camera-open"
                >
                    <div
                        class="personal-detail__avatar__circle"
                        v-if="!avatar && !isCameraOpen"
                    >
                        <v-avatar
                            v-if="$store.state.srcAvatar !== ''"
                            size="90px"
                            v-ripple
                            class="mb-2"
                        >
                            <img loading="lazy" :src="$store.state.srcAvatar"/>
                        </v-avatar>
                        <img loading="lazy"
                            class="avatar-icon"
                            v-if="$store.state.srcAvatar === ''"
                            src="@/assets/images/personal-detail/avatar.png"
                        />
                    </div>
                    <v-avatar size="90px" v-ripple v-else class="mb-2">
                        <img loading="lazy"
                            v-if="!isCameraOpen"
                            :src="avatar.imageURL"
                            alt="avatar"
                        />
                        <div
                            v-if="isCameraOpen"
                            v-show="!isLoading"
                            :class="{ flash: isShotPhoto }"
                        >
                            <div
                                class="camera-shutter"
                                :class="{ flash: isShotPhoto }"
                            ></div>

                            <video
                                v-show="!isPhotoTaken"
                                ref="camera"
                                :width="155"
                                :height="155"
                                autoplay
                            ></video>

                            <canvas
                                v-show="isPhotoTaken"
                                id="photoTaken"
                                ref="canvas"
                                :width="90"
                                :height="90"
                            ></canvas>
                        </div>
                    </v-avatar>
                    <div
                        v-show="isCameraOpen && isLoading"
                        class="camera-loading"
                    >
                        <ul class="loader-circle">
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    <div v-if="isCameraOpen && !isLoading" class="button-camera">
                        <button
                            type="button"
                            class="button-camera__save t-transform-cap"
                            @click="takePhoto"
                        >
                            {{ getTranslatedText("save") }}
                        </button>
                        <button
                            type="button"
                            class="button-camera__cancel t-transform-cap"
                            @click="toggleCamera"
                        >
                            {{ getTranslatedText("cancel") }}
                        </button>
                    </div>
                    <div class="personal-detail__avatar__edit">
                        <img loading="lazy"
                            src="@/assets/images/common/edit-avatar.svg"
                        />
                    </div>
                </div>
            </ImageInput>
        </div>
        <p class="name-user">{{userName}}</p>
        <ul class="menus">
            <li v-for="(item, index) in listMenuNavigation"
                :key="index"
                v-show="item.isShow"
                class="menu-item"
                >
                <div class="item" @click="handleClickRedirect(item)" 
                    :class="{ 'active': $route.fullPath === item.url || $route.fullPath === item.url1 || $route.fullPath === item.url2 }">
                    <div class="icon-menu"><img loading="lazy"
                        :src="
                            require(`@/assets/images/navigation/${item.icon}`)
                        "
                        :alt="item.icon"
                    /></div>
                    <div class="icon-menu-focus" v-if="item.iconFocus"><img loading="lazy"
                        :src="
                            require(`@/assets/images/navigation/${item.iconFocus}`)
                        "
                        :alt="item.icon"
                    /></div>
                    <span>{{ getTranslatedText(item.title) }}</span>
                    <span v-if="item.hasNumber" class="number"><a>{{ $store.state.unreadNotify }}</a></span>
                </div>
            </li>
            <li class="menu-item">
                <div class="item" @click="handleLogout()">
                    <div class="icon-menu"><img loading="lazy"
                        src="@/assets/images/common/signout.svg"
                        alt="signout"
                    />
                    </div>
                    <div class="icon-menu-focus"><img loading="lazy"
                        src="@/assets/images/common/signout-white.svg"
                        alt="signout"
                    />
                    </div>
                    <span>{{ getTranslatedText("sign out") }}</span>
                </div>
            </li>
        </ul>
        <Toast :title="toastData.message" :type="toastData.type" id="toast-navbar"/>
    </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";
import { listMenuNavigation } from "@/resources/listNavigation";
import { redirectPathApp } from "@/components/common/utils";
import ImageInput from "@/components/common/image-input";
import UserAuthenticate from "@/services/user.service";
import Toast from "@/components/common/toast/Toast.vue";

export default {
    mixins: [LanguageMixin, RouterMixin],
    components: { ImageInput, Toast },
    props: {
        userName: {
            type: String,
            default: JSON.parse(localStorage.getItem("user_info")) ? JSON.parse(localStorage.getItem("user_info")).name : ''
        },
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            listMenuNavigation,
            avatar: null,
            saved: false,
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            isOpenPopupOptionAvatar: false,
        };
    },
    watch: {
        avatar: {
            handler: function () {
                this.saved = false;
            },
            deep: true,
        },
    },
    methods: {
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("toast-navbar");
        },
        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },
        handleLogout() {
            this.$store.dispatch("handleLogout");
            this.routerPush("/login");
        },
        async reloadAvatar() {
            try {
                const res = await UserAuthenticate.getDataUser();
                if (res.avatar) {
                    this.$store.dispatch("onChangeSrcAvatar", res.avatar);
                }
            } catch (error) {
                console.log(error)
            }
        },
        stopCameraStream() {
            let tracks = this.$refs.camera?.srcObject.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        },

        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true,
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch((error) => {
                    this.isLoading = false;
                    alert("May the browser didn't support or there is some errors.");
                });
        },

        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },
        togglePopupOptionsAvatar(data) {
            this.isOpenPopupOptionAvatar = data;
        },
        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const context = this.$refs.canvas?.getContext("2d");
            context.drawImage(this.$refs.camera, 0, 0, 90, 90);
            let canvas = document.getElementById("photoTaken").toDataURL("image/png");
            canvas = canvas.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
            let formData = new FormData();
            const data = this.base64toBlob(canvas, "image/png");
            const start = Date.now();
            const avatarName = `${start}avatar.png`;
            const file = new File([data], avatarName, {
                lastModified: 1534584790000,
            });
            formData.append("file", file);
            this.stopCameraStream();
            UserAuthenticate.uploadAvatar(formData).then((res) => {
                if (res.status === 200) {
                    this.getUserData();
                    if (res.avatar) {
                        this.$store.dispatch("onChangeSrcAvatar", res.avatar);
                    }
                    this.showToast(this.getTranslatedText(res.message), "success");
                    this.isCameraOpen = false;
                    setTimeout(() => {
                        this.togglePopupOptionsAvatar(false);
                    }, 500);
                    // this.isOpenPopupOptionAvatar = false;
                } else {
                    this.showToast(this.getTranslatedText(res.message), "error");
                }
            });
        },
        base64toBlob(base64Data, contentType) {
            contentType = contentType || "";
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                const begin = sliceIndex * sliceSize;
                const end = Math.min(begin + sliceSize, bytesLength);

                const bytes = new Array(end - begin);
                for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, {type: contentType});
        },
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/nav-bar/_nav-bar.scss" />
