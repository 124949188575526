import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class BankService extends BaseService {
    getCurrencies() {
        return this.get(API_URL + "/api/currencies").then(
            ({ data }) => data
        )
    }
    checkRequiredIban(country_code) {
        return this.post(API_URL + '/api/common/required-iban', {country_code: country_code}).then((response) => {
            return response.data;
        });
    }
    checkValidateIban(iban) {
        return this.post(API_URL + '/api/user/validate-iban', {iban}).then((response) => {
            return response.data;
        });
    }
    getCurrencyByCountryCode(country_code) {
        return this.post(API_URL + '/api/user/get-currency-by-country-code', {country_code}).then((response) => {
            return response.data;
        });
    }
    checkValidateIfsc(ifsc) {
        return this.post(API_URL + '/api/user/validate-ifsc', {ifsc}).then((response) => {
            return response.data;
        });
    }
}

export default new BankService();
