<template>
    <div>
        <div v-if="isLoadingImg" class="avatar-loading">
            <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="openNav()" v-else>
            <slot name="activator"></slot>
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input
            type="file"
            ref="file"
            :name="uploadFieldName"
            @change="onFileChange($event.target.name, $event.target.files)"
            style="display: none"
        />
        <!-- error dialog displays any potential error messages -->
        <v-dialog v-model="errorDialog" max-width="300">
            <v-card>
                <v-card-text class="subheading">{{ errorText }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div
            id="idNavigationAvatar"
            class="navigation-avatar__navigation-menus"
        >
            <div
                v-click-outside="clickOutSide"
                class="navigation-avatar__navigation-menus__container"
            >
                <div
                    @click="closeNav"
                    class="navigation-avatar__navigation-menus__line"
                >
                    <img loading="lazy" src="@/assets/images/personal-detail/line-head.png" />
                </div>
                <div
                    class="navigation-avatar__navigation-menus__content__desktop"
                >
                    <div
                        v-for="(item, i) in menusAccountDesktop"
                        :key="i"
                        @click="handleClickAccountItem(item.value)"
                        class="navigation-avatar__navigation-menu__item"
                    >
                        <img loading="lazy"
                            :src="
                                require(`@/assets/images/personal-detail/${item.icon}`)
                            "
                        />
                        {{ item.title }}
                    </div>
                </div>
                <div class="navigation-avatar__navigation-menus__content">
                    <div
                        v-for="(item, i) in menusAccount"
                        :key="i"
                        @click="handleClickAccountItem(item.value)"
                        class="navigation-avatar__navigation-menu__item"
                    >
                        <img loading="lazy"
                            :src="
                                require(`@/assets/images/personal-detail/${item.icon}`)
                            "
                        />
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserAuthenticate from "@/services/user.service";
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    name: "image-input",
    data() {
        return {
            errorDialog: null,
            errorText: "",
            uploadFieldName: "file",
            maxSize: 1024,
            menusAccount: [
                {
                    title: this.getTranslatedText("Capture from Camera"),
                    icon: "camera.png",
                    value: 1,
                },
                {
                    title: this.getTranslatedText("Choose from Gallery"),
                    icon: "image.png",
                    value: 2,
                },
            ],
            menusAccountDesktop: [
                {
                    title: this.getTranslatedText("Choose from Gallery"),
                    icon: "image.png",
                    value: 2,
                },
            ],
            toastData: {
                message: "",
                type: "success",
            },
            isLoadingImg:false
        };
    },
    props: {
        // Use "value" to enable using v-model
        value: Object,
        isOpenPopup: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isOpenPopup(newVal, oldVal) {
            // watch it
            // console.log("Prop changed: ", newVal, " | was: ", oldVal);
            if (newVal === false) {
                this.closeNav();
            }
        },
    },
    methods: {
        openNav() {
            document.getElementById("idNavigationAvatar").style.height = "100%";
            setTimeout(() => {
                this.$emit("changeShowPopup", true);
            }, 500);
        },
        clickOutSide() {
            if (this.isOpenPopup) {
                this.closeNav();
            }
        },
        closeNav() {
            this.$emit("changeShowPopup", false);
            document.getElementById("idNavigationAvatar").style.height = "0%";
        },
        handleClickAccountItem(value) {
            this.$emit("changeShowPopup", false);
            document.getElementById("idNavigationAvatar").style.height = "0%";
            if (value === 2) {
                this.$refs.file.click();
            } else {
                this.$emit("CaptureFromCamera");
            }
        },
        onFileChange(fieldName, file) {
            const { maxSize } = this;
            let imageFile = file[0];
            if (file.length > 0) {
                let size = imageFile.size / maxSize / maxSize;
                if (!imageFile.type.match("image.*")) {
                    // check whether the upload is an image
                    this.errorDialog = true;
                    return this.$emit("showToast", {
                        message: this.getTranslatedText("Please choose an image file"),
                        type: "error",
                    });
                    // this.errorText = this.getTranslatedText(
                    //     "Please choose an image file"
                    // );
                } else if (size > 1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true;
                    return this.$emit("showToast", {
                        message: this.getTranslatedText("Your file is too big! Please select an image under 1MB"),
                        type: "error",
                    });
                    // this.errorText = this.getTranslatedText(
                    //     "Your file is too big! Please select an image under 1MB"
                    // );
                } else {
                    // Append file into FormData and turn file into image URL
                    this.isLoadingImg = true;
                    let formData = new FormData();
                    let imageURL = URL.createObjectURL(imageFile);
                    formData.append(fieldName, imageFile);
                    UserAuthenticate.uploadAvatar(formData).then((res) => {
                        if (res.status === 200) {
                            // Emit the FormData and image URL to the parent component
                            this.$emit("input", { formData, imageURL });
                            this.$emit("reloadAvatar");
                            this.$emit("showToast", {
                                message: this.getTranslatedText(res.message),
                                type: "success",
                            });
                        } else {
                            this.$emit("showToast", {
                                message: this.getTranslatedText(res.message),
                                type: "error",
                            });
                        }
                        this.isLoadingImg = false
                    });
                }
            }
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/image-input/image-input.scss"
/>
