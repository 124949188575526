const FACEBOOK_ID = process.env.VUE_APP_FACEBOOK_ID;
const FACEBOOK_VERSION = process.env.VUE_APP_FACEBOOK_VERSION;

export function initFacebookSdk() {
  return new Promise(resolve => {
      // wait for facebook sdk to initialize before starting the vue app
      window.fbAsyncInit = function () {
          const FB = window.FB;
          FB.init({
              appId: FACEBOOK_ID,
              cookie: true,
              xfbml: true,
              version: FACEBOOK_VERSION
          });
      };

      // load facebook sdk script
      (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) { return; }
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));    
  });
}
  

  