import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class SeoService extends BaseService {
    apiGetDataSeo(page_url) {
        return this.post(API_URL + "/api/seo", { page_url }).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new SeoService();
