export default {
    data() {
        return {
            totalPrice: 0,
            listBlock: [],
        };
    },
    methods: {
        updateBlock(listBlockLocal) {
            this.listBlock = listBlockLocal;
        },

        onChangeTotalPrice(number) {
            this.totalPrice = number;
        },
    },
};
