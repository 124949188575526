<template>
    <div class="main">
        <BannerCustom/>
        <div
            class="section-notifications"
            id="section-notifications">
            <NavBar/>
            <div class="contents">
                <div class="header">
                    <div class="inbox">
                        <img loading="lazy" src="@/assets/images/common/inbox.svg"/>
                        <span class="title">{{ getTranslatedText("My Inbox") }}</span>
                    </div>
                    <div>
                        <img loading="lazy" src="@/assets/images/common/logo.svg"/>
                    </div>
                </div>
                <template>
                    <ul class="section-notifications__list-notifications">
                        <li v-for="(item, index) in listNotifications"
                            :key="index"
                            class="section-notifications__item-notifications">
                            <div
                                class="section-notifications__item-notifications-title"
                            >
                                <p class="title">{{ getTranslatedText(item.title) }}</p>
                                <p class="section-notifications__item-notifications-description">
                                    {{ item.description }}
                                </p>
                            </div>
                            <p class="date">{{ renderDateNotication(item.date) }}</p>
                        </li>
                    </ul>
                </template>
                <vue-bottom-sheet max-height="90%" ref="bottomMenuList">
                    <BottomSheet :isHiddenBtn="true">
                        <template slot="body-sheet">
                            <ul class="bottom-sheet-list-navigation__menu-list">
                                <li
                                    v-for="(item, index) in listMenuNavigation"
                                    :key="index"
                                    v-show="item.isShow"
                                    class="bottom-sheet-list-navigation__menu-item"
                                >
                                    <div
                                        @click="handleClickRedirect(item)"
                                        class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                                    >
                                        <img loading="lazy"
                                            class="user-avatar"
                                            :src="
                                                require(`@/assets/images/navigation/${item.icon}`)
                                            "
                                            :alt="item.icon"
                                        />
                                        {{ getTranslatedText(item.title) }}
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </BottomSheet>
                </vue-bottom-sheet>
            </div>
        </div>
    </div>
</template>
<script>
import { listMenuNavigation } from "@/resources/listNavigation";
import {
    isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    removeTagHtml,
} from "@/components/common/utils";

import notificationsService from "@/services/notification.service";
import BottomSheet from "@/components/common/bottom-sheet";
import BannerCustom from "@/components/common/banner";
import NavBar from "@/components/common/nav-bar/NavBar";
import moment from "moment";

import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [languageMixin, RouterMixin],
    components: {
        BottomSheet,
        NavBar,
        BannerCustom
    },
    data() {
        return {
            indexEdit: -1,
            listMenuNavigation,
            listNotifications: [],
            isShowConsent: IS_SHOW_CONSENT,
        };
    },
    created() {
        console.log(this.$route.fullPath)
        this.readAllNotify()
        this.fetchData();
    },
    mounted(){
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("section-notifications")) {
                document.getElementById("section-notifications").style.marginBottom = "180px"
            }
        } else {
            document.getElementById("section-notifications").style.marginBottom = "0"
        }
    },
    // metaInfo() {
    //     return {
    //         title: 'Notifications',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Notifications'
    //             }
    //         ]
    //     };
    // },
    methods: {
        async readAllNotify() {
            const res = await notificationsService.readAllNotify()
            if (res.status === 200) {
                this.$store.dispatch("setUnreadNotify");
                this.fetchData();
            }
        },
        async fetchData() {
            try {
                const locale = localStorage.getItem("locale");
                const resp = await notificationsService.getList(locale);
                if (!isArrayEmpty(resp.data)) {
                    let isTagHtml = /<[^>]*>?/gm;
                    let listFavourite = resp.data.map((item) => {
                        let description = !isStrEmpty(item.description)
                            ? isTagHtml.test(item.description)
                                ? removeTagHtml(
                                      this.getTranslatedText(item.description)
                                  ).replace(/^(.{100}[^\s]*).*/, "$1")
                                : item.description
                            : "";

                        return {
                            id: item.id,
                            title: !isStrEmpty(item.title)
                                ? item.title.replace(/^(.{20}[^\s]*).*/, "$1")
                                : item.message.replace(
                                      /^(.{20}[^\s]*).*/,
                                      "$1"
                                  ),
                            description,
                            html: item.content,
                            date: item.updated_at,
                            notified: item.notified,
                        };
                    });

                    this.listNotifications = listFavourite;
                }
            } catch (error) {
                console.log(error);
            }
        },
        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },

        renderDateNotication(date) {
            let locale = this.$store.state.isRtl ? "ar" : "en";
            moment.locale(locale);
            const timestamp = moment(date).fromNow(true);
            return timestamp;
        },

        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/notification/_notification.scss"
/>
