import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class UserService extends BaseService {
    getDataUser() {
        return this.get(API_URL + "/api/user/my-detail").then((response) => {
            return response.data.data;
        });
    }
    updateProfile(data: any) {
        return this.post(API_URL + "/api/user/update-profile", {
            full_name: data.full_name,
            phone: data.phone,
            gender: data.gender,
            email: data.email,
            dob: data.dob,
            nationality: data.nationality,
            country_code: data.country_code,
            calling_code: data.calling_code.replace(/\+/g, ""),
            code: data.code_otp,
            otp_by: data.otp_by,
            address: data.address,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zipcode: data.zipcode,
            country_bank: data.country_bank,
            current_password: data.current_password,
            confirm_password: data.confirm_password
        }).then((response) => {
            return response.data;
        });
    }
    uploadAvatar(data) {
        return this.post(API_URL + "/api/user/avatar", data).then(
            (response) => {
                return response.data;
            }
        );
    }
    confirmEmail(data: any) {
        return this.post(API_URL + "/api/user/send-verification-email", data).then(
            (response) => {
                return response.data;
            }
        );
    }
    verifyEmail(tokenEmail: string, type: string) {
        return this.post(API_URL + `/api/common/verify-email`, {tokenEmail, type}).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new UserService();
