<template>
    <div class="section-set-password">
        <div class="section-set-password__body">
            <h1 class="title-custom">
                {{ getTranslatedText("New Password") }}
            </h1>
            <p class="sub-title">{{ getTranslatedText("Enter your new password") }}.</p>
            <FormCommon
                v-if="methodLogin === 1"
                :formItem="formItem"
                :dataForm="dataForm"
                @on-bur-input="onBurInputFormData"
                @update-form-data="updateFormData"
                @onSubmitForm="onSubmitForm"
                @onToggleShowPassword="onToggleShowPassword"
            >   
                <div slot="guide">
                    <div class="title-guide">{{ getTranslatedText('Password must contain') }}</div>
                    <div class="sub-guide">
                        {{ getTranslatedText('At Least 1 Letter') }}<br/>
                        {{ getTranslatedText('At Least 1 Number') }}<br/>
                        {{ getTranslatedText('At Least 1 Special Character') }}<br/>
                        {{ getTranslatedText('At Least 1 Uppercase Letter') }}<br/>
                        {{ getTranslatedText( "At least from [limit] to 30 characters long", {limit: limitPass}) }}<br/>
                    </div>
                </div>
                <div class="box-btn" slot="button">
                    <div class="section-set-password__btn">
                        <ButtonCustom
                            classBtn="section-set-password__btn-continue"
                            typeBtn="submit"
                            type="primary"
                            :isLoading="isLoadingButton"
                            text="confirm"
                        />
                    </div>
                </div>
            </FormCommon>
            <div v-if="methodLogin === 2">
                <p class="sent-email t-fir-transform-cap">
                    {{
                        getTranslatedText(
                            "we have sent password reset link and instruction to your email"
                        )
                    }}
                </p>
                <div class="email">
                    <span>{{ dataUser.email}}</span>
                    <button
                        class="btn-edit-email t-transform-cap"
                        @click="onClickGoBack"
                    >
                        {{ getTranslatedText("edit") }}.
                    </button>
                </div>

                <p class="email-description t-fir-transform-cap">
                    {{
                        getTranslatedText(
                            "we have sent password reset link and instruction to your email"
                        )
                    }}
                </p>
                <div class="btn-resend">
                    <button
                        class="t-transform-cap"
                        :disabled="isDisableResend"
                        @click.prevent="handleResendLink"
                    >
                        {{ getTranslatedText("resend link") }}
                    </button>
                    <span>in {{ renderNumber(timerCount) }} {{ getTranslatedText("min") }} </span>
                </div>
                <ButtonCustom
                    classBtn="button-method-login"
                    text="edit"
                    typeBtn="button"
                    type="primary"
                    @onClickButton="onClickGoBack"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { isStrEmpty, renderLocaleNumber } from "@/components/common/utils";

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";

import languageMixin from "@/mixins/language/language-mixin";

const LIMIT_PASSWORD = process.env.VUE_APP_LIMIT_PASSWORD;

export default {
    mixins: [languageMixin],
    components: {
        FormCommon,
        ButtonCustom,
    },
    props: {
        text: {
            type: String,
            default: "",
        },
        textBtnContinue: {
            type: String,
            default: "Continue",
        },
        isShowIconBtnContibue: {
            type: Boolean,
            default: true,
        },
        dataUser: {
            type: Object,
            default() {
                return {};
            },
        },
        isLogin: {
            type: Boolean,
            default: false,
        },
        methodLogin: {
            type: Number,
            default: 0,
        },
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            timerCount: 60,
            isDisableResend: true,
            limitPass: LIMIT_PASSWORD,
            dataForm: {
                password: "",
                confirmPassword: "",
                isCheckPassWord: [],
            },
            formItem: [
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "password",
                    required: true,
                    showIconRequired: true,
                    showIconEye: true,
                    label: "New Password",
                    for: "input-password",
                    id: "input-password",
                    state: null,
                    textInvalid: "",
                    textValid: "",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "confirmPassword",
                    required: true,
                    showIconRequired: true,
                    showIconEye: true,
                    label: "Confirm New Password",
                    for: "input-confirmPassword",
                    id: "input-confirmPassword",
                    state: null,
                    textInvalid: "",
                    textValid: "",
                },
                {
                    type: "slot",
                    nameSlot: "guide",
                },
            ],
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value === 0) {
                    this.isDisableResend = false;
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleResendLink() {
            this.timerCount = 60;
            this.$emit("submitResend");
        },
        updateFormData(item) {
            let { value, name } = item;
            let isContainsLowercase = /^(?=.*[a-z]).*$/;
            let isContainsNumber = /^(?=.*[0-9]).*$/;
            let isContainsSymbol =
                /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
            let isContainsUppercase = /^(?=.*[A-Z]).*$/;
            if (name === "password") {
                this.dataForm.password = value;
                if (
                    this.dataForm.password !== this.dataForm.confirmPassword &&
                    this.dataForm.confirmPassword.length > 0
                ) {
                    this.formItem[1].textInvalid = "Password not match";
                    this.formItem[1].state = false;
                    if (!isContainsLowercase.test(this.dataForm.password)) {
                        this.formItem[0].textInvalid = 'Password must contain At Least 1 Character';
                        this.formItem[0].state = false;
                        this.formItem[1].state = false;
                    } 
                    else if (!isContainsNumber.test(this.dataForm.password)) {
                        this.formItem[0].textInvalid = 'Password must contain at least 1 number';
                        this.formItem[0].state = false;
                        this.formItem[1].state = false;
                    }
                    else if (!isContainsSymbol.test(this.dataForm.password)) {
                        this.formItem[0].textInvalid = 'Password must contain At Least 1 Special Character';
                        this.formItem[0].state = false;
                        this.formItem[1].state = false;
                    }
                    else if (!isContainsUppercase.test(this.dataForm.password)) {
                        this.formItem[0].textInvalid = 'Password must contain At Least 1 Capital Character';
                        this.formItem[0].state = false;
                        this.formItem[1].state = false;
                    }
                    else if (value.length < LIMIT_PASSWORD || value.length >= 30) {
                        this.formItem[0].textInvalid = this.getTranslatedText('Password must contain At Least From [limit] To 30 Characters Long', { limit: LIMIT_PASSWORD});
                        this.formItem[0].state = false;
                        this.formItem[1].state = false;
                    } else {
                        this.formItem[0].textInvalid = '';
                        this.formItem[0].state = null;
                    }
                }
                // check at least 1 character
                else if (!isContainsLowercase.test(this.dataForm.password)) {
                    this.formItem[0].textInvalid = 'Password must contain At Least 1 Character';
                    this.formItem[0].state = false;
                    this.formItem[1].state = false;
                }  
                // check at least 1 number
                else if (!isContainsNumber.test(this.dataForm.password)) {
                    this.formItem[0].textInvalid = 'Password must contain at least 1 number';
                    this.formItem[0].state = false;
                    this.formItem[1].state = false;
                }
                // check At Least 1 Special Character
                else if (!isContainsSymbol.test(this.dataForm.password)) {
                    this.formItem[0].textInvalid = 'Password must contain At Least 1 Special Character';
                    this.formItem[0].state = false;
                    this.formItem[1].state = false;
                }
                // check At Least 1 Capital Character
                else if (!isContainsUppercase.test(this.dataForm.password)) {
                    this.formItem[0].textInvalid = 'Password must contain At Least 1 Capital Character';
                    this.formItem[0].state = false;
                    this.formItem[1].state = false;
                }
                // check limit password
                else if (value.length < LIMIT_PASSWORD || value.length >= 30) {
                    this.formItem[0].textInvalid = this.getTranslatedText('Password must contain At Least From [limit] To 30 Characters Long', { limit: LIMIT_PASSWORD});
                    this.formItem[0].state = false;
                    this.formItem[1].state = false;
                } else {
                    this.formItem[0].state = null;
                    this.formItem[1].state = null;
                    this.formItem[0].textInvalid = '';
                    this.formItem[1].textInvalid = '';
                }
            }
            if (name === "confirmPassword") {
                this.dataForm.confirmPassword = value;
                if (this.dataForm.password !== this.dataForm.confirmPassword) {
                this.formItem[1].textInvalid = "Password not match";
                this.formItem[1].state = false;
                } else {
                this.formItem[1].state = null;
                this.formItem[1].textInvalid = "";
                }
            }
            this.dataForm[name] = value;
        },

        onBurInputFormData(event) {
            let { value, name } = event.target;
            if (value.length > 3) {
                let item = {
                    value,
                    name,
                };
                this.updateFormData(item);
            }
        },

        onSubmitForm() {
            if (this.formItem[0].state !== null) {
               return false;
            }

            if (this.formItem[1].state !== null) {
                return false;
            }
            this.$emit("on-submit-password", this.dataForm);
        },

        renderNumber(number) {
            return renderLocaleNumber(parseInt(number))
        },

        onToggleShowPassword(name) {
            let index = this.formItem.findIndex(x => x.name === name)
            if(index !== -1 ) {
                this.formItem[index].typeShow = this.formItem[index].typeShow === 'password' ? 'text' : 'password'
            }
        }

    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_set-password.scss"
/>
