import BaseService from "@/services/base.service";
const API_URL = process.env.VUE_APP_API_URL;

class NetcoreService extends BaseService {
    purchase(payload) {
        return this.post(API_URL + `/api/net-core/add-activity`, payload).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new NetcoreService();
