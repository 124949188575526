<template>
    <div class="select-quantity">
        <b-button
            class="select-quantity__action-select-number-btn minus"
            :disabled="isDisabelButton"
            @click="() => onRemoveTickets(1, true)"
        >
            <img loading="lazy" src="@/assets/images/tickets/minus.svg" />
        </b-button>
        <b-form-input
            v-model="numberSelect"
            :disabled="isDisabelButton"
            @keydown.native="checkDigit"
            @blur="onBlurNumberTickets"
            class="select-quantity__action-select-number-input"
        />
        <b-button
            class="select-quantity__action-select-number-btn plus"
            :disabled="isDisabelButton"
            @click="() => onAddTickets(1, true)"
        >
            <img class="minus-img" loading="lazy" src="@/assets/images/tickets/plus.svg" />
        </b-button>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";
import { checkDigitCommon, idRandom, isArrayEmpty, isStrEmpty } from "@/components/common/utils";

import EventBus from '@/components/common/EventBus';

const VUE_APP_MAXIUM_BALL = process.env.VUE_APP_MAXIUM_BALL;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;

export default {
    mixins: [languageMixin],
    props: {
        isDisabelButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            numberSelect: 0,
            listBlock: [],
            listGames: [],
            isLoadingPage: false,
            totalPrice: 0,
            exchangeRate: EXCHANGE_RATE,
        };
    },
    watch: {
        totalPrice(newToalPrice, oldToalPrice) {
            if (newToalPrice !== oldToalPrice) {
                this.$emit("onChangeTotalPrice", newToalPrice);
            }
        },

        listBlock(listBlock) {
            this.$emit("updateBlock", listBlock);
        },
    },
    created() {
        this.getDataLocalStorage();
        EventBus.$on('onRemoveTicket', (value) => {
            this.listBlock = value
            if(this.numberSelect > 1) {
                    this.numberSelect--
            }
        })
        EventBus.$on('onAddTicketNumber', () => {
            this.onAddTickets(1, true) 
        })
    },
    destroyed() {
        EventBus.$off('onRemoveTicket', () => {})
        EventBus.$off('onAddTicketNumber')
    },
    methods: {
        getDataLocalStorage() {
            let list_block = !isStrEmpty(localStorage.getItem("list_block"))
                ? JSON.parse(localStorage.getItem("list_block"))
                : [{
                    id: idRandom(),
                    name: 'Item 1',
                    numbersChecked: [],
                    priceMore: 2.5,
                    date: [],
                    valueDate: "",
                    isError: false,
                    automatically: false,
                    doubles: [],
                }];

            let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];

            if (isArrayEmpty(list_block) && !isArrayEmpty(list_games)) {
                let date = !isArrayEmpty(list_games) ? [list_games[0].event_id] : [];
                let valueDate = !isArrayEmpty(list_games) ? list_games[0].draw_date : "";

                let item = {
                    id: idRandom(),
                    name: `Item ${this.listBlock.length + 1}`,
                    numbersChecked: [],
                    priceMore: 2.5,
                    date,
                    valueDate,
                    isError: false,
                    automatically: false,
                    doubles: [],
                };

                list_block.push(item);
            }
            this.numberSelect = list_block.length > 0 ? list_block.length : 1;
            this.listBlock = list_block;
            this.listGames = list_games;
            this.totalPrice = this.numberSelect * 2.5 * this.exchangeRate;
        },

        checkDigit(event) {
            return checkDigitCommon(event);
        },

        onBlurNumberTickets(event) {
            const { value } = event.target;
            if (isStrEmpty(value)) {
                this.numberSelect = this.listBlock.length.toString();
                return;
            }

            let numberSelect = 0;
            if (parseInt(value) > VUE_APP_MAXIUM_BALL) {
                numberSelect = VUE_APP_MAXIUM_BALL;
                this.numberSelect = VUE_APP_MAXIUM_BALL
            } else if (parseInt(value) < 1) {
                numberSelect = 1;
                this.numberSelect = 1
            } else {
                numberSelect = parseInt(value);
            }
            this.onAddRemoveMutitickets(numberSelect);
        },

        onAddRemoveMutitickets(numberSelect) {
            let quantity = numberSelect - parseInt(this.listBlock.length);
            if (quantity > 0) {
                this.onAddTickets(quantity);
            } else {
                this.onRemoveTickets(Math.abs(quantity));
            }
        },

        onAddTickets(quantity, isSelectNumber) {
            let numberSelect = isSelectNumber ? parseInt(this.numberSelect) : parseInt(this.numberSelect) - 1
            if (numberSelect < VUE_APP_MAXIUM_BALL) {
                let date = !isArrayEmpty(this.listGames)
                    ? [this.listGames[0].event_id]
                    : [];

                let valueDate = !isArrayEmpty(this.listGames)
                    ? this.listGames[0].draw_date
                    : "";

                let listBlockLocal = this.listBlock;

                for (let index = 0; index < quantity; index++) {
                    let item = {
                        id: idRandom(),
                        name: `Item ${this.listBlock.length + 1}`,
                        numbersChecked: [],
                        priceMore: 2.5,
                        date,
                        valueDate,
                        isError: false,
                        automatically: false,
                        doubles: [],
                    };
                    listBlockLocal.push(item);
                }
                if(isSelectNumber) this.numberSelect = parseInt(this.numberSelect) + quantity;
                this.listBlock = listBlockLocal;
                this.totalPrice += quantity * 2.5 * this.exchangeRate;
            }
        },

        onRemoveTickets(index, isSelectNumber) {
            let validate = isSelectNumber ? 1 : 0
            if (parseInt(this.numberSelect) > validate) {
                this.listBlock.splice(this.listBlock.length - index, index);
                if(isSelectNumber) this.numberSelect = parseInt(this.numberSelect) - index;
                this.totalPrice -= index * 2.5 * this.exchangeRate;
            }
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/select-quantity/select-quantity-register.scss"
/>
