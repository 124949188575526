import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

interface BalanceInterFace {
    amount: any;
    phone?: string;
    calling_code?: string;
    email?: string;
}

interface PuscharseInterFace {
    card_id: string;
    amount: string;
    card_security_code: string;
}

interface WithdrawalBankingInterFace {
    amount: number;
    account_first_name: string;
    account_middle_name: string;
    account_last_name: string;
    bank_name: string;
    branch_code: string;
    account_type: string;
    account_number: string;
    proof_docs: string;
    user_fica_docs_status_id: number;
    city: string;
    country: string;
    bank_account_currency: string;
    swift_code: number;
}

interface CheckLimitDepositFace {
    user_id: string;
    amount: string;
}

class WalletService extends BaseService {
    getWinning(page: string, perPage: string) {
        return this.post(
            API_URL + `/api/user/winning-bets?page=${page}&per_page=${perPage}`,
            ""
        ).then(({ data }) => data);
    }

    withdrawalBanking(param: WithdrawalBankingInterFace) {
        return this.post(API_URL + `/api/user/withdrawal-banking`, param).then(
            ({ data }) => data
        );
    }

    balanceTransfer(payload: BalanceInterFace) {
        return this.post(API_URL + `/api/user/balance-transfer`, payload).then(
            ({ data }) => data
        );
    }

    puscharseByCard(payload: PuscharseInterFace) {
        return this.post(API_URL + `/api/user/add-credit`, payload).then(
            ({ data }) => data
        );
    }

    checkLimitDeposit(param: CheckLimitDepositFace) {
        return this.post(
            API_URL + "/api/user/limit-deposit-per-week",
            param
        ).then((response) => {
            return response.data;
        });
    }
}

export default new WalletService();
