import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

interface listFilter {
    perPage: string;
    page: string;
    type: string;
    dateFrom: string;
    dateTo: string;
}

class MyTransactionsService extends BaseService {
    getList(param: listFilter) {
        return this.post(
            API_URL +
                `/api/user/my-transactions?per_page=${param.perPage}&page=${param.page}&type=${param.type}&date_from=${param.dateFrom}&date_to=${param.dateTo}`,
            ""
        ).then(({ data }) => data);
    }
}

export default new MyTransactionsService();
