import PaymentService from "@/services/payment.servive";

const NAME_PAYMENT = process.env.VUE_APP_NAME;
const ADDRESS_PAYMENT = process.env.VUE_APP_ADDRESS;

export default {
    data() {
        return {
            methodPayment: 1,
            namePayment: NAME_PAYMENT,
            addressPayment: ADDRESS_PAYMENT,
            isLoadingPayment: false,
            isLoadingPagePayment: true
        };
    },
    created() {
        if(window.location.hash.includes('hc-action-complete') || window.location.pathname.includes('completeCallback')) { 
            let methodPayment = window.location.pathname === '/checkout' ? 2 : 1
            localStorage.setItem("methodPayment", methodPayment);
            window.location.href = '/payment/successful'
        } else {
            this.isLoadingPagePayment = false
        }

        window.errorCallback = (e) => {
            this.$router.replace({'query': null});
            this.errorPayment('error');
            return false
        }

        window.cancelCallback = (e) => {
            this.$router.replace({'query': null});
            this.errorPayment('cancel');
            return false
        }
         
    },
    beforeDestroy () {
        delete window.completeCallback // cleanup
        delete window.errorCallback // cleanup
        delete window.cancelCallback // cleanup
    },

    methods: {
        async errorPayment(type) {
            try {
                if(type !== 'cancel') { 
                    localStorage.setItem("methodPayment", this.methodPayment);
                    this.$router.push('/payment/failure')
                    localStorage.removeItem('referencePayment')
                }
            } catch (error) {
                localStorage.removeItem('referencePayment')
                this.$router.push('/payment/failure')
            }
        },

        showPaymentPage() {
            Checkout.showPaymentPage()
        }

    },
};
