<script>
export default {
    // metaInfo() {
    //     return {
    //         title: 'OMillionaire Results: View Weekly Winning Green Certificate Raffle IDs and Grand Draw Numbers | Live Draw every Thursday at 8pm UAE time',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Buy a Green Certificate for only AED 25 to participate in OMillionaire draw and select your 7 lucky numbers and get a chance to win over AED 80 million.'
    //             }
    //         ]
    //     };
    // },
};
</script>
