import Vue from "vue";
import Vuex from "vuex";

import { listNumbersRandom } from "@/resources/listNumbersRandom";
import notificationsService from "@/services/notification.service";
import authService from "@/services/auth.service";
import gameService from "@/services/game.service";
import seoService from  "@/services/seo.service";

Vue.use(Vuex);

const tokenRedirect = new URLSearchParams(window.location.search).get("token");

export default new Vuex.Store({
    state: {
        isLoggedIn: tokenRedirect
            ? tokenRedirect
            : !!localStorage.getItem("access_token"),
        numbersRandom: [],
        listNumbersRandom,
        amoutCart: localStorage.getItem("total_items"),
        unreadNotify: 0,
        idEditCart: "",
        balance: "0",
        currency_code: "AED",
        isRtl: localStorage.getItem("locale") === "ar",
        is_app: window.location.hostname.includes("app"),
        srcAvatar: "",
        listGameLoto: [],
        isUpdateProfile: false,
        dataHead: {},
        isScreenMobile:false
    },
    mutations: {
        SET_LIST_GAME(state, data) {
            state.listGameLoto = data
        },
        SET_BALANCE(state, data) {
            state.balance = data.balance;
            state.currency_code = data.currency_code;
        },
        SET_UNREAD_NOTIFY(state, data) {
            state.unreadNotify = data;
        },
        SET_SRC_AVATAR(state, data) {
            state.srcAvatar = data;
        },
        loginUser(state) {
            state.isLoggedIn = true;
        },
        logoutUser(state) {
            state.isLoggedIn = false;
            state.isUpdateProfile = false
            state.amoutCart = '0';
            localStorage.removeItem("access_token");
            localStorage.removeItem("list_block_tmp");
            localStorage.removeItem("user_info");
            localStorage.removeItem("number_index");
            localStorage.removeItem("list_block");
            localStorage.removeItem("total_items");
            localStorage.removeItem("cardHolderName");
            localStorage.removeItem("balance_data");
            localStorage.removeItem("payment_vendor");
            localStorage.removeItem("type_sign_up");
            localStorage.removeItem("totalAmountPayment");
            localStorage.removeItem("gift_redeemed");
            localStorage.removeItem("email-confirmation");
            localStorage.removeItem("counter");
        },
        updatePickNumberTickets(state, payload) {
            state.numbersRandom = payload.numbersRandom;
            state.listNumbersRandom = payload.listNumbersRandom;
        },

        updateAmountCart(state, number) {
            state.amoutCart = number;
        },

        onChangeIdEditCart(state, id) {
            state.idEditCart = id;
        },

        updateHead(state, data) {
            state.dataHead = data;
        },
    },
    actions: {
        async getListGame({ commit }) {
            const respLotoGame = await gameService.getLottoGames("4");
            commit('SET_LIST_GAME', respLotoGame.data)
        },

        async setBalance({ commit, state }) {
            if(state.isLoggedIn) {
                try {
                    const resp = await authService.getBalance();
                    if(resp.status === 200) {
                        let item = {
                            balance: resp.data.total,
                            currency_code: resp.data.currency_code
                        }
                        commit("SET_BALANCE", item);
                        localStorage.setItem('balance_data', JSON.stringify(item))
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },

        setUnreadNotify({ commit }) {
            notificationsService.getUnreadNotify().then((res) => {
                if (res.status === 200) {
                    commit("SET_UNREAD_NOTIFY", res.data.total);
                }
            });
        },
        onChangeSrcAvatar({ commit, state }, src) {
            commit("SET_SRC_AVATAR", src);
        },
        handleLogout({ commit }) {
            commit("logoutUser");
        },
        handleLogin({ commit }) {
            commit("loginUser");
        },

        onChangeAnountCart({ commit, state }, number: number) {
            commit("updateAmountCart", number);
        },

        // on edit checkout cart
        onChangeIdEditCheckoutCart({ commit, state }, id: string) {
            commit("onChangeIdEditCart", id);
        },
        // tab normal pick
        pickNumberTickets({ commit, state }, payload: any) {
            console.log(payload, "payload");

            // commit("updatePickNumberTickets", payloadUpdate);
        },

        randomNumbers({ commit, state }, payload: any) {
            let numbersRandom: any[] = [...state.numbersRandom];
            const listNumbersRandom: any[] = [...state.listNumbersRandom];

            const numbers = Array.from(Array(44), (_, i) => i + 1);
            numbers.sort(() => Math.random() - 0.5);
            numbersRandom = numbers.slice(0, 6);
            listNumbersRandom.forEach((item, i) => {
                item.active = numbersRandom.includes(item.number);
            });

            const payloadUpdate: any = {
                numbersRandom,
                listNumbersRandom,
            };

            commit("updatePickNumberTickets", payloadUpdate);
        },

        async getDataSeo({commit}, data) {
            try {
              await seoService.apiGetDataSeo(data.page_url).then(res => {
                if (res.status === 200) {
                  commit("updateHead", res.data);
                  return res.data;
                }
              })
            } catch (e) {
              console.log(e);
            }
        },
    },
});
