import { render, staticRenderFns } from "./BrandAmbassador.vue?vue&type=template&id=30505f10&scoped=true&"
import script from "./BrandAmbassador.vue?vue&type=script&lang=js&"
export * from "./BrandAmbassador.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/mobile/static-pages/brand-ambassador.scss?vue&type=style&index=0&id=30505f10&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30505f10",
  null
  
)

export default component.exports