<template>
    <b-modal
        :id="id"
        modal-class="modal-fullscreen"
        style="max-width: 100% !important"
        centered
        header-class="modal-header"
        hide-footer
        hide-header
    >
        <template #default="">
            <b-container class="confirm-modal">
                <b-row>
                    <h3 class="confirm-modal__title t-transform-cap">
                        {{ title }}
                    </h3>
                </b-row>
                <b-row>
                    <h3 class="confirm-modal__description">
                        {{ description }}
                    </h3>
                </b-row>
                <b-row>
                    <div class="confirm-modal__btn">
                        <ButtonCustom
                            typeBtn="button"
                            type="default"
                            :text="textBtnCancel"
                            @onClickButton="$emit('onCancelModal')"
                        />
                        <ButtonCustom
                            typeBtn="button"
                            type="primary"
                            :isLoading="isLoadingButton"
                            :text="textBtnDelete"
                            @onClickButton="$emit('onDelete')"
                        />
                    </div>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import ButtonCustom from "@/components/common/button";

export default {
    components: { ButtonCustom },
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        textBtnDelete: {
            type: String,
            default: "",
        },
        textBtnCancel: {
            type: String,
            default: "",
        },
        showBtnCancel: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: "",
        },
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/confirm/_confirm.scss" />
