<template>
    <div class="live_watch">
        <div class="live_watch__header">
            <div class="live_watch__header--left">
                <h1 class="live_watch__title">{{ getTranslatedText('Live Watch') }}</h1>
                <div class="line"></div>
                <span class="watch">{{ getTranslatedText('Watch the Live Draw Straight from this Page') }}</span>
            </div>
            <div class="live_watch__header--right">
                <div class="live_watch__header--btn_view" @click="onShowVideo(dataLive, noLiveWatch)">
                    <img loading="lazy" width="136" height="136" src="@/assets/images/landing-page/view-live.webp" alt="" />
                    <div>
                        <div class="view">{{ getTranslatedText('view') }}</div>
                        <span class="live">{{ getTranslatedText('live draw') }}</span>
                    </div>
                </div>
                <div class="live_watch__header--participate">
                    <ButtonCustom
                        text="JOIN NOW"
                        typeBtn="primary"
                        type="primary"
                        classBtn="btn-participate"
                        @onClickButton="onClickByNow"
                    />
                </div>
            </div>
        </div>
        <div class="live_watch__draw">
            <div class="live_watch__draw--count-down">
                <div class="live_watch__draw--loading-video" v-if="isLoadingVideo !== false">
                    <b-spinner
                        variant="light"
                    ></b-spinner>
                </div>
                <div class="live_watch__draw--live-video" v-show="showVideo" >
                    <div id="youtube-player"></div>
                </div>
                <div class="live_watch__draw--count-down-wrap" v-show="!showVideo">
                    <h5 class="live_watch__draw--count-down-title">{{ getTranslatedText('Time Remaining') }}</h5>
                    <CountDownDate
                    @dataTimeCount="dataTimeCount"
                    />
                </div>

            </div>
            <div class="main-title">
                <div>
                    <div class="live_watch__draw--title" v-if="titlePastVideo">
                        <h5>{{ titlePastVideo }}</h5>
                    </div>
                    <div class="live_watch__draw--title" v-else>
                        <h5 class="t-transform-cap">{{ dataLive.video_title ? dataLive.video_title : getTranslatedText('O! Millionaire Live Draw') }}</h5>
                        <span class="sub-title">{{ dataLive.draw_date ? '' : formatDate(nextThursday) }}</span>
                    </div>
                </div>
                <div class="title-right">
                    {{ getTranslatedText('The O! Millionaire Grand Draw is Live every Thursday at 8:00 PM (GST),') }} <br/>
                    {{ getTranslatedText("We'll catch you there next time! Win your best life with O! Millionaire!") }}
                </div>
            </div>
        </div>
        <span class="mb-sub-title">{{ getTranslatedText("The O! Millionaire Grand Draw is Live every Thursday at 8 PM GST, We'll catch you there next time! Win your best life with O! Millionaire!") }}</span>
        <div class="live_watch__past">
            <h2 class="live_watch__title">{{ getTranslatedText('Past Draw Shows') }}</h2>
            <div class="live_watch__past--list_draws" v-if="dataPastDraws">
                <div class="live_watch__past--item"
                    v-for="(item, index) in dataPastDraws.data"
                    :key="index"
                    @click="onShowVideo(item)"
                >
                    <div class="live_watch__past--item-loading" v-if="index === isLoadingVideo">
                        <b-spinner
                            variant="secondary"
                        ></b-spinner>
                    </div>
                    <img :src="item.thumbnail_link" alt="">
                    <div>
                        <h6 class="h6">{{ item.title }}</h6>
                        <div class="date">{{ formatDate(item.published_at) }}</div>
                    </div>
                </div>
            </div>
            <div class="loading-past-draw" v-if="isLoadingPastDraw">
                <b-card>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                </b-card>
            </div>
            <ButtonCustom
                v-if="dataPastDraws?.last_page > 1"
                text="watch more"
                typeBtn="primary"
                type="primary"
                :classBtn="page === dataPastDraws.last_page ? 'btn-watch-more hiden' : 'btn-watch-more'"
                @onClickButton="onWatchMore"
                :isDisabled="isLoadingPastDraw"
            />
        </div>
        <Toast :title="toastData.message" :type="toastData.type"/>
    </div>
</template>

<script>
import languageMixin from "@/mixins/language/language-mixin";
import ButtonCustom from "@/components/common/button";
import CountDownDate from "@/components/common/count-down-date";
import RouterMixin from "@/mixins/router/router-mixin";
import {redirectPathApp} from "@/components/common/utils"
import LiveWatchService from "@/services/live-watch.service";
import Toast from "@/components/common/toast/Toast.vue";
import moment from 'moment';

const TARGET_TIME_LIVE = process.env.VUE_APP_TARGET_TIME_LIVE ? process.env.VUE_APP_TARGET_TIME_LIVE : 600000;
let youTubePlayerAPI, scriptTag;

export default {
    mixins: [languageMixin, RouterMixin],
    components:{
        ButtonCustom ,CountDownDate, Toast
    },
    data(){
        return{
            player: null,
            dataLive:false,
            dataPastDraws:"",
            firstDataPastDraws:"",
            showVideo:false,
            noLiveWatch:true,
            isLoadingVideo:false,
            isLoadingPastDraw:true,
            isLoadingMore:false,
            page:1,
            toastData: {
                message: "",
                type: "success",
            },
            hasRunLive:false,
            nextThursday:'',
            titlePastVideo:false,
        }
    },
    created(){
        this.addScriptCanonical()
        this.getDataLiveDraws(this.page);
        this.initYouTubePlayerAPI();
    },
    methods:{
        addScriptCanonical() {
            scriptTag = document.createElement("link");
            scriptTag.rel = "canonical";
            scriptTag.href =`https://omillionaire.com/live-watch`
            document.head.appendChild(scriptTag);
        },
        formatDate(value) {
            return moment(value).format('MMMM DD, YYYY');
        },

        initYouTubePlayerAPI(){
            youTubePlayerAPI = document.createElement('script');
            youTubePlayerAPI.src = 'https://www.youtube.com/iframe_api';
            document.head.appendChild(youTubePlayerAPI);
            youTubePlayerAPI.onload = () => {
                const createIframeYT = () => {
                    if(window.YT.Player){
                        this.onYouTubeIframeAPIReady();
                    }else{
                        setTimeout(createIframeYT, 100);
                    }
                }
                createIframeYT();
            }
        },
        async getDataLiveDraws(page, isWatchMore = false, callBackLive = false){
            try{
                const res = await LiveWatchService.getDrawVideo('youtube', 6, page);
                if(res.status === 200){
                    if(isWatchMore){
                        this.dataPastDraws.data = this.dataPastDraws.data.concat(res.data)
                    }else{
                        if(res.live_video ){
                            this.dataLive = res.live_video;
                            const showVideoIframe = ()=>{
                                if(typeof this.player?.loadVideoById === 'function'){
                                    this.noLiveWatch = false;
                                }else{
                                    setTimeout(showVideoIframe, 100)
                                }
                            }
                            showVideoIframe();
                        }
                        if(callBackLive) return;
                        this.dataPastDraws = res;
                        this.firstDataPastDraws = res.data;

                    }
                }else{
                    this.showToast(this.getTranslatedText(res.message), "error");
                }
                this.isLoadingPastDraw = false;
            }catch(error){
                console.log(error)
                this.showToast(error, "error");
                this.isLoadingPastDraw = false;
            }
        },
        onYouTubeIframeAPIReady() {
            this.player = new window.YT.Player('youtube-player', {
                videoId: '',
                playerVars: {
                    autoplay: 0,
                },
            });
        },
        onClickByNow(){
            if(this.$store.state.is_app){
                redirectPathApp('/buy/green-certificates');
            } else {
                this.routerPush('/buy/green-certificates');
            }
        },
        dataTimeCount(timeleft, nextThursday){
            // timeleft is milliseconds
            // check time left <= 10 minutes = 600000 milliseconds
            this.nextThursday = nextThursday
            if(timeleft <= Number(TARGET_TIME_LIVE)){
                if(!this.hasRunLive){
                    this.hasRunLive = true
                    const callDataLive = () => {
                        if(!this.dataLive){
                            this.getDataLiveDraws(this.page, false, true);
                            setTimeout(callDataLive, 60000)
                        }else{
                            this.noLiveWatch = false;
                        }
                    };
                    callDataLive();
                }
            }else if(this.dataLive && typeof this.player?.loadVideoById === 'function'){
                    this.noLiveWatch = false;
            }else{
                this.noLiveWatch = true;
            }
        },
        onShowVideo(data, liveVideo = false){
            if(liveVideo){
                this.showVideo = false;
                this.player.stopVideo();
                this.titlePastVideo = false;
                return;
            }
            if(typeof this.player?.loadVideoById === 'function'){
                this.showVideo = true;
                this.player.loadVideoById(data.video_id);
                this.player.playVideo();
                let scrollDiv = 0;
                this.titlePastVideo = data.title ? data.title : false
                window.scrollTo({ top: scrollDiv, behavior: 'smooth',});
            }
        },
        onWatchMore(){
            this.page++
            this.isLoadingPastDraw = true;
            if(this.page > this.dataPastDraws.last_page){
                this.page = 1;
                this.dataPastDraws.data = this.firstDataPastDraws;
                this.isLoadingPastDraw = false;
                return
            }
            this.getDataLiveDraws(this.page, true);
        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
    },
    watch:{
        noLiveWatch(){
            this.onShowVideo(this.dataLive, this.noLiveWatch);
        }
    },
    beforeDestroy(){
        if(youTubePlayerAPI && youTubePlayerAPI.parentNode){
            youTubePlayerAPI.parentNode.removeChild(youTubePlayerAPI);
        }
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    }
}
</script>
<style scoped lang="scss" src="@/assets/scss/mobile/live-watch/_live-watch.scss"/>
