<template>
    <b-modal
        :id="id"
        modal-class="modal-fullscreen"
        style="max-width: 100% !important"
        centered
        header-class="modal-header"
        hide-footer
        hide-header
        no-close-on-backdrop
        @show="resetModal"
        @hidden="resetModal"
    >
        <template #default="">
            <div class="amount-modal">
                <div class="close-btn" @click="resetModal"><img loading="lazy" src="@/assets/images/common/close.svg"/></div>
                <div class="header">
                    <span>{{ getTranslatedText(title) }}</span>
                </div>
                <slot/>
                <div class="footer">
                    <img class="cer" loading="lazy" src="@/assets/images/common/logo-cer.webp"/>
                    <img class="om" loading="lazy" src="@/assets/images/common/logo-om.webp"/>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import ButtonCustom from "@/components/common/button";
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    components: { ButtonCustom },
    props: {
        title: {
            type: String,
            default: "",
        },  
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {
        resetModal() {
            this.$bvModal.hide(this.id);
            this.$emit("resetModal");
        }
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/amount-popup/_amount-popup.scss" />
