<template>
    <div class="section-notify" :style="cssProps">
        <img loading="lazy"
            class="section-notify__img"
            src="@/assets/images/notify/earth.png"
        />
        <div class="section-notify__title">{{getTranslatedText("you are inspiring")}}!</div>
        <div class="section-notify__sub-title">
            {{getTranslatedText('Congratulations on your green certificates!')}}
        </div>
        <div class="section-notify__download t-fir-transform-cap">
            {{getTranslatedText('download your ')}} 2 {{getTranslatedText('green certificates to find your raffle ids')}}
        </div>
        <div class="section-notify__watch t-fir-transform-cap">
            {{getTranslatedText('watch our weekly draws every')}} <br />
        </div>
        <div class="section-notify__buy-more t-transform-cap">{{getTranslatedText('buy more')}}</div>
    </div>
</template>
<script>

import languageMixin from '@/mixins/language/language-mixin';

export default {
    mixins: [languageMixin],
    components: {},
    data() {
        return {
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/notify/background-notify.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
        };
    },
    methods: {},
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/notify/_notify.scss" />
