<template>
    <div :class="{ sectionConfirmOtp: step === 3 }" class="main">
        <div class="loading-full-page" v-if="isLoadingPage">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <div v-else class="section-personal">
            <BannerCustom/>
            <div class="section-personal__contents">
            <NavBar v-if="step !== 3 && !isScreenMobile" :userName="userName"/>
            <div v-if="step === 1" class="personal-detail">
                <div class="menu">
                    <p @click="personalDetail" :class="{ 'active': $route.fullPath === '/personal-detail' }">{{ getTranslatedText('my profile') }}</p>
                    <p @click="changePassword" :class="{ 'active': $route.fullPath === '/change-password' }">{{ getTranslatedText('Change Password') }}</p>
                    <p @click="deleteAccount" :class="{ 'active': $route.fullPath === '/delete-account' }">{{ getTranslatedText('Delete Account') }}</p>
                </div>
                <div class="menu-mobile" @click="onShowBottomMenuList">
                    <p>{{ getTranslatedText('my profile') }}</p>
                    <img loading="lazy" src="@/assets/images/navigation/chevron-down.svg"/>
                </div>
                <div class="upload-avatar">
                    <ImageInput
                        @reloadAvatar="reloadAvatar"
                        v-model="avatar"
                        @CaptureFromCamera="toggleCamera"
                        :is-open-popup="isOpenPopupOptionAvatar"
                        @changeShowPopup="togglePopupOptionsAvatar"
                        @showToast="showToast($event.message, $event.type)"
                    >
                        <div
                            slot="activator"
                            class="personal-detail__avatar__camera-open"
                        >
                            <div
                                class="personal-detail__avatar__circle"
                                v-if="!avatar && !isCameraOpen"
                            >
                                <v-avatar
                                    v-if="$store.state.srcAvatar !== ''"
                                    size="90px"
                                    v-ripple
                                    class="mb-2"
                                >
                                    <img loading="lazy" :src="$store.state.srcAvatar"/>
                                </v-avatar>
                                <img loading="lazy"
                                    class="avatar-icon"
                                    v-if="$store.state.srcAvatar === ''"
                                    src="@/assets/images/personal-detail/avatar.png"
                                />
                            </div>
                            <v-avatar size="90px" v-ripple v-else class="mb-2">
                                <img loading="lazy"
                                    v-if="!isCameraOpen"
                                    :src="avatar.imageURL"
                                    alt="avatar"
                                />
                                <div
                                    v-if="isCameraOpen"
                                    v-show="!isLoading"
                                    :class="{ flash: isShotPhoto }"
                                >
                                    <div
                                        class="camera-shutter"
                                        :class="{ flash: isShotPhoto }"
                                    ></div>

                                    <video
                                        v-show="!isPhotoTaken"
                                        ref="camera"
                                        :width="155"
                                        :height="155"
                                        autoplay
                                    ></video>

                                    <canvas
                                        v-show="isPhotoTaken"
                                        id="photoTaken"
                                        ref="canvas"
                                        :width="90"
                                        :height="90"
                                    ></canvas>
                                </div>
                            </v-avatar>
                            <div
                                v-show="isCameraOpen && isLoading"
                                class="camera-loading"
                            >
                                <ul class="loader-circle">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                            <div v-if="isCameraOpen && !isLoading" class="button-camera">
                                <button
                                    type="button"
                                    class="button-camera__save t-transform-cap"
                                    @click="takePhoto"
                                >
                                    {{ getTranslatedText("save") }}
                                </button>
                                <button
                                    type="button"
                                    class="button-camera__cancel t-transform-cap"
                                    @click="toggleCamera"
                                >
                                    {{ getTranslatedText("cancel") }}
                                </button>
                            </div>
                            <div class="personal-detail__avatar__edit">
                                <img loading="lazy"
                                    src="@/assets/images/common/edit-avatar.svg"
                                />
                            </div>
                        </div>
                    </ImageInput>
                    <p class="name">{{userName}}</p>
                    <p class="email">{{userEmail}}</p>
                </div>
                <div id="personal-detail">
                    <div class="personal-detail__title">
                        <p class="main-title t-transform-cap">{{ getTranslatedText("Add Your Personal Details") }}</p>
                        <p class="sub">{{ getTranslatedText("Enter your full name and date of birth that appears on your passport or valid ID’s.") }}</p>
                    </div>
                    <hr class="line"/>
                    <div class="personal-detail__container">
                        <FormCommon
                            :formItem="formItem"
                            :dataForm="dataForm"
                            @on-bur-input="onBurInputFormData"
                            @update-form-data="updateFormData"
                            @onSubmitForm="checkSubmitForm"
                        >
                            <div slot="desMethosRes" class="des-method-register">
                                <p class="main-title t-transform-cap">{{ getTranslatedText("Home Address") }}</p>
                                <p class="sub">{{ getTranslatedText("Please enter your valid address.") }}</p>
                                <hr class="line"/>
                            </div>
                            <b-input-group-prepend
                                slot="propend-phone-number"
                                class="phone-code"
                            >
                                <button
                                    class="show-dropdown-personal-detail"
                                    type="button"
                                    @click="showDropDow"
                                >
                                <div class="flag-group">
                                    <img loading="lazy"
                                        class="user-avatar"
                                        :src="require(`@/assets/images/flags/${dataForm.flag}`)"
                                        :alt="dataForm.flag"
                                    />
                                </div>
                                    {{ dataForm.calling_code }}
                                </button>
                                <b-dropdown ref="select_phone_code">
                                    <b-form-input
                                        v-model="textSearchPhoneNumber"
                                        :placeholder="getTranslatedText('search')"
                                        @input="onChangeFilterPhoneNumbers"
                                    ></b-form-input>
                                    <b-dropdown-item
                                        @click="selectPhoneCode(item)"
                                        v-for="(item, index) in phoneNumbersFilter"
                                        :key="index"
                                    >
                                        <div>
                                            <div class="flag-group">
                                                <img loading="lazy"
                                                    class="user-avatar"
                                                    :src="require(`@/assets/images/flags/${item.flag}`)"
                                                    :alt="item.flag"
                                                />
                                            </div>
                                            <span class="name-country">{{ item.name }}</span>
                                            ({{ item.number }})
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <img loading="lazy"
                                    class="dropdown-phone-icon"
                                    src="@/assets/images/personal-detail/dropdown-black.png"
                                />
                                <p class="sub-title">{{ getTranslatedText("(Used for Log in)") }}</p>
                            </b-input-group-prepend>
                            <template slot="birthDate">
                                <DateDropdown
                                    :default="dataForm.dob"
                                    label="date of Birth"
                                    :setDefault="false"
                                    min="1920"
                                    :max="maxYearBirth"
                                    :isHideLabelItem="true"
                                    @input="updateDate"
                                    :textInvalidFeedback="textInvalidFeedbackDate"
                                />
                            </template>
                            <div slot="addressSlot" class="des-method-register"></div>
                            <div slot="addressSlot1" class="des-method-register"></div>
                            <div v-if="showBtnVerify" class="personal-detail__box-btn-verify" slot="buttonVerify">
                                <ButtonCustom
                                    classBtn="personal-detail-btn-save"
                                    typeBtn="button"
                                    type="primary"
                                    text="verify"
                                    @onClickButton="checkSubmitForm('email')"
                                />
                            </div>
                            <div class="personal-detail__box-btn" slot="button">
                                <ButtonCustom
                                    :isLoading="isDisabledBtnSave"
                                    classBtn="personal-detail-btn-save"
                                    typeBtn="submit"
                                    type="primary"
                                    text="save changes"
                                />
                            </div>
                        </FormCommon>
                    </div>
                </div>
            </div>
            <div class="personal-detail" v-if="step === 2">
                <div class="menu">
                    <p @click="personalDetail" :class="{ 'active': $route.fullPath === '/personal-detail' }">{{ getTranslatedText('my profile') }}</p>
                    <p @click="changePassword" :class="{ 'active': $route.fullPath === '/change-password' }">{{ getTranslatedText('Change Password') }}</p>
                    <p @click="deleteAccount" :class="{ 'active': $route.fullPath === '/delete-account' }">{{ getTranslatedText('Delete Account') }}</p>
                </div>
                <div class="menu-mobile" @click="onShowBottomMenuList">
                    <p>{{ getTranslatedText('my profile') }}</p>
                    <img loading="lazy" src="@/assets/images/navigation/chevron-down.svg"/>
                </div>
                <div class="confirm-pass">
                <ConfirmPassword
                    :dataSource="dataForm"
                    :methodsChangeInfo="dataForm.otp_by"
                    @onClickGoBack="onClickGoBack"
                    @onSubmitForm="onSubmitConfirmPass"
                    :isLoadingButton="isLoadingButtonPass"
                />
                </div>
            </div>
            </div>
            <div v-if="step !== 3" class="line-bottom"></div>
            <p v-if="step !== 3" class="guide" :class="{'guide-confirm' : step === 2}">
            <span v-if="!isScreenMobile"></span>{{ getTranslatedText("fields marked with") }}</p>
            <div class="section-confirm-otp section-confirm-otp-profile">
                <ConfirmOTP
                    v-if="step === 3"
                    :dataSource="dataForm"
                    :isLoadingButton="isLoadingButtonOTP"
                    :textBtnContinue="getTranslatedText('done')"
                    @onClickGoBack="onClickGoBack"
                    :methodsChangeInfo="dataForm.otp_by"
                    @onSubmitForm="onSubmitConfirmOTP"
                    @sendOTP="onSubmitForm('resend')"
                    :text="getTranslatedText('enter otp')"
                    :isLoadingButtonRecaptcha="isLoadingBtn"
                />
            </div>
        </div>

        <vue-bottom-sheet max-height="90%" ref="bottomMenuList" max-width="100%">
            <BottomSheet :isHiddenBtn="true">
                <template slot="body-sheet">
                    <ul class="bottom-sheet-list-navigation__menu-list">
                        <li
                            v-for="(item, index) in listMenuNavigationAccount"
                            :key="index"
                            v-show="item.isShow"
                            class="bottom-sheet-list-navigation__menu-item"
                        >
                            <div
                                @click="handleClickRedirect(item)"
                                class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                            >
                                <img loading="lazy"
                                    class="user-avatar"
                                    :src="require(`@/assets/images/navigation/${item.icon}`)"
                                    :alt="item.icon"
                                />
                                {{ getTranslatedText(item.title) }}
                            </div>
                        </li>
                    </ul>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
        <Toast :title="toastData.message" :type="toastData.type" :hasVariableTrans="toastData.hasVariableTrans"/>
        <ConfirmRecaptcha
            id="modalRecaptchaProfile"
            idRecaptcha="recaptcha-profile"
            :sitekey="sitekey"
            :isLoadingButton="isLoadingBtn"
            @onSubmitRecaptcha="sendOTPForPhone('resend')"
        />
    </div>
</template>
<script>
import {listMenuNavigation} from "@/resources/listNavigation";
import { listMenuNavigationAccount } from "@/resources/listNavigationAccount";
import {phoneNumbers} from "@/resources/phoneNumber";
import {
    filterFullTextSearch,
    isStrEmpty,
    redirectPathApp,
    validateEmail,
    isArrayEmpty
} from "@/components/common/utils";
import {setUserInfo, getUserInfo} from "@/services/axios";

import LanguageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";

import authService from "@/services/auth.service";
import UserService from "@/services/user.service";
import AuthService from "@/services/auth.service";
import UserAuthenticate from "@/services/user.service";
import countriesService from "@/services/countries.service";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import ImageInput from "@/components/common/image-input";
import NavBar from "@/components/common/nav-bar/NavBar";
import BottomSheet from "@/components/common/bottom-sheet";
import ConfirmOTP from "@/views/mobile/authenticate/ConfirmOTP";
import ConfirmPassword from "@/views/mobile/authenticate/ConfirmPassword";
import Toast from "@/components/common/toast/Toast.vue";
import DateDropdown from "@/components/common/date-dropdown/DateDropdown.vue";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";

import moment from "moment";
import {isPossiblePhoneNumber, isValidPhoneNumber} from "libphonenumber-js";
import parseMax from "libphonenumber-js/max";
import BannerCustom from "@/components/common/banner";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;
const RECAPTCHA_TYPE = process.env.VUE_APP_RECAPTCHA_TYPE;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

let scriptTag, scriptTag1
export default {
    mixins: [LanguageMixin, RouterMixin],
    components: {
        FormCommon,
        ButtonCustom,
        ImageInput,
        ConfirmOTP,
        ConfirmPassword,
        BottomSheet,
        Toast,
        DateDropdown,
        ConfirmRecaptcha,
        NavBar,
        BannerCustom
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
                hasVariableTrans: true
            },
            sitekey: SITEKEY,
            recaptchaType: RECAPTCHA_TYPE,
            messageRecaptcha: '',
            isOpenPopupOptionAvatar: false,
            listMenuNavigation,
            listMenuNavigationAccount,
            isDisabledBtnSave: false,
            isChangedPhone: false,
            step: 1,
            isLoadingButtonOTP: false,
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            isLoadingPage: true,
            isLoadingBtn: false,
            link: "#",
            maxYearBirth: "0",
            maxDate: "",
            textInvalidFeedbackDate: "",
            userDataLayer: null,
            userId: null,
            userName: null,
            userEmail: null,
            isShowConsent: IS_SHOW_CONSENT,
            dataForm: {
                full_name: "",
                gender: "",
                calling_code: "",
                code: "",
                flag: "",
                phone: "",
                email: "",
                nationality: "",
                country_code: "",
                dob: "",
                otp_by: "",
                code_otp: "",
                nameCountry: "",
                address: "",
                address2: "",
                city: "",
                state: "",
                zipcode: "",
                country_bank: "",
                phoneCallingCode: "",
                current_password: "",
                confirm_password: ""
            },
            formItem: [
                {
                    type: "input-group",
                    typeInput: "number",
                    placeholder: "",
                    name: "phone",
                    label: `mobile number`,
                    for: "input-phone",
                    id: "input-phone-personal",
                    prepend: "code",
                    nameSlotInputPrepend: "propend-phone-number",
                    inputPhoneCountry: true,
                    state: null,
                    textInvalid: "Please enter phone",
                    required: true,
                },
                {
                    type: "input",
                    typeInput: "email",
                    placeholder: "someone@example.com",
                    name: "email",
                    showIconRequired: false,
                    label: `email`,
                    for: "input-email",
                    id: "input-email",
                    state: null,
                    textInvalid: "Please enter email",
                    hasVerify: true
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "first | middle (optional) | last",
                    name: "full_name",
                    required: true,
                    label: `full name`,
                    for: "input-full-name",
                    id: "input-full-name",
                },
                {
                    type: "select",
                    label: `country of residence`,
                    name: "country_code",
                    required: true,
                    for: "input-country-personal-detail",
                    id: "input-country-personal-detail",
                    idGroup: "input-group-country",
                    options: [],
                },
                {
                    type: "slot",
                    nameSlot: "birthDate",
                },
                {
                    type: "select",
                    name: "gender",
                    required: false,
                    label: `gender`,
                    id: "input-gender",
                    options: [
                        {text: this.getTranslatedText("male"), value: "male"},
                        {
                            text: this.getTranslatedText("female"),
                            value: "female",
                        },
                        {
                            text: this.getTranslatedText("not specify"),
                            value: "not-specify",
                        },
                    ],
                },
                {
                    type: "select",
                    label: `nationality`,
                    name: "nationality",
                    required: true,
                    for: "input-nationality-personal-detail",
                    id: "input-nationality-personal-detail",
                    idGroup: "input-group-nationality",
                    options: [],
                },
                {
                    type: "slot",
                    nameSlot: "addressSlot",
                },
                {
                    type: "slot",
                    nameSlot: "desMethosRes",
                },
                {
                    type: "slot",
                    nameSlot: "addressSlot1",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Address Line 1",
                    name: "address",
                    label: `Address Line 1`,
                    for: "input-line-1",
                    id: "input-line-1",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Address Line 2",
                    name: "address2",
                    label: `Address Line 2`,
                    for: "input-line-2",
                    id: "input-line-2",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Town/City",
                    name: "city",
                    label: `Town/City`,
                    for: "input-city-town",
                    id: "input-city-town",
                    className: "oooo"
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "State/Province",
                    name: "state",
                    label: `State/Province`,
                    for: "input-state",
                    id: "input-state",
                },
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "ZIP/Postal Code",
                    name: "zipcode",
                    label: `ZIP/Postal Code`,
                    for: "input-zip",
                    id: "input-zip",
                },
                {
                    type: "select",
                    label: `Country`,
                    name: "country_bank",
                    for: "input-country-bank",
                    id: "input-country-bank",
                    idGroup: "input-group-country-bank",
                    options: [],
                },
            ],
            phoneNumbersFilter: phoneNumbers,
            textSearchPhoneNumber: "",
            menusAccount: [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    path: "/personal-detail",
                },
                {title: "My Numbers", icon: "my-numbers.png", path: ""},
                {title: "Manage Cards", icon: "card.png", path: ""},
                {title: "My Wallet", icon: "wallet.png", path: ""},
                {title: "Notifications", icon: "notification.png", path: ""},
                {title: "Recurring Games", icon: "recurring.png", path: ""},
            ],
            avatar: null,
            saving: false,
            saved: false,
            isOpenPopup: false,
            maxDateForm: moment().format("YYYY"),
            isLoadingButtonPass: false,
            isCheckMail: false,
            isCheckPhone: false,
            isNotConfirmPass: false,
            showBtnVerify: false,
            screenWidth: window.innerWidth,
            isScreenMobile: false,
        };
    },
    watch: {
        screenWidth(value) {
            if (value <= 1279) {
                this.isScreenMobile = true
            }
            else {
                this.isScreenMobile = false
            }
        },
        avatar: {
            handler: function () {
                this.saved = false;
            },
            deep: true,
        },
    },
    mounted() {
        this.getUserData();
    },
    created() {
        if (this.screenWidth <= 1279) {
                this.isScreenMobile = true
            }
        else {
            this.isScreenMobile = false
        }
        window.addEventListener('resize', this.handleResize)
        this.fetchRecaptcha()
        this.$store.dispatch("getDataSeo", {page_url: "/personal-detail"});
        this.fetchDateCountries();
        this.fetDateAgo();
    },
    beforeDestroy() {
      let recap = document.querySelector(".grecaptcha-badge")
      if(recap && recap.parentNode) {
        recap.parentNode.removeChild(recap)
      }
    },
    updated() {
        if(!this.isLoadingPage) {
            let elm1 = document.getElementById("personal-detail")
            if (elm1) {
                if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
                    elm1.style.marginBottom = "200px"
                } else {
                    elm1.style.marginBottom = "0"
                }
            }
        }
    },
    // metaInfo() {
    //     const data = this.$store.state.dataHead;
    //     return {
    //         title: data.title,
    //         meta: [
    //             {
    //                 vmid: "keywords",
    //                 name: "keywords",
    //                 content: data.keywords,
    //             },
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: data.description,
    //             },
    //         ],
    //     };
    // },
    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        fetchRecaptcha() {
            if(this.recaptchaType !== 'v2') {
                const scriptTag2 = document.createElement("script");
                scriptTag2.src =`https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`
                document.head.appendChild(scriptTag2);
            }
        },
        reloadAvatar() {
            this.getUserData();
        },
        fetDateAgo() {
            const nowYear = moment().format("YYYY");
            const nowMonthDate = moment().format("MM-DD");
            let yearAgo = `${parseInt(nowYear) - 18}-${nowMonthDate}`;
            this.maxDate = yearAgo;
            this.maxYearBirth = `${parseInt(nowYear) - 18}`;
        },

        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        togglePopupOptionsAvatar(data) {
            this.isOpenPopupOptionAvatar = data;
        },
        async sendOTP(isShowNoti) {
            let {email, code} = this.dataForm;

            let phone = this.dataForm.phone;
            let calling_code = `${code.replace(/\+/g, "")}`;

            const urlApi = !isStrEmpty(email)
                ? AuthService.sendOTPToEmail("update_profile", email)
                : AuthService.sendOTPToPhone("update_profile", phone, calling_code);

            try {
                const resp = await urlApi;

                if (resp.status === 200 && isShowNoti) {
                    this.showToast(this.getTranslatedText(resp.message), "success");
                }
            } catch (err) {
                console.log(err, "err");
            }
        },
        async sendOTPForPhone(type) {
            this.isLoadingBtn = true
            let token
            if(this.recaptchaType === 'v2') {
                token = grecaptcha.enterprise.getResponse();
            } else {
                token = await grecaptcha.enterprise.execute(this.sitekey, {action: 'profile_update'})
            }
            if(!token) {
                this.showToast(this.getTranslatedText("recaptcha is required"), "error");
                this.isLoadingBtn = false
                return false
            }     
            if (this.dataForm.otp_by === "phone") {
                AuthService.sendOTPToPhone(
                    "update_profile",
                    this.dataForm.phone,
                    this.dataForm.calling_code.replace(/\+/g, ""),
                    "",
                    token,
                    "profile_update",
                    false,
                    this.userId
                ).then((res) => {
                    if (res.status === 200) {
                        this.isLoadingBtn = false
                        this.step = 3;
                        this.$bvModal.hide("modalRecaptchaProfile");
                        this.showToast(this.getTranslatedText(res.message), "success");
                    } else {
                        this.isLoadingBtn = false
                        this.$bvModal.hide("modalRecaptchaProfile");
                        let message = "";
                        !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                        }) : (message = res.message);
                        if(res.data && !res.data.can_update_profile) {
                            this.showToast(this.getTranslatedText(
                                "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                                {count: res.data.max_number_limit_time, 
                                    time: res.data.update_profile_time_limit, 
                                    date: res.data.next_update_date}), "error");
                        } else {
                            this.showToast(this.getTranslatedText(message), "error");
                        }
                    }
                });
            }
            if (this.dataForm.otp_by === "email" && type === 'resend') {
                AuthService.sendOTPToEmail("update_profile", this.dataForm.email, true, token, "profile_update", this.userId).then(
                    (res) => {
                        if (res.status === 200) {
                            this.step = 3;
                            this.isLoadingBtn = false
                            this.$bvModal.hide("modalRecaptchaProfile");
                            this.showToast(this.getTranslatedText(res.message), "success");
                        } else {
                                this.isLoadingBtn = false
                                this.$bvModal.hide("modalRecaptchaProfile");
                                let message = "";
                                !isArrayEmpty(res.errors)
                                ? res.errors.forEach((element) => {
                                    message += element.message;
                                }) : (message = res.message);
                                if(res.data && !res.data.can_update_profile) {
                                    this.showToast(this.getTranslatedText(
                                        "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                                        {count: res.data.max_number_limit_time, 
                                            time: res.data.update_profile_time_limit, 
                                            date: res.data.next_update_date}), "error");
                                } else {
                                    this.showToast(this.getTranslatedText(message), "error");
                                }
                            }
                    }
                );
            }
        },
        async onSubmitConfirmEmail() {
             try {
                const data = {
                    email: this.dataForm.email,
                    type: "update_email",
                    user_id: this.userId
                }
                const res = await UserService.confirmEmail(data)
                if(res.status !== 200) {
                    let message = "";
                    !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                        })
                        : (message = res.message);
                    if(res.data && !res.data.can_update_profile) {
                        this.showToast(this.getTranslatedText(
                        "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                        {count: res.data.max_number_limit_time, 
                            time: res.data.update_profile_time_limit, 
                            date: res.data.next_update_date}), "error");
                    } else {
                            this.showToast(message, "error");
                    }
                } else {
                    localStorage.setItem('email-confirmation', res.data.email)
                    this.$router.push('/email-confirmation');
                    window.performance.navigation.TYPE_RELOADED = true
                }
            } catch (error) {
                console.log(error)
            }
        },
        async onSubmitConfirmPass(data) {
            if(data.password !== data.confirm_password) {
                return false
            }
            try {
                this.isLoadingButtonPass = true
                const resp = await AuthService.verifyAccount(this.userId, data.password, data.confirm_password);
                if(resp.status === 200) {
                    this.dataForm.current_password = data.password
                    this.dataForm.confirm_password = data.confirm_password
                    if(this.isCheckMail && this.dataForm.otp_by === 'email') {
                        this.onSubmitConfirmEmail()
                    } else {
                        this.onSubmitForm()
                    }
                    this.isLoadingButtonPass = false
                } else {
                    let message = "";
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message;
                        })
                        : (message = resp.message);

                    this.showToast(message, "error");
                    this.isLoadingButtonPass = false
                }
            } catch (error) {
                console.log(error)
                this.isLoadingButtonPass = false
            }
        },
        async onSubmitConfirmOTP(dataOTP) {
            const {code} = dataOTP;
            this.dataForm.code_otp = dataOTP.code;
            const {email, phone} = this.dataForm;
            this.isLoadingButtonOTP = true;
            const urlApi =
                this.dataForm.otp_by === "email"
                    ? AuthService.apiCheckOTPByEmail(code, "update_profile", email)
                    : AuthService.apiCheckOTPByPhone(
                    code,
                    "update_profile",
                    phone,
                    this.dataForm.calling_code.replace(/\+/g, "")
                    );

            try {
                const resp = await urlApi;
                if (resp.status === 200) {
                    this.dataForm.dob = this.dataForm.dob && this.dataForm.dob !== 'Invalid date' ? moment(this.dataForm.dob).format("MM/DD/YYYY") : '';
                    UserService.updateProfile(this.dataForm).then((res) => {
                        if (res.status === 200) {
                            this.step = 1;
                            this.renderProfileInfor('complete', this.dataForm)
                            this.getUserData();
                            this.dataForm.otp_by = "";
                            this.showToast(this.getTranslatedText(res.message), "success");
                        } else {
                            let message = ''
                            this.renderProfileInfor('incomplete', this.userDataLayer)
                             !isArrayEmpty(res.errors)
                                        ? res.errors.forEach((element) => {
                                            message += element.message;
                                })
                                : (message = res.message);
                            if(resp.data && !resp.data.can_update_profile) {
                                this.showToast(this.getTranslatedText(
                                    "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                                    {count: resp.data.max_number_limit_time, 
                                     time: resp.data.update_profile_time_limit, 
                                     date: resp.data.next_update_date}), "error");
                            } else {
                                this.showToast(this.getTranslatedText(message), "error");
                            }
                        }
                    });
                } else {
                    let message = "";
                    !isArrayEmpty(resp.errors)
                        ? resp.errors.forEach((element) => {
                            message += element.message;
                        })
                        : (message = resp.message);

                    this.showToast(message, "error");
                }
                this.isLoadingButtonOTP = false;
            } catch (err) {
                console.log(err, "err");
            }
        },

        renderProfileInfor(type, data) {
            let phone = data.calling_code + data.phone
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'my_profile_update',
                    'method': '${type}',
                    'mobile': ${phone || '"N/A"'},
                    'calling_code': ${data.calling_code || '"N/A"'},
                    'email': '${data.email || "N/A"}',
                    'full_name': '${data.full_name || data.name || "N/A"}',
                    'date_of_birth': '${moment(data.dob).format('YYYY-MM-DD') || "N/A"}',
                    'country_code': '${data.country_code || "N/A"}',
                    'nationality':'${data.nationality || "N/A"}',
                    'gender': '${data.gender || "N/A"}',
                    'address': '${data.address || "N/A"}',
                    'town_city': '${data.city || "N/A"}',
                    'state': '${data.state || "N/A"}',
                    'zip_postalcode': '${data.zipcode || "N/A"}',
                    'country': '${data.country_bank || "N/A"}'
                });
            `;
            document.head.appendChild(scriptTag);
            scriptTag1 = document.createElement('script');
            scriptTag1.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'mobile': ${phone || '"N/A"'},
                    'calling_code': ${data.calling_code || '"N/A"'},
                    'email': '${data.email || "N/A"}',
                    'full_name': '${data.full_name || data.name || "N/A"}',
                    'date_of_birth': '${moment(data.dob).format('YYYY-MM-DD') || "N/A"}',
                    'country_code': '${data.country_code || "N/A"}',
                    'nationality':'${data.nationality || "N/A"}',
                    'gender': '${data.gender || "N/A"}',
                    'address': '${data.address || "N/A"}',
                    'town_city': '${data.city || "N/A"}',
                    'state': '${data.state || "N/A"}',
                    'zip_postalcode': '${data.zipcode || "N/A"}',
                    'country': '${data.country_bank || "N/A"}'
                });
            `;
            document.head.appendChild(scriptTag1);
        },

        onClickGoBack() {
            this.step = 1;
        },
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },

        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true,
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch((error) => {
                    this.isLoading = false;
                    alert("May the browser didn't support or there is some errors.");
                });
        },

        stopCameraStream() {
            let tracks = this.$refs.camera?.srcObject.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        },

        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const context = this.$refs.canvas?.getContext("2d");
            context.drawImage(this.$refs.camera, 0, 0, 90, 90);
            let canvas = document.getElementById("photoTaken").toDataURL("image/png");
            canvas = canvas.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
            let formData = new FormData();
            const data = this.base64toBlob(canvas, "image/png");
            const start = Date.now();
            const avatarName = `${start}avatar.png`;
            const file = new File([data], avatarName, {
                lastModified: 1534584790000,
            });
            formData.append("file", file);
            this.stopCameraStream();
            UserAuthenticate.uploadAvatar(formData).then((res) => {
                if (res.status === 200) {
                    this.getUserData();
                    if (res.avatar) {
                        this.$store.dispatch("onChangeSrcAvatar", res.avatar);
                    }
                    this.showToast(this.getTranslatedText(res.message), "success");
                    this.isCameraOpen = false;
                    setTimeout(() => {
                        this.togglePopupOptionsAvatar(false);
                    }, 500);
                    // this.isOpenPopupOptionAvatar = false;
                } else {
                    this.showToast(this.getTranslatedText(res.message), "error");
                }
            });
        },

        base64toBlob(base64Data, contentType) {
            contentType = contentType || "";
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                const begin = sliceIndex * sliceSize;
                const end = Math.min(begin + sliceSize, bytesLength);

                const bytes = new Array(end - begin);
                for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, {type: contentType});
        },

        downloadImage() {
            const download = document.getElementById("downloadPhoto");
            const canvas = document
                .getElementById("photoTaken")
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            download.setAttribute("href", canvas);
        },
        personalDetail() {
            this.routerPush('/personal-detail');
        },
        changePassword() {
            this.routerPush('/change-password');
        },
        deleteAccount() {
            this.routerPush('/delete-account');
        },
        async fetchDateCountries() {
            try {
                const resp = await countriesService.getCountries();
                if (resp.status === 200) {
                    const countries = resp.data.map(({code, name}) => ({
                        value: code,
                        text: name,
                    }));
                    this.formItem[3].options = countries;
                    this.formItem[6].options = countries;
                    this.formItem[15].options = countries;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getUserData() {
            try {
                const res = await UserService.getDataUser();
                this.userDataLayer = res
                if(res.email && !res.phone_number) {
                    this.isNotConfirmPass = true
                }
                if (res.avatar) {
                    this.$store.dispatch("onChangeSrcAvatar", res.avatar);
                }
                let index = phoneNumbers.findIndex(
                    (x) => x.number === `+${res.calling_code}`
                );

                let fullName = `${res.first_name + " " + res.last_name}`;
                this.userDataLayer['full_name'] = fullName

                const calling_code = `+${
                    res?.calling_code || phoneNumbers[0].number.replace(/\+/g, "")
                }`;
                const i = res.calling_code.toString().length;
                const phone = res.phone_number?.slice(i) || "";
                this.userDataLayer['phone'] = phone
                if (
                    !isStrEmpty(res.dob) &&
                    !isStrEmpty(res.gender) &&
                    !isStrEmpty(res.nationality) &&
                    !isStrEmpty(res.country_code) &&
                    !isStrEmpty(res.phone_number)
                ) {
                    this.$store.state.isUpdateProfile = false;
                } else {
                    this.$store.state.isUpdateProfile = true;
                }

                this.dataForm.full_name = fullName;
                this.dataForm.gender = res.gender;
                this.dataForm.calling_code = calling_code;
                this.dataForm.code = "";
                this.dataForm.phone = phone;
                this.dataForm.email = res.email;
                this.dataForm.nationality = res?.nationality || "";
                this.dataForm.country_code = res?.country_code || "";
                this.dataForm.dob = res.dob;
                this.formItem[4].valueDatepicker = moment(res.dob).format("YYYY-MM-DD");
                this.dataForm.address = res.address;
                this.dataForm.address2 = res.address2;
                this.dataForm.state = res.state;
                this.dataForm.zipcode = res.zipcode;
                this.dataForm.city = res.city;
                this.dataForm.country_bank = res?.country_bank || "";
                this.userId = res.id
                this.userName = fullName
                this.userEmail = res.email
                let userInfo = {
                    calling_code: calling_code,
                    email: res.email,
                    id: res.id,
                    name: fullName,
                    phone: res.phone_number,
                    address: res.address,
                    state: res.state,
                    city: res.city,
                    zipcode: res.zipcode,
                    country_code: res.country_code,
                };
                setUserInfo(userInfo);

                if (index !== -1) {
                    this.dataForm.nameCountry = phoneNumbers[index].name;
                    this.dataForm.flag = phoneNumbers[index].flag;
                } else {
                    this.dataForm.nameCountry = phoneNumbers[0].name;
                    this.dataForm.flag = phoneNumbers[0].flag;
                }

                this.isLoadingPage = false;
            } catch (error) {
                console.log(error);
            }
        },
        uploadImage() {
            this.saving = true;
            setTimeout(() => this.savedAvatar(), 1000);
        },
        savedAvatar() {
            this.saving = false;
            this.saved = true;
        },
        handleLogout() {
            this.$store.dispatch("handleLogout");
            this.$router.push("/login");
        },

        handleClickAccountItem(path) {
            this.isOpenPopup = false;
            document.getElementById("idNavigation").style.height = "0%";
            this.$router.push(path);
        },
        handleValidatePhoneNumber(phone) {
            const phoneCallingCode = `${this.dataForm.calling_code}${phone}`;
            this.dataForm.phoneCallingCode = phoneCallingCode;
            const isPossiblePhone = isPossiblePhoneNumber(phoneCallingCode) === true;
            const typePhone = ['MOBILE', 'FIXED_LINE_OR_MOBILE']
            const isValidType = typePhone.includes(parseMax(phoneCallingCode)?.getType());
            const isValidNumber = isValidPhoneNumber(phoneCallingCode) === true
            this.formItem[0].state = isPossiblePhone && isValidNumber && isValidType;
            // this.formItem[5].state = isPossiblePhone;
            if (!this.formItem[0].state && phone) {
                this.formItem[0].textInvalid = this.getTranslatedText(
                    "incorrect phone format"
                );
                this.isCheckPhone = false
            } else {
                this.formItem[0].textInvalid = "";
            }
        },
        // call api check email and phone
        async onBurInputFormData(event, phoneCode) {
            const userInfo = JSON.parse(getUserInfo());
            const {name, value} = event.target;
            let {calling_code, phone, email} = this.dataForm;
            if (name === "email" && 
                (isStrEmpty(value) || value === email) && !isStrEmpty(phone) && `${'+'+userInfo.phone}` === `${calling_code+phone}`) {
                this.dataForm.email = value;
                this.dataForm.otp_by = "";
            }
            if (name === "phone" && 
                (isStrEmpty(value) || value === phone) && !isStrEmpty(phone) && `${'+'+userInfo.phone}` === `${calling_code+phone}`) {
                this.dataForm.phone = value;
                this.dataForm.otp_by = "";
            }
            if ((name === "email" || name === "phone") && 
                (isStrEmpty(value) || value === email || value === phone) && !isStrEmpty(phone) && `${'+'+userInfo.phone}` !== `${calling_code+phone}`) {
                this.dataForm.otp_by = "phone";
            }

            if (name === "phone" && isStrEmpty(value) && !isStrEmpty(email)) {
                this.dataForm.phone = "";
            }
            if(name === "email") {
                if(!isStrEmpty(email) && !isStrEmpty(userInfo.email) && email !== userInfo.email) {
                    this.dataForm.otp_by = "email";
                    this.showBtnVerify = true
                } else if(!isStrEmpty(email) && isStrEmpty(userInfo.email)) {
                    this.dataForm.otp_by = "email";
                    this.showBtnVerify = true
                } else {
                    this.showBtnVerify = false
                }
            }
            switch (true) {
                case name === "email" &&
                isStrEmpty(value) &&
                !isStrEmpty(phone) &&
                this.formItem[1].state !== null:
                    this.formItem[1].state = null;
                    break;
                // check phone no empty
                case this.formItem[1].state !== null &&
                name === "phone" &&
                isStrEmpty(email) &&
                !isStrEmpty(value):
                    this.formItem[1].state = null;
                    this.dataForm.phone = value;
                    break;
                // check email empty but phone no empty
                case this.formItem[1].state !== null &&
                name === "email" &&
                value === "" &&
                !isStrEmpty(phone):
                    this.formItem[1].state = null;
                    this.dataForm.email = "";
                    this.dataForm.otp_by = "";
                    break;
                // check Incorrect email format
                case name === "email" && !isStrEmpty(value) && !validateEmail(value):
                    this.formItem[1].state = false;
                    this.formItem[1].textInvalid = this.getTranslatedText(
                        "correct email format"
                    );
                    this.dataForm.email = value;
                    break;
                default:
                    break;
            }
        },

        updateDate(date) {
            let dateFormat = date.split(".");

            let valueConvert = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;

            let dateFormatUTC = moment(valueConvert).format("MM/DD/YYYY");

            if (
                !isStrEmpty(this.textInvalidFeedbackDate) &&
                moment(dateFormatUTC).format("YYYY-MM-DD") <=
                moment(this.maxDate).format("YYYY-MM-DD")
            ) {
                this.textInvalidFeedbackDate = "";
            }
            this.dataForm.dob = dateFormatUTC;
        },

        // update data resgister
        updateFormData(item) {
            const userInfo = JSON.parse(getUserInfo());
            let {value, name, isSelectDate} = item;

            if (name === "full_name") {
                this.dataForm.full_name = value;
                if (!isStrEmpty(value) && /[.,?'"/!;:{}\-—`()[\]]/.test(value)) {
                    this.formItem[2].state = false;
                    this.formItem[2].textInvalid = 'Please do not enter punctuation marks'
                } else {
                    this.formItem[2].state = null;
                    this.formItem[2].textInvalid = "";
                }
            }

            if (name !== "email" && name !== "phone") {
                this.dataForm[name] = value;
            }

            if (isSelectDate || name === "dob") {
                this.formItem[4].state = true;
            }
            if (name === "email") {
                this.dataForm.otp_by = "email";
                this.dataForm.email = value;
                if (!validateEmail(value)) {
                    this.formItem[1].state = false;
                    this.formItem[1].textInvalid = this.getTranslatedText(
                        "correct email format"
                    );
                } else {
                    this.formItem[1].state = true;
                    this.formItem[1].textInvalid = "";
                }
            }
            if(name === "email") {
                if(!isStrEmpty(this.dataForm.email) && !isStrEmpty(userInfo.email) && this.dataForm.email !== userInfo.email) {
                    this.showBtnVerify = true
                } else if(!isStrEmpty(this.dataForm.email) && isStrEmpty(userInfo.email)) {
                    this.showBtnVerify = true
                } else {
                    this.showBtnVerify = false
                }
            }
            if (name === "phone") {
                this.dataForm.phone = value;
                // this.dataForm.otp_by = "phone";
                this.handleValidatePhoneNumber(value);
            }
        },

        selectPhoneCode(item) {
            if (
                this.dataForm.calling_code !== item.number &&
                !isStrEmpty(this.dataForm.phone)
            )
            {
                let event = {
                    target: {
                        value: this.dataForm.phone,
                        name: "phone",
                    },
                };
                let phoneCode = item.number;
                this.onBurInputFormData(event, phoneCode);
            }
            this.dataForm.calling_code = item.number;
            this.dataForm.code = item.number;
            this.dataForm.otp_by = "phone";
            this.dataForm.flag = item.flag;
            this.dataForm.nameCountry = item.name.replace(/^(.{5}[^\s]*).*/, "$1");
            this.textSearchPhoneNumber = "";
            this.phoneNumbersFilter = phoneNumbers;
            this.handleValidatePhoneNumber(this.dataForm.phone);
        },
        async checkSubmitEmail(email, userInfo, phone, calling_code) {
            if (validateEmail(email) && email !== "") {
                this.isCheckMail = true
                if (email === userInfo.email && calling_code.replace(/\+/g, "") + phone === userInfo.phone) {
                    this.formItem[1].textInvalid = "";
                    this.formItem[1].state = true;
                    this.isCheckMail = false
                    return;
                }
                if(email === userInfo.email && calling_code.replace(/\+/g, "") + phone !== userInfo.phone) {
                    this.isCheckMail = true
                    return;
                }
                try {
                    const res = await authService.checkMail(email);
                    if (res.status === 200) {
                        this.formItem[1].state = true;
                        this.isCheckMail = true
                    }
                    if (res.status === 400) {
                        let message = "";
                        res.errors.forEach((element) => {
                            message += element.message;
                        });
                        this.formItem[1].state = false;
                        this.formItem[1].textInvalid = this.getTranslatedText(message);
                        this.isCheckMail = false
                    } else {
                        this.formItem[1].state = null;
                    }
                } catch (error) {
                    this.formItem[1].textInvalid = this.getTranslatedText(error);
                }
            }
            if (isStrEmpty(email) && calling_code.replace(/\+/g, "") + phone !== userInfo.phone) {
                this.isCheckMail = true
                this.dataForm.otp_by = "phone"
                if(calling_code.replace(/\+/g, "") + phone === userInfo.phone) {
                    this.dataForm.otp_by = ""
                }
            }
        },
        async checkSubmitPhone(phone, calling_code, userInfo, email) {
            if (phone !== "" && !isStrEmpty(calling_code)) {
                this.handleValidatePhoneNumber(phone);
                if (calling_code.replace(/\+/g, "") + phone === userInfo.phone && email === userInfo.email) {
                    this.formItem[0].textInvalid = "";
                    this.formItem[0].state = true;
                    this.isCheckPhone = false
                    return;
                }
                if(calling_code.replace(/\+/g, "") + phone === userInfo.phone && email !== userInfo.email) {
                    this.isCheckPhone = true
                    return;
                }
                if (isStrEmpty(this.formItem[0].textInvalid)) {
                    try {
                        const res = await authService.checkPhone(calling_code, phone);
                        if (res.status === 200) {
                            this.formItem[0].state = true;
                            this.isCheckPhone = true
                        }

                        if (res.status === 400) {
                            let message = "";
                            res.errors.forEach((element) => {
                                message += element.message;
                            });
                            this.formItem[0].state = false;
                            this.formItem[0].textInvalid = message;
                            this.isCheckPhone = false
                        } else {
                            this.formItem[0].state = null;
                        }
                        this.dataForm.phone = phone;
                    } catch (error) {
                        this.formItem[0].textInvalid = error;
                    }
                }
            } else {
                this.isCheckPhone = false
            }
        },
        async checkSubmitForm(type) {
            const userInfo = JSON.parse(getUserInfo());
            const {email, phone, calling_code} = this.dataForm;
            try {
                this.isDisabledBtnSave = true;
                if(type === 'email') {
                    event.preventDefault();
                    await Promise.all([this.checkSubmitEmail(email, userInfo, phone, calling_code)])
                } else {
                    type = ''
                    await Promise.all([this.checkSubmitPhone(phone, calling_code, userInfo, email)])
                }
                this.isDisabledBtnSave = false;
                if(type !== 'email' && this.isCheckPhone && this.dataForm.otp_by === 'phone' && !this.isNotConfirmPass && !this.showBtnVerify) {
                    this.step = 2
                } else if(type === 'email' && this.isCheckMail && this.dataForm.otp_by !== '' && !this.isNotConfirmPass) {
                    this.dataForm.otp_by = 'email'
                    this.step = 2
                }else if(type === 'email' && this.isCheckMail && this.dataForm.otp_by !== '' && this.isNotConfirmPass) {
                    this.dataForm.otp_by = 'email'
                    this.onSubmitConfirmEmail()
                } else if(type !== 'email' && this.dataForm.otp_by !== '' && this.showBtnVerify) {
                    const element = document.getElementById('input-email');
                    element.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center', inline: 'center' });
                    this.formItem[1].state = false;
                    this.formItem[1].textInvalid = this.getTranslatedText(
                        "Please verify your email"
                    );
                } else {
                    this.onSubmitForm()
                }
            } catch (error) {
                console.log(error);
                this.isDisabledBtnSave = false;
            }
        },
        onSubmitForm(type) {
            if (this.formItem[2].state === false) {
                return false;
            } else {
                this.formItem[2].state = true;
            }
            if (this.formItem[1].state === false) {
                return false;
            } else {
                this.formItem[1].state = true;
            }
            if (isStrEmpty(this.dataForm.phone) || this.formItem[0].state === false) {
                this.formItem[0].state = false;
                if (isStrEmpty(this.dataForm.phone)) {
                    this.formItem[0].textInvalid = "Please enter phone";
                }
                return false;
            } else {
                this.formItem[0].state = true;
            }

            if (this.formItem[0].state === false) {
                this.showToast(this.getTranslatedText("Please update data", "warning"));
                return false;
            }

            if (
                moment(this.dataForm.dob).isValid() &&
                moment(this.dataForm.dob).format("YYYY-MM-DD") >
                moment(this.maxDate).format("YYYY-MM-DD")
            ) {
                this.textInvalidFeedbackDate =
                    "age should be more than 18 years. please enter a valid date of birth";
                return false;
            }

            if (this.dataForm.otp_by === "phone" && type !== 'resend') {
                this.messageRecaptcha = ''
                // this.$bvModal.show("modalRecaptchaProfile");
            }
            if(this.recaptchaType === 'v2' && ((type === 'resend' && this.dataForm.otp_by === "email") || this.dataForm.otp_by === "phone")) {
                this.$nextTick(() => {
                    grecaptcha.enterprise.ready(() => {
                        try {
                            grecaptcha.enterprise.render('recaptcha-profile', {
                                sitekey: this.sitekey,
                                action: 'profile_update',
                                hl: localStorage.getItem("locale") || 'en'
                            })
                        } catch (err) {
                            console.log(err)
                        }
                        })
                    }
                )
                this.$bvModal.show("modalRecaptchaProfile");
            } 
            if(this.recaptchaType !== 'v2') {
                this.sendOTPForPhone(type)
            }
            if (this.dataForm.otp_by === "email" && isStrEmpty(this.dataForm.email)) {
                this.dataForm.otp_by = "";
            }

            if (isStrEmpty(this.dataForm.email)) {
                this.formItem[1].state = null;
            }

            if (this.dataForm.otp_by === "email" && type !== 'resend') {
                AuthService.sendOTPToEmail("update_profile", this.dataForm.email, false, "", "", this.userId).then(
                    (res) => {
                        if (res.status === 200) {
                            this.step = 3;
                            this.showToast(this.getTranslatedText(res.message), "success");
                        } else {
                            let message = "";
                            !isArrayEmpty(res.errors)
                            ? res.errors.forEach((element) => {
                                message += element.message;
                            }) : (message = res.message);
                            if(res.data && !res.data.can_update_profile) {
                                this.showToast(this.getTranslatedText(
                                    "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                                    {count: res.data.max_number_limit_time, 
                                        time: res.data.update_profile_time_limit, 
                                        date: res.data.next_update_date}), "error");
                            } else {
                                this.showToast(this.getTranslatedText(message), "error");
                            }
                        }
                    }
                );
            }

            if (this.dataForm.otp_by === "") {
                this.dataForm.dob = this.dataForm.dob && this.dataForm.dob !== 'Invalid date' ? moment(this.dataForm.dob).format("MM/DD/YYYY") : null
                let dataConver = {...this.dataForm};
                UserService.updateProfile(dataConver).then((res) => {
                    if (res.status === 200) {
                        this.renderProfileInfor('complete', this.dataForm)
                        this.dataForm.otp_by = "";
                        this.getUserData();
                        this.showToast(this.getTranslatedText(res.message), "success");
                    } else {
                        let message = ''
                        this.renderProfileInfor('incomplete', this.userDataLayer)
                        !isArrayEmpty(res.errors)
                                    ? res.errors.forEach((element) => {
                                        message += element.message;
                            })
                            : (message = res.message);
                        if(res.data && !res.data.can_update_profile) {
                            this.showToast(this.getTranslatedText(
                                "You have updated your information [count] time within [time] hours. You can only update again on [date].",
                                {count: res.data.max_number_limit_time, 
                                    time: res.data.update_profile_time_limit, 
                                    date: res.data.next_update_date}), "error");
                        } else {
                            this.showToast(this.getTranslatedText(message), "error");
                        }
                    }
                });
            }
        },

        showDropDow() {
            const dropdown = this.$refs.select_phone_code;
            dropdown.show();
        },

        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },
        onChangeFilterPhoneNumbers(value) {
            let phoneNumber = [...phoneNumbers];
            if (!isStrEmpty(value)) {
                this.phoneNumbersFilter = filterFullTextSearch(
                    phoneNumber,
                    value,
                    "name",
                    "number"
                );
            } else {
                this.phoneNumbersFilter = phoneNumber;
            }
        },
        handleClickRedirect(item) {
            console.log("🚀 ~ handleClickRedirect ~ item:", item)
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/personal-detail/_personal-detail.scss"
/>
