<template>
    <div class="daterange-picker">
        <date-range-picker
            ref="picker"
            :date-range="dateRangeProps"
            singleDatePicker="range"
            :opens="opens"
            :autoApply="autoApply"
            :showWeekNumbers="false"
            :locale-data="locale"
            @finish-selection="onChangeCustomDate"
            @update="updateValues"
        >
            <!--    header slot-->

            <!--    input slot (new slot syntax)-->
            <template #input="" style="min-width: 350px">
                <button
                    v-if="showButtonToggle"
                    @click.stop="$refs.picker.togglePicker()"
                    class="button-open-calader"
                >
                    <img loading="lazy" src="@/assets/images/common/datepicker.png" />
                    {{ getTranslatedText("Date") }}
                </button>
            </template>
            <!--    date slot-->
            <template #date="data">
                <span class="small"> {{ data.date | dateCell }}</span>
            </template>
            <!--    ranges (new slot syntax) -->
            <template #ranges="ranges">
                <div class="ranges">
                    <ul>
                        <li
                            v-for="(range, name) in ranges.ranges"
                            :key="name"
                            @click="onChangeRangesDate(ranges, range, name)"
                            :class="{ active: rangeName === name }"
                        >
                            <b>{{ getTranslatedText(name) }}</b>
                        </li>
                    </ul>
                </div>
            </template>
        </date-range-picker>
    </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";

import languageMixin from "@/mixins/language/language-mixin";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

export default {
    mixins: [languageMixin],
    components: { DateRangePicker },
    props: {
        opens: {
            type: String,
            default: "center",
        },
        showButtonToggle: {
            type: Boolean,
            default: true,
        },
        autoApply: {
            type: Boolean,
            default: true,
        },
        dateRangeProps: {
            type: Object,
        },
        isFilter: {
            type: Boolean,
            default: true,
        },
        rangeName: {
            type: String,
            default: "",
        },
    },
    data() {
        let dateRange = this.dateRangeProps;
        let locale = localStorage.getItem("locale") === "ar" ? "ar" : "en";

        return {
            dateRange,
            locale: {
                direction: "ltr", //direction of text
                format: "DD-MM-YYYY", //fomart of the dates displayed
                weekLabel: "W",
                customRangeLabel: "Custom Range",
                daysOfWeek: moment.localeData(locale).weekdaysMin(), //enray of days - see moment documenations for details
                monthNames: moment.localeData(locale).monthsShort(), //array of month names - see moment documenations for details
                firstDay: 1, //ISO first day of week - see moment documenations for details
                applyLabel: this.getTranslatedText("Apply"),
                cancelLabel: this.getTranslatedText("Cancel"),
            },
        };
    },
    filters: {
        dateCell(value) {
            const options = {
                day: "numeric",
            };

            let locale =
                localStorage.getItem("locale") === "ar" ? "ar-EG" : undefined;

            let dt = new Date(value);

            return dt.toLocaleDateString(undefined, options);
        },
        date(val) {
            return val ? val.toLocaleString() : "";
        },
    },
    methods: {
        clearDate() {
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
        },

        onChangeRangesDate(ranges, range, name) {
            ranges.clickRange(range);
            this.$emit("onChangeRangeName", name, this.isFilter);
        },

        updateValues(value) {
            this.$emit("onFilterDate", value, this.isFilter);
        },

        onChangeCustomDate() {
            this.$emit("onChangeCustomDate", this.isFilter);
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/common/daterange-picker/_daterange-picker.scss"
/>
