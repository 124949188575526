<template>
    <div class="count-date">
        <div class="date days">
            <span>{{ getTranslatedText('days') }}</span>
            <div id="days" class="date-circle"></div>
        </div>
        <div class="date hours">
            <span>{{ getTranslatedText('hours') }}</span>
            <div id="hours" class="date-circle"></div>
        </div>
        <div class="date mins">
            <span>{{ getTranslatedText('mins') }}</span>
            <div id="mins" class="date-circle"></div>
        </div>
        <div class="date secs">
            <span>{{ getTranslatedText('secs') }}</span>
            <div id="secs" class="date-circle"></div>
        </div>
    </div>
</template>
<script>
import languageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [languageMixin],
    data(){
        return{

        }
    },
    mounted(){
        this.getCountDownDate()
    },
    methods:{
        getNextThursday(date) {
            const dateCopy = new Date(date.getTime());

            const nextThursday = new Date(
                dateCopy.setDate(
                dateCopy.getDate() + ((7 - dateCopy.getDay() + 4) % 7 || 7),
                ), 
            );

            return nextThursday;
        },
        getCountDownDate() {
            let d = new Date();
            let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
            let uaeDate = new Date(utc + (3600000*4));
            let countDownDate
            if(uaeDate.getDay() === 4 && uaeDate.getTime() <= uaeDate.setHours(20, 0, 0, 0)) {
                const date = new Date(utc + (3600000*4));
                countDownDate = date.setHours(20, 0, 0, 0);
            } else {
                countDownDate = this.getNextThursday(uaeDate).setHours(20, 0, 0, 0);
            }
            let inputDate = new Date(countDownDate);
            let year = inputDate.getFullYear();
            let month = String(inputDate.getMonth() + 1).padStart(2, '0');
            let day = String(inputDate.getDate()).padStart(2, '0');
            const nextThursday = `${year}-${month}-${day}`;
            const updateElement = (elementId, value) => {
                const element = document.getElementById(elementId);
                if (element) {
                    element.innerHTML = value;
                }
            };
            let myFunction = setInterval(() =>{
                let d = new Date();
                let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
                let uaeDate = new Date(utc + (3600000*4));
                let now = uaeDate.getTime();
                let timeleft = countDownDate - now;
                let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
                let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
                updateElement("days", days < 10 ? '0' + days : days);
                updateElement("hours", hours < 10 ? '0' + hours : hours);
                updateElement("mins", minutes < 10 ? '0' + minutes : minutes);
                updateElement("secs", seconds < 10 ? '0' + seconds : seconds);
                if (timeleft < 0) {
                    clearInterval(myFunction);
                    updateElement("days", "00");
                    updateElement("hours", "00");
                    updateElement("mins", "00");
                    updateElement("secs", "00");    
                }
                this.$emit('dataTimeCount', timeleft, nextThursday);
            }, 1000)
        },

    }
}
</script>
<style scoped lang="scss" src="@/assets/scss/common/count-down-date/count-down-date.scss"/>
