import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class ContactService extends BaseService {
    contactUs(data) {
        return this.post(API_URL + "/api/common/contact", data).then(
            (response) => {
                return response.data;
            }
        );
    }
    questionsContactUs(locale) {
        return this.get(API_URL + `/api/common/questions?locale=${locale}`).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new ContactService();
