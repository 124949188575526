<script>
export default {
    // metaInfo() {
    //     return {
    //         title: 'O! Millionaire Shop | Gift a Friend or Buy for Yourself eco-friendly merchandise and Win Your Best Life while Saving the Planet',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'O! Millionaire Shop offers you a variety of eco-oriented merchandise to celebrate your efforts to Save The Planet. Become one of our brand ambassadors by wearing your earth-friendly choices with pride. Shop our O! Millionaire merchandise today!'
    //             }
    //         ]
    //     };
    // },
};
</script>
