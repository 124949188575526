import { RouteConfig } from "vue-router";
import { metaRequireAuth } from "../meta-constants";
import Favourite from "@/views/mobile/favourite/index.vue";
import Notifications from "@/views/mobile/notifications/index.vue";
import Wallet from "@/views/mobile/wallet/index.vue";
import PersonalDetail from "@/views/mobile/personal-detail/index.vue";
import ChangePassword from "@/views/mobile/authenticate/ChangePassword.vue";
// import ManageCards from "@/views/mobile/card/manage-cards.vue";
// import AddCard from "@/views/mobile/card/add-card.vue";
import PaymentSuccessful from "@/views/mobile/notifications/successful/index.vue";
import PaymentFailure from "@/views/mobile/notifications/failure/index.vue";
import PaymentPending from '@/views/mobile/notifications/pending/index.vue'
// import Recurring from "@/views/mobile/recurring/index.vue";
import MyTransactions from "@/views/mobile/my-transaction/index.vue";
// import CardDetail from "@/views/mobile/card/card-detail.vue";
import DeleteAccount from "@/views/mobile/authenticate/DeleteAccount.vue";
import SignUpPage from "@/views/mobile/authenticate/SignUp.vue";
import Payment from "@/views/mobile/payment/index.vue";
import Generating from "@/views/mobile/notifications/green-certificate-generating/index.vue";
import EmailConfirmation from "@/views/mobile/authenticate/EmailConfirmation.vue";

const authenticatedRoutes: Array<RouteConfig> = [
    {
        path: "/personal-detail",
        name: "PersonalDetail",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: PersonalDetail,
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: ChangePassword,
    },
    {
        path: "/favourite",
        name: "Favourite",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: Favourite,
    },
    {
        path: "/notifications",
        name: "Notifications",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: Notifications,
    },
    {
        path: "/wallet",
        name: "Wallet",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: Wallet,
    },
    // {
    //     path: "/manage-cards",
    //     name: "Manage Cards",
    //     meta: metaRequireAuth,
    //     component: ManageCards,
    // },
    // {
    //     path: "/add-card",
    //     name: "Add Cards",
    //     meta: metaRequireAuth,
    //     component: AddCard,
    // },
    // {
    //     path: "/card-detail",
    //     name: "CardDetail",
    //     meta: metaRequireAuth,
    //     component: CardDetail,
    // },
    // {
    //     path: "/recurring-games",
    //     name: "Recurring Games",
    //     meta: metaRequireAuth,
    //     component: Recurring,
    // },
    {
        path: "/my-transactions",
        name: "My Transactions",
        meta: metaRequireAuth,
        component: MyTransactions,
    },
    {
        path: "/payment/successful",
        name: "Payment Successful",
        meta: metaRequireAuth,
        component: PaymentSuccessful,
    },
    {
        path: "/payment/failure",
        name: "Payment Failure",
        meta: metaRequireAuth,
        component: PaymentFailure,
    },
    {
        path: "/payment/pending",
        name: "Payment Pending",
        meta: metaRequireAuth,
        component: PaymentPending,
    },
        {
        path: "/delete-account",
        name: "DeleteAccount",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: DeleteAccount,
    },
    {
        path: "/registration-completed",
        name: "Register",
        meta: { isShowFooter: true, authenticated: true, isChangeBgColor: true },
        component: SignUpPage,
    },
    {
        path: "/payment",
        name: "Payment",
        meta: { isChangeBgColor: true, authenticated: true, isHideFloating: true },
        component: Payment,
    },
    {
        path: "/payment/green-certificate-generating",
        name: "Generating",
        meta: { isChangeBgColor: true, authenticated: true },
        component: Generating,
    },
    {
        path: "/email-confirmation",
        name: "EmailConfirmation",
        meta: { isChangeBgColor: true, authenticated: true },
        component: EmailConfirmation,
    }
];

export default authenticatedRoutes;
