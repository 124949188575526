<template>
    <div class="section-add-credit">
        <div
            class="section-add-credit__content-header hide-desktop"
            :style="cssProps"
        >
            <ButtonCustom
                classBtn="section-add-credit__header"
                type="go-back"
                text="Enter Voucher Code"
                :isGoBackHistory="false"
                @onClickButton="onClickGoBack"
            />
            <div class="voucherCode">
                <b-form-input
                    required
                    v-model="voucher"
                    maxlength = "5"
                    :state="stateVoucherCode"
                    @change="formatter"
                    id="input-add-voucher">
                </b-form-input>
                <b-form-invalid-feedback id="input-voucher">
                {{ getTranslatedText("The voucher code is required") }}
                </b-form-invalid-feedback>
            </div>
        </div>
        <div class="voucherCode__btn-group">
            <b-button
                class="voucherCode__button voucherCode__validate"
                @click="onSubmitVoucher"
                :disabled="isLoadingButton"
            >
                <b-spinner v-if="isLoadingButton" />
                {{ getTranslatedText("Validate Voucher")}}
                <img loading="lazy"
                    src="@/assets/images/common/arrow-right.png"
                />
            </b-button>
            <b-button
                class="voucherCode__button voucherCode__cancel"
                @click="$emit('onClickGoBack')"
            >
                {{ getTranslatedText("Cancel")}}
                <img loading="lazy"
                    src="@/assets/images/common/arrow-right.png"
                />
            </b-button>
        </div>
    </div>
</template>
<script>

import BottomSheet from "@/components/common/bottom-sheet";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import VoucherService from "@/services/voucher.service";
import languageMixin from "@/mixins/language/language-mixin";
import paymentMixin from "@/mixins/payment/payment-mixin"
import { isArrayEmpty, isStrEmpty } from "@/components/common/utils";
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";
let scriptTag

export default {
    mixins: [languageMixin, paymentMixin, convertGSTtime],
    components: { FormCommon, ButtonCustom, BottomSheet },
    data() {
        return {
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/wallet/background-phone.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            toastData: {
                message: "",
                type: "success",
            },
            isLoadingButton: false,
            voucher: '',
            stateVoucherCode: null
        };
    },
    methods: {
        onClickGoBack() {
            this.$emit("onClickGoBack");
        },

        formatter(value) {
            if (!value) {
                this.stateVoucherCode = false;
            } else {
                this.stateVoucherCode = null;
            }
        },

        onSubmitVoucher() {
            if (!this.voucher) {
                this.stateVoucherCode = false;
            } else {
                try {
                    this.isLoadingButton = true;
                    VoucherService.addVoucherCode({
                        code: this.voucher
                    })
                    .then(res => {
                        if(res.status === 200) {
                            scriptTag = document.createElement('script');
                            scriptTag.textContent = `
                                window.dataLayer = window.dataLayer || [];
                                dataLayer.push({
                                    'event': 'redeem_gift',
                                    'voucher_code': '${this.voucher}',
                                    'voucher_value': ${res.data.amount},
                                });
                            `;
                            document.head.appendChild(scriptTag);
                            this.$emit(
                                "showToast", res.data.message, "success"
                            );
                            this.$store.dispatch("setBalance");
                            this.onClickGoBack();
                        } else {
                            let message = "";
                             if (!isStrEmpty(res.data?.attempt_type) && !isStrEmpty(res.data?.banned_until)) {
                                let dateGST = this.convertGSTtime(res.data.banned_until, true)
                                if(res.data.attempt_type === 1) {
                                    message = this.getTranslatedText(
                                        'User Ban Voucher in 10 minutes. Please use voucher after [date] gst', { date: dateGST })
                                } else {
                                    message = this.getTranslatedText(
                                        'User Ban Voucher in 1 day. Please use voucher after [date] gst', { date: dateGST })     
                                }
                                this.$emit("showToast", message, "error", true)
                            } else {
                                    !isArrayEmpty(res.errors)
                                        ? res.errors.forEach((element) => {
                                            message += element.message;
                                })
                                : (message = res.message);
                                this.$emit(
                                    "showToast", message, "error"
                                );
                            }
                            this.isLoadingButton = false;
                        }
                    })
                } catch (error) {
                    console.log(error);
                }
                this.stateVoucherCode = null;
            }
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/wallet/_wallet.scss" />
