<template>
    <SideBar>
        <div class="section-my-transactions" id="section-my-transactions">
            <div class="section-my-transactions__header">
                <div class="section-my-transactions__header-title">
                    {{ getTranslatedText("My Transactions") }}
                    <img loading="lazy"
                        src="@/assets/images/common/vector-down.png"
                        @click="onShowBottomMenuList"
                    />
                </div>

                <div class="section-my-transactions__header-filter">
                    <button
                        @click="onShowFilter"
                        class="section-my-transactions__header-filter__mobile"
                    >
                        <img loading="lazy" src="@/assets/images/common/filter.png" />
                        {{ getTranslatedText("filter") }}
                    </button>
                    <span
                        class="section-my-transactions__header-filter__desktop"
                        >{{ getTranslatedText("filter by") }}:</span
                    >
                    <DatePicker
                        :autoApply="false"
                        :dateRangeProps="dateRange"
                        :rangeName="rangeName"
                        @onFilterDate="onFilterDate"
                        @onChangeCustomDate="onChangeCustomDate"
                        @onChangeRangeName="onChangeRangeName"
                    />
                    <div
                        class="section-my-transactions__header-filter__type"
                        @click="handleClickDropdown"
                    >
                        <img loading="lazy"
                            class="section-my-transactions__header-filter__type-icon"
                            src="@/assets/images/common/datepicker.png"
                        />
                        <span class="text-type">{{ getTranslatedText('type') }}</span>
                        <b-dropdown
                            id="dropdown-form"
                            :text="getTranslatedText('')"
                            right
                            ref="dropdown"
                            class="section-my-transactions__dropdown-form"
                        >
                            <b-form-group
                                v-slot="{ ariaDescribedby }"
                                class="section-my-transactions__checkbox"
                            >
                                <b-form-checkbox-group
                                    id="section-my-transactions__checkbox-group"
                                    v-model="selected"
                                    :options="options"
                                    :aria-describedby="ariaDescribedby"
                                    @input="
                                        (value) =>
                                            onChangeFilterType(value, true)
                                    "
                                    name="flavour-1"
                                ></b-form-checkbox-group> </b-form-group
                        ></b-dropdown>
                    </div>
                </div>
            </div>

            <NoEmpty
                v-if="listTransaction.length === 0 && !isLoadingPage"
                :text="getTranslatedText('no data')"
            />
            <div v-else class="section-my-transactions__body">
                <p class="section-my-transactions__showing">
                    {{
                        getTranslatedText(`showing [page] - [total_page]`, {
                            page: perPageRender,
                            total_page: totalList,
                        })
                    }}
                </p>
                <div class="loading-full-page" v-if="isLoadingPage">
                    <b-card>
                        <b-skeleton animation="wave" width="85%"></b-skeleton>
                        <b-skeleton animation="wave" width="55%"></b-skeleton>
                        <b-skeleton animation="wave" width="70%"></b-skeleton>
                    </b-card>
                </div>
                <div v-else class="section-my-transactions__list-transactions">
                    <div
                        class="section-my-transactions__item-transactions"
                        v-for="(item, index) in listTransaction"
                        :key="index"
                    >
                        <img loading="lazy"
                            class="section-my-transactions__item-transactions__image"
                            :src="
                                require(`@/assets/images/common/${
                                    item.transaction_type === 'Credit'
                                        ? `up.png`
                                        : `down.png`
                                }`)
                            "
                            alt="statusTransactions"
                        />
                        <div class="section-my-transactions__content">
                            <div class="section-my-transactions__content-left">
                                <h1
                                    class="section-my-transactions__content-left__title"
                                >
                                    {{
                                        getTranslatedText(
                                            item.transaction_description
                                        )
                                    }}
                                </h1>
                                <span
                                    class="section-my-transactions__content-left__date"
                                >
                                    {{ renderDate(item.date) }}
                                </span>
                            </div>
                            <div>
                                <div class="section-my-transactions__content-right">
                                    <p
                                        class="section-my-transactions__content-right__price"
                                    >
                                        {{
                                            getTranslatedText(
                                                `${currencyCode} [transaction_price]`,
                                                {
                                                    transaction_price:
                                                        formatNumberWinners(
                                                            item.amount
                                                        ),
                                                }
                                            )
                                        }}
                                    </p>
                                    <div
                                        class="section-my-transactions__content-status"
                                        :class="item.transaction_status"
                                    >
                                        <img loading="lazy"
                                            :src="
                                                require(`@/assets/images/common/${
                                                    item.transaction_status ===
                                                    'Completed'
                                                        ? `compeleted.png`
                                                        : item.transaction_status ===
                                                        'Pending'
                                                        ? `processing.png`
                                                        : `failed.png`
                                                }`)
                                            "
                                            :alt="item.transaction_status"
                                        />
                                        <p class="t-transform-cap">
                                            {{
                                                getTranslatedText(
                                                    item.transaction_status
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div v-if="item.link_invoice" class="section-my-transactions__content__download__box-label" @click="!isLoading && downloadFile(item.link_invoice)">
                                    <img loading="lazy" src="@/assets/images/download.png" />
                                    <div class="download__label t-transform-cap">
                                        {{ getTranslatedText('invoice') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="section-my-transactions__pagination"
                    v-if="isShowPagination"
                >
                    <b-pagination
                        :total-rows="totalList"
                        v-model="paramFilter.page"
                        @input="onChangePagination"
                        align="center"
                        pills
                        first-number
                        last-number
                        :index="1"
                    ></b-pagination>
                </div>
            </div>
        </div>

        <vue-bottom-sheet
            max-height="90%"
            ref="bottomMenuList"
            class="bottom-sheet-list-navigation"
        >
            <BottomSheet :isHiddenBtn="true">
                <template slot="body-sheet">
                    <ul class="bottom-sheet-list-navigation__menu-list">
                        <li
                            v-for="(item, index) in listMenuNavigation"
                            :key="index"
                            v-show="item.isShow"
                            class="bottom-sheet-list-navigation__menu-item"
                        >
                            <div
                                @click="handleClickRedirect(item)"
                                class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                            >
                                <img loading="lazy"
                                    class="user-avatar"
                                    :src="
                                        require(`@/assets/images/navigation/${item.icon}`)
                                    "
                                    :alt="item.icon"
                                />
                                {{ getTranslatedText(item.title) }}
                            </div>
                        </li>
                    </ul>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>

        <vue-bottom-sheet
            max-height="90%"
            :swipe-able="false"
            :background-scrollable="false"
            @closed="closeFilterMyTransaction"
            ref="filterMyTransaction"
            class="section-my-transactions__bottom-filter"
        >
            <BottomSheet :isHiddenBtn="true">
                <template slot="body-sheet">
                    <h1 class="section-my-transactions__bottom-filter__title">
                        {{ getTranslatedText("filters") }}
                    </h1>
                    <h2
                        class="section-my-transactions__bottom-filter__title-date"
                    >
                        {{ getTranslatedText("by date") }}
                    </h2>
                    <DatePicker
                        opens="inline"
                        :showButtonToggle="true"
                        :dateRangeProps="dateRangeMobile"
                        :isFilter="false"
                        :rangeName="rangeNameMobile"
                        @onFilterDate="onFilterDate"
                        @onChangeCustomDate="onChangeCustomDate"
                        @onChangeRangeName="onChangeRangeName"
                    />
                    <b-form-group
                        :label="getTranslatedText('by type')"
                        class="section-my-transactions__checkbox"
                    >
                        <b-form-checkbox-group
                            id="section-my-transactions__checkbox-group__mobile"
                            v-model="selectedMobile"
                            :options="options"
                            name="flavour-2"
                        ></b-form-checkbox-group>
                    </b-form-group>
                    <ButtonCustom
                        :isLoading="isLoadingButton"
                        classBtn="section-my-transactions__btn-filter"
                        typeBtn="button"
                        type="primary"
                        text="apply"
                        icon="arrow-right.png"
                        @onClickButton="onFinishFilter"
                    />
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
    </SideBar>
</template>
<script>
import {
    formatNumberMoney,
    isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    renderLocaleNumber,
    renderLocalePrice,
} from "@/components/common/utils";
import { listMenuNavigation } from "@/resources/listNavigation";

import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"

import MyTransactionsService from "@/services/my-transaction";
import NoEmpty from "@/components/common/no-empty";
import SideBar from "@/components/common/sidebar/SideBar";
import DatePicker from "@/components/common/daterange-picker";
import BottomSheet from "@/components/common/bottom-sheet";
import ButtonCustom from "@/components/common/button";
import moment from "moment";
const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [languageMixin, RouterMixin],
    components: { SideBar, NoEmpty, DatePicker, BottomSheet, ButtonCustom },

    data() {
        return {
            currencyCode: CURRENCY_CODE,
            listMenuNavigation,
            dateRange: { startDate: "", endDate: "" },
            selected: [],
            isShowConsent: IS_SHOW_CONSENT,
            rangeName: "",
            rangeNameMobile: "",
            dateRangeMobile: { startDate: "", endDate: "" },
            selectedMobile: [],
            options: [
                {
                    text: this.getTranslatedText("add credit to wallet"),
                    value: "add-credit-to-wallet",
                },
                {
                    text: this.getTranslatedText("transfer to bank"),
                    value: "transfer-to-bank",
                },
                {
                    text: this.getTranslatedText("transfer funds"),
                    value: "transfer-funds",
                },
                // { text: this.getTranslatedText("Transfer	"), value: "transfer" },
                { text: this.getTranslatedText("purchase"), value: "purchase" },
                // { text: this.getTranslatedText("payment"), value: "payment" },
                { text: this.getTranslatedText("winnings"), value: "winnings" },
                // { text: this.getTranslatedText("deposit"), value: "deposit" },
                // { text: this.getTranslatedText("voucher"), value: "voucher" },
                // { text: this.getTranslatedText("float"), value: "float" },
                // { text: this.getTranslatedText("Free Bet"), value: "free-bet" },
            ],
            isLoadingButton: false,
            isLoadingPage: true,
            isShowPagination: false,
            isLoading: false,
            currentPage: "",
            listTransaction: [],
            totalList: 0,
            perPageRender: 0,
            paramFilter: {
                page: 1,
                perPage: 20,
                type: "",
                dateFrom: "",
                dateTo: "",
            },
        };
    },
    created() {
        this.fetchdata();
    },
    mounted(){
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("section-my-transactions")) {
                document.getElementById("section-my-transactions").style.marginBottom = "180px"
            }
        } else {
            document.getElementById("section-my-transactions").style.marginBottom = "0"
        }
    },
    // metaInfo() {
    //     return {
    //         title: 'Omillionaire Transactions',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Omillionaire Transactions'
    //             }
    //         ]
    //     };
    // },
    methods: {
        async fetchdata(isFilterMobile) {
            window.scroll(0, 0);
            this.isLoadingPage = true;
            this.isLoadingButton = isFilterMobile;
            try {
                const resp = await MyTransactionsService.getList(
                    this.paramFilter
                );
                if (resp.status === 200) {
                    let perPageRender =
                        resp.data.length === resp.per_page
                            ? resp.per_page * resp.page
                            : resp.per_page * resp.page -
                              (resp.per_page - resp.data.length);

                    this.listTransaction = resp.data;
                    this.totalList = resp.total;
                    this.isShowPagination = true;
                    this.perPageRender = perPageRender;
                }
                this.isLoadingButton = false;
                this.isLoadingPage = false;
            } catch (err) {
                console.log(err);
            }
        },

        onFinishFilter() {
            let type = "";
            if (!isArrayEmpty(this.selected)) {
                this.selected.map((x) => {
                    type += isStrEmpty(type) ? `${x}` : `,${x}`;
                });
            }
            this.paramFilter.type = type;
            this.dateRange = this.dateRangeMobile;
            this.selected = this.selectedMobile;
            this.rangeName = this.rangeNameMobile;
            this.paramFilter.page = 1;
            this.totalList = 0;
            this.perPageRender = 0;
            this.fetchdata();
            this.$refs.filterMyTransaction?.close();
        },

        onChangePagination() {
            this.fetchdata();
        },

        formatNumberWinners(price) {
            return formatNumberMoney(parseFloat(price));
        },

        handleClickDropdown() {
            this.$refs.dropdown?.show(true);
        },

        onShowFilter() {
            this.$refs.filterMyTransaction?.open();
            document.querySelector("body").classList.add("overflowHidden");
            document.querySelector("html").classList.add("overflowHidden");
        },

        closeFilterMyTransaction() {
            document.querySelector("body").classList.remove("overflowHidden");
            document.querySelector("html").classList.remove("overflowHidden");
        },

        onChangeFilterType(value, isFilter) {
            let type = "";
            value.map((x) => {
                type += isStrEmpty(type) ? `${x}` : `,${x}`;
            });
            this.paramFilter.type = type;
            this.paramFilter.page = 1;
            this.totalList = 0;
            this.perPageRender = 0;
            this.selectedMobile = value;
            if (isFilter) {
                this.fetchdata();
            }
        },

        onFilterDate(dateRange, isFilter) {
            this.paramFilter.dateFrom = moment(dateRange.startDate).format(
                "YYYY/MM/DD"
            );
            this.paramFilter.dateTo = moment(dateRange.endDate).format(
                "YYYY/MM/DD"
            );
            this.paramFilter.page = 1;
            this.totalList = 0;
            this.perPageRender = 0;
            this.dateRangeMobile = dateRange;
            this.rangeNameMobile = this.rangeName;
            if (isFilter) {
                this.fetchdata();
                this.dateRange = dateRange;
            }
        },

        onChangeCustomDate(isFilter) {
            this.rangeName = "";
            this.rangeNameMobile = "";
        },

        onChangeRangeName(name, isFilter) {
            if (isFilter) {
                this.rangeName = name;
            } else {
                this.rangeNameMobile = name;
            }
        },

        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },

        renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },

        renderDate(date) {
            let dateFomat = date.split("/");

            var timestamp = Date.parse(
                `${dateFomat[1]}/${dateFomat[0]}/${dateFomat[2]}`
            );

            // let locale = this.$store.state.isRtl ? "ar" : "en";
            // moment.locale(locale);

            return moment(new Date(timestamp)).format("DD/MM/YYYY");
        },

        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },

        downloadFile(url) {
            this.isLoading = true
            window.open(url, "_blank")
            setTimeout(() => {
                this.isLoading = false
            }, 2000);
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/my-transaction/_my-transaction.scss"
/>
