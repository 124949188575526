<template>
    <div class="main section-ticket" :class="{ mainFavorite: step !== 1 }">
        <BannerCustom/>
        <div class="contents-favourite" :class="{ actionFavourite: step !== 1 }">
            <NavBar v-if="step === 1"/>
            <div class="section-favourite" id="section-favourite">
                <template v-if="step === 1">
                    <div class="dropdown-menu-numbers">
                        <div @click="updateMenu" class="dropdown-menu-numbers__contents">
                            <p class="title">{{ getTranslatedText(textMenuSelected) }}</p>
                            <img loading="lazy" src="@/assets/images/navigation/chevron-down.svg"/>
                        </div>
                    </div>
                    <ul id="showMenu" v-if="isShow">
                        <li v-if="menuSelected !== 'past'" @click="updateMenuSelected('past')">{{ getTranslatedText('Past Combinations') }}</li>
                        <li v-if="menuSelected !== 'win'" @click="updateMenuSelected('win')">{{ getTranslatedText('Winning Numbers') }}</li>
                        <li v-if="menuSelected !== 'fav'" @click="updateMenuSelected('fav')">{{ getTranslatedText('Favorite numbers') }}</li>
                    </ul>
                    <TabCommon
                        :tabs="tabs"
                        :is-pos-device="true"
                        :class-container="'section-favourite__favourite-custom'"
                        @handleChangeTab="handleChangeTab"
                    >
                        <template :slot="menuSelected" v-if="menuSelected === 'fav'">
                            <NoEmpty
                                v-if="listFavourite.length === 0"
                                :text="getTranslatedText('no data')"
                            />
                            <div
                                v-else
                                v-for="(item, index) in listFavourite"
                                :key="index"
                            >
                                <NumberFavourite
                                    :balls="item.balls"
                                    :title="item.title"
                                    :indexItem="index"
                                    :showDownload="false"
                                    :maxiumBlue="maxiumBlue"
                                    :maxiumGreen="maxiumGreen"
                                    :minimumBall="minimumBall"
                                    @onClickEdit="onClickEditBall"
                                    @onClickDelete="onClickConfirmModal"
                                />
                            </div>

                            <div class="section-favourite__action-add">
                                <ButtonCustom
                                    typeBtn="button"
                                    type="default"
                                    text=""
                                    icon="plus.svg"
                                    @onClickButton="onClickAddFavourite"
                                />
                            </div>
                        </template>
                        <div :slot="menuSelected" v-if="menuSelected === 'win'">
                           <WinningNumbers
                                :maxiumBlue="maxiumBlue"
                                :maxiumGreen="maxiumGreen"
                                :minimumBall="minimumBall"
                                @onReGenCerticate="reGenCerticate"
                                @handleFavorite="handleFavorite"
                                @onClickIconArrow="onClickIconArrow"
                           />
                        </div>
                        <div :slot="menuSelected" class="past-combinations" v-if="menuSelected === 'past'">
                            <NoEmpty
                                v-if="listPastCombinations.length === 0"
                                :text="getTranslatedText('no data')"
                            />
                            <div
                                v-else
                                v-for="(item, index) in listPastCombinations"
                                :key="index"
                            >
                                <NumberFavourite
                                    :raffle_id="item.raffle_id"
                                    :dataNumber="item"
                                    :is-past="true"
                                    :showDownload="true"
                                    :isDownload="
                                        item.raffle_id ? true : false
                                    "
                                    :link_certificate="item.link_certificate"
                                    :balls="item.balls"
                                    :dateBall="item.date"
                                    :indexItem="index"
                                    :isAction="false"
                                    :maxiumBlue="maxiumBlue"
                                    :maxiumGreen="maxiumGreen"
                                    :minimumBall="minimumBall"
                                    :isShowIconArrow="true"
                                    :userInfo="userInfo"
                                    :isLoadingDownLoad="item.isLoadingDownLoad"
                                    @showToast="showToast"
                                    @onClickIconArrow="onClickIconArrow(item)"
                                    @onReGenCerticate="reGenCerticate(item)"
                                    @handleFavorite="handleFavorite(true, item.balls)"
                                />
                            </div>
                            <div v-if="totalPastCombinations > 0" class="section-favourite__pagination">
                                <b-pagination
                                    :total-rows="totalPastCombinations"
                                    v-model="currentPastCombinations"
                                    :per-page="perPagePagination"
                                    @input="onChangePagination"
                                    pills
                                    align="center"
                                    hide-goto-end-buttons
                                    prev-text="Back"
                                    next-text="Next"
                                ></b-pagination>
                                <div class="title">
                                    {{ currentPastCombinations + '/' + totalPage + ' ' + getTranslatedText('of') + ' ' + totalPastCombinations}}
                                    <span>{{ totalPastCombinations > 1 ? getTranslatedText('entries') : getTranslatedText('entry') }}</span>
                                </div>
                            </div>
                        </div>
                        <template slot="weekly-subscription">
                            <div class="loading-full-page" v-if="isLoaddingPage">
                                <b-card>
                                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                                </b-card>
                            </div>
                            <NoEmpty
                                v-else-if="dataWeekly.length === 0"
                                :text="getTranslatedText('no data')"
                            />
                            <div class="weekly-subscription" v-else>
                                <div class="weekly-subscription__content" v-for="(item, index) in dataWeekly" :key="index">
                                    <div class="weekly-subscription__items">
                                        <div class="head">
                                            <span>{{ getTranslatedText('Item')}} {{ index + 1 }}</span>
                                            <img v-if="item.cancelled_at" loading="lazy" src="@/assets/images/common/deleted.svg" @click="showModalConfirm(item, 'modal-confirm-delete-subs')"/>
                                        </div>
                                        <div class="weekly-subscription__item">
                                            <div class="weekly-subscription__item__left">
                                                <div class="weekly-subscription__item__number">
                                                    <ul>
                                                        <li v-for="(number, i) in item.bets.bets[0]" :key="i" :class="`${renderClass(number, i)}`"><span>{{ number }}</span></li>
                                                    </ul>
                                                </div>
                                                <div v-if="!item.cancelled_at" class="item__left--date">
                                                    <span>{{ getTranslatedText('Draw Date') }}: </span>
                                                    <span class="date">{{ formatDate(drawDate) }}</span>
                                                </div>
                                                <div v-if="!item.cancelled_at" class="item__left--time-left">
                                                    ({{ item.remainingTime }})
                                                </div>
                                            </div>
                                            <div v-if="item.cancelled_at" class="weekly-subscription__item__resubs">
                                                <div class="weekly-subscription__item__handle--text">
                                                    <span>{{ getTranslatedText('Subscribe to play the same numbers') }}.</span>
                                                    <img loading="lazy"
                                                        style="cursor: pointer"
                                                        src="@/assets/images/checkout/info.svg"
                                                        v-b-tooltip.hover
                                                        :title="getTranslatedText('You have selected to purchase the same item each week. Free cancellation anytime.')"
                                                    />
                                                </div>
                                                <div class="weekly-subscription__item__handle--button">
                                                    <ButtonCustom
                                                        :isDisabled="item.has_debuct_money"
                                                        typeBtn="button"
                                                        text="Next Draw"
                                                        type="default"
                                                        classBtn="button-next"
                                                        @onClickButton="showModalConfirm(item, 'modal-confirm-resubscribe-subs', 1)"
                                                    />
                                                    <ButtonCustom
                                                        :isDisabled="item.has_debuct_money"
                                                        typeBtn="button"
                                                        text="Weekly"
                                                        type="default"
                                                        classBtn="button-weekly"
                                                        @onClickButton="showModalConfirm(item, 'modal-confirm-resubscribe-subs', 2)"
                                                    />
                                                </div>
                                            </div>
                                            <div v-else class="weekly-subscription__item__btn">
                                                <div v-if="item.is_pause" class="weekly-subscription__item__btn__left paused">
                                                    <div class="sub">{{ getTranslatedText('Subscription:') }}</div>
                                                    <div class="select-group">
                                                        {{ getTranslatedText('Paused') }}
                                                    </div>
                                                </div>
                                                <div v-else class="weekly-subscription__item__btn__left">
                                                    <div class="sub">{{ getTranslatedText('Subscribed:') }}</div>
                                                    <div class="select-group">
                                                        <img loading="lazy" src="@/assets/images/common/inner.svg"/>
                                                        <select class="select-type" v-model="item.recurring_type" @change="showModalConfirm(item, 'modal-confirm-change-subs', '', $event)">
                                                            <option value="1">{{ getTranslatedText('Next draw')}}</option>
                                                            <option value="2">{{ getTranslatedText('Weekly')}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="weekly-subscription__item__btn__right">
                                                    <img v-if="item.is_pause" :class="item.has_debuct_money ? 'disable-btn' : ''" loading="lazy" src="@/assets/images/common/play.svg" @click="onCheckSubscription(false, item, 'modal-confirm-unpause-subs')">
                                                    <img v-else loading="lazy" src="@/assets/images/common/pause.svg" @click="onCheckSubscription(true, item, 'modal-confirm-pause-subs')">
                                                    <img loading="lazy" src="@/assets/images/common/cancel.svg" @click="showModalConfirm(item, 'modal-confirm-cancel-subs')"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="totalItemWeekly > perPageWeekly" class="weekly-subscription__pagination">
                                <div class="weekly-subscription__pagination__count">
                                    {{pageCountWeekly}}/{{totalItemWeekly}}
                                </div>
                                <b-pagination
                                    v-model="numberPageWeekly"
                                    :total-rows="totalItemWeekly"
                                    :per-page="perPageWeekly"
                                ></b-pagination>
                            </div>
                            <ConfirmModal
                                id="modal-confirm-unsubcribe-favourite"
                                :title="getTranslatedText('Unsubscribe this numbers?')"
                                :description="
                        getTranslatedText(
                            'once the number is unsubscribed cannot be recovered. Do you want to unsubscribe it?'
                        )
                    "
                                :textBtnCancel="getTranslatedText('cancel')"
                                :textBtnDelete="getTranslatedText('unsubscribe')"
                                :isLoadingButton="isLoadingButtonConfirmUnsubcribe"
                                :showBtnCancel="true"
                                @onCancelModal="onClickCancelModalWeekly('modal-confirm-unsubcribe-favourite')"
                                @onDelete="handleUnsubscribe"
                            />
                            <ConfirmModal
                                id="modal-confirm-skip-a-week-favourite"
                                :title="getTranslatedText('Skip a week this numbers?')"
                                :description="
                        getTranslatedText(
                            'once the number is Skip a week cannot be recovered. Do you want to Skip a week it?'
                        )
                    "
                                :textBtnCancel="getTranslatedText('cancel')"
                                :textBtnDelete="getTranslatedText('Skip a week')"
                                :isLoadingButton="isLoadingButtonConfirmSkipAWeek"
                                :showBtnCancel="true"
                                @onCancelModal="onClickCancelModalWeekly('modal-confirm-skip-a-week-favourite')"
                                @onDelete="handleSkipAWeek"
                            />
                        </template>
                    </TabCommon>
                </template>
                <template v-if="step === 2"
                    ><AddFavourite
                        @onGoBack="onGoBack"
                        @onSubmit="onSubmitAdd"
                        @showToast="showToast"
                        :listFavourite="listFavourite"
                /></template>
                <template v-if="step === 3">
                    <EditFavourite
                        :dataDetail="dataDetail"
                        @onGoBack="onGoBack"
                        :indexItem="indexEdit"
                        :listFavourite="listFavourite"
                        @onSubmit="onSubmitEdit"
                        @showToast="showToast"
                        @onRemoveTicket="onClickConfirmModal"
                    />
                </template>
                <div class="sheet-fav">
                    <vue-bottom-sheet
                        max-height="90%"
                        ref="bottomHistoryNumber"
                        class="bottom-sheet__container-custom"
                    >
                        <BottomSheet
                            v-if="dataDetail !== null"
                            title=""
                            classBtn="bottom-sheet-favourite__button btn-success"
                            type="primary"
                            text="Download Green Certificate"
                            :isDownload="true"
                            icon="down.svg"
                            @onClickSheet="handleDownload"
                            :isLoadingButton="isLoadingButton"
                            :isHiddenBtn="dataDetail.isShowButton ? false : true"
                        >
                            <template slot="body-sheet" v-if="dataDetail !== null">
                                <div class="header">
                                    <p>{{ getTranslatedText('My numbers') }}</p>
                                    <div>
                                        <img @click="handleFavorite(true, dataDetail.balls)" src="@/assets/images/common/heart-white.svg" alt="">
                                        <img @click="handleQuickPick(dataDetail.balls)" src="@/assets/images/common/youtube.svg" alt="">
                                    </div>
                                </div>
                                <div class="bottom-sheet-favourite" :style="cssProps">
                                    <div class="bottom-sheet-favourite__list-ball">
                                        <div
                                            :class="[`bottom-sheet-favourite__item-ball ${renderClass(
                                                number,
                                                index
                                            )}`, { highLight: dataDetail.ballsFinal.length > 0 && !dataDetail?.ballsFinal[index].isHighlight }]"
                                            v-for="(number, index) in dataDetail.balls"
                                            :key="index"
                                        >
                                            <div
                                                class="bottom-sheet-favourite__box-ball"
                                            >
                                                <div
                                                    class="bottom-sheet-favourite__number"
                                                >
                                                    {{ renderNumberLocale(number) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="raffle_id !== ''">
                                    <div class="bottom-sheet-favourite__raffle-id t-transform-cap">
                                        <div v-if="dataDetail.raffle_id">
                                            <span>{{ getTranslatedText("raffleID") }}</span>
                                            <div class="raffle-detail">
                                                <p>{{ dataDetail.raffle_id.slice(0, 4) + ' ' + dataDetail.raffle_id.slice(4, 8) || getTranslatedText("Pending Generation") }}</p>
                                                <img v-if="dataDetail?.raffle_winnings && dataDetail.raffle_winnings !== '0'" src="@/assets/images/tick.svg" alt="">
                                            </div>
                                        </div>
                                        <div class="item--double">
                                            <img v-if="Number(dataDetail?.double_jackpot) > 0" src="@/assets/images/inspiring/double.svg" alt="">
                                            <img v-if="Number(dataDetail?.secure_jackpot) > 0" src="@/assets/images/secure-green.svg" alt="">
                                        </div>
                                    </div>
                                    <div class="bottom-content">
                                        <p class="bottom-sheet-favourite__date">
                                            {{ getTranslatedText('draw date') }}: {{ dataDetail.date }}
                                        </p>
                                        <!-- <div class="bottom-sheet-favourite__download__icon">
                                            <b-spinner v-if="isLoadingButton" variant="success" />
                                            <img loading="lazy" v-if="userInfo.phone !== '' && dataDetail.link_certificate" @click="!isLoadingButton && downloadFileSendToLink(dataDetail.raffle_id, 'phone')" src="@/assets/images/common/icon-sms.png" />
                                            <img loading="lazy"  v-if="userInfo.email !== '' && dataDetail.link_certificate" @click="!isLoadingButton && downloadFileSendToLink(dataDetail.raffle_id, 'email')" src="@/assets/images/common/icon-email.png" />
                                        </div> -->
                                        <!-- <p v-if="!dataDetail.isShowButton"  class="bottom-sheet-favourite__generating">{{ getTranslatedText('Re-generate Certificate') }}</p> -->
                                        <ButtonCustom
                                            v-if="!dataDetail.isShowButton"
                                            :isLoading="dataDetail.isLoadingDownLoad"
                                            classBtn="bottom-sheet-favourite__button btn-success"
                                            type="primary"
                                            text="Re-generate Certificate"
                                            :isDownload="true"
                                            icon="down.svg"
                                            @onClickButton="reGenCerticate(dataDetail)"
                                        />
                                    </div>
                                </template>
                            </template>
                        </BottomSheet>
                    </vue-bottom-sheet>

                    <vue-bottom-sheet max-height="90%" ref="bottomMenuList">
                        <BottomSheet :isHiddenBtn="true">
                            <template slot="body-sheet">
                                <ul class="bottom-sheet-list-navigation__menu-list">
                                    <li
                                        v-for="(item, index) in listMenuNavigation"
                                        :key="index"
                                        v-show="item.isShow"
                                        class="bottom-sheet-list-navigation__menu-item"
                                    >
                                        <div
                                            @click="handleClickRedirect(item)"
                                            class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                                        >
                                            <img loading="lazy"
                                                class="user-avatar"
                                                :src="
                                                    require(`@/assets/images/navigation/${item.icon}`)
                                                "
                                                :alt="item.icon"
                                            />
                                            {{ getTranslatedText(item.title) }}
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </BottomSheet>
                    </vue-bottom-sheet>
                </div>
                <ConfirmModal
                    id="modal-confirm-remove-favourite"
                    :title="getTranslatedText('delete Favourite Number?')"
                    :description="
                        getTranslatedText(
                            'once the number is deleted cannot be recovered. Do you want to delete it?'
                        )
                    "
                    :textBtnCancel="getTranslatedText('cancel')"
                    :textBtnDelete="getTranslatedText('delete')"
                    :isLoadingButton="isLoadingButtonConfirm"
                    :showBtnCancel="true"
                    @onCancelModal="onCancelRemove"
                    @onDelete="onClickDeleteBall"
                />
                <Toast :title="toastData.message" :type="toastData.type" />
            </div>
        </div>
        <ConfirmModal
            id="modal-confirm-pause-subs"
            :title="getTranslatedText('Are you pausing the subscription?')"
            :description="
                getTranslatedText(
                    `Pausing your subscription means your numbers won't be entered for future draws until you reactivate. Would like to continue?`
                )
            "
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonPause"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-pause-subs')"
            @onDelete="onPauseSubscription()"
        />
        <ConfirmModal
            id="modal-confirm-unpause-subs"
            :title="getTranslatedText('Are you unpausing the subscription?')"
            :description="dataEachSubs && dataEachSubs.recurring_type === 1 ?
                getTranslatedText(
                    `Unpausing will re-enter your chosen numbers for upcoming next draw. Do you want to continue?`
                ) : getTranslatedText('Unpausing will re-enter your chosen numbers for upcoming weekly draws. Do you want to continue?')
            "
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonUnPause"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-unpause-subs')"
            @onDelete="onPauseSubscription()"
        />
        <ConfirmModal
            id="modal-confirm-cancel-subs"
            :title="getTranslatedText('Are you cancelling the subscription?')"
            :description="getTranslatedText(
                    `This will remove you from automatic entries in future draws. Do you wish to continue?`
                )"
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonCancel"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-cancel-subs')"
            @onDelete="onCancelRecurring()"
        />
        <ConfirmModal
            id="modal-confirm-resubscribe-subs"
            :title="getTranslatedText('Do you wish to resubscribe?')"
            :description="typeSubs && typeSubs === 1 ?
                getTranslatedText(
                    `Resubscribing will automatically enter your chosen numbers in upcoming next draw. Would you like to proceed?`
                ) : getTranslatedText('Resubscribing will automatically enter your chosen numbers in upcoming weekly draws. Would you like to proceed?')
            "
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonResubs"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-resubscribe-subs')"
            @onDelete="onResubscribing()"
        />
        <ConfirmModal
            id="modal-confirm-change-subs"
            :title="getTranslatedText('Do you wish to change subscription?')"
            :description="valueSubs && valueSubs === '1' ?
                getTranslatedText(
                    `This will automatically enter your chosen numbers for the upcoming next draw. Would you like to proceed?`
                ) : getTranslatedText('This will automatically enter your chosen numbers for all upcoming weekly draws. Would you like to proceed?')
            "
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonChange"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-change-subs')"
            @onDelete="onChangeRecurringType()"
        />
        <ConfirmModal
            id="modal-confirm-delete-subs"
            :title="getTranslatedText('Are you deleting your subscription?')"
            :description="getTranslatedText(
                    `Once deleted you can no longer participate and use the same number. Do you want to proceed?`
                )"
            :textBtnCancel="getTranslatedText('Close')"
            :textBtnDelete="getTranslatedText('Confirm')"
            :isLoadingButton="isLoadingButtonDelete"
            :showBtnCancel="true"
            @onCancelModal="hideModalConfirm('modal-confirm-delete-subs')"
            @onDelete="onDeleteSubscription()"
        />
        <vue-bottom-sheet max-height="90%" ref="bottomFavorite">
            <BottomSheet
                :title="getTranslatedText('mark as favorite')"
                :description="
                    getTranslatedText('Give your favorite number a name')
                "
                :isHiddenBtn="true"
                classBody="bottom_favorite"
            >
                <template slot="body-sheet">
                    <div class="bottom-favorite">
                        <div
                            class="box-number-favorite t-transform-cap"
                            :style="cssProps"
                        >
                            <h2 class="title-number">
                                {{ getTranslatedText("selected numbers") }}
                            </h2>
                            <div class="box-number-favorite__box-balls">
                                <div class="list-number-favorite">
                                    <div
                                        v-for="(
                                            item, index
                                        ) in dataFavorite.number"
                                        :class="`item-number-favorite ${renderClass(
                                            item, index
                                        )}`"
                                        :key="index"
                                    >
                                        <span
                                            ><a>{{
                                                renderNumberLocale(item)
                                            }}</a></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormCommon
                            :formItem="dataFavorite.formItem"
                            :dataForm="dataFavorite"
                            @update-form-data="updateDataFavorite"
                        />
                        <div class="box-number-favorite__box-button">
                            <ButtonCustom
                                typeBtn="button"
                                type="default"
                                classBtn="button-favorite cancel"
                                text="cancel"
                                @onClickButton="handleFavorite(false)"
                            />
                            <ButtonCustom
                                typeBtn="button"
                                type="primary"
                                classBtn="button-favorite add"
                                :isLoading="isLoadingBtn"
                                text="add"
                                @onClickButton="onFinishFavorite"
                            />
                        </div>
                    </div>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
    </div>
</template>
<script>
import { getUserInfo } from "@/services/axios";
import { listMenuNavigation } from "@/resources/listNavigation";
import {
    isArrayEmpty,
    isStrEmpty,
    redirectPathApp,
    renderClassBall,
    renderLocaleNumber,
} from "@/components/common/utils";

import LanguageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"

import toolService from "@/services/tool.service";
import FavouriteService from "@/services/favourite.service";
import gameService from '@/services/game.service';

import NumberFavourite from "@/components/common/number-favourite/index.vue";
import TabCommon from "@/components/common/tabs/tabs-swift.vue";
import BottomSheet from "@/components/common/bottom-sheet";
import ButtonCustom from "@/components/common/button";
import ConfirmModal from "@/components/common/confirm";
import NoEmpty from "@/components/common/no-empty";

import AddFavourite from "@/views/mobile/favourite/add";
import EditFavourite from "@/views/mobile/favourite/edit";
import SideBar from "@/components/common/sidebar/SideBar";
import Toast from "@/components/common/toast/Toast.vue";
import moment from "moment";
import BannerCustom from "@/components/common/banner";
import NavBar from "@/components/common/nav-bar/NavBar";
import FormCommon from "@/components/common/form";
import WinningNumbers from "@/components/common/winning-numbers";
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [LanguageMixin, RouterMixin],
    components: {
        TabCommon,
        NumberFavourite,
        ButtonCustom,
        AddFavourite,
        EditFavourite,
        BottomSheet,
        ConfirmModal,
        NoEmpty,
        SideBar,
        Toast,
        BannerCustom,
        NavBar,
        FormCommon,
        WinningNumbers
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            isShowConsent: IS_SHOW_CONSENT,
            raffle_id: "",
            step: 1,
            maxiumBlue: 0,
            maxiumGreen: 0,
            minimumBall: 0,
            dataDetail: null,
            indexEdit: -1,
            idWeekly: -1,
            indexWeekly: -1,
            pageHistory: "1",
            isLoadingButton: false,
            isLoadingButtonConfirm: false,
            isLoadingButtonConfirmUnsubcribe: false,
            isLoadingButtonConfirmSkipAWeek: false,
            isLoadingBtn: false,
            dataFavorite: {
                name: "",
                number: [],
                formItem: [
                    {
                        type: "input",
                        typeInput: "text",
                        placeholder: "Name...",
                        name: "name",
                        required: false,
                        state: null,
                        textInvalid: "Please enter name",
                        label: "Enter Name",
                        for: "input-Name",
                        id: "input-Name",
                    },
                ],
            },
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/tickets/background-favourite.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            listMenuNavigation,
            listPastCombinations: [],
            listFavourite: [],
            totalPastCombinations: 0,
            currentPastCombinations: 1,
            perPagePagination: 20,
            totalPage: 1,
            userInfo: null,
            tabs: [
                {
                    title: this.getTranslatedText("Past Combinations"),
                    isShow: false,
                    nameSlot: "past",
                },
                {
                    title: this.getTranslatedText("Winning Numbers"),
                    isShow: false,
                    nameSlot: "win",
                },
                {
                    title: this.getTranslatedText("Favorite Numbers"),
                    isShow: false,
                    nameSlot: "fav",
                },
                // {
                //     title: this.getTranslatedText("Weekly Subscription"),
                //     isShow: false,
                //     nameSlot: "weekly-subscription",
                // },
            ],
            dataWeekly:[],
            numberPageWeekly:1,
            perPageWeekly:10,
            totalItemWeekly:0,
            pageCountWeekly:0,
            isLoaddingPage:false,
            subscribedType: '',
            isResubscribing: false,
            drawDate: '',
            isLoadingButtonPause: false,
            isLoadingButtonUnPause: false,
            isLoadingButtonCancel: false,
            isLoadingButtonResubs: false,
            isLoadingButtonChange: false,
            isLoadingButtonDelete: false,
            dataEachSubs: null,
            isPauseSubs: false,
            typeSubs: '',
            valueSubs: '',
            menuSelected: 'past',
            textMenuSelected: 'Past Combinations',
            isShow: true
        };
    },
    created() {
        this.fetchDataValidateBall();
        this.fetdataListFavourite();
        this.fetdataListPasrCombinations();
        this.fetchDataWeekly();
        let list_games = !isStrEmpty(localStorage.getItem("list_games"))
                ? JSON.parse(localStorage.getItem("list_games"))
                : [];
        this.drawDate =  list_games[0]?.draw_date
    },
    mounted(){
        const parameters = this.$route.query;
        if(parameters?.tab === 'past') {
            this.menuSelected = 'past';
            this.textMenuSelected = 'Past Combinations'
            this.tabs[0].isActive = true
            this.tabs[2].isActive = false
            this.tabs[1].isActive = false
            this.$router.replace({'query': null});
        } else if(parameters?.tab === 'win') {
            this.menuSelected = 'win';
            this.textMenuSelected = 'winning numbers'
            this.tabs[1].isActive = true
            this.tabs[0].isActive = false
            this.tabs[2].isActive = false
            this.$router.replace({'query': null});
        } else if(parameters?.tab === 'fav') {
            this.menuSelected = 'fav';
            this.textMenuSelected = 'favorite numbers'
            this.tabs[2].isActive = true
            this.tabs[0].isActive = false
            this.tabs[1].isActive = false
            this.$router.replace({'query': null});
        }
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            if(document.getElementById("section-favourite")) {
                document.getElementById("section-favourite").style.marginBottom = "140px"
            }
        } else {
            document.getElementById("section-favourite").style.marginBottom = "0"
        }
        setInterval(() => {
            this.dataWeekly.forEach((item) =>{
                item.remainingTime = this.updateRemainingTime(this.drawDate)
            })
        },1000)
        const element = document.getElementById("showMenu")
        if(element) {
            element.style.display = 'none'
        }
    },
    // metaInfo() {
    //     return {
    //         title: 'Omillionaire Favourite',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Omillionaire Favourite'
    //             }
    //         ]
    //     };
    // },
    watch: {
        currentPastCombinations(val) {
            if(val) {
                this.currentPastCombinations = val;
                this.fetdataListPasrCombinations();
            }
        },
        numberPageWeekly(){
            this.fetchDataWeekly();
        }
    },
    methods: {
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        updateMenu() {
            const element = document.getElementById("showMenu")
            if(element) {
                element.style.display = element.style.display ==='none' ? 'block' : 'none'
            }
            this.isShow = true
        },
        onChangePagination(value) {
            this.currentPastCombinations = value
            this.totalPage = Math.ceil(this.totalPastCombinations / this.perPagePagination)
        },
        updateMenuSelected(type) {
            const element = document.getElementById("showMenu")
            if(element) {
                element.style.display = element.style.display ==='none' ? 'block' : 'none'
            }
            this.isShow = this.isShow ? true : false
            this.menuSelected = type
            if (type === 'past') {
                this.textMenuSelected = 'Past Combinations'
                this.tabs[0].isActive = true
                this.tabs[1].isActive = false
                this.tabs[2].isActive = false
            } else if (type === 'win') {
                this.textMenuSelected = 'winning numbers'
                this.tabs[1].isActive = true
                this.tabs[0].isActive = false
                this.tabs[2].isActive = false
            } else {
                this.textMenuSelected = 'favorite numbers'
                this.tabs[2].isActive = true
                this.tabs[0].isActive = false
                this.tabs[1].isActive = false
            }
        },
        handleChangeTab(index) {
            if (index === 0) {
                this.menuSelected = 'past'
            } else if (index === 1) {
                this.menuSelected = 'win'
            } else {
                this.menuSelected = 'fav'
            }
        },
        handleQuickPick(number){
            this.$router.push({name:'Tickets'});
            localStorage.setItem('number-quick-pick', JSON.stringify(number));
        },
        async fetchDataValidateBall() {
            try {
                const resp = await toolService.getValidateTicket();

                this.listNumber = new Array(resp.quatityNumber);

                this.maxiumBlue = resp.maxiumBlue;
                this.maxiumGreen = resp.maxiumGreen;
                this.minimumBall = resp.minMaxTickets[0];
            } catch (err) {
                console.log(err, "err");
            }
        },

        async fetdataListFavourite() {
            try {
                let info = getUserInfo() ? JSON.parse(getUserInfo()) : "";

                let param = {
                    user_id: info?.id,
                };
                this.userInfo = info
                const resp = await FavouriteService.getList(param);

                if (resp.status === 200 && !isArrayEmpty(resp.data)) {
                    let listFavourite = resp.data.map((item) => {
                        let balls = !isArrayEmpty(item.favourite_number[0])
                            ? item.favourite_number[0].map((number) =>
                                  parseInt(number)
                              )
                            : [];

                        return {
                            id: item.id,
                            title: item.favourite_name,
                            balls,
                            ballsFinal: []
                        };
                    });
                    listFavourite = listFavourite.sort(
                        (a, b) => Number(a.id) - Number(b.id)
                    );
                    let user_info = JSON.parse(localStorage.getItem("user_info"));
                    this.userInfo = user_info
                    this.listFavourite = listFavourite;
                }
            } catch (err) {
                console.log(err, "err");
            }
        },

        async reGenCerticate(data) {
            data.isLoadingDownLoad = true
            try {
                const res = await FavouriteService.reGenCerticate(data.raffle_id)
                if(res.status === 200) {
                    window.open(res.data, "_blank")
                    setTimeout(() => {
                        data.isLoadingDownLoad = false
                    }, 2000);
                    this.fetdataListPasrCombinations()
                    data.isShowButton = true
                    data.link_certificate = res.data
                    data.isLoadingDownLoad = false
                } else {
                    data.isLoadingDownLoad = false
                    let message = ''
                    !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                    })
                    : (message = res.message);
                    this.showToast(this.getTranslatedText(message), "errorGenC")
                }
            } catch (error) {
                data.isLoadingDownLoad = false
                console.log(error)
            }
        },

        async fetdataListPasrCombinations() {
            try {
                const resp = await FavouriteService.getListHistory(this.currentPastCombinations, this.perPagePagination);
                if (resp.status === 200 && !isArrayEmpty(resp.data)) {
                    let locale = this.$store.state.isRtl ? "ar" : "en";
                    moment.locale(locale)

                    let listPastCombinations = resp.data.map((item) => {
                        let balls = [];
                        if (!isArrayEmpty(item.bets.bets)) {
                            item.bets.bets.map((x) => {
                                balls = balls.concat(x);
                            });
                        }

                        let date = moment(item.draw_date).format("DD/MM/YYYY");

                        return {
                            id: item.id,
                            date,
                            reference: item.reference,
                            raffle_id: item.raffle_id,
                            link_certificate: item.link_certificate,
                            balls,
                            ballsFinal: [],
                            double_jackpot: item.bets.double_jackpot,
                            secure_jackpot: item.bets.secure_jackpot,
                            isLoadingDownLoad: false
                        };
                    });
                    this.pageCountPastCombinations = this.currentPastCombinations * this.perPagePagination;
                    if(this.pageCountPastCombinations > resp.total) {
                        this.pageCountPastCombinations = resp.total;
                    }

                    this.totalPastCombinations = resp.total
                    this.listPastCombinations = listPastCombinations;
                }
            } catch (err) {
                console.log(err, "err");
            }
        },

        onClickAddFavourite() {
            this.isShow = false
            this.step = 2;
        },

        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },

        // action
        onClickEditBall(index) {
            this.isShow = false
            this.dataDetail = { ...this.listFavourite[index] };
            this.indexEdit = index;
            this.step = 3;
        },

        // remove

        onClickConfirmModal(index) {
            this.indexEdit = index;
            this.$bvModal.show("modal-confirm-remove-favourite");
        },

        onCancelRemove() {
            this.$bvModal.hide("modal-confirm-remove-favourite");
            this.indexEdit = -1;
        },

        onClickConfirmModalWeekly(id, index, modal) {
            this.idWeekly = id;
            this.indexWeekly = index;
            this.$bvModal.show(modal);
        },
        onClickCancelModalWeekly(modal) {
            this.idWeekly = -1;
            this.indexWeekly = -1;
            this.$bvModal.hide(modal);
        },
        async handleSkipAWeek() {
            try{
                this.isLoadingButtonConfirmSkipAWeek = true;
                this.dataWeekly[this.indexWeekly].isLoadingSkipAWeek = true;
                const res = await FavouriteService.skipAWeek(this.idWeekly);
                if(res.status === 200) {
                    this.showToast(this.getTranslatedText(res.message), "success");
                    await this.fetchDataWeekly(true);
                    this.$bvModal.hide("modal-confirm-skip-a-week-favourite");
                }else{
                    this.showToast(this.getTranslatedText(res.message), "error")
                    this.dataWeekly[this.indexWeekly].isLoadingSkipAWeek = false;
                }
                this.isLoadingButtonConfirmSkipAWeek = false;
            }catch(error) {
                console.log(error)
                this.dataWeekly[this.indexWeekly].isLoadingSkipAWeek = false;
                this.isLoadingButtonConfirmSkipAWeek = false;
            }
        },

        async onClickDeleteBall() {
            this.isLoadingButtonConfirm = true;
            if (this.indexEdit !== -1) {
                let param = {
                    id: this.listFavourite[this.indexEdit].id,
                };
                try {
                    const resp = await FavouriteService.remove(param);
                    if (resp.status === 200) {
                        this.listFavourite.splice(this.indexEdit, 1);
                        this.isLoadingButtonConfirm = false;
                        this.$bvModal.hide("modal-confirm-remove-favourite");
                        this.showToast(
                            this.getTranslatedText(resp.message),
                            "success"
                        );
                        if (this.step !== 1) {
                            this.step = 1
                            this.textMenuSelected = 'favorite numbers'
                            this.tabs[2].isActive = true
                            this.tabs[0].isActive = false
                            this.tabs[1].isActive = false
                        }
                    }
                } catch (err) {
                    console.log(err, "err");
                }
            }
        },

        onGoBack() {
            this.step = 1;
            this.dataDetail = null;
        },

        // sheet bottom history number
        onClickIconArrow(item) {
            this.raffle_id = !isStrEmpty(item.link_certificate) ? true : false;
            item['isShowButton'] = !isStrEmpty(item.link_certificate) ? true : false
            this.dataDetail = { ...item };
            this.$refs.bottomHistoryNumber?.open();
        },

        handleDownload() {
            this.isLoadingButton = true
            window.open(this.dataDetail.link_certificate, "_blank")

            setTimeout(() => {
                this.isLoadingButton = false
            }, 2000);
        },
        // action step add
        onSubmitAdd(item) {
            let payload = { ...item };
            delete payload.message;
            this.listFavourite.push({ ...payload });
            this.step = 1;
            this.menuSelected = 'fav';
            this.textMenuSelected = 'favorite numbers'
            this.tabs[2].isActive = true
            this.tabs[0].isActive = false
            this.tabs[1].isActive = false
            this.showToast(this.getTranslatedText(item.message), "success");
        },

        // action step edit
        onSubmitEdit(item) {
            if (this.indexEdit !== -1) {
                let payload = { ...item };
                delete payload.message;

                this.listFavourite[this.indexEdit] = payload;
                this.indexEdit = -1;
                this.dataDetail = null;
                this.step = 1;
                this.menuSelected = 'fav';
                this.textMenuSelected = 'favorite numbers'
                this.tabs[2].isActive = true
                this.tabs[0].isActive = false
                this.tabs[1].isActive = false
                this.showToast(this.getTranslatedText(item.message), "success");
            }
        },

        renderClass(number, index) {
            return renderClassBall(
                number,
                index,
                this.minimumBall,
                this.maxiumBlue,
                this.maxiumGreen
            );
        },

        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },

          renderNumberLocale(number) {
            return renderLocaleNumber(number);
        },

        //  async downloadFileSendToLink(code, type) {
        //     try {
        //         this.isLoadingButton = true
        //         const resp = type === 'email' ? await FavouriteService.downloadCertificateSendToMail(code) : await FavouriteService.downloadCertificateSendToPhone(code)
        //         if(resp.status === 200) {
        //             this.showToast(this.getTranslatedText(resp.message), "success")
        //             this.isLoadingButton = false
        //         } else {
        //             this.showToast(this.getTranslatedText(resp.message), "error")
        //             this.isLoadingButton = false
        //         }
        //     } catch (error) {
        //         this.isLoadingButton = false
        //         console.log(error)
        //     }
        // },
        showModalConfirm(data, id, type, val) {
            this.typeSubs = type
            this.dataEachSubs = data
            this.valueSubs = val?.target?.value
            this.$bvModal.show(id);
        },
        hideModalConfirm(id) {
            if(id === 'modal-confirm-change-subs') {
                this.dataWeekly.map((item, index) => {
                    if (item.id === this.dataEachSubs.id) {
                        item.recurring_type = this.dataEachSubs.recurring_type === "2" ? "1" : "2"
                    }
                })
            }
            this.$bvModal.hide(id);
        },
        onCheckSubscription(isPause ,data, id) {
            if(isPause === false && data.has_debuct_money) {
                return
            } else {
                this.showModalConfirm(data, id)
                this.isPauseSubs = isPause
                this.dataEachSubs = data
            }

        },
        async onPauseSubscription() {
            this.isLoadingButtonPause = true
            this.isLoadingButtonUnPause = true
            try {
                const res = await FavouriteService.pauseSubscription(this.isPauseSubs, this.dataEachSubs.id);
                if(res.status === 200) {
                    if (this.isPauseSubs) {
                        this.dataEachSubs.is_pause = true
                        this.$bvModal.hide('modal-confirm-pause-subs');
                    } else {
                        this.dataEachSubs.is_pause = false
                        this.$bvModal.hide('modal-confirm-unpause-subs');
                    }
                    this.showToast(this.getTranslatedText(res.message), "success")
                    this.fetchDataWeekly()
                    this.isLoadingButtonPause = false
                    this.isLoadingButtonUnPause = false
                } else {
                    this.showToast(this.getTranslatedText(res.message), "error")
                    this.isLoadingButtonPause = false
                    this.isLoadingButtonUnPause = false
                }
            } catch(error) {
                this.isLoadingButtonPause = false
                this.isLoadingButtonUnPause = false
                console.log(error);
            }
        },
        async onDeleteSubscription() {
            this.isLoadingButtonDelete = true
            try {
                const res = await FavouriteService.deleteSubscription(this.subscribedType, this.dataEachSubs.id);
                if(res.status === 200) {
                    this.fetchDataWeekly()
                    this.isLoadingButtonDelete = false
                    this.$bvModal.hide('modal-confirm-delete-subs')
                    this.showToast(this.getTranslatedText(res.message), "success")
                } else {
                    this.isLoadingButtonDelete = false
                    this.showToast(this.getTranslatedText(res.message), "error")
                }
            } catch(error) {
                this.isLoadingButtonDelete = false
                console.log(error);
            }
        },
        async onResubscribing() {
            this.isLoadingButtonResubs = true
            try {
                const res = await FavouriteService.resubscribing(this.typeSubs, this.dataEachSubs.id);
                if(res.status === 200) {
                    this.fetchDataWeekly()
                    this.$bvModal.hide('modal-confirm-resubscribe-subs')
                    this.isLoadingButtonResubs = false
                    this.showToast(this.getTranslatedText(res.message), "success")
                } else {
                    this.isLoadingButtonResubs = false
                    this.showToast(this.getTranslatedText(res.message), "error")
                }
            } catch(error) {
                this.isLoadingButtonResubs = false
                console.log(error);
            }
        },
        async onCancelRecurring() {
            this.isLoadingButtonCancel = true
            try {
                const res = await FavouriteService.unSubWeekly(this.dataEachSubs.id);
                if(res.status === 200) {
                    this.isResubscribing = true
                    this.fetchDataWeekly()
                    this.$bvModal.hide('modal-confirm-cancel-subs')
                    this.isLoadingButtonCancel = false
                    this.showToast(this.getTranslatedText(res.message), "success")
                } else {
                    this.isResubscribing = false
                    this.isLoadingButtonCancel = false
                    this.showToast(this.getTranslatedText(res.message), "error")
                }
            } catch(error) {
                this.isLoadingButtonCancel = false
                console.log(error);
            }
        },
        async onChangeRecurringType() {
            this.isLoadingButtonChange = true
            try {
                const res = await FavouriteService.changeRecurringType(this.valueSubs, this.dataEachSubs.id);
                if(res.status === 200) {
                    this.subscribedType = this.valueSubs
                    this.isLoadingButtonChange = false
                    this.$bvModal.hide('modal-confirm-change-subs')
                    this.showToast(this.getTranslatedText(res.message), "success")
                } else {
                    this.subscribedType = this.valueSubs
                    this.isLoadingButtonChange = false
                    this.showToast(this.getTranslatedText(res.message), "error")
                }
            } catch(error) {
                this.isLoadingButtonChange = false
                console.log(error);
            }
        },
        async fetchDataWeekly(callUnSubWeekly){
            try{
                if(!callUnSubWeekly){
                    this.isLoaddingPage = true;
                }
                const res = await FavouriteService.getListWeekly(this.numberPageWeekly, this.perPageWeekly);
                if(res.status === 200) {
                    if(res.data.length < 1 && this.numberPageWeekly > 1 ){
                        this.numberPageWeekly -= 1;
                        return false;
                    }
                    res.data.forEach((item) =>{
                        item.remainingTime = this.updateRemainingTime(this.drawDate);
                        item.isLoadingUnSub = false
                        item.isLoadingSkipAWeek = false
                    })
                    this.dataWeekly = res.data;
                    this.totalItemWeekly = res.total;
                    this.pageCountWeekly = this.numberPageWeekly * this.perPageWeekly;
                    if(this.pageCountWeekly > res.total) {
                        this.pageCountWeekly = res.total;
                    }
                }else{
                    this.showToast(this.getTranslatedText(res.message), "error")
                }
                this.isLoaddingPage = false;
            }catch(error) {
                console.log(error);
                this.isLoaddingPage = false;
            }
        },
        async handleUnsubscribe(){
            try{
                this.isLoadingButtonConfirmUnsubcribe = true;
                this.dataWeekly[this.indexWeekly].isLoadingUnSub = true;
                const res = await FavouriteService.unSubWeekly(this.idWeekly);
                if(res.status === 200) {
                    this.showToast(this.getTranslatedText(res.message), "success");
                    await this.fetchDataWeekly(true);
                    this.$bvModal.hide("modal-confirm-unsubcribe-favourite");
                }else{
                    this.showToast(this.getTranslatedText(res.message), "error");
                    this.dataWeekly[this.indexWeekly].isLoadingUnSub = false;
                }
                this.isLoadingButtonConfirmUnsubcribe = false;
            }catch(error) {
                console.log(error)
                this.dataWeekly[this.indexWeekly].isLoadingUnSub = false;
                this.isLoadingButtonConfirmUnsubcribe = false;
            }
        },
        formatDate(date){
            let result = moment(date).format("DD/MM/YYYY");
            return result;
        },
        updateRemainingTime(targetTime) {
            const dateObject = new Date(targetTime).getTime();
            const currentTime = new Date().getTime();
            const timeDifference = dateObject - currentTime;

            if (timeDifference <= 0) {
                return `0 ${this.getTranslatedText('Seconds Remaining')}`;
            }

            const oneDay = 24 * 60 * 60 * 1000;
            const oneHour = 60 * 60 * 1000;
            const oneMinute = 60 * 1000;

            if (timeDifference >= oneDay) {
                return `${Math.floor(timeDifference / oneDay)} ${this.getTranslatedText('Days Remaining')}`;
            } else if (timeDifference >= oneHour) {
                return `${Math.floor(timeDifference / oneHour)} ${this.getTranslatedText('Hours Remaining')}`;
            } else if (timeDifference >= oneMinute) {
                return `${Math.floor(timeDifference / oneMinute)} ${this.getTranslatedText('Minutes Remaining')}`;
            } else {
                return `${Math.floor(timeDifference / 1000)} ${this.getTranslatedText('Seconds Remaining')}`;
            }
        },
          handleFavorite(state, number) {
            if(state){
                this.$refs.bottomFavorite?.open();
                this.dataFavorite.number = number;
            }else{
                this.$refs.bottomFavorite?.close();
            }

        },
        updateDataFavorite(item) {
            let { value, name } = item;
            if (
                name === "name" &&
                this.dataFavorite.formItem[0].state === false
            ) {
                this.dataFavorite.formItem[0].state = true;
            }
            this.dataFavorite[name] = value;
        },
        async onFinishFavorite(){
            this.isLoadingBtn = true;
            if (this.dataFavorite.name === "") {
                this.dataFavorite.formItem[0].state = false;
                this.isLoadingBtn = false;
                return false;
            }
            if (!this.$store.state.isLoggedIn) {
                this.showToast(
                    this.getTranslatedText("Please Log In"),
                    "error"
                );

                this.$router.push("/login");
                this.isLoadingBtn = false;
                return false;
            }
            try {
                let user_info = JSON.parse(localStorage.getItem("user_info"));

                let param = {
                    user_id: user_info.id,
                    favourite_name: this.dataFavorite.name,
                    favourite_number: this.dataFavorite.number,
                    bonus_ball: [],
                };

                const resp = await gameService.addFavourite(param);

                if (resp.status === 200) {
                    this.dataFavorite.name = "";
                    this.dataFavorite.formItem[0].state = true;
                    this.$refs.bottomFavorite?.close();
                    this.isLoadingBtn = false;
                    this.showToast(
                        this.getTranslatedText("Number market as Favorite"),
                        "success"
                    );
                    this.fetdataListFavourite();
                } else {
                    this.isLoadingBtn = false;
                    if (!isArrayEmpty(resp.errors)) {
                        resp.errors.map((x) => {
                            this.showToast(
                                this.getTranslatedText(x.message),
                                "error"
                            );
                        });
                    } else {
                        this.showToast(
                            this.getTranslatedText(resp.message),
                            "error"
                        );
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/favourite/_favourite.scss"
/>
<style scoped lang="scss" src="@/assets/scss/mobile/tickets/_tickets.scss" />
