import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;
const GAME_API_URL = process.env.VUE_APP_GAME_API_URL;
const GAME_ACCOUNT = process.env.VUE_APP_GAME_ACCOUNT;

class DrawsService extends BaseService {
    listDraws(perPage, page) {
        return this.get(API_URL + `/api/event/list-draws?per_page=${perPage}&page=${page}`).then((response) => {
            return response.data;
        });
    }

    listWinners(eventId) {
        return this.get(API_URL + `/api/event/raffle-winners?event_id=${eventId}`).then((response) => {
            return response.data;
        });
    }

    listPastDraws() {
        return this.post(GAME_API_URL + '/fixed-standard-lotto/results', {account: GAME_ACCOUNT}).then((response) => {
            return response.data;
        });
    }
}

export default new DrawsService();
