import BaseService from "@/services/base.service";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

interface GetListInterFace {
    user_id: string;
}

interface AddInterFace {
    user_id: string;
    favourite_name: string;
    favourite_number: any[];
    bonus_ball: any[];
}

interface UpdateInterFace {
    id: string;
    favourite_name: string;
    favourite_number: any[];
    bonus_ball: any[];
}

interface DeleteInterface {
    id: string;
}

class FavouriteService extends BaseService {
    getList(param: GetListInterFace) {
        return this.post(
            API_URL + "/api/user/list-favourite-number",
            param
        ).then((response) => {
            return response.data;
        });
    }

    add(param: AddInterFace) {
        return this.post(API_URL + "/api/user/favourite-number", param).then(
            (response) => {
                return response.data;
            }
        );
    }

    update(param: UpdateInterFace) {
        return this.post(
            API_URL + "/api/user/update-favourite-number",
            param
        ).then((response) => {
            return response.data;
        });
    }

    remove(param: DeleteInterface) {
        return this.post(API_URL + "/api/user/delete-favourite", param).then(
            (response) => {
                return response.data;
            }
        );
    }

    getListHistory(page, per_page) {
        const locale = localStorage.getItem("locale");
        return this.get(API_URL + `/api/user/history-bet?page=${page}&locale=${locale}&per_page=${per_page}`).then((response) => {
            return response.data;
        });
    }

    downloadCertificate(id, lang) {
        const token = localStorage.getItem("access_token");
        return axios
            .get(API_URL + `/api/download/certificate/${id}`, {
                headers: { lang: lang, Authorization: token },
            })
            .then((response) => {
                return response.data;
            });
    }

    downloadCertificateSendToMail(code) {
        const locale = localStorage.getItem("locale");
        let param = {
            locale,
            code
        }
        return this.post(API_URL + "/api/download/send-to-mail", param).then(
            (response) => {
                return response.data;
            }
        );
    }

    downloadCertificateSendToPhone(code) {
        const locale = localStorage.getItem("locale");
        let param = {
            locale,
            code
        }
        return this.post(API_URL + "/api/download/send-to-phone", param).then(
            (response) => {
                return response.data;
            }
        );
    }

    reGenCerticate(code) {
        return this.post(API_URL + "/api/download/re-generate-certificate", {code}).then(
            (response) => {
                return response.data;
            }
        );
    }

    getListWeekly(page=1, perPage=10) {
        return this.get(API_URL + `/api/recurring/list/transaction-bet?per_page=${perPage}&page=${page}`).then((response) => {
            return response.data;
        });
    }

    unSubWeekly(transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/cancel`, {transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }

    skipAWeek(transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/skip`, {transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }

    changeRecurringType(recurring_type: number, transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/changeRecurringType`, {recurring_type, transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }

    resubscribing(recurring_type: number, transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/resubscribing`, {recurring_type, transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }

    deleteSubscription(recurring_type: number, transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/delete`, {recurring_type, transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }

    pauseSubscription(is_pause: boolean, transaction_recurring_id: number) {
        return this.post(API_URL + `/api/recurring/pause`, {is_pause, transaction_recurring_id}).then((response) => {
            return response.data;
        });
    }
}

export default new FavouriteService();
