<template>
    <div class="news container">
        <div class="news__container">
            <b-table striped :fields="fields" :items="items">
                <template #cell(title)="row">
                    <a :href="row.item.link" target="_blank">
                        {{ row.item.title }}
                    </a>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script>
import languageMixin from '@/mixins/language/language-mixin';
import moment from "moment";
export default {
    mixins: [languageMixin],
    components: {
    },
    data() {
        return {
            fields: [
                {
                    // A regular column with custom formatter
                    key: 'date',
                    label: this.getTranslatedText("date")
                },
                {
                    key: 'title',
                    label: this.getTranslatedText("Title")
                },
            ],
            items: [
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate at omillionaire.com and win upto OMR 5 Million',
                    link: "https://www.eyeofriyadh.com/news/details/buy-your-green-certifiicate-at-omillionaire-com-and-win-upto-omr-5-million"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate at omillionaire.com and win upto OMR 5mln',
                    link: "https://www.zawya.com/en/press-release/companies-news/buy-your-green-certifiicate-at-omillionairecom-and-win-upto-omr-5mln-eb2el8t8"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate at omillionaire.com and win upto OMR 5 Million',
                    link: "https://www.thearabianstories.com/2022/05/17/buy-your-green-certificate-at-omillionaire-com-and-win-upto-omr-5-million/"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate at omillionaire and win upto OMR 5 Million',
                    link: "https://menafn.com/1104228620/Buy-your-green-certifiicate-at-omillionaire-and-win-upto-OMR-5-Million"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate at omillionaire and win upto OMR 5 Million',
                    link: "https://ww.muscatdaily.com/2022/05/17/buy-your-green-certificate-and-win-upto-or5mn/"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certifiicate at omillionaire.com and win upto OMR 5 Million',
                    link: "https://www.eyeofdubai.ae/news/details/buy-your-green-certifiicate-at-omillionaire-com-and-win-upto-omr-5-million"
                },
                {
                    date: this.formatDateString("05/17/2022"),
                    title: 'Buy your green certificate and win upto OR5mn',
                    link: "https://twitter.com/muscat_daily/status/1526586049186746372?t=1tX5ocpNASMtTjOIr0aYbQ&s=08"
                },
                {
                    date: this.formatDateString("4/5/2022"),
                    title: 'Environmental Initiative O! Millionaire’s Green Certificate Can Win Participants Up To RO5M',
                    link: "https://www.oerlive.com/oman/environmental-initiative-o-millionaires-green-certificate-can-win-participants-up-to-ro5m/"
                },
                {
                    date: this.formatDateString("4/3/2022"),
                    title: 'Environmental initiative O! Millionaire’s green certificate can win participants up to OMR 5 million',
                    link: "https://menafn.com/1103955303/Environmental-initiative-O-Millionaires-green-certificate-can-win-participants-up-to-OMR-5-million"
                },
                {
                    date: this.formatDateString("3/31/2022"),
                    title: 'Environmental initiative O! Millionaire’s green certificate can win participants up to OMR 5 million',
                    link: "https://www.thearabianstories.com/2022/03/31/environmental-initiative-o-millionaires-green-certificate-can-win-participants-up-to-omr-5-million/"
                },
                {
                    date: this.formatDateString("3/31/2022"),
                    title: 'Green Millionaire Certificates provide great opportunities to win prizes of up to 5 million OMR',
                    link: "https://www.alamaliktisaad.com/%D8%B4%D9%87%D8%A7%D8%AF%D8%A7%D8%AA-%D8%A7%D9%84%D9%85%D9%84%D9%8A%D9%88%D9%86%D9%8A%D8%B1-%D8%A7%D9%84%D8%AE%D8%B6%D8%B1%D8%A7%D8%A1-%D8%AA%D9%82%D8%AF%D9%85-%D9%81%D8%B1%D8%B5%D8%A7%D9%8B-%D8%B1/"
                },
            ]
        };
    },
    // metaInfo() {
    //     return {
    //         title: 'Media Center: Revisit Winning Moments, Press Coverage, and What’s Happening with O! Millionaire Today',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'The O! Millionaire Media Center gives participants and our winners a platform to share their stories and plans in celebrating their winnings with their family and friends and their journey toward saving the planet.'
    //             }
    //         ]
    //     };
    // },
    methods: {
        formatDateString(date) {
            let locale = this.$store.state.isRtl ? "ar" : "en";
            moment.locale(locale);
            return moment(new Date(date)).format('dddd, DD-MM-YYYY');
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/news.scss" />
