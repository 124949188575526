<template>
    <div class="signup-buttons">
        <!-- <div id="fb-root"></div> -->
        <button class="button-login google-signup" @click.prevent="loginWithGoogle" :disabled="isLoadingButton">
          <b-spinner v-if="isLoadingButton" />
          
          <img loading="lazy" v-if="isShowIconCommon" class="flag-ar" src="@/assets/images/common/icon-google.png"/>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true"><title>Google</title><g fill="none" fill-rule="evenodd"><path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path><path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path><path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path><path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path></g></svg>
              {{ getTranslatedText(textButton) }}
        </button>
        <div v-if="isShowFbSocial">
          <button href="#" class="facebook-signup" @click.prevent="loginWithFacebook" :disabled="isLoadingButton">
          <b-spinner v-if="isLoadingButton" />
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0001 1.09961C-2.13423 1.51361 -3.57543 21.3467 10.2097 23.8996L10.2095 15.8848H7.30017V12.5692H10.2097V10.0425C10.2908 5.41625 14.0084 5.24657 17.103 5.80409V8.62673H15.6579C14.2345 8.62673 13.7905 9.51089 13.7905 10.4178V12.5694H16.9686L16.4605 15.8848H13.7905V23.8996C27.5787 21.3443 26.1311 1.51193 12.0001 1.09961Z" fill="#0A2AD2"/>
          </svg>
          {{ getTranslatedText(textButtonFB) }}
          </button>
        </div>
    </div>
</template>

<script>
import languageMixin from "@/mixins/language/language-mixin";
import { redirectPathApp } from "@/components/common/utils";

export default {
  mixins: [languageMixin],
  name: 'login_signup_social', 
  props: {
      isLoadingButton: {
          type: Boolean,
          default: false,
      },
      isShowIconCommon: {
          type: Boolean,
          default: false,
      },
      textButton: {
        type: String,
        default: 'login with google'
      },
      textButtonFB: {
        type: String,
        default: 'Continue with Facebook'
      },
      isShowFbSocial: {
        type: Boolean,
        default: false,
      }
  },
  methods: {
    loginWithGoogle () {
      if (this.$store.state.is_app) {
        redirectPathApp('/login');
      }
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // on success do something
          let getAuthResponse = GoogleUser.getAuthResponse()
          // console.log('GoogleUser', GoogleUser)
          // console.log('getId', GoogleUser.getId())
          // console.log('basicprofile', GoogleUser.getBasicProfile().getName())
          // console.log('getBasicProfile', GoogleUser.getBasicProfile())
          // console.log('getAuthResponse', GoogleUser.getAuthResponse())
          let param = {
            idToken: getAuthResponse.id_token,
            type: 'google'
          }
          this.$emit('onLogin', param)
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    loginWithFacebook () {
      window.FB.login(response => {
        FB.getLoginStatus(({ authResponse }) => {
              if (authResponse) {
                let param = {
                  idToken: authResponse.accessToken,
                  type: 'facebook'
                }
                this.$emit('onLogin', param)
              }
        });
      })
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/__socialLogin.scss" />