<template>
    <div :class="`bottom-sheet ${classBody}`">
        <h2 v-if="title" class="title t-transform-cap">
            {{ getTranslatedText(title) }}
        </h2>
        <p v-if="description" class="description">
            {{ getTranslatedText(description) }}
        </p>
        <slot name="body-sheet" />
        <ButtonCustom
            v-if="!isHiddenBtn"
            :isLoading="isLoadingButton"
            :classBtn="classBtn"
            :typeBtn="typeBtn"
            :type="type"
            :text="text"
            :icon="icon"
            :isDownload="isDownload"
            @onClickButton="onClickButton"
        />
    </div>
</template>
<script>
import ButtonCustom from "@/components/common/button";
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    components: {
        ButtonCustom,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },

        type: {
            type: String,
            default: "",
        },
        typeBtn: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: null,
        },
        classBtn: {
            type: String,
            default: "",
        },
        isHiddenBtn: {
            type: Boolean,
            default: false,
        },
        classBody: {
            type: String,
            default: "",
        },
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClickButton() {
            this.$emit("onClickSheet");
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/bottom-sheet/bottom-sheet.scss"
/>
