<template>
    <div class="section-action-number-favourite">
        <div class="section-action-number-favourite__title">
          {{ getTranslatedText('Add Your Favorite Number') }}
        </div>
        <div class="section-action-number-favourite__contents-main">
            <div class="content">
                <p class="title-fav">{{getTranslatedText('item')}} {{`${listFavourite.length + 1}`}}</p>
                <FormCommon
                    :formItem="formItem"
                    :dataForm="dataForm"
                    :class-container="'section-action-number-favourite__form'"
                    @update-form-data="updateFormData"
                    @onSubmitForm="onSubmitForm"
                />

                <QuickPick
                    :listNumber="listNumber"
                    :numbersChecked="listBlockTickets[0].numbersChecked"
                    :showTextSelected="false"
                    :indexTicket="0"
                    :minimumBalls="minimum"
                    :maxiumBlue="maxiumBlue"
                    :maxiumGreen="maxiumGreen"
                    :showBtnRandom="false"
                    :showLine="true"
                    class="section-action-number-favourite__quick-pick"
                    @handleRandomNumbers="handleRandomNumbers"
                    @handlePickNumber="handlePickNumber"
                >
                </QuickPick>
            </div>
            <div class="section-edit-number" id="section-edit-number">
                <button
                    @click="onSubmitForm"
                    :disabled="isLoadingButton"
                    class="section-edit-number__btn-right t-transform-cap"
                >
                    <b-spinner v-if="isLoadingButton" />
                    {{ getTranslatedText("confirm") }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserInfo } from "@/services/axios";

import favouriteService from "@/services/favourite.service";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import QuickPick from "@/components/common/quick-pick/QuickPick.vue";

import quickPickMixin from "@/mixins/quick-pick/quick-pick-mixin";
import LanguageMixin from "@/mixins/language/language-mixin";
import { formatNumberMoney, isStrEmpty } from "@/components/common/utils";
const IS_SHOW_CONSENT = process.env.VUE_APP_SHOW_CONSENT;

export default {
    mixins: [quickPickMixin, LanguageMixin],
    components: {
        FormCommon,
        ButtonCustom,
        QuickPick,
    },
    props: {
        listFavourite: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            toastData: {
                message: "",
                type: "success",
            },
            isShowConsent: IS_SHOW_CONSENT,
            isLoadingButton: false,
            dataForm: {
                name: "",
            },
            formItem: [
                {
                    type: "input",
                    typeInput: "text",
                    placeholder: "Name",
                    hideIconRq: true,
                    name: "name",
                    required: true,
                    label: "",
                    for: "input-name",
                    id: "input-name",
                },
            ],
        };
    },

    mounted() {
        let elm = document.getElementById("section-edit-number")
        if(!localStorage.getItem('cookieConsent') && this.isShowConsent=== 'yes') {
            elm.style.bottom = "200px"
        } else {
            elm.style.bottom = "0"
        }
        const element = document.getElementById("showMenu")
        if(element) {
            element.style.display = 'none'
        }
    },

    methods: {
        onClickConfirmModal() {
            this.indexTicket = 0;
            this.$bvModal.show("modal-confirm-remove-ticket");
        },
        async onSubmitForm() {
            this.isLoadingButton = true;
            let payload = {
                title: this.dataForm.name || "",
                balls: this.listBlockTickets[0].numbersChecked,
                ballsFinal: []
            };

            if (isStrEmpty(payload.title)) {
                this.$emit(
                    "showToast",
                    `${this.getTranslatedText(
                        "The favourite name is required"
                    )}`,
                    "error"
                );
                this.isLoadingButton = false;

                return false;
            }

            if (payload.balls.length < this.minimum) {
                this.$emit(
                    "showToast",
                    this.getTranslatedText( `please choose at least [minium]`, {
                            minium: this.minimum
                        }
                    ),
                    "error"
                );
                this.isLoadingButton = false;
                return false;
            }

            // if()

            try {
                let info = getUserInfo() ? JSON.parse(getUserInfo()) : "";

                let param = {
                    user_id: info?.id,
                    favourite_name: payload.title,
                    favourite_number: payload.balls,
                    bonus_ball: [],
                };
                const resp = await favouriteService.add(param);
                if (resp.status === 200) {
                    this.isLoadingButton = false;
                    payload.message =
                        resp?.message || "Favourite number is saved";
                    payload.id = resp?.data.id || "";
                    this.listBlockTickets[0].numbersChecked = [];
                    localStorage.setItem("list_block", JSON.stringify(this.listBlockTickets));
                    this.$emit("onSubmit", payload);
                } else {
                    if (resp.errors) {
                        resp.errors.map((message) => {
                            return this.$emit(
                                "showToast",
                                this.getTranslatedText(message.message),
                                "error"
                            );
                        });
                    } else {
                        this.$emit(
                            "showToast",
                            this.getTranslatedText(resp.message),
                            "error"
                        );
                    }
                    this.isLoadingButton = false;
                }
            } catch (err) {
                console.log(err, "err");
            }
        },

        updateFormData(event) {
            let { value } = event;
            this.dataForm.name = value;
        },

        formatNumberWinners(price) {
            return formatNumberMoney(Number(price));
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/favourite/_favourite.scss"
/>
