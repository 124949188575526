import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class WinnersService extends BaseService {
    winnersList(data) {
        return this.post(API_URL + "/api/common/public-winners", data).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new WinnersService();
