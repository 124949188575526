<template>
    <div class="loading">
        <div class="main">
            <img loading="lazy" src="@/assets/images/common/logo-loading.svg"/>
            <div class="content">
                <span></span>
            </div>
        </div>
    </div>
</template>
<script>

export default {};
</script>
<style scoped lang="scss" src="@/assets/scss/common/loading/_loading.scss" />
