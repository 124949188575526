<template>
    <b-modal
        :id="id"
        modal-class="modal-fullscreen"
        style="max-width: 100% !important"
        centered
        header-class="modal-header"
        hide-footer
        hide-header
        @show="resetModal"
        @hidden="resetModal"
    >
        <template #default="">
            <div class="banner-modal">
                <div class="close-btn" @click="resetModal"><img loading="lazy" src="@/assets/images/common/close-black.svg"/></div>
                <img class="img-dt" loading="lazy" src="@/assets/images/common/banner.webp"/>
                <img class="img-mb" loading="lazy" src="@/assets/images/common/banner-mb.webp"/>
            </div>
        </template>
    </b-modal>
</template>

<script>
import ButtonCustom from "@/components/common/button";
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    components: { ButtonCustom },
    props: {
        id: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {
        resetModal() {
            this.$bvModal.hide(this.id);
        }
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/banner-popup/_banner-popup.scss" />
