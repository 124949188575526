<template>
    <div class="section-otp">
        <div class="section-otp__body">
            <!-- <img loading="lazy" class="verify__img" src="@/assets/images/common/otp.svg" /> -->
            <p class="section-otp__description">
                {{ getTranslatedText('OTP Verification text') }}
            </p>
            <div class="section-otp__text text-phone">{{ getTranslatedText('Please enter the code sent to') }}
                <span>{{ renderMethodOTP() }}. </span>
                <span class="t-transform-cap change" @click="onClickGoBack">{{ getTranslatedText('change') }}</span>
            </div>
            <v-otp-input
                v-model="dataForm.code"
                class="input-otp"
                type="number"
            ></v-otp-input>
            <div class="section-otp__box-btn">
                <ButtonCustom
                    :isLoading="isLoadingButton"
                    text="verify"
                    classBtn="section-otp__box-btn-send"
                    typeBtn="submit"
                    type="primary"
                    @onClickButton="onSubmitForm"
                />
            </div>
            <div class="section-otp__btn-resend">
                <button
                    class="t-transform-cap resend-btn"
                    type="button"
                    :class="isDisableResend ? 'disable-btn' : ''"
                    :disabled="isDisableResend"
                    @click="sendOTP(true)"
                >
                    {{ getTranslatedText("resend") }}
                </button>
                <span class="otp">
                    {{ getTranslatedText("OTP") }}. 
                </span>
                <span class="timer">{{ '(' + renderNumber(timerCount) + getTranslatedText(" sec") + ')' }}</span>
            </div>
        </div>
         <!-- <ConfirmRecaptcha
            id="modalRecaptcha"
            refName="recaptchaForgotPass"
            idRecaptcha="recaptcha-main"
            :sitekey="sitekey"
            :isLoadingButton="isLoadingButtonRecaptcha"
            :messageRecaptcha="messageRecaptcha"
            @onSubmitRecaptcha="sendOTP(true)"
        /> -->
    </div>
</template>
<script>
import "@/assets/scss/common/form/_form.scss";
import "@/assets/scss/mobile/authenticate/_register.scss";

import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import languageMixin from "@/mixins/language/language-mixin";
import { renderLocaleNumber, isStrEmpty } from "@/components/common/utils";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;

export default {
    mixins: [languageMixin],
    components: {
        FormCommon,
        ButtonCustom,
        ConfirmRecaptcha
    },
    props: {
        text: {
            type: String,
            default: "",
        },
        textBtnContinue: {
            type: String,
            default: "set password",
        },
        dataSource: {
            type: Object,
            default() {
                return {};
            },
        },
        isHiddenMethodOtp: {
            type: Boolean,
            default: false,
        },
        isLoadingButton: {
            type: Boolean,
            default: false,
        },
        methodsChangeInfo: {
            type: String,
            default: ''
        },
        isLoadingButtonRecaptcha: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            timerCount: 120,
            isDisableResend: true,
            messageRecaptcha: '',
            sitekey: SITEKEY,
            dataForm: {
                code: "",
            }
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value === 0) {
                    this.isDisableResend = false;
                }
            },
            immediate: true,
        },
    },
    methods: {
        // fetchRecaptcha() {
        //     this.$nextTick(() => {
        //         grecaptcha.ready(() => {
        //             try {
        //                 grecaptcha.render('recaptcha-main', {
        //                     hl: localStorage.getItem("locale") || 'en'
        //                 })
        //             } catch (err) {
        //                 console.log(err)
        //             }
        //             })
        //         }
        //     )
        // },
        showRecaptcha() {
            this.messageRecaptcha = ''
            this.fetchRecaptcha()
            this.$bvModal.show("modalRecaptcha");
        },
        sendOTP(isShowNoti) {
            this.timerCount = 120;
            this.isDisableResend = true;
            this.$emit("sendOTP", isShowNoti);
        },

        onSubmitForm() {
            this.$emit("onSubmitForm", this.dataForm);
        },

        renderMethodOTP() {
            const { email, phone, code, calling_code } = this.dataSource;

            let textMethodRegister = "";
            if (!isStrEmpty(this.methodsChangeInfo) ? this.methodsChangeInfo === 'email' : !isStrEmpty(email)) {
                textMethodRegister = `${email}`
            } else {
                let codeFomat = !isStrEmpty(this.methodsChangeInfo) ? calling_code : code
                textMethodRegister = codeFomat+`${phone}`
            }

            return textMethodRegister;
        },

        onClickGoBack() {
            this.$emit("onClickGoBack");
        },

        renderNumber(number) {
            return renderLocaleNumber(parseInt(number))
        },

        updateFormData(item) {
            let { value, name } = item;
            this.dataForm[name] = value;
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_confirm-otp.scss"
/>
