import { render, staticRenderFns } from "./Inspiring.vue?vue&type=template&id=274faec9&scoped=true&"
import script from "./Inspiring.vue?vue&type=script&lang=js&"
export * from "./Inspiring.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/mobile/inspiring/inspiring.scss?vue&type=style&index=0&id=274faec9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274faec9",
  null
  
)

export default component.exports