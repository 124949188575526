<template>
    <div class="main">
        <div class="loading-full-page" v-if="isLoadingPage">
            <b-card>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card>
        </div>
        <div class="contents-delete" v-else>
            <BannerCustom/>
            <div class="delete_account t-transform-cap">
                <NavBar :userName="userName"/>
                <div class="contents">
                    <div class="menu">
                        <p @click="personalDetail" :class="{ 'active': $route.fullPath === '/personal-detail' }">{{ getTranslatedText('my profile') }}</p>
                        <p @click="changePassword" :class="{ 'active': $route.fullPath === '/change-password' }">{{ getTranslatedText('Change Password') }}</p>
                        <p @click="deleteAccount" :class="{ 'active': $route.fullPath === '/delete-account' }">{{ getTranslatedText('Delete Account') }}</p>
                    </div>
                    <div class="menu-mobile" @click="onShowBottomMenuList">
                        <div>
                            <p>{{ getTranslatedText('delete account') }}</p>
                            <div class="line"></div>
                        </div>
                        <img loading="lazy" src="@/assets/images/navigation/chevron-down.svg"/>
                    </div>
                <div class="delete_account__wrap" v-if="step === 1">
                    <p class="title">{{ getTranslatedText('Delete Account') }}</p>
                    <hr class="line"/>
                    <FormCommon
                        :formItem="formItem"
                        :dataForm="dataForm"
                        @update-form-data="updateFormData"
                        @onToggleShowPassword="onToggleShowPassword"
                        @onSubmitForm="onSubmitForm"
                    >
                        <div class="box-btn delete_account__btn-bottom" slot="button">
                            <ButtonCustom
                                classBtn="register-btn-continue"
                                typeBtn="submit"
                                type="primary"
                                text="save changes"
                                :isDisabled="!dataForm.my_number"
                            />
                        </div>
                    </FormCommon>
                </div>
                </div>
            </div>
        </div>
        <div class="line-bottom"></div>
        <p class="guide">{{ getTranslatedText("fields marked with") }}</p>
        <ConfirmModal
            id="modal-confirm-remove-account"
            :title="
                getTranslatedText('Are you sure you want to delete your account?')
            "
            :description="
                getTranslatedText(
                    'This action cannot be undone. Before proceeding, please be aware that all your data associated with this account will be permanently removed.'
                )
            "
            textBtnCancel="Cancel"
            textBtnDelete="Delete"
            :isLoadingButton="isLoadingButtonConfirm"
            :showBtnCancel="true"
            @onCancelModal="onCancelRemove"
            @onDelete="handleDeleteAccount"
        />
        <Toast :title="toastData.message" :type="toastData.type" />
        <ConfirmRecaptcha
            id="modalRecaptchaAccount"
            idRecaptcha="recaptcha-account"
            :sitekey="sitekey"
            :isLoadingButton="isLoadingBtn"
            @onSubmitRecaptcha="sendOTPForPhone"
        />
        <vue-bottom-sheet max-height="90%" ref="bottomMenuList" max-width="100%">
            <BottomSheet :isHiddenBtn="true">
                <template slot="body-sheet">
                    <ul class="bottom-sheet-list-navigation__menu-list">
                        <li
                            v-for="(item, index) in listMenuNavigationAccount"
                            :key="index"
                            v-show="item.isShow"
                            class="bottom-sheet-list-navigation__menu-item"
                        >
                            <div
                                @click="handleClickRedirect(item)"
                                class="bottom-sheet-list-navigation__menu-item-content t-transform-cap"
                            >
                                <img loading="lazy"
                                    class="user-avatar"
                                    :src="require(`@/assets/images/navigation/${item.icon}`)"
                                    :alt="item.icon"
                                />
                                {{ getTranslatedText(item.title) }}
                            </div>
                        </li>
                    </ul>
                </template>
            </BottomSheet>
        </vue-bottom-sheet>
    </div>
</template>

<script>
import ButtonCustom from "@/components/common/button";
import FormCommon from "@/components/common/form";
import languageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin";
import UserService from "@/services/user.service";
import ConfirmOTP from "@/views/mobile/authenticate/ConfirmOTP";
import AuthService from "@/services/auth.service";
import ConfirmModal from "@/components/common/confirm";
import Toast from "@/components/common/toast/Toast.vue";
import { isArrayEmpty, redirectPathApp } from "@/components/common/utils";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";
import BannerCustom from "@/components/common/banner";
import NavBar from "@/components/common/nav-bar/NavBar";
import BottomSheet from "@/components/common/bottom-sheet";
import { listMenuNavigationAccount } from "@/resources/listNavigationAccount";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;
const RECAPTCHA_TYPE = process.env.VUE_APP_RECAPTCHA_TYPE;

export default {
    mixins: [languageMixin, RouterMixin],
    components: {
        FormCommon,
        ButtonCustom,
        ConfirmOTP,
        ConfirmModal,
        Toast,
        ConfirmRecaptcha,
        BannerCustom,
        NavBar,
        BottomSheet
    },
    data(){
        return{
            toastData: {
                message: "",
                type: "success",
            },
            sitekey: SITEKEY,
            recaptchaType: RECAPTCHA_TYPE,
            messageRecaptcha: '',
            listMenuNavigationAccount,
            step:1,
            isLoadingPage:true,
            isLoadingBtn:false,
            isLoadingButtonOTP:false,
            isLoadingButtonConfirm:false,
            user_id:"",
            userName: null,
            dataForm: {
                my_number:"",
                password: "",
                confirm_password: "",
            },
            dataSource:{
                calling_code:'',
                phone:'',
                code:'',
                email:''
            },
            formItem: [
                {
                    type: "input-group",
                    typeInput: "text",
                    name: "my_number",
                    required: true,
                    label: "my number",
                    for: "input-idUser",
                    id: "input-idUser",
                    state: null,
                    textValid: "",
                    isDisable:true,
                    textInvalid:'You have not updated your phone number. Please update your phone number to proceed.'
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    name: "password",
                    required: true,
                    label: "enter password",
                    for: "input-password",
                    id: "input-password",
                    showIconEye: true,
                    state: null,
                    textInvalid: "please enter the correct password",
                    textValid: "",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    placeholder: "",
                    name: "confirm_password",
                    required: true,
                    label: "confirm password",
                    for: "input-confirmPassword",
                    id: "input-confirmPassword",
                    showIconEye: true,
                    state: null,
                    textInvalid: "passwords are not the same",
                    textValid: "",
                },
            ],
            dataOTP:'',
            isPasswordMatching:false,
            confirmPasswordTouched: false,
        }
    },
    created(){
        this.fetchRecaptcha();
        this.getUserData();
    },
    beforeDestroy() {
      let recap = document.querySelector(".grecaptcha-badge")
      if(recap && recap.parentNode) {
        recap.parentNode.removeChild(recap)
      }
    },
    methods:{
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },
        fetchRecaptcha() {
            if(this.recaptchaType !== 'v2') {
                const scriptTag2 = document.createElement("script");
                scriptTag2.src =`https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`
                document.head.appendChild(scriptTag2);
            }
        },
        personalDetail() {
            this.routerPush('/personal-detail');
        },
        changePassword() {
            this.routerPush('/change-password');
        },
        deleteAccount() {
            this.routerPush('/delete-account');
        },
        handleClickRedirect(item) {
            if (this.$store.state.is_app && item.isRedirect) {
                redirectPathApp(item.url);
            } else {
                this.routerPush(item.url);
            }
        },
        onShowBottomMenuList() {
            this.$refs.bottomMenuList?.open();
        },
        async getUserData(){
            try{
                const res = await UserService.getDataUser();
                if(res.id){
                    this.user_id = res.id
                    this.userName = `${res.first_name + " " + res.last_name}`;
                }
                this.dataSource.calling_code = `+${res?.calling_code}`
                res?.phone_number ? this.dataForm.my_number = `+${res?.phone_number}`: this.formItem[0].state = false
                const i = res?.calling_code.toString().length
                this.dataSource.phone = res?.phone_number?.slice(i) || "";
                this.dataSource.email = res?.email
                this.isLoadingPage = false
            }catch(error){
                console.log(error)
            }
        },
        onToggleShowPassword(name) {
            let index = this.formItem.findIndex(x => x.name === name)
            if(index !== -1 ) {
                this.formItem[index].typeShow = this.formItem[index].typeShow === 'password' ? 'text' : 'password'
            }
        },
        updateFormData(item){
            let { value, name } = item;
            this.dataForm[name] = value;
        },
        onSubmitForm(type){
             if(this.recaptchaType === 'v2') {
                this.$nextTick(() => {
                    grecaptcha.enterprise.ready(() => {
                        try {
                            grecaptcha.enterprise.render('recaptcha-account', {
                                sitekey: this.sitekey,
                                action: 'account_delete',
                                hl: localStorage.getItem("locale") || 'en'
                            })
                        } catch (err) {
                            console.log(err)
                        }
                        })
                    }
                )
                if (this.formItem[2].state) {
                    this.$bvModal.show("modalRecaptchaAccount");
                } else {
                    this.$bvModal.hide("modalRecaptchaAccount");
                }
            } else {
                this.sendOTPForPhone(type)
            }
        },
        async sendOTPForPhone(type) {
            let token
            if(this.recaptchaType === 'v2') {
                token = grecaptcha.enterprise.getResponse();
            } else {
                token = await grecaptcha.enterprise.execute(this.sitekey, {action: 'account_delete'})
            }   
            if(!token) {
                this.showToast(this.getTranslatedText("recaptcha is required"), "error");
                this.isLoadingBtn = false
                return false
            }     
            if(this.isPasswordMatching && this.dataForm.my_number){
                this.isLoadingBtn = true;
                const res = await AuthService.verifyDeactivateUser(
                    this.user_id, this.dataForm.password, 
                    this.dataForm.confirm_password, 'deactivate-user', token, 'account_delete');
                if(res.status === 200){
                    this.showToast(this.getTranslatedText(res.message), "success");
                    this.$bvModal.hide("modalRecaptchaAccount");
                    this.$bvModal.show("modal-confirm-remove-account");
                } else {
                    let message = "";
                    !isArrayEmpty(res.errors)
                    ? res.errors.forEach((element) => {
                        message += element.message;
                    }) : (message = res.message);
                    this.$bvModal.hide("modalRecaptchaAccount");
                    this.showToast(this.getTranslatedText(message), "error");
                }
                this.isLoadingBtn = false;
            }else{
                return false
            }
            
        },
        onClickGoBack(){
            if(this.step === 2){
                this.step = 1
            }else{
                this.$router.push({name:'PersonalDetail'})
            }
        },
        async handleDeleteAccount(){
            try {
                this.isLoadingButtonConfirm = true;
                const resp = await AuthService.deleteAccountUser(this.user_id);
                this.isLoadingButtonConfirm = false;
                if (resp.status === 200) {
                    this.showToast(this.getTranslatedText(resp.message), "success");
                    setTimeout(() => {
                        this.$store.dispatch("handleLogout");
                        this.$router.push({name: "LandingPage"});
                    },1500)
                } else {
                    this.showToast(this.getTranslatedText(resp.message), "error");
                }
            } catch (err) {
                console.log(err, "err");
            }
        },
        onCancelRemove() {
            this.$bvModal.hide("modal-confirm-remove-account");
            this.step = 1
        },
    },
    watch: {
        dataForm: {
            handler(newValue, oldValue) {
                if(newValue.confirm_password){
                    this.confirmPasswordTouched = true;
                }
                if(this.confirmPasswordTouched){
                    this.isPasswordMatching = newValue.password === newValue.confirm_password;
                    this.formItem[2].state = newValue.password === newValue.confirm_password;
                }
            },
            deep: true,
        },
    },
}
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_delete-account.scss"
/>
