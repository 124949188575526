<template>
    <div class="section-notifications__payment-success" :style="cssProps">
        <img loading="lazy" src="@/assets/images/common/success.png" alt="success" />
        <div class="section-notifications__payment-success__body">
            <h1 class="title t-transform-cap">{{ titlePage }}</h1>
            <p
                class="description t-fir-transform-cap"
                v-if="!isHiddenDescription"
            >
                {{ renderTextDescrition() }}
            </p>
            <ButtonCustom
                v-if="isShowButton"
                type="primary"
                :text="renderTextButton()"
                @onClickButton="onClickMovePage"
            />
        </div>
        <Toast :title="toastData.message" :type="toastData.type" />
    </div>
</template>
<script>
import "@/assets/scss/mobile/notification/_notification.scss";

import { isStrEmpty } from "@/components/common/utils";

import ButtonCustom from "@/components/common/button";

import gameService from "@/services/game.service";
import authService from "@/services/auth.service";

import languageMixin from "@/mixins/language/language-mixin";
import paymentServive from '@/services/payment.servive';
import Toast from "@/components/common/toast/Toast.vue";

export default {
    mixins: [languageMixin],
    components: { ButtonCustom, Toast },
    data() {
        return {
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/notify/background-notify.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            methodPayment: -1,
            isLoadingButton: false,
            isShowButton: true,
            isHiddenDescription: false,
            titlePage: "",
            countDown: 120,
            toastData: {
                message: "",
                type: "success",
            },
        };
    },
    created() {
        if (isStrEmpty(localStorage.getItem("methodPayment"))) {
            this.$router.push("/home");
        } else {
            this.methodPayment = parseInt(
                localStorage.getItem("methodPayment")
            );
            this.renderTextTitle();
            if (parseInt(localStorage.getItem("methodPayment")) === 2) {
                this.isShowButton = false;
                setTimeout(() => {
                    this.onSubmitBet();
                    this.countDownTimer()
                }, 2000);
            } else {
                localStorage.removeItem('puschasePayment')
            }
            localStorage.removeItem("methodPayment");
        }
    },
    methods: {
        showToast(message, type) {
            this.toastData.message = message && this.getTranslatedText(message);
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },

        renderTextTitle(notification) {
            if (this.methodPayment === 2) {
                this.titlePage =
                    notification ||
                    this.getTranslatedText(
                        "Generating your Green Certificate."
                    );
            } else {
                this.titlePage = this.getTranslatedText("payment success!!");
            }
        },

        renderTextDescrition() {
            if (this.methodPayment === 2) {
                return `${this.getTranslatedText("please wait")}....`;
            } else {
                return this.getTranslatedText("your payment is successful");
            }
        },

        renderTextButton() {
            if (this.methodPayment === 2) {
                return "home page";
            } else {
                return "wallet page";
            }
        },

        onClickMovePage() {
            if (this.methodPayment === 1) {
                this.$router.push("/wallet");
            } else {
                this.$router.push("/home");
            }
        },

        // async resultNboPayment() {
        //     try {
        //         let reference = localStorage.getItem('reference')
        //          const res = await PaymentService.resultNboPayment(reference, "success")
        //         if(res.status === 200) {
        //             this.$store.dispatch("setBalance");
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },

        paymentFailure(message) {
            this.isLoadingButton = false;
            this.isHiddenDescription = true;
            this.isShowButton = true;
            localStorage.setItem("methodPayment", 2);
            localStorage.removeItem("referencePayment");
            this.$router.push(`/payment/failure?errors=${message || ''}`);
        },

        async onSubmitBet() {
            this.isLoadingButton = true;
            try {
                let referencePayment = localStorage.getItem('referencePayment')
                const respStatusPayment = await paymentServive.callBackStatusPayment(referencePayment)
                if(respStatusPayment.status === 200 && respStatusPayment.data.status === 'completed' && this.countDown > 0) {
                    let paramCheckout = JSON.parse(localStorage.getItem("cart_checkout"));
                    const resp = await gameService.transact(paramCheckout);

                    if (resp.status === 200) {
                        await authService.removeListCart();
                        localStorage.removeItem("list_block");
                        localStorage.removeItem("list_block_tmp");
                        localStorage.removeItem("cart_checkout");
                        localStorage.removeItem("total_items");
                        localStorage.removeItem("referencePayment");
                        localStorage.removeItem("gift_redeemed");
                        this.$store.dispatch("onChangeAnountCart", 0);
                        this.$store.dispatch("setBalance");
                        this.isLoadingButton = false;
                        this.isHiddenDescription = true;
                        this.isShowButton = true;
                        this.$router.push(`/thank-you?reference=${resp.data.reference}`);
                        window.performance.navigation.TYPE_RELOADED = true
                    } else {
                        this.paymentFailure(resp.data?.message)
                    }
                    localStorage.removeItem("puschasePayment");
                } else if ((respStatusPayment.status === 200 && respStatusPayment.data.status === 'failure')) {
                    this.paymentFailure(respStatusPayment.data?.message)
                } else if(this.countDown === 0) {
                    this.showToast("Please wait for the transaction to be completed, this can take up to two(2) minutes. We advise you not to close this screen", "error");
                } else if (respStatusPayment.status !== 200) {
                    this.paymentFailure(respStatusPayment.data?.message)
                } else {
                    setTimeout(() => {
                        this.onSubmitBet(true)
                    }, 3000);
                }
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
