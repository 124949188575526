import axios from "axios";
import axiosLib from "axios";

const axiosAuth = axiosLib.create();

export function setToken(data) {
    localStorage.setItem("access_token", data);
}

export function getAccessToken() {
    return localStorage.getItem("access_token");
}

export function deleteToken() {
    localStorage.removeItem("access_token");
}

export function deleteCart() {
    localStorage.removeItem("list_block_tmp");
    localStorage.removeItem("total_cart");
}

export const getUserInfo = () => {
    return localStorage.getItem("user_info");
};

export const setUserInfo = (data) => {
    data = JSON.stringify(data);
    localStorage.setItem("user_info", data);
};

export const deteleUserInfo = () => {
    return localStorage.removeItem("user_info");
};

export const getLocale = () => {
    return localStorage.getItem("locale") || 'en';
};


function getRefreshToken() {
    return localStorage.getItem("refresh_token");
}

axiosAuth.interceptors.request.use((request) => {
    const token = getAccessToken();
    if (token) {
        request.headers.Authorization = `${getAccessToken()}`;
    }

    request.headers.locale = getLocale()

    return request;
});

axiosAuth.interceptors.response.use((response) => {
    if(response.data.status === 401 && response.data.message.toLowerCase() === 'unauthorized' && response.config.method !== 'get') {
        localStorage.removeItem("access_token");
        localStorage.removeItem("list_block_tmp");
        localStorage.removeItem("user_info");
        localStorage.removeItem("number_index");
        localStorage.removeItem("list_block");
        localStorage.removeItem("total_items");
        localStorage.removeItem("list_games");
        localStorage.removeItem("cardHolderName");
        window.location.href = '/login'
        return false
    }
    return response;
}, (error) => {
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});

export default axiosAuth;
