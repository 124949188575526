<template>
    <div>
        <NoEmpty
            v-if="listBalls.length === 0"
            :text="getTranslatedText('no data')"
        />
        <div class="winning-numbers" v-else>
            <p class="title-mb">{{ getTranslatedText('past winnings') }}</p>
            <ul class="section-wallet__body-list-balls">
                <li
                    v-for="(item, index) in listBalls"
                    :key="index"
                    class="section-wallet__body-item-balls"
                >
                    <div>
                    <div class="header">
                        <p class="number">{{ getTranslatedText('My Numbers') }}</p>
                        <p>{{ getTranslatedText('RaffleID') }}</p>
                    </div>
                    <div class="contents-win">
                        <div class="left">
                            <div class="list-ball">
                                <div class="section-wallet__list-ball-select section-wallet__container">
                                    <div
                                        v-for="(number, i) in item.ballsFinal"
                                        :key="i"
                                        :class="[`section-wallet__item-ball-select ${renderClass(
                                            number.number,
                                            i
                                        )}`, { highLight: !number.isHighlight }]"
                                    >
                                        <div
                                            class="section-wallet__box-ball-select"
                                        >
                                            {{ formatNumber(number.number) }}
                                        </div>
                                    </div>
                                </div>
                                <img loading="lazy"
                                    class="section-number-favourite__icon-show-ball"
                                    src="@/assets/images/common/icon-chevron.svg"
                                    alt="random-numbers"
                                    @click="$emit('onClickIconArrow', item)"
                                />
                            </div>
                            <div>
                            <div v-if="parseInt(item.price)" class="section-wallet__container__flex">
                                <p class="section-wallet__item-balls-price t-transform-cap">
                                    {{ getTranslatedText("winnings amount")}}
                                    ({{ getTranslatedText(currencyCode) }}):
                                    <span>{{ formatPrice(item.price) }}</span>
                                </p>
                            </div>
                            <div v-if="item.raffle_winnings && item.raffle_winnings !== '0'" class="section__container section-wallet__container__flex">
                                <p class="section-wallet__item-balls-price t-transform-cap">
                                    {{ getTranslatedText("raffle winnings")}}
                                    ({{ getTranslatedText(currencyCode) }}):
                                    <span>{{ formatPrice(item.raffle_winnings) }}</span>
                                </p>
                            </div>
                            </div>
                            <div v-if="item.date" class="section-wallet__container date-dt section-wallet__container__flex">
                                <p class="section-wallet__item-balls-date">
                                    {{  getTranslatedText('draw date') }}: {{ item.date }}
                                </p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="box" :class="{'show-mb' : item.raffle_winnings && item.raffle_winnings === '0'}">
                                <div
                                    class="section-number-favourite__item-desktop__past__des__raffle"
                                    v-if="item.raffle_id"
                                >
                                    {{ item.raffle_id.slice(0, 4) + ' ' + item.raffle_id.slice(4, 8) || getTranslatedText("Pending Generation")}}
                                </div>
                                <img v-if="item.raffle_winnings && item.raffle_winnings !== '0'" src="@/assets/images/tick.svg" alt="">
                            </div>
                            <div class="item--double">
                                <img v-if="Number(item.double_jackpot) > 0" src="@/assets/images/inspiring/double.svg" alt="">
                                <img v-if="Number(item.secure_jackpot) > 0" src="@/assets/images/secure-green.svg" alt="">
                            </div>
                            <div v-if="item.date" class="section-wallet__container date-mb section-wallet__container__flex">
                                <p class="section-wallet__item-balls-date">
                                    {{  getTranslatedText('draw date') }}: {{ item.date }}
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="green-cer">
                        <div class="inspiring__item--coupon_item">
                            <img @click="downloadFile(item.link_certificate, item.isLoadingDownLoad, item)" src="@/assets/images/inspiring/icon-arrow-down.svg" alt="">
                            <img @click="handleFavorite(true, item.balls)" src="@/assets/images/inspiring/icon-heart.svg" alt="">
                            <img @click="handleQuickPick(item.balls)" src="@/assets/images/inspiring/icon-play.svg" alt="">
                        </div>
                    </div>
                </li>
            </ul>
            <div
                v-if="listBalls.length > 0"
                class="section-wallet__pagination"
            >
                <b-pagination
                    v-model="pageWinningbets"
                    :total-rows="totalPage"
                    :per-page="perPage"
                    @input="onChangePagination"
                    pills
                    align="center"
                    hide-goto-end-buttons
                    prev-text="Back"
                    next-text="Next"
                ></b-pagination>
                <div class="title">
                    {{ pageWinningbets + '/' + totalPagi + ' ' + getTranslatedText('of') + ' ' + totalPage}}
                    <span>{{ totalPage > 1 ? getTranslatedText('entries') : getTranslatedText('entry') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import languageMixin from "@/mixins/language/language-mixin";
import { isArrayEmpty, renderClassBall, renderLocaleNumber, renderLocalePrice } from "@/components/common/utils";
import WalletService from "@/services/wallet.service";
import moment from "moment";
import NoEmpty from "@/components/common/no-empty";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;

export default {
  mixins: [languageMixin],
  components: {NoEmpty},
  props: {
      maxiumBlue: {
        type: Number,
        default: -1,
      },
      maxiumGreen: {
        type: Number,
        default: -1,
      },
      minimumBall: {
        type: Number,
        default: 0,
      },
  },
  data() {
    return {
        perPage: 20,
        pageWinningbets: 1,
        totalPage: 1,
        pageCount: 0,
        listBalls: [],
        totalPagi: 1,
        totalWinnings: "0.00",
        currencyCode: CURRENCY_CODE,
        exchangeRate: EXCHANGE_RATE,
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
        pageWinningbets(val) {
            if(val) {
                this.pageWinningbets = val;
                this.fetchData();
            }
        },
    },
  methods: {
    renderClass(number, index) {
        return renderClassBall(
            number,
            index,
            this.minimumBall,
            this.maxiumBlue,
            this.maxiumGreen
        );
    },
    formatNumber(number) {
        return renderLocaleNumber(number)
    },
    formatPrice(price) {
        price = price.replaceAll(',', '')
        return renderLocalePrice(parseFloat(price))
    },
    downloadFile(url, isLoadingDownLoad, item) {
        if(url) {
            this.isLoading = true
            window.open(url, "_blank")
            setTimeout(() => {
                this.isLoading = false
            }, 2000);
        } else {
            if(!isLoadingDownLoad) {
                this.$emit("onReGenCerticate", item);
            }
        }
    },
    handleFavorite(state, number) {
        this.$emit("handleFavorite", state, number);
    },
    handleQuickPick(number){
        this.$router.push({name:'Tickets'});
        localStorage.setItem('number-quick-pick', JSON.stringify(number));
    },
    onChangePagination(value) {
        this.pageWinningbets = value
        this.totalPagi = Math.ceil(this.totalPage / this.perPage)
    },
    async fetchData() {
        try {
            const respWinning = await WalletService.getWinning(this.pageWinningbets, this.perPage);
            if (
                respWinning.status === 200 &&
                !isArrayEmpty(respWinning.data)
            ) {
                this.totalPage = respWinning.total;
                this.pageCount = this.perPage*this.pageWinningbets;
                if(this.pageCount > this.totalPage) {
                    this.pageCount = this.totalPage;
                }

                let listBalls = respWinning.data.map((v) => {
                    let balls = [];
                    let ballsResults = [];
                    let ballsFinal = [];

                    let bet = JSON.parse(v?.bet);
                    let results = JSON.parse(v?.results);
                    bet.bets.map((x) => {
                        balls = balls.concat(x);
                    });
                    if(results) {
                        results.map((x) => {
                            ballsResults = ballsResults.concat(x);
                        });
                    }


                    if(results) {
                        balls.map(ball => {
                            if(ballsResults.includes(ball)) {
                                ballsFinal.push({isHighlight: true, number: ball});
                            } else {
                                ballsFinal.push({number: ball});
                            }
                        })
                    } else {
                        balls.map(ball => {
                            ballsFinal.push({number: ball});
                        })
                    }

                    let locale = this.$store.state.isRtl ? "ar" : "en";
                    moment.locale(locale)

                    let date = moment(moment.utc(v.draw_date)).format('DD/MM/YYYY')
                    let price = parseFloat(v?.winnings?.replace(/,/g, ""))
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return {
                        id: v.id,
                        balls,
                        ballsFinal,
                        price,
                        date,
                        raffle_id: v?.raffle_code?.toString() || '',
                        raffle_winnings: v?.raffle_winnings?.toString() || '',
                        double_jackpot: bet?.double_jackpot,
                        secure_jackpot: bet?.secure_jackpot,
                        link_certificate: v?.link_certificate,
                        isLoadingDownLoad: false
                    };
                });
                this.totalWinnings = respWinning.total_winning;
                this.listBalls = listBalls;
            }
        } catch (error) {
            console.log(error);
        }
    },
  }
}
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/_winning-numbers.scss" />