import { phoneNumbers } from "@/resources/phoneNumber";
import { getCountryCallingCode } from "libphonenumber-js";

export default {
    data() {
        return {
            phoneNumbersFilter: phoneNumbers,
        };
    },

    methods: {
        handleDefaultCountry(dataForm) {
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const ct = require('countries-and-timezones');
            const timezone = ct.getTimezone(tz);
            const userCountry = timezone.countries[0];
            const countryCallingCode = `+${getCountryCallingCode(userCountry)}`;
            this.phoneNumbersFilter.forEach(item=> {
                if(item.number === countryCallingCode) {
                    dataForm.calling_code = item.number;
                    dataForm.code = item.number;
                    dataForm.flag = item.flag;
                    dataForm.nameCountry = item.name.replace(
                        /^(.{5}[^\s]*).*/,
                        "$1"
                    );
                }
            })
        },
    },
};
