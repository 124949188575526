<template>
<div class="main">
    <BannerCustom/>
    <div class="forgot-password"
        :class="{
            sectionConfirmOtp: step === 2,
            sectionSetPassword: step === 3,
        }">
        <div v-if="step === 1" class="forgot-password__container">
            <h1 class="title-custom">
                {{ getTranslatedText("Forgot Password") }}
            </h1>
            <p class="sub-title">{{ getTranslatedText("Enter you phone number") }}</p>
            <div class="forgot-password__body">
                <FormCommon
                    class="mt-4"
                    :formItem="methodForgotPassword === '2' ? formItemEmail : formItemPhone"
                    :dataForm="dataForm"
                    @update-form-data="updateFormData"
                    @onSubmitForm="checkSubmitFormRecaptcha"
                >
                    <b-input-group-prepend
                                slot="propend-phone-number"
                                class="phone-code"
                            >
                                <button
                                    class="show-dropdown"
                                    type="button"
                                    @click="showDropDow"
                                >
                                <div class="flag-group">
                                    <img loading="lazy"
                                        class="user-avatar"
                                        :src="
                                            require(`@/assets/images/flags/${dataForm.flag}`)
                                        "
                                        :alt="dataForm.flag"
                                    />
                                </div>
                                    {{ dataForm.calling_code }}
                                </button>

                                <b-dropdown ref="select_phone_code">
                                    <b-form-input
                                        v-model="textSearchPhoneNumber"
                                        :placeholder="
                                            getTranslatedText('Search')
                                        "
                                        @input="onChangeFilterPhoneNumbers"
                                    ></b-form-input>
                                    <b-dropdown-item
                                        @click="selectPhoneCode(item)"
                                        v-for="(item, index) in phoneNumbersFilter"
                                        :key="index"
                                    >
                                        <div>
                                            <div class="flag-group">
                                            <img loading="lazy"
                                                class="user-avatar"
                                                :src="
                                                    require(`@/assets/images/flags/${item.flag}`)
                                                "
                                                :alt="item.flag"
                                            />
                                            </div>
                                            <span class="name-country">{{
                                                item.name
                                            }}</span>
                                            ({{ item.number }})
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <img loading="lazy"
                                    class="dropdown-phone-icon"
                                    src="@/assets/images/personal-detail/dropdown-black.png"
                                />
                            </b-input-group-prepend>
                    <div slot="button" class="mt-4">
                        <div class="forgot-password__box-btn">
                            <ButtonCustom
                                :isLoading="isLoadingButton"
                                text="confirm"
                                classBtn="forgot-password__box-btn-send"
                                typeBtn="submit"
                                type="primary"
                            />
                        </div>
                    </div>
                </FormCommon>
            </div>
             <div class="section-login__login-bottom__desktop">
                    <div
                        class="section-login__create-account__des"
                    >
                        {{ getTranslatedText("New account?") }}
                    </div>
                    <div
                        @click="$router.push('/register')"
                        class="section-login__create-account t-transform-cap"
                    >
                        {{ getTranslatedText("Register Here.") }}
                    </div>
            </div>
        </div>
        <div v-else-if="step === 2" class="section-confirm-otp">
                <ConfirmOTP
                    :dataSource="dataForm"
                    @onClickGoBack="onClickGoBack"
                    @onSubmitForm="onSubmitResetPassword"
                    @sendOTP="checkSubmitFormRecaptcha('resend')"
                    :methodsChangeInfo="methodForgotPassword === '2' ? 'email' : 'phone'"
                    :text="getTranslatedText('enter otp')"
                    :textBtnContinue="getTranslatedText('reset password')"
                    :isLoadingButton="isLoadingButton"
                    :isLoadingButtonRecaptcha="isLoadingButtonRecaptcha"
                />
        </div>
        <div v-else class="section-confirm-otp">
            <SetPassWord
                :text="getTranslatedText('reset password')"
                :textBtnContinue="getTranslatedText('done')"
                @on-submit-password="resetPassWord"
                @onResetPassword="onSubmitResetPassword"
                @submitResend="onClickForgotPassword('resend')"
                @onClickGoBack="onClickGoBack"
                @submitDone="$router.push('/home')"
                :dataUser="dataForm"
                :methodLogin="parseInt(methodForgotPassword)"
                :isShowIconBtnContibue="false"
            />
        </div>
        <Toast :title="toastData.message" :type="toastData.type" />
    </div>
    <ConfirmRecaptcha
        id="modalRecaptchaPass"
        idRecaptcha="recaptcha-pass"
        :sitekey="sitekey"
        :isLoadingButton="isLoadingButton"
        @onSubmitRecaptcha="onClickForgotPassword"
    />
</div>
</template>
<script>
import { phoneNumbers } from "@/resources/phoneNumber";
import AuthService from "@/services/auth.service";
import FormCommon from "@/components/common/form";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";
import ButtonCustom from "@/components/common/button";
import BannerCustom from "@/components/common/banner";
import Toast from "@/components/common/toast/Toast.vue";

import ConfirmOTP from "@/views/mobile/authenticate/ConfirmOTP";
import SetPassWord from "@/views/mobile/authenticate/SetPassword.vue";

import languageMixin from "@/mixins/language/language-mixin";
import defaultCountryCodeMixin from "@/mixins/default-country-code/default-country-code-mixin";

import { filterFullTextSearch, isStrEmpty, isArrayEmpty} from "@/components/common/utils";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;
const RECAPTCHA_TYPE = process.env.VUE_APP_RECAPTCHA_TYPE;

let scriptTag

export default {
    mixins: [languageMixin, defaultCountryCodeMixin],
    components: {
        FormCommon,
        ButtonCustom,
        ConfirmOTP,
        SetPassWord,
        Toast,
        BannerCustom,
        ConfirmRecaptcha
    },
    mounted() {
        const parameters = this.$route.query;
        if (parameters.step) {
            this.step = +parameters.step;
            this.methodForgotPassword = !isStrEmpty(localStorage.getItem('methodForgotPassword')) ? parseInt(localStorage.getItem('methodForgotPassword')) : 1
            this.codeOTP = parameters.code || '';
            this.$router.replace({'query': null});
        } else {
            this.step = 1;
        }
        this.handleDefaultCountry(this.dataForm)
    },
    data() {
        return {
            sitekey: SITEKEY,
            recaptchaType: RECAPTCHA_TYPE,
            methodForgotPassword: '1',
            step: 1,
            isLoadingButton: false,
            isLoadingButtonRecaptcha: false,
            textSearchPhoneNumber: '',
            toastData: {
                message: "",
                type: "success",
            },
            methodOptions: [
                { text: this.getTranslatedText('By Phone'), value: '1' },
            ],
            phoneNumbersFilter: phoneNumbers,
            phoneNumbers,
            dataForm: {
                phone: "",
                email: "",
                calling_code: "+968",
                code: "+968",
                flag: "Flag_of_Oman.svg",
                nameCountry: "Oman",
                // confirm_phone: ""
            },
            formItemPhone: [
                {
                    type: "input-group",
                    typeInput: "number",
                    placeholder: "",
                    name: "phone",
                    label: "Mobile Number",
                    for: "input-phone",
                    id: "input-phone-login",
                    required: true,
                    showIconRequired: true,
                    prepend: "code",
                    nameSlotInputPrepend: "propend-phone-number",
                    state: null,
                    textInvalid: "Please enter phone",
                    textValid: ""
                },
                // {
                //     type: "input-group",
                //     typeInput: "number",
                //     placeholder: "",
                //     name: "confirm_phone",
                //     label: "Confirm Mobile Number",
                //     for: "input-phone-confirm",
                //     id: "input-phone-login-confirm",
                //     required: true,
                //     showIconRequired: true,
                //     prepend: "code",
                //     nameSlotInputPrepend: "propend-phone-number",
                //     state: null,
                //     textInvalid: "Please enter phone",
                //     textValid: ""
                // },
                {
                    type: "slot",
                    nameSlot: "recaptcha",
                },
            ],
            formItemEmail: [
                {
                    type: "input",
                    typeInput: "email",
                    label: "email",
                    placeholder: "email",
                    name: "email",
                    required: true,
                    hideIconRq: true,
                    showIconRequired: true,
                    for: "input-email",
                    id: "input-email",
                    state: null,
                    textInvalid: "Please enter email",
                },
                {
                    type: "slot",
                    nameSlot: "recaptcha",
                },
            ],
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value === 0) {
                    this.isDisableResend = false;
                }
            },
            immediate: true,
        },
    },
    created() {
        this.addScriptCanonical()
        this.fetchRecaptcha()
    },
    beforeDestroy() {
        let recap = document.querySelector(".grecaptcha-badge")
        if(recap && recap.parentNode) {
            recap.parentNode.removeChild(recap)
        }
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    },
    // metaInfo() {
    //     return {
    //         title: 'Change Your Life, Win Big Now!',
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: 'Simply Match all 7 numbers in Any order to Win up to AED 200 Million'
    //             }
    //         ]
    //     };
    // },
    methods: {
        addScriptCanonical() {
            scriptTag = document.createElement("link");
            scriptTag.rel = "canonical";
            scriptTag.href =`https://omillionaire.com/forgot-password`
            document.head.appendChild(scriptTag);
        },
        fetchRecaptcha() {
            if(this.recaptchaType !== 'v2') {
                const scriptTag2 = document.createElement("script");
                scriptTag2.src =`https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`
                document.head.appendChild(scriptTag2);
            }
        },
        onSubmitForm() {
            this.$emit("onSubmitForm", this.dataForm);
        },

        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
            this.$bvToast.show("my-toast");
        },

        updateFormData(item) {
            let { value, name } = item;
            this.dataForm[name] = value;
            // if (name === "phone") {
            //     this.formItemPhone[0].state = null;
            //     this.dataForm.phone = value;
            //     if (
            //         this.dataForm.phone !== this.dataForm.confirm_phone &&
            //         this.dataForm.confirm_phone.length > 0
            //     ) {
            //         this.formItemPhone[1].textInvalid = "Phone not match";
            //         this.formItemPhone[1].state = false;
            //     } else {
            //         this.formItemPhone[1].state = null;
            //         this.formItemPhone[1].textInvalid = "";
            //     }
            // }
            // if (name === "confirm_phone") {
            //     this.dataForm.confirm_phone = value;
            //     if (this.dataForm.phone !== value) {
            //     this.formItemPhone[1].textInvalid = "Phone not match";
            //     this.formItemPhone[1].state = false;
            //     } else {
            //     this.formItemPhone[1].state = null;
            //     this.formItemPhone[1].textInvalid = "";
            //     }
            // }
        },

         async onBurInputFormData(event, phoneCode) {
            const { name, value } = event.target;
            let { calling_code, phone } = this.dataForm;

            switch (true) {
                // check phone already exist and change code phone
                case (name === "phone" && value !== phone) ||
                    !isStrEmpty(phoneCode):
                    // calling_code check change code phone

                    calling_code = !isStrEmpty(phoneCode)
                        ? phoneCode.replace(/\+/g, "")
                        : calling_code.replace(/\+/g, "");

                    try {
                        const res = await AuthService.checkPhone(
                            calling_code,
                            value
                        );
                        if (res.status === 400) {
                            let message = "";
                            res.errors.forEach((element) => {
                                message += element.message;
                            });
                            this.formItemPhone[0].state = true;
                            this.formItemPhone[0].textValid = message;
                        } else {
                            this.formItemPhone[0].state = null;
                        }

                        this.dataForm.phone = value;
                    } catch (error) {
                        this.formItemPhone[0].state = false;
                        this.formItemPhone[0].textInvalid = error;
                    }
                    break;
                default:
                    break;
            }
        },
        checkSubmitFormRecaptcha(type) {
            if(this.recaptchaType === 'v2') {
                this.$nextTick(() => {
                    grecaptcha.enterprise.ready(() => {
                        try {
                            grecaptcha.enterprise.render('recaptcha-pass', {
                                sitekey: this.sitekey,
                                action: 'password_reset',
                                hl: localStorage.getItem("locale") || 'en'
                            })
                        } catch (err) {
                            console.log(err)
                        }
                        })
                    }
                )
                this.$bvModal.show("modalRecaptchaPass");
            } else {
                this.onClickForgotPassword(type)
            }
        },

        async onClickForgotPassword(type) {
            
            if (type === 'resend') {
                this.isLoadingButtonRecaptcha = true
            } else {
                this.isLoadingButton = true
            }
            let methodForgotPassword = parseInt(this.methodForgotPassword)

            if(methodForgotPassword === 1 &&  this.dataForm.phone.length < 5) {
                this.showToast(
                    this.getTranslatedText('Incorrect phone number'),
                    "error"
                );
                if (type === 'resend') {
                    this.isLoadingButtonRecaptcha = false
                } else {
                    this.isLoadingButton = false
                }
                return false
            }
            let token
            if(this.recaptchaType === 'v2') {
                token = grecaptcha.enterprise.getResponse();
            } else {
                token = await grecaptcha.enterprise.execute(this.sitekey, {action: 'password_reset'})
            }
            if(type !== 'resend') {
                if(!token){
                    this.showToast(
                        this.getTranslatedText(
                            'recaptcha is required'
                        ),
                        "error"
                    );
                    if (type === 'resend') {
                        this.isLoadingButtonRecaptcha = false
                    } else {
                        this.isLoadingButton = false
                    }
                    return false;
                }
            }

            if(methodForgotPassword === 2 &&  this.dataForm.email.length < 5) {
                this.showToast(
                    this.getTranslatedText('Incorrect email'),
                    "error"
                );
                if (type === 'resend') {
                    this.isLoadingButtonRecaptcha = false
                } else {
                    this.isLoadingButton = false
                }
                return false
            }
            if (methodForgotPassword === 1 && this.dataForm.phone !== "") {
                if(!token) {
                    this.showToast(this.getTranslatedText("recaptcha is required"), "error");
                    this.isLoadingButton = false
                    return false
                }     
                AuthService.sendOTPToPhone(
                    "forgot_password",
                    this.dataForm.phone,
                    this.dataForm.calling_code.replace(/\+/g, ""),
                    true,
                    token,
                    "password_reset",
                    type === 'resend'
                ).then((res) => {
                    if (res.status === 200) {
                        if (type === "resend") {
                            this.showToast(
                                this.getTranslatedText(res.message),
                                "success"
                            );
                        }
                        localStorage.setItem('methodForgotPassword', methodForgotPassword)
                        this.step = methodForgotPassword === 1 ? 2 : 3;
                        if (type === 'resend') {
                            this.isLoadingButtonRecaptcha = false
                        } else {
                            this.isLoadingButton = false
                        }
                        this.$bvModal.hide("modalRecaptchaPass");
                    } else {
                        if (type === 'resend') {
                            this.isLoadingButtonRecaptcha = false
                        } else {
                            this.isLoadingButton = false
                        }
                        this.$bvModal.hide("modalRecaptchaPass");
                        let message = "";
                        !isArrayEmpty(res.errors)
                        ? res.errors.forEach((element) => {
                            message += element.message;
                        }) : (message = res.message);
                        this.showToast(
                            this.getTranslatedText(message),
                            "error"
                        );
                    }
                });
            } else {
                AuthService.forgotPasswordByEmail(this.dataForm.email).then(
                    (res) => {
                        if (res.status === 200) {
                            if (type === "resend") {
                                this.showToast(
                                    this.getTranslatedText(res.message),
                                    "success"
                                );
                            }
                            this.step = methodForgotPassword === 1 ? 2 : 3;
                            this.isLoadingButton = false
                        } else {
                            this.isLoadingButton = false
                            this.showToast(
                                this.getTranslatedText(res.message),
                                "error"
                            );
                        }
                    }
                );
            }
        },

        selectPhoneCode(item) {
            if (
                this.dataForm.calling_code !== item.number &&
                !isStrEmpty(this.dataForm.phone)
            ) {
                let event = {
                    target: {
                        value: this.dataForm.phone,
                        name: "phone",
                    },
                };

                let phoneCode = item.number;

                this.onBurInputFormData(event, phoneCode);
            }
            this.dataForm.calling_code = item.number;
            this.dataForm.code = item.number;
            this.dataForm.flag = item.flag;
            this.dataForm.nameCountry = item.name.replace(
                /^(.{5}[^\s]*).*/,
                "$1"
            );
            this.textSearchPhoneNumber = "";
            this.phoneNumbersFilter = phoneNumbers;
        },

        showDropDow() {
            const dropdown = this.$refs.select_phone_code;
            dropdown.show();
        },

        onChangeFilterPhoneNumbers(value) {
            let phoneNumber = [...phoneNumbers];
            if (!isStrEmpty(value)) {
                this.phoneNumbersFilter = filterFullTextSearch(
                    phoneNumber,
                    value,
                    "name",
                    "number"
                );
            } else {
                this.phoneNumbersFilter = phoneNumber;
            }
        },

        onClickGoBack() {
            if (this.methodLogin === 1 && this.step !== 3) {
                this.step -= 1;
            } else {
                this.step = 1;
                this.fetchRecaptcha()
            }
        },

        onSubmitResetPassword(data) {
            let methodForgotPassword = parseInt(this.methodForgotPassword)
            this.codeOTP = data.code;
            if (methodForgotPassword === 1) {
                AuthService.apiCheckOTPByPhone(
                    data.code,
                    "forgot_password",
                    this.dataForm.phone,
                    this.dataForm.calling_code.replace(/\+/g, "")
                ).then((res) => {
                    if (res.status === 200) {
                        this.step = 3;
                    } else {
                        let message = "";
                        !isArrayEmpty(res.errors)
                            ? res.errors.forEach((element) => {
                                message += element.message;
                            })
                            : (message = res.message);

                        this.showToast(message, "error");
                    }
                });
            } else {
                AuthService.forgotPasswordByEmail(this.dataForm.email).then(
                    (res) => {
                        if (res.status === 200) {
                            this.step = 3;
                        } else {
                            let message = "";
                            !isArrayEmpty(res.errors)
                                ? res.errors.forEach((element) => {
                                    message += element.message;
                                })
                                : (message = res.message);

                            this.showToast(message, "error");
                        }
                    }
                );
            }
        },

        resetPassWord(data) {
            AuthService.resetPassWord(
                data.password,
                this.codeOTP
            ).then((res) => {
                if (res.status === 200) {
                    this.showToast(
                        this.getTranslatedText(res.message),
                        "success"
                    );
                    setTimeout(() => {
                        this.$router.push('/login')
                        this.step = 1;
                    }, 1000);

                } else {
                    this.showToast(
                        this.getTranslatedText(res.message),
                        "error"
                    );
                }
            });
        },
    },
};
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_forgot-password.scss"
/>
