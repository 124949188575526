import { render, staticRenderFns } from "./index.vue?vue&type=template&id=756fcf18&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/mobile/payment/_payment.scss?vue&type=style&index=0&id=756fcf18&prod&scoped=true&lang=scss&"
import style1 from "@/assets/scss/mobile/checkout/_checkout-iframe.scss?vue&type=style&index=1&id=756fcf18&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756fcf18",
  null
  
)

export default component.exports