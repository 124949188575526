
export default {
    data() {
        return {};
    },

    methods: {
        getTranslatedText(text, xargs = {}) {
            if (!text) {
                return;
            }
            const rsText = text?.toLowerCase();
            var translatedText = rsText;
            const locale = localStorage.getItem("locale");
            const localeStorage = localStorage.getItem(
                "muti_language_" + locale
            );

            if (localeStorage) {
                const locale = JSON.parse(localeStorage);
                // console.log('testing', locale[rsText]);
                if (rsText in locale) {
                    translatedText = locale[rsText];
                }
            }
            if (xargs) {
                for (var key in xargs) {
                    translatedText = translatedText.replace(
                        "[" + key + "]",
                        xargs[key]
                    );
                }
            }

            return translatedText;
        },
    },
};
