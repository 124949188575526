<template>
    <b-modal
        :id="id"
        modal-class="modal-fullscreen"
        style="max-width: 100% !important"
        centered
        header-class="voucherCode__modal-header"
        body-class="voucherCode__modal-body"
        hide-footer
        :hide-header-close="true"
        @hidden="$emit('onCancelModal')"
    >
        <template #modal-title>
            <h1 class="voucherCode__title">{{ getTranslatedText("Enter Voucher Code") }}</h1>
        </template>
        <div class="voucherCode">
            <b-form-input
                required
                :state="stateVoucherCode"
                v-model="voucher"
                maxlength = "5"
                @change="formatter"
                id="input-add-voucher">
            </b-form-input>
            <b-form-invalid-feedback id="input-voucher">
                {{ getTranslatedText("The voucher code is required") }}
            </b-form-invalid-feedback>
            <div class="voucherCode__btn-group">
                <b-button
                    class="voucherCode__button voucherCode__validate"
                    @click="onSubmitVoucher"
                    :disabled="isLoadingButton"
                >
                    <b-spinner v-if="isLoadingButton" />
                    {{ getTranslatedText("Validate Voucher")}}
                </b-button>
                <b-button
                    class="voucherCode__button voucherCode__cancel"
                    @click="$emit('onCancelModal')"
                >
                    {{ getTranslatedText("Cancel")}}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<script>

import VoucherService from "@/services/voucher.service";

import languageMixin from "@/mixins/language/language-mixin";
import paymentMixin from "@/mixins/payment/payment-mixin"

import ButtonCustom from "@/components/common/button";
import EventBus from '@/components/common/EventBus'
import { isArrayEmpty, isStrEmpty } from "@/components/common/utils";
import convertGSTtime from "@/mixins/convert-gst-time/convert-gst-time-mixin";
let scriptTag

export default {
    mixins: [languageMixin, paymentMixin, convertGSTtime],
    components: {
        ButtonCustom
    },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoadingButton: false,
            voucher: '',
            stateVoucherCode: null
        };
    },
    created() {
        EventBus.$on('onHideVoucherCode', () => {
            this.voucher = '';
            this.stateVoucherCode = null;
        })
    },
    destroyed() {
        EventBus.$off('onHideVoucherCode', () => {})
    },
    methods: {
        formatter(value) {
            if (!value) {
                this.stateVoucherCode = false;
            } else {
                this.stateVoucherCode = null;
            }
        },

        onSubmitVoucher() {
            if (!this.voucher) {
                this.stateVoucherCode = false;
            } else {
                try {
                    this.isLoadingButton = true;
                    VoucherService.addVoucherCode({
                        code: this.voucher
                    })
                    .then(res => {
                        if(res.status === 200) {
                            scriptTag = document.createElement('script');
                            scriptTag.textContent = `
                                window.dataLayer = window.dataLayer || [];
                                dataLayer.push({
                                    'event': 'redeem_gift',
                                    'voucher_code': '${this.voucher}',
                                    'voucher_value': ${res.data.amount},
                                });
                            `;
                            document.head.appendChild(scriptTag);
                            this.$emit("showToast", res.data.message, "success");
                            this.isLoadingButton = false;
                            this.$store.dispatch("setBalance");
                            this.$emit('onCancelModal');
                        } else {
                            let message = "";
                            if (!isStrEmpty(res.data?.attempt_type) && !isStrEmpty(res.data?.banned_until)) {
                                let dateGST = this.convertGSTtime(res.data.banned_until, true)
                                if(res.data.attempt_type === 1) {
                                    message = this.getTranslatedText(
                                        'User Ban Voucher in 10 minutes. Please use voucher after [date] gst', { date: dateGST })
                                } else {
                                    message = this.getTranslatedText(
                                        'User Ban Voucher in 1 day. Please use voucher after [date] gst', { date: dateGST })     
                                }
                                this.$emit("showToast", message, "error", true)
                            } else {
                                !isArrayEmpty(res.errors)
                                    ? res.errors.forEach((element) => {
                                        message += element.message;
                                })
                                : (message = res.message);
                                this.$emit(
                                    "showToast", message, "error"
                                );
                            }
                            this.isLoadingButton = false;
                        }
                    })
                } catch (error) {
                    console.log(error);
                }
                this.stateVoucherCode = null;
            }
        },
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
    }
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/voucherCode/voucherCode.scss" />
