<template>
    <div class="main">
        <BannerCustom title=""/>
        <div class="buy-now-container">
            <div class="buy-now">
                <div class="buy-now__image">
                    <div class="show-desktop"/>
                </div>
                <div class="buy-now__content">
                    <h1 class="buy-now__title">
                        {{ getTranslatedText("Buy Green Certificates") }}
                    </h1>
                    <p class="buy-now__description text__note">
                        <span>{{getTranslatedText("By purchasing Green Certificates, you are contributing to our Green Initiative — Oasis Park.Oasis Park is the world's first and only state-of-the-art green sanctuary powered by renewable energy on a mission to plant millions of trees to combat climate change and fight global warming.") }}</span>
                    </p>
                    <div class="show-mb">
                        <div class="bg"/>
                    </div>
                    <div class="buy-now__description-highlight">
                        <div class="left">
                            {{ getTranslatedText("Buy a Green Certificate and get a chance to win the") }}
                            <span>{{ getTranslatedText("Grand Prize") }}</span>
                            {{ getTranslatedText("when you match all seven numbers in any order.") }}
                        </div>
                        <div class="line"></div>
                        <div class="right">
                            {{ getTranslatedText("One Guaranteed Raffle Winner each week will win") }}
                            <span class="cost">{{ getTranslatedText("AED 100,000") }}</span>
                            {{ getTranslatedText("when your") }}
                            <span>{{ getTranslatedText("Green Certificate ID") }}</span>
                            {{ getTranslatedText("matches with the Raffle ID.") }}
                        </div>
                    </div>
                    <div class="buy-now__footer">
                        <div class="buy-now__action">
                            <div class="buy-now__action-info">
                                <div class="buy-now__action-info-price">
                                    <span class="highlight">{{
                                        getTranslatedText(currencyCode)
                                    }}</span>
                                    <span>{{ ' ' + formatLocalePrice(2.5 * this.exchangeRate) }}</span>
                                </div>
                                <span class="buy-now__action-info-des">{{
                                    getTranslatedText("per green certificate")
                                }}</span>
                            </div>
                            <div class="group-action">
                                <div class="buy-now__action-select-tickets">
                                    <SelectQuantity
                                        @onChangeTotalPrice="onChangeTotalPrice"
                                        @updateBlock="updateBlock"
                                    />
                                </div>
                                <div class="buy-now__action-btn btn-dt">
                                    <ButtonCustom
                                        classBtn="buy-now__btn-action"
                                        classOption="btn-radius"
                                        typeBtn="primary"
                                        type="primary"
                                        text="green cer"
                                        :isLoading="isLoadingBtn"
                                        @onClickButton="onNextTickets"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="buy-now__action-btn btn-mb">
                            <ButtonCustom
                                classBtn="buy-now__btn-action"
                                classOption="btn-radius"
                                typeBtn="primary"
                                type="primary"
                                text="green cer"
                                :isLoading="isLoadingBtn"
                                @onClickButton="onNextTickets"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="buy-now__description-mb">
                    <div class="text">
                        {{ getTranslatedText("Buy a Green Certificate and get a chance to win the") }}
                        <span>{{ getTranslatedText("Grand Prize") }}</span>
                        {{ getTranslatedText("when you match all seven numbers in any order.") }}
                    </div>
                    <div class="line"></div>
                    <div class="text">
                        {{ getTranslatedText("One Guaranteed Raffle Winner each week will win") }}
                        <span class="cost">{{ getTranslatedText("AED 100,000") }}</span>
                        {{ getTranslatedText("when your") }}
                        <span>{{ getTranslatedText("Green Certificate ID") }}</span>
                        {{ getTranslatedText("matches with the Raffle ID.") }}
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import LanguageMixin from "@/mixins/language/language-mixin";
import BuyNowMixin from "@/mixins/buy-now/buy-now-mixin";
import ButtonCustom from "@/components/common/button";
import SelectQuantity from "@/components/common/select-quantity/SelectQuantityRegister";
import BannerCustom from "@/components/common/banner";

import { renderLocalePrice } from "@/components/common/utils";

const CURRENCY_CODE = process.env.VUE_APP_CURRENCY_CODE;
const EXCHANGE_RATE = process.env.VUE_APP_EXCHANGE_RATE;
let scriptTag
export default {
    mixins: [LanguageMixin, BuyNowMixin],
    components: { ButtonCustom, SelectQuantity, BannerCustom },
    data() {
        return {
            currencyCode: CURRENCY_CODE,
            exchangeRate: EXCHANGE_RATE,
            isLoadingBtn: false,
        };
    },
    // metaInfo() {
    //     return {
    //         title: "Buy Green Certificate for only AED 25 | Win up to AED 200M with O! Millionaire",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Purchase a Green Certificate for O! Millionaire's Oasis Park initiative. Each certificate is an entry to win over AED 100 million and the weekly AED100,000 raffle!"
    //             }
    //         ]
    //     };
    // },
    methods: {
        onNextTickets() {
            let user = JSON.parse(localStorage.getItem("user_info"))
            let id = user ? parseInt(user.id) : "'N/A'"
            scriptTag = document.createElement('script');
            scriptTag.textContent = `
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'buy_now',
                    'user_id': ${id},
                    'prqt': ${this.listBlock.length},
                    'currency': '${this.currencyCode}',
                    'amount': ${parseFloat(2.5 * this.exchangeRate * this.listBlock.length).toFixed(2)}
                });
            `;
            document.head.appendChild(scriptTag);
            localStorage.setItem("list_block", JSON.stringify(this.listBlock));
            this.$router.push("/numbers");
        },

        // func common
        formatLocalePrice(number) {
            return renderLocalePrice(number);
        },
        addMarginBottom(){
            if(this.isScreenMobile){
                document.body.style.setProperty('margin-bottom', '100px', 'important');
            }else{
                document.body.style.removeProperty('margin-bottom');
            }
        }
    },
    beforeDestroy() {
      function removeScript(script) {
          if (script && script.parentNode) {
              script.parentNode.removeChild(script);
          }
      }
      removeScript(scriptTag);
    }
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/buy-now/buy-now.scss" />
