<template>
    <div class="section-menu-container">
        <div class="section-menu-desktop" :class="{ hideMenu: isHideMenu }">
            <div class="section-menu-desktop__navigation-menus__content">
                <div
                    v-for="(account, i) in menusAccount"
                    :key="i"
                    @click="
                        handleClickAccountItem(account.path, account.redirect)
                    "
                    class="section-menu-desktop__navigation-menu__item"
                    :class="{ activeItem: account.isActive }"
                >
                    <img loading="lazy"
                        :src="
                            require(`@/assets/images/header/account/${
                                account.isActive
                                    ? account.iconActive
                                    : account.icon
                            }`)
                        "
                    />
                    {{ getTranslatedText(account.title) }}
                </div>
            </div>
        </div>
        <div
            class="section-menu-desktop-right"
            :class="{ fullContent: isHideMenu }"
        >
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { redirectPathApp } from "../utils";

import LanguageMixin from "@/mixins/language/language-mixin";
import RouterMixin from "@/mixins/router/router-mixin"

const ENABLE_MANAGE_CREDIT_CARDS = process.env.VUE_APP_ENABLE_MANAGE_CREDIT_CARDS;
const ENABLE_RECURRING = process.env.VUE_APP_ENABLE_RECURRING;

export default {
    mixins: [LanguageMixin, RouterMixin],
    props: {
        isHideMenu: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},

    mounted() {
        this.handleActiveMenu(this.$router.currentRoute.path);
        if(ENABLE_MANAGE_CREDIT_CARDS === 'no' && ENABLE_RECURRING === 'no') {
            this.menusAccount = [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    iconActive: "personal-active.png",
                    path: "/personal-detail",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Numbers",
                    icon: "my-numbers.png",
                    iconActive: "my-numbers-active.png",
                    path: "/favourite",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Wallet",
                    icon: "wallet.png",
                    iconActive: "wallet-active.png",
                    path: "/wallet",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "Notifications",
                    icon: "notification.png",
                    iconActive: "notification-active.png",
                    path: "/notifications",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Transactions",
                    icon: "my-transactions.png",
                    iconActive: "my-transactions-active.png",
                    path: "/my-transactions",
                    isActive: false,
                    redirect: false,
                },
            ];
        }
        if(ENABLE_MANAGE_CREDIT_CARDS === 'no' && ENABLE_RECURRING !== 'no') {
            this.menusAccount = [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    iconActive: "personal-active.png",
                    path: "/personal-detail",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Numbers",
                    icon: "my-numbers.png",
                    iconActive: "my-numbers-active.png",
                    path: "/favourite",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Wallet",
                    icon: "wallet.png",
                    iconActive: "wallet-active.png",
                    path: "/wallet",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "Notifications",
                    icon: "notification.png",
                    iconActive: "notification-active.png",
                    path: "/notifications",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "Recurring Games",
                    icon: "recurring.png",
                    iconActive: "recurring-active.png",
                    path: "/recurring-games",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "My Transactions",
                    icon: "my-transactions.png",
                    iconActive: "my-transactions-active.png",
                    path: "/my-transactions",
                    isActive: false,
                    redirect: false,
                },
            ];
        }
        if(ENABLE_MANAGE_CREDIT_CARDS !== 'no' && ENABLE_RECURRING === 'no') {
            this.menusAccount = [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    iconActive: "personal-active.png",
                    path: "/personal-detail",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Numbers",
                    icon: "my-numbers.png",
                    iconActive: "my-numbers-active.png",
                    path: "/favourite",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "Manage Cards",
                    icon: "card.png",
                    iconActive: "card-active.png",
                    path: "/manage-cards",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "My Wallet",
                    icon: "wallet.png",
                    iconActive: "wallet-active.png",
                    path: "/wallet",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "Notifications",
                    icon: "notification.png",
                    iconActive: "notification-active.png",
                    path: "/notifications",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Transactions",
                    icon: "my-transactions.png",
                    iconActive: "my-transactions-active.png",
                    path: "/my-transactions",
                    isActive: false,
                    redirect: false,
                },
            ]
        }
        if(ENABLE_MANAGE_CREDIT_CARDS !== 'no' && ENABLE_RECURRING !== 'no') {
            this.menusAccount = [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    iconActive: "personal-active.png",
                    path: "/personal-detail",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Numbers",
                    icon: "my-numbers.png",
                    iconActive: "my-numbers-active.png",
                    path: "/favourite",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "Manage Cards",
                    icon: "card.png",
                    iconActive: "card-active.png",
                    path: "/manage-cards",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "My Wallet",
                    icon: "wallet.png",
                    iconActive: "wallet-active.png",
                    path: "/wallet",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "Notifications",
                    icon: "notification.png",
                    iconActive: "notification-active.png",
                    path: "/notifications",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "Recurring Games",
                    icon: "recurring.png",
                    iconActive: "recurring-active.png",
                    path: "/recurring-games",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "My Transactions",
                    icon: "my-transactions.png",
                    iconActive: "my-transactions-active.png",
                    path: "/my-transactions",
                    isActive: false,
                    redirect: false,
                },
            ]
        }
    },

    data() {
        return {
            enableManageCreditCards: 'yes',
            menusAccount: [
                {
                    title: "Personal Details",
                    icon: "personal.png",
                    iconActive: "personal-active.png",
                    path: "/personal-detail",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Numbers",
                    icon: "my-numbers.png",
                    iconActive: "my-numbers-active.png",
                    path: "/favourite",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "My Wallet",
                    icon: "wallet.png",
                    iconActive: "wallet-active.png",
                    path: "/wallet",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "Notifications",
                    icon: "notification.png",
                    iconActive: "notification-active.png",
                    path: "/notifications",
                    isActive: false,
                    redirect: false,
                },
                {
                    title: "Recurring Games",
                    icon: "recurring.png",
                    iconActive: "recurring-active.png",
                    path: "/recurring-games",
                    isActive: false,
                    redirect: true,
                },
                {
                    title: "My Transactions",
                    icon: "my-transactions.png",
                    iconActive: "my-transactions-active.png",
                    path: "/my-transactions",
                    isActive: false,
                    redirect: false,
                },
            ],
        };
    },

    methods: {
        handleClickAccountItem(path, isRedirect) {
            if (this.$store.state.is_app && isRedirect) {
                redirectPathApp(path);
            } else {
                this.routerPush(path)
            }
        },
        handleActiveMenu(path) {
            this.menusAccount.forEach((item) => {
                if (item.path === path) {
                    item.isActive = true;
                }
            });
        },
    },
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/sidebar/sidebar.scss" />
