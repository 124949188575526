<template>
    <div class="banner">
        <div class="contents">
            <h1>{{ getTranslatedText(title) }}</h1>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>

import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    props: {
        title: {
            type: String,
            default: "",
        }
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/common/banner/_banner.scss" />
