import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class CountriesService extends BaseService {
    getCountries() {
        return this.get(API_URL + "/api/common/countries").then(
            ({ data }) => data
        );
    }
}

export default new CountriesService();
