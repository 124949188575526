<template>
    <div class="section-notifications__payment-pending" :style="cssProps">
        <div class="section-notifications__payment-pending__body">
            <h1 class="title t-transform-cap">
                {{ getTranslatedText("transaction pending!") }}
            </h1>
            <p class="description t-fir-transform-cap">
                {{
                    getTranslatedText(
                        "Please wait will we check your transaction status, it will be only a few moments."
                    )
                }}
            </p>
            <ButtonCustom
                type="primary"
                text="View Balance"
                @onClickButton="onClickMovePage"
            />
        </div>
    </div>
</template>
<script>
import "@/assets/scss/mobile/notification/_notification.scss";

import { isStrEmpty } from "@/components/common/utils";
import ButtonCustom from "@/components/common/button";

import languageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [languageMixin],
    components: {
        ButtonCustom,
    },
    data() {
        return {
            cssProps: {
                backgroundImage: `url(${require("@/assets/images/notify/background-notify.png")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            },
            methodPayment: -1,
        };
    },
    created() {
        if (isStrEmpty(localStorage.getItem("methodPayment"))) {
            this.$router.push("/home");
        } else {
            this.methodPayment = parseInt(
                localStorage.getItem("methodPayment")
            );
            localStorage.removeItem("methodPayment");
        }
    },
    methods: {
        onClickMovePage() {
            // if (this.methodPayment === 1) {
            //     this.$router.push("/wallet");
            // } else {
            //     this.$router.push("/home");
            // }
        this.$router.push("/wallet");
        },
    },
};
</script>
