<template>
    <div class="main-email">
        <BannerCustom/>
        <div class="section-email-confirm">
            <img loading="lazy" src="@/assets/images/common/email-success.svg"/>
            <p class="title">{{ getTranslatedText('Email Confirmation') }}</p>
            <div class="sub-title">
                {{ getTranslatedText('We have sent an email to')}}
                <a class="sub-title__email" :href="'mailto:'+email">
                    {{ email }}
                </a>
                 {{ getTranslatedText('for email address verification. Once received, click the verify button to complete the verification process.')}}
            </div>
        </div>
    </div>
</template>
<script>
import BannerCustom from "@/components/common/banner";
import LanguageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [LanguageMixin],
    components: {BannerCustom},
    data() {
        return {
            email: ''
        }
    },
    created() {
        this.email = localStorage.getItem('email-confirmation')
        if(this.$route.path === '/email-confirmation' && !window.performance.navigation.TYPE_RELOADED) {
            this.$router.push({path: '/personal-detail'})
        }
    },
    methods: {},
};
</script>
<style scoped lang="scss" src="@/assets/scss/common/confirm/_confirm.scss" />
