<template>
    <div>
        <div class="delete_account t-transform-cap">
            <div class="delete_account__wrap">
                <div class="delete_account__wrap__title">
                        <p class="main-title t-transform-cap">{{ getTranslatedText("Update Profile") }}</p>
                </div>
                <hr class="line"/>
                <FormCommon
                    :formItem="formItem"
                    :dataForm="dataForm"
                    @update-form-data="updateFormData"
                    @onToggleShowPassword="onToggleShowPassword"
                    @onSubmitForm="onSubmitForm"
                >
                    <div class="update-profile" v-if="methodsChangeInfo === 'email'" slot="infoAccount">
                        <label>{{ getTranslatedText('My email') }}<span class="obligatory">*</span></label><br/>
                        <input class="form-control" v-model="dataSource.email" disabled>
                    </div>
                    <div class="update-profile" v-else slot="infoAccount">
                        <label>{{ getTranslatedText('Mobile number') }}<span class="obligatory">*</span>
                            <span class="guide-phone">{{ getTranslatedText('(Used for Log in)') }}</span>
                        </label><br/>
                        <div class="group-phone">
                            <div class="group-phone__flag">
                                 <div class="flag-group">
                                    <img loading="lazy"
                                        class="user-avatar"
                                        :src="require(`@/assets/images/flags/${dataSource.flag}`)"
                                        :alt="dataSource.flag"
                                    />
                                </div>
                                <div>{{ dataSource.calling_code }}</div>
                                <img loading="lazy"
                                    class="phone-icon"
                                    src="@/assets/images/personal-detail/dropdown-black.png"
                                />
                            </div>
                            <input class="form-control" v-model="dataSource.phone" disabled>
                        </div>
                    </div>
                    <div class="delete_account__btn-bottom" slot="button">
                        <ButtonCustom
                            classBtn="register-btn-continue"
                            typeBtn="submit"
                            type="primary"
                            text="save changes"
                            :isLoading="isLoadingButton"
                            :isDisabled="!dataForm.password || !dataForm.confirm_password || !dataSource.email && !dataSource.phone"
                        />
                        <ButtonCustom
                            classBtn="register-btn-close"
                            typeBtn="button"
                            type="default"
                            text="back"
                            @onClickButton="onClickGoBack"
                        />
                    </div>
                </FormCommon>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonCustom from "@/components/common/button";
import FormCommon from "@/components/common/form";
import languageMixin from "@/mixins/language/language-mixin";

export default {
    mixins: [languageMixin],
    components: {
        FormCommon,
        ButtonCustom,
    },
    props: {
        methodsChangeInfo: {
            type: String,
            default: ''
        },
        dataSource: {
            type: Object,
            default() {
                return {};
            },
        },
        isLoadingButton: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            phone: "",
            dataForm: {
                password: "",
                confirm_password: "",
            },
            formItem: [
                {
                    type: "slot",
                    nameSlot: "infoAccount",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    hideIconRq: false,
                    showIconEye: true,
                    name: "password",
                    required: true,
                    label: "enter password",
                    for: "input-password",
                    id: "input-password",
                    state: null,
                    textInvalid: "please enter the correct password",
                    textValid: "",
                },
                {
                    type: "input",
                    typeInput: "password",
                    typeShow: 'password',
                    hideIconRq: false,
                    showIconEye: true,
                    placeholder: "",
                    name: "confirm_password",
                    required: true,
                    label: "confirm password",
                    for: "input-confirmPassword",
                    id: "input-confirmPassword",
                    state: null,
                    textInvalid: "passwords are not the same",
                    textValid: "",
                },
            ],
            isPasswordMatching:false,
            confirmPasswordTouched: false,
        }
    },
    methods:{
        onToggleShowPassword(name) {
            let index = this.formItem.findIndex(x => x.name === name)
            if(index !== -1 ) {
                this.formItem[index].typeShow = this.formItem[index].typeShow === 'password' ? 'text' : 'password'
            }
        },
        updateFormData(item){
            let { value, name } = item;
            this.dataForm[name] = value;
        },
        onSubmitForm(){
            this.$emit("onSubmitForm", this.dataForm);
        },
        onClickGoBack() {
            this.$emit("onClickGoBack");
        },
    },
    watch: {
        dataForm: {
            handler(newValue, oldValue) {
                if(newValue.confirm_password){
                    this.confirmPasswordTouched = true;
                }
                if(this.confirmPasswordTouched){
                    this.isPasswordMatching = newValue.password === newValue.confirm_password;
                    this.formItem[2].state = newValue.password === newValue.confirm_password;
                }
            },
            deep: true,
        },
    },
}
</script>

<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_confirm-pass.scss"
/>
