import { render, staticRenderFns } from "./index.vue?vue&type=template&id=40fa16dc&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/common/bottom-sheet/bottom-sheet.scss?vue&type=style&index=0&id=40fa16dc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fa16dc",
  null
  
)

export default component.exports