import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

interface TransactInterFace {
    totalAmount: number;
    games: any[];
}

interface AddFavouriteInterFace {
    user_id: number;
    favourite_name: string;
    favourite_number: any[];
    bonus_ball: any[];
}

interface CheckLimtGAmeInterFace {
    user_id: string;
    quantity: string;
}

class GameService extends BaseService {
    getLottoGames(limit: string) {
        return this.get(API_URL + `/api/lotto/lottoGames?limit=${limit}`).then(
            ({ data }) => data
        );
    }

    transact(param: TransactInterFace) {
        return this.post(API_URL + `/api/lotto/transact`, param).then(
            ({ data }) => data
        );
    }

    addFavourite(param: AddFavouriteInterFace) {
        return this.post(API_URL + `/api/user/favourite-number`, param).then(
            ({ data }) => data
        );
    }

    listFavourite(user_id: string) {
        return this.post(
            API_URL + `/api/user/list-favourite-number`,
            user_id
        ).then(({ data }) => data);
    }

    checkLimitGame(param: CheckLimtGAmeInterFace) {
        return this.post(API_URL + `/api/user/limit-game-per-week`, param).then(
            ({ data }) => data
        );
    }

    getResult(id: string) {
        return this.get(API_URL + `/api/event/result/${id}`, "").then(
            ({ data }) => data
        );
    }

    getTimeNextLive() {
        return this.get(API_URL + `/api/event/next-live`).then(
            ({ data }) => data
        );
    }

    getReference(id: String) {
        return this.get(API_URL + `/api/user/mybet/?reference=${id}`).then(
            ({ data }) => data
        );
    }
}

export default new GameService();
