<template>
    <div class="how-to">
        <h1 class="how-to__head">
            {{ getTranslatedText('T&Cs') }}
        </h1>
        <div class="how-to__contents">
            <div v-if="isLoading" class="how-to__list-items__loading">
                <b-spinner />
            </div>
            <div v-if="!isLoading" class="how-to__list-items" :class="{active: isActiveContent}" id="abc">
                <div class="how-to__list-item">
                    {{ titleConditions }}
                    <img loading="lazy"
                        v-if="!isActiveContent"
                        @click="isActiveContent = !isActiveContent"
                        class="how-to__list-item__title-icon-down"
                        src="@/assets/images/static-pages/dropdown-how-to.png"
                    />
                    <img loading="lazy"
                        v-if="isActiveContent"
                        @click="isActiveContent = !isActiveContent"
                        class="how-to__list-item__title-icon-up"
                        src="@/assets/images/static-pages/icon-up-how-to.png"
                    />
                </div>
                <div
                    v-if="isActiveContent && termsConditions"
                    class="how-to__list-item__content"
                    v-html="termsConditions"
                />
            </div>
        </div>
    </div>
</template>
<script>
import languageMixin from '@/mixins/language/language-mixin';
import TermsConditions from "@/services/terms-conditions.service";
let scriptTag

export default {
    mixins: [languageMixin],
    components: {
    },
    data() {
        return {
            isActiveContent: true,
            termsConditions: '',
            titleConditions: '',
            isLoading: false
        };
    },
    created() {
      this.addScriptCanonical()
      this.getDataTermsConditions();
    },
    beforeDestroy(){
        function removeScript(script) {
            if (script && script.parentNode) {
                script.parentNode.removeChild(script);
            }
        }
        removeScript(scriptTag);
    },
    // metaInfo() {
    //     return {
    //         title: "Terms and Conditions: Learn How to Become an O! Millionaire Winner Today | World's First Green Raffle Draw",
    //         meta: [
    //             {
    //                 vmid: "description",
    //                 name: "description",
    //                 content: "Create an Account, Buy a Green Certificate for only AED 25 and Win over AED 100 million while supporting our tree plantation green initiative Oasis Park."
    //             }
    //         ]
    //     };
    // },
    methods: {
        addScriptCanonical() {
            scriptTag = document.createElement("link");
            scriptTag.rel = "canonical";
            scriptTag.href =`https://omillionaire.com/terms-and-conditions`
            document.head.appendChild(scriptTag);
        },
        async navigateCookie() {
            const parameters = this.$route.query;
            if(parameters?.isCookie) {
                this.$router.replace({'query': null});
                const element = await this.termsConditions ? document?.getElementById('cookie-id') : null;                
                element.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center', inline: 'center' });
            }          
        },
        getDataTermsConditions() {
            this.isLoading = true;
            const locale = localStorage.getItem("locale");
            if(locale) {
                TermsConditions.getTermsConditions(locale).then((res) => {
                    if (res.status === 200) {
                        this.termsConditions = res.data.body;
                        this.titleConditions = res.data.title;
                        this.navigateCookie()
                    }
                    this.isLoading = false;
                });
            } else {
                TermsConditions.getTermsConditions('en').then((res) => {
                    if (res.status === 200) {
                        this.termsConditions = res.data.body;
                        this.titleConditions = res.data.title;
                        this.navigateCookie()
                    }
                    this.isLoading = false;
                });
            }
        }
    },
};
</script>

<style scoped lang="scss" src="@/assets/scss/mobile/static-pages/howto.scss" />
