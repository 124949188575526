import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class VoucherService extends BaseService {
    addVoucherCode(data) {
        return this.post(API_URL + "/api/voucher/used", data).then(
            (response) => {
                return response.data;
            }
        );
    }
}

export default new VoucherService();
