<template>
    <div class="section-number-favourite section-ticket" :class="{ favoriteContainer:isAction }">
        <div v-if="isAction" class="header" :class="{ favoriteHeader:isAction }">
            {{ title }}
        </div>
        <div class="past-number-contents">
            <div class="infor">
                <div v-if="!isAction" class="header">
                    <p class="number">{{ getTranslatedText('My Numbers') }}</p>
                    <p>{{ getTranslatedText('RaffleID') }}</p>
                </div>
                <div v-if="isPast" class="section-number-favourite__item-desktop__past">
                    <div class="content-fav">
                        <div class="sub-content">
                        <div class="section-number-favourite__list-ball">
                            <div
                                :class="`section-number-favourite__item-ball ${renderClass(
                                    number,
                                    index
                                )}`"
                                v-for="(number, index) in balls"
                                :key="index"
                            >
                                <div class="section-number-favourite__box-ball">
                                    <span class="section-number-favourite__number">{{
                                        renderNumber(number)
                                    }}</span>
                                </div>
                            </div>
                            <img loading="lazy"
                                v-if="isShowIconArrow"
                                class="section-number-favourite__icon-show-ball"
                                src="@/assets/images/common/icon-chevron.svg"
                                alt="random-numbers"
                                @click="$emit('onClickIconArrow', indexItem)"
                            />
                        </div>
                        <div
                            class="section-number-favourite__item-desktop__past__des__date"
                        >
                            {{ getTranslatedText('draw date') }}: {{ dateBall }}
                            <span class="text-up">{{ getTranslatedText('GST') }}</span>
                        </div>
                        </div>
                        <div class="section-number-favourite__item-desktop__past__des">
                            <div
                                class="section-number-favourite__item-desktop__past__des__raffle"
                                v-if="raffle_id"
                            >
                                {{ raffle_id.slice(0, 4) + ' ' + raffle_id?.slice(4, 8) || getTranslatedText("Pending Generation")}}
                            </div>
                            <div class="item--double">
                                <img v-if="Number(dataNumber?.double_jackpot) > 0" src="@/assets/images/inspiring/double.svg" alt="">
                                <img v-if="Number(dataNumber?.secure_jackpot) > 0" src="@/assets/images/secure-green.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isPast" class="section-number-favourite__item-desktop">
                    <!-- <div class="section-number-favourite__list-ball__text">
                        <div class="section-number-favourite__title__desktop">
                            {{ title }}
                        </div>
                    </div> -->
                    <div class="section-number-favourite__list-ball">
                        <div
                            :class="`section-number-favourite__item-ball ${renderClass(
                                number,
                                index
                            )}`"
                            v-for="(number, index) in balls"
                            :key="index"
                        >
                            <div class="section-number-favourite__box-ball">
                                <span class="section-number-favourite__number">{{
                                    renderNumber(number)
                                }}</span>
                            </div>
                        </div>
                        <img loading="lazy"
                            v-if="isShowIconArrow"
                            class="section-number-favourite__icon-show-ball"
                            src="@/assets/images/common/icon-chevron.svg"
                            alt="random-numbers"
                            @click="$emit('onClickIconArrow', indexItem)"
                        />
                    </div>
                </div>
                <div
                    class="section-number-favourite__list-ball hide-desktop"
                    @click="$emit('onClickIconArrow', indexItem)"
                >
                    <div
                        :class="`section-number-favourite__item-ball ${renderClass(
                            number,
                            index
                        )}`"
                        v-for="(number, index) in balls"
                        :key="index"
                    >
                        <div class="section-number-favourite__box-ball">
                            <span class="section-number-favourite__number">{{
                                renderNumber(number)
                            }}</span>
                        </div>
                    </div>
                    <img loading="lazy"
                        v-if="isShowIconArrow"
                        class="section-number-favourite__icon-show-ball"
                        :class="{ isRtl: $store.state.isRtl }"
                        src="@/assets/images/common/icon-chevron.svg"
                        alt="random-numbers"
                    />
                </div>
                <template v-if="dateBall">
                    <p class="section-number-favourite__raffle" v-if="raffle_id">{{ getTranslatedText('Raffle ID') }} {{ raffle_id.slice(0, 4) + ' ' + raffle_id.slice(4, 8) || getTranslatedText("Pending Generation")}}</p>
                    <p class="section-number-favourite__date">{{ getTranslatedText('draw date') }}: {{ dateBall }}</p>
                </template>
            </div>
            <div class="action-fav" v-if="isAction">
                <p v-if="isAction" class="name-fav">{{ title }}</p>
                <div class="section-number-favourite__action">
                    <b-button
                        class="section-number-favourite__button section-number-favourite__action_edit t-transform-cap"
                        @click="$emit('onClickEdit', indexItem)"
                        v-if="isShowEdit"
                    >
                        <img loading="lazy"
                            src="@/assets/images/common/edit.svg"
                            alt="random-numbers"
                        />
                    </b-button>
                    <b-button
                        class="section-number-favourite__button section-number-favourite__action_delete t-transform-cap"
                        @click="$emit('onClickDelete', indexItem)"
                        v-if="isShowDelete"
                    >
                        <img loading="lazy"
                            src="@/assets/images/common/delete.svg"
                            alt="random-numbers"
                        />
                    </b-button>
                    <img class="play-button-dt" @click="handleQuickPick(balls)" src="@/assets/images/inspiring/icon-play.svg" alt="">
                </div>
            </div>
            <b-button
                class="play-button"
                @click="handleQuickPick(balls)"
                v-if="isAction"
            >
                {{ getTranslatedText('play')}}
            </b-button>
            <div v-else class="green-cer">
                <div class="inspiring__item--coupon_item">
                    <img v-if="showDownload" @click="downloadFile(link_certificate, isLoadingDownLoad)" src="@/assets/images/inspiring/icon-arrow-down.svg" alt="">
                    <img @click="handleFavorite()" src="@/assets/images/inspiring/icon-heart.svg" alt="">
                    <img @click="handleQuickPick(balls)" src="@/assets/images/inspiring/icon-play.svg" alt="">
                </div>
                <p class="download-text">{{ getTranslatedText("Download:") }}</p>
                <div v-if="showDownload" class="section-number-favourite__download" 
                    :disabled="isLoadingDownLoad && !link_certificate"
                    :class="{ disable: isLoadingDownLoad && !link_certificate }">
                    <b-spinner v-if="isLoading" variant="success" />
                    <b-spinner v-if="isLoadingDownLoad && !link_certificate" variant="success" />
                    <!-- <div class="section-number-favourite__download__icon">
                        <img loading="lazy" v-if="userInfo.phone !== '' && link_certificate" @click="!isLoading && downloadFileSendToLink(raffle_id, 'phone')" src="@/assets/images/common/icon-sms.png" /> -->
        <!--                    <img loading="lazy"  v-if="userInfo.email !== '' && link_certificate" @click="!isLoading && downloadFileSendToLink(raffle_id, 'email')" src="@/assets/images/common/icon-email.png" />-->
                    <!-- </div> -->
                    <div class="section-number-favourite__download__box-label" @click="downloadFile(link_certificate, isLoadingDownLoad)">
                        <!-- <img loading="lazy" src="@/assets/images/download.png" /> -->
                        <div class="section-number-favourite__download__label t-transform-cap">
                            {{ link_certificate ? getTranslatedText("green certificate") : getTranslatedText('Re-generate Certificate') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { renderClassBall, renderLocaleNumber, isArrayEmpty } from "../utils";

import languageMixin from "@/mixins/language/language-mixin";
// import favouriteService from '@/services/favourite.service';

export default {
    mixins: [languageMixin],
    components: {
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        dataNumber: {
            type: Object,
            default: () => {}
        },
        raffle_id: {
            type: String,
            default: "",
        },
        isPast: {
            type: Boolean,
            default: false,
        },
        isDownload: {
            type: Boolean,
            default: false,
        },
        balls: {
            type: Array,
            default: () => null,
        },
        isAction: {
            type: Boolean,
            default: true,
        },
        indexItem: {
            type: Number,
            default: -1,
        },
        maxiumBlue: {
            type: Number,
            default: -1,
        },
        maxiumGreen: {
            type: Number,
            default: -1,
        },
        isShowEdit: {
            type: Boolean,
            default: true,
        },
        isShowDelete: {
            type: Boolean,
            default: true,
        },
        minimumBall: {
            type: Number,
            default: 0,
        },
        dateBall: {
            type: String,
            default: "",
        },
        isShowIconArrow: {
            type: Boolean,
            default: false,
        },
        link_certificate: {
            type: String,
            default: ''
        },
        showDownload: {
            type: Boolean,
            default: true
        },
        userInfo: {
            type: Object,
            default: () => {}
        },
        isLoadingDownLoad: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        renderClass(number, index) {
            return renderClassBall(
                number,
                index,
                this.minimumBall,
                this.maxiumBlue,
                this.maxiumGreen
            );
        },
        showToast(message, type) {
            this.toastData.message = message;
            this.toastData.type = type;
        },

        downloadFile(url, isLoadingDownLoad) {
            if(url) {
                this.isLoading = true
                window.open(url, "_blank")
                setTimeout(() => {
                    this.isLoading = false
                }, 2000);
            } else {
                if(!isLoadingDownLoad) {
                    this.$emit("onReGenCerticate");
                }
            }
        },

        renderNumber(number) {
            return renderLocaleNumber(number);
        },

        // async downloadFileSendToLink(code, type) {
        //     try {
        //         this.isLoading = true
        //         const resp = type === 'email' ? await favouriteService.downloadCertificateSendToMail(code) : await favouriteService.downloadCertificateSendToPhone(code)
        //         if(resp.status === 200) {
        //             this.$emit(
        //                 "showToast",
        //                 this.getTranslatedText(resp.message),
        //                 "success"
        //             );
        //             this.isLoading = false
        //         } else {
        //             this.$emit(
        //                 "showToast",
        //                 this.getTranslatedText(resp.message),
        //                 "error"
        //             );
        //             this.isLoading = false
        //         }
        //     } catch (error) {
        //         this.isLoading = false
        //         console.log(error)
        //     }
        // }
        handleFavorite() {
           this.$emit("handleFavorite");
        },
        handleQuickPick(number){
            this.$router.push({name:'Tickets'});
            localStorage.setItem('number-quick-pick', JSON.stringify(number));
        },
    },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/common/number-favourite/_number-favourite.scss"
/>
