export default {
    methods: {
        getNextThursday() {
            let d = new Date();
            let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
            let dateUae = new Date(utc + (3600000*4))
            const dateCopy = new Date(dateUae.getTime());

            const nextThursday = new Date(
                dateCopy.setDate(
                dateCopy.getDate() + ((7 - dateCopy.getDay() + 4) % 7 || 7),
                ), 
            );

            if(dateUae.getDay() === 4 && dateUae.getTime() <= dateUae.setHours(20, 0, 0, 0)) {
                return dateUae.setHours(20, 0, 0, 0)
            } else {
                return nextThursday.setHours(20, 0, 0, 0);
            }
        },
    },
};
