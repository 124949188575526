/* eslint-disable max-len */
import { RouteConfig } from "vue-router";

import { metaRequireGuest } from "@/router/meta-constants";

import LoginPage from "@/views/mobile/authenticate/Login.vue";
import ForgotPasswordPage from "@/views/mobile/authenticate/ForgotPassword.vue"
import SignUpPage from "@/views/mobile/authenticate/SignUp.vue";

const authenticateRoutes: Array<RouteConfig> = [
    {
        path: "/login",
        name: "Login",
        meta: { isShowFooter: true, guest: true, isChangeBgColor: true },
        component: LoginPage,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        meta: { isShowFooter: true, guest: true, isChangeBgColor: true },
        component: ForgotPasswordPage,
    },
    {
        path: "/register",
        name: "Register",
        meta: { isShowFooter: true, guest: true, isChangeBgColor: true },
        component: SignUpPage,
    }
];

export default authenticateRoutes;
