import BaseService from "@/services/base.service";

const API_URL = process.env.VUE_APP_API_URL;

class BannerService extends BaseService {
    getBanners() {
        return this.get(API_URL + "/api/common/banners").then(
            ({ data }) => data
        )
    }
}

export default new BannerService();
